import React from "react"
import {Box, Dialog, DialogContent, DialogTitle, Grid, Typography} from "@mui/material";
import Icons from "../icons";
import {OrangeFilledButton, SimpleButton} from "../buttons";
import {connect} from "react-redux";
import {get_user_data, product_tour_action, product_tour_run} from "../../actions/actions";

function ProductTourDialog(props) {
    const {userProfile, product_tour_run, product_tour_action,get_user_data} = props;
    const [open, setOpen] = React.useState(userProfile.product_tour !== 0 ? false : true);
    const handleSkipTour=async()=>{
        await setOpen(false);
        await product_tour_action(2);
        await get_user_data()
    }
    return (
        <Dialog open={open}>
            <DialogTitle style={{background: '#E7F3FC'}}>
                <Grid container>
                    <Grid item xs={12} style={{textAlign: "center"}}>
                        <Box pt={1}>
                            {Icons.insightsWhiteLogo}
                        </Box>
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent>
                <Grid container alignItems={"center"}>
                    <Grid item xs={12} style={{textAlign: "center"}} >
                        <br/>
                        <Typography variant={"h1"} style={{fontWeight: 700, display: "inline-flex"}}>
                            Welcome {userProfile.first_name}!&ensp;{Icons.helloIcon}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} style={{textAlign: "center"}}>
                        <br/>
                        <Typography variant={"h3"}>
                            Get a deeper understanding of your customers with LXRInsights. Take this quick tour to understand the
                            interface better and have a smooth experience.
                        </Typography>
                    </Grid>
                </Grid>
                <br/>
                <br/>
                <Grid container>
                    <Grid item xs={12} style={{textAlign: "center"}}>
                        <OrangeFilledButton onClick={()=>{product_tour_run(true);setOpen(false);product_tour_action(1);}} variant={'contained'} style={{minWidth:200}}>Take me through</OrangeFilledButton>
                    </Grid>
                    <Grid item xs={12} style={{textAlign: "center"}}>
                        <br/>
                        <SimpleButton onClick={handleSkipTour} style={{textDecoration: "underline",fontSize:16}}>Skip Tour</SimpleButton>
                    </Grid>
                </Grid>
                <br/>
                <br/>
            </DialogContent>
        </Dialog>
    )
}

const mapStateToProps = (state) => ({
    userProfile: state.userProfile,
});
const mapDispatchToProps = (dispatch) => ({
    get_user_data: () => dispatch(get_user_data()),
    product_tour_run: (tourRunValue) => dispatch(product_tour_run(tourRunValue)),
    product_tour_action: (tour_action) => dispatch(product_tour_action(tour_action)),
});
export default connect(mapStateToProps, mapDispatchToProps)(ProductTourDialog);