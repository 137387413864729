import React, {useEffect} from "react";
import {Box, Button, Card, Drawer, Grid, Skeleton, Tooltip, Typography, useTheme} from "@mui/material";
import {Close, ContentCopyOutlined} from '@mui/icons-material';
import {border} from "../../assets/jss/commonStyles";
import GroupPageStyles from "../../assets/jss/groupPageStyles";
import Icons from "../icons";
import {connect} from "react-redux";
import {OrangeFilledButton} from "../buttons";
import {clearGPTContent, clearGPTMoreContent, generate_more_content} from "../../actions/actions";
import {GAEventTracker} from "../..";

const GPTSidebar=(props)=>{
    const {open,handleCancel,value,product,generateContentReducer,generateMoreContentReducer,generate_more_content,clearGPTMoreContent,count}=props;
    const [isCopied, setIsCopied] = React.useState(false);
    const [copiedIndex, setCopiedIndex] = React.useState(null);
    const theme=useTheme();
    const classes=GroupPageStyles();
    const [combinedResponse, setCombinedResponse] = React.useState([]);
    const generateMoreFlag =true;


    const copyToClipboard = (text,id) => {
      navigator.clipboard.writeText(text);  
      setCopiedIndex(id);
      setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 800);
    };
    const charLimit={'gads-headline':30,'meta-headline':25,'gads-description':90,'meta-description':27}

    useEffect(() => {
    }, [generateContentReducer]);

    useEffect(() => {
      if (generateMoreContentReducer.data && !generateMoreContentReducer.isLoading) {
        setCombinedResponse(prevResponse => [...prevResponse, ...generateMoreContentReducer.data]);
      }
    }, [generateMoreContentReducer]);

    useEffect(() => {
      if (!open) {
        setCombinedResponse([]);
        clearGPTMoreContent()
      }
    }, [open]);

    const handleGenerateMore=()=>{
        GAEventTracker('Generate More', 'Group');
        if(value === 'meta-description' || value ==='gads-description'){
          const data={
              'product_name':product.product,
              'channel':value==='meta-description'?'meta-ads':'google-ads',
              'count':count,
              'generate_more':generateMoreFlag
          }
          clearGPTMoreContent()
          generate_more_content(data,'description')
      }else{
          const data={
              'product_name':product.product,
              'channel':value==='gads-headline'?'google-ads':'meta-ads',
              'count':count,
              'generate_more':generateMoreFlag
          }
          clearGPTMoreContent()
          generate_more_content(data,'headlines') 
      }
    }
    
    return (
      <React.Fragment>
        <Drawer
          anchor={"right"}
          open={open}
          BackdropProps={{ style: { backgroundColor: "rgba(0, 0, 0, 0.1)" } }}
          classes={{ root:{ boxShadow:"none"} }}
        >
          <div style={{ width: 440 }} role={"presentation"}>
            <Box
              p={2.5}
              borderBottom={border}
              className={"flex"}
              justifyContent={"space-between"}
            >
              <Grid container>
                <Grid item xs={6}>
                  <Typography style={{ fontWeight: 700, paddingTop: "5px" }}>
                    Powered By ChatGPT
                  </Typography>
                </Grid>
                <Grid item xs={6} style={{ textAlign: "right" }}>
                  <Button onClick={handleCancel}>
                    <Close
                      style={{
                        color: theme.palette.primary.main,
                        fontSize: "24px",
                      }}
                    />
                  </Button>
                </Grid>
              </Grid>
            </Box>
            <Box p={2.5} pb={4}>
                <Typography variant="body1">
                  <span style={{ textTransform: "capitalize" }}>
                  {value==="gads-headline"?"Google Ads Headlines":null}
                  {value==="meta-headline"?"Meta Ads Headlines":null}
                  {value==="meta-description"?"Meta Ads Description":null}
                  {value==="gads-description"?"Google Ads Description":null}
                  </span>{" "}
                  for <span style={{ fontWeight: 700 }}>{product.product}</span>
                </Typography>
              <Box pt={1}>
                {Icons.chatgptIcon}
                  {!generateContentReducer.isLoading?
                    generateContentReducer.data && generateContentReducer.status ==='success' ? generateContentReducer.data.map((line, i) => (
                      <React.Fragment>
                      <Card className={classes.gptcard}>
                        <Tooltip title={copiedIndex === i && isCopied ?"Copied":null}>
                        <Button
                          style={{
                            textTransform: "none",
                            float: "right",
                            minWidth: "15px",
                          }}
                          onClick={() => {
                            copyToClipboard(line,i);
                          }}
                        >
                          <ContentCopyOutlined />
                        </Button>
                        </Tooltip>
                        <Box py={1}>
                          <Typography key={i}>{line}</Typography>
                        </Box>
                        <Typography style={{fontSize:'12px',float:'right'}}>{line.length > charLimit[value]?<><span style={{color:"#ef5350"}}>{line.length}</span>/{charLimit[value]}&nbsp;</>:<><span style={{color:"#4caf50"}}>{line.length}</span>/{charLimit[value]}&nbsp;</>}</Typography>
                      </Card>
                      </React.Fragment>
                  )): <Box p={2}><Typography>Unable to process your request. Please try again.</Typography></Box>
                  :<Box>
                    <br/>
                   <Skeleton variant="rectangular" height={250} />
                </Box>}
                        {combinedResponse ? 
                          combinedResponse.map((line, i) => (
                          <Card className={classes.gptcard}>
                            <Tooltip title={copiedIndex === i && isCopied ?"Copied":null}>
                            <Button
                              style={{
                                textTransform: "none",
                                float: "right",
                                minWidth: "15px",
                              }}
                              onClick={() => {
                                copyToClipboard(line,i);
                              }}
                            >
                              <ContentCopyOutlined />
                            </Button>
                            </Tooltip>
                            <Box py={1}>
                              <Typography key={i}>{line}</Typography>
                            </Box>
                            <Typography style={{fontSize:'12px',float:'right'}}>{line.length > charLimit[value]?<><span style={{color:"#ef5350"}}>{line.length}</span>/{charLimit[value]}&nbsp;</>:<><span style={{color:"#4caf50"}}>{line.length}</span>/{charLimit[value]}&nbsp;</>}</Typography>
                          </Card>
                        )):null}
                      {(!generateMoreContentReducer.data && generateMoreContentReducer.status==='failed')|generateMoreContentReducer.message==='failed' ?<Box p={2}><Typography>Unable to process your request. Please try again.</Typography></Box>:null}
                      {generateMoreContentReducer.isLoading && 
                      <Box>
                          <br/>
                        <Skeleton variant="rectangular" height={250} />
                      </Box>}
                      <br/>
                      {generateMoreContentReducer.isLoading | generateContentReducer.isLoading | generateContentReducer.message === 'failed' | generateContentReducer.status === 'failed'?null:
                       <Box style={{float:'right'}}>
                        <OrangeFilledButton onClick={()=>{handleGenerateMore()}}>Generate More&nbsp;</OrangeFilledButton>
                      </Box>}
              </Box>
            </Box>
          </div>
        </Drawer>
      </React.Fragment>
    );
}
const mapStateToProps = (state) => ({
  generateContentReducer:state.generateContentReducer,
  generateMoreContentReducer:state.generateMoreContentReducer
});
const mapDispatchToProps = (dispatch) => ({
  generate_more_content: (data, contentType) => dispatch(generate_more_content(data, contentType)),
  clearGPTContent:()=>dispatch(clearGPTContent()),
  clearGPTMoreContent:()=>dispatch(clearGPTMoreContent())
});

export default connect(mapStateToProps,mapDispatchToProps)(GPTSidebar);