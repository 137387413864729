import React, { useEffect } from "react";
import * as _ from "lodash";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import { border } from "../../assets/jss/commonStyles";
import NumericLabel from "react-pretty-numbers";
import clsx from "clsx";
// import {Cell, Pie, PieChart, ResponsiveContainer} from "recharts";
import CustomerGroup from "../getGroupDetails";
import { OrangeFilledButton } from "../buttons";
import { Link } from "react-router-dom";
import { GAEventTracker } from "../../index";
import { useTheme } from "@emotion/react";

export const CustomTableCell = withStyles((theme) => ({
  root: {
    fontSize: "0.875rem",
    borderBottom: border,
    borderRight: border,
    paddingLeft: 8,
    paddingRight: 8,
    minWidth: 104,
    wordBreak: "break-word",
  },
}))(TableCell);

export const CustomTableCell1 = withStyles((theme) => ({
  root: {
    fontSize: "0.875rem",
    borderBottom: border,
    borderRight: border,
    paddingLeft: 8,
    paddingRight: 8,
    minWidth: 180,
    wordBreak: "break-word",
  },
}))(TableCell);

function ViewDetailsButton(props) {
  const { link, gaAction } = props;

  return (
    <Link to={link} className={clsx("flex", "text-decoration-none")}>
      <OrangeFilledButton
        onClick={() => {
          GAEventTracker("Overview Table", "View More", gaAction);
        }}
        variant="contained"
        style={{ fontWeight: 700 }}
      >
        View more details
      </OrangeFilledButton>
    </Link>
  );
}

// function GroupPieChart(props) {
//     const ColorsA = ["#F58120", "#EEC4A8"];
//     const ColorsB = ["#00a3ff", "#EEC4A8"];
//     const ColorsC = ["#7c7c82", "#EEC4A8"];
//     const ColorsAll = ["#F58120", "#00a3ff", "#7c7c82"];
//     const {data, segment} = props;
//     const removeAllData = segment !== 'all' ? _.filter(data, function (o) {
//         return o.segment !== 'all'
//     }) : []
//     const groupData = segment !== 'all' ? _.map(_.filter(data, function (o) {
//         return o.segment === segment
//     }), (d) => ({
//         segment: d.segment,
//         customers: d.customers
//     })) : []

//     const finalGroupData = groupData[0]
//     const otherData = {}
//     otherData['customers'] = segment !== 'all' ? _.sumBy(_.filter(removeAllData, function (o) {
//         return o.segment !== segment
//     }), 'customers') : null
//     otherData['segment'] = 'other'
//     const chartData = [finalGroupData, otherData]
//     const totalRev = _.sumBy(chartData, 'customers')
//     const allSegChartData = _.map(
//       _.filter(data, function (o) {
//         return o.segment !== "all";
//       }),
//       (d) => ({
//         segment: d.segment,
//         customers: d.customers,
//       })
//     );
//     const pieChartData = segment === "all" ? allSegChartData : chartData;
//     return (
//         <Box>
//             <Typography variant={"h5"}>{CustomerGroup(segment)().name}</Typography>
//             {/* <ResponsiveContainer style={{width:'90%'}} height={120}>
//                 <PieChart style={{width:'100%'}}>
//                     <Pie
//                         data={pieChartData}
//                         dataKey={'customers'}
//                         outerRadius={52}
//                         fill={'#EEC4A8'}
//                     >
//                         {segment !== 'all' ?
//                             segment ==="a"?
//                                 data.map((entry, index) => <Cell key={`cell-${index}`} fill={ColorsA[index]}
//                                                              stroke={'none'}/>)
//                                 :segment ==="b"?
//                                     data.map((entry, index) => <Cell key={`cell-${index}`} fill={ColorsB[index]}
//                                                                 stroke={'none'}/>):
//                                     data.map((entry, index) => <Cell key={`cell-${index}`} fill={ColorsC[index]}
//                                                                 stroke={'none'}/>)
//                             :
//                             data.map((entry, index) => <Cell key={`cell-${index}`} fill={ColorsAll[index]}
//                                                              stroke={'none'}/>)

//                         }
//                     </Pie>
//                 </PieChart>
//             </ResponsiveContainer> */}
//         </Box>
//     )
// }

function OverviewTable(props) {
  const {
    overview_reducer,
    currency,
    country_code,
    tracking,
    max_window_height,
  } = props;
  useEffect(() => {}, [max_window_height]);
  const paramOptions1 = {
    commafy: true,
    justification: "L",
    wholenumber: "ceil",
    shortFormatMinValue: 10000,
  };
  const paramOptions2 = {
    wholenumber: "ceil",
    locales: "en-US",
    currency: true,
    currencyIndicator: currency,
    commafy: true,
    justification: "L",
  };
  const paramOptions3 = {
    commafy: true,
    justification: "L",
    precision: 1,
  };
  const paramOptions4 = {
    commafy: true,
    justification: "L",
    wholenumber: "ceil",
  };
  const all = _.find(overview_reducer.data, { segment: "all" })
    ? _.find(overview_reducer.data, { segment: "all" })
    : null;
  const a = _.find(overview_reducer.data, { segment: "a" })
    ? _.find(overview_reducer.data, { segment: "a" })
    : null;
  const b = _.find(overview_reducer.data, { segment: "b" })
    ? _.find(overview_reducer.data, { segment: "b" })
    : null;
  const c = _.find(overview_reducer.data, { segment: "c" })
    ? _.find(overview_reducer.data, { segment: "c" })
    : null;
  // const d = _.find(overview_reducer.data, {'segment': 'd'}) ? _.find(overview_reducer.data, {'segment': 'd'}) : null;
  const revenuePercent = (rev) => {
    return (
      <Typography variant={"h5"}>
        ({Math.round((rev * 100) / overview_reducer.totalRevenue)}%)
      </Typography>
    );
  };
  const totalCustomersFormat = (cus) => {
    return <NumericLabel params={paramOptions1}>{cus}</NumericLabel>;
  };
  const revenueFormat = (reve) => {
    return <NumericLabel params={paramOptions2}>{reve}</NumericLabel>;
  };
  const perCustomerFormat = (ord) => {
    return <NumericLabel params={paramOptions3}>{ord}</NumericLabel>;
  };
  const avgDaysFormat = (day) => {
    return (
      <>
        <NumericLabel params={paramOptions4}>{day}</NumericLabel> day(s)
      </>
    );
  };
  const secondOrderCondition = (seg) => {
    return (
      seg.days_gap_1 !== 0 &&
      seg.days_gap_1 !== "" &&
      seg.orders / seg.customers > 1.05
    );
  };

  const tableHead = [
    {
      param: (
        <Typography variant={"h5"} style={{ fontWeight: 700 }}>
          Key Insights
        </Typography>
      ),
      allvalue: (
        <Typography variant={"h5"} style={{ fontWeight: 700 }}>
          All Customers
        </Typography>
      ),
      avalue: (
        <Typography variant={"h5"} style={{ fontWeight: 700 }}>
          High Value Customers
        </Typography>
      ),
      bvalue: (
        <Typography variant={"h5"} style={{ fontWeight: 700 }}>
          Mid Value Customers
        </Typography>
      ),
      cvalue: (
        <Typography variant={"h5"} style={{ fontWeight: 700 }}>
          Low Value Customers
        </Typography>
      ),
    },
  ];
  const tableData = overview_reducer.data
    ? [
        // {
        //     param: '',
        //     allvalue: <GroupPieChart data={overview_reducer.data} segment={'all'}/>,
        //     avalue: <GroupPieChart data={overview_reducer.data} segment={'a'}/>,
        //     bvalue: <GroupPieChart data={overview_reducer.data} segment={'b'}/>,
        //     cvalue: <GroupPieChart data={overview_reducer.data} segment={'c'}/>,
        //     // dvalue: <GroupPieChart data={overview_reducer.data} segment={'d'}/>
        // },
        {
          param: country_code
            ? `Total Customers (${country_code})`
            : "Total Customers",
          allvalue: (
            <>
              <span> {totalCustomersFormat(all.customers)}</span>
              {/* <span style={{fontWeight:700}}>&nbsp;(100%)</span> */}
            </>
          ),
          avalue: (
            <>
              <span> {totalCustomersFormat(a.customers)}</span>
              <span> ({Math.round((a.customers * 100) / all.customers)}%)</span>
            </>
          ),
          bvalue: (
            <>
              <span> {totalCustomersFormat(b.customers)}</span>
              <span> ({Math.round((b.customers * 100) / all.customers)}%)</span>
            </>
          ),
          cvalue: (
            <>
              <span> {totalCustomersFormat(c.customers)}</span>
              <span> ({Math.round((c.customers * 100) / all.customers)}%)</span>
            </>
          ),
          show: true,
          // dvalue: totalCustomersFormat(d.customers)
        },
        // {
        //     param: 'Revenue Contribution',
        //     allvalue: revenuePercent(all.revenue),
        //     avalue: revenuePercent(a.revenue),
        //     bvalue: revenuePercent(b.revenue),
        //     cvalue: revenuePercent(c.revenue),
        //     // dvalue: revenuePercent(d.revenue)
        // },
        {
          param: "Total Orders",
          allvalue: (
            <>
              <span> {totalCustomersFormat(all.orders)}</span>
              {/* <span style={{fontWeight:700}}>&nbsp;(100%)</span> */}
            </>
          ),
          avalue: (
            <>
              <span> {totalCustomersFormat(a.orders)}</span>
              <span> ({Math.round((a.orders * 100) / all.orders)}%)</span>
            </>
          ),
          bvalue: (
            <>
              <span> {totalCustomersFormat(b.orders)}</span>
              <span> ({Math.round((b.orders * 100) / all.orders)}%)</span>
            </>
          ),
          cvalue: (
            <>
              <span> {totalCustomersFormat(c.orders)}</span>
              <span> ({Math.round((c.orders * 100) / all.orders)}%)</span>
            </>
          ),
          show: true,
          // dvalue: totalCustomersFormat(d.orders)
        },
        {
          param: "Total Revenue",
          allvalue: (
            <span style={{ display: "flex" }}>
              {revenueFormat(all.revenue)}
              {/* &nbsp;{revenuePercent(all.revenue)} */}
            </span>
          ),
          avalue: (
            <span style={{ display: "flex" }}>
              {revenueFormat(a.revenue)}
              &nbsp;{revenuePercent(a.revenue)}
            </span>
          ),
          bvalue: (
            <span style={{ display: "flex" }}>
              {revenueFormat(b.revenue)}
              &nbsp;{revenuePercent(b.revenue)}
            </span>
          ),
          cvalue: (
            <span style={{ display: "flex" }}>
              {revenueFormat(c.revenue)}
              &nbsp;{revenuePercent(c.revenue)}
            </span>
          ),
          show: true,
          // dvalue: revenueFormat(d.revenue)
        },
        {
          param: "Avg. Recency",
          allvalue: all.avg_recency ? avgDaysFormat(all.avg_recency) : "NA",
          avalue: a.avg_recency ? avgDaysFormat(a.avg_recency) : "NA",
          bvalue: b.avg_recency ? avgDaysFormat(b.avg_recency) : "NA",
          cvalue: c.avg_recency ? avgDaysFormat(c.avg_recency) : "NA",
          show: true,
          // dvalue: avgDaysFormat(d.avg_recency)
        },
        {
          param: "Avg. Order per Customer",
          allvalue: perCustomerFormat(all.orders / all.customers),
          avalue: perCustomerFormat(a.orders / a.customers),
          bvalue: perCustomerFormat(b.orders / b.customers),
          cvalue: perCustomerFormat(c.orders / c.customers),
          show: true,
          // dvalue: perCustomerFormat(d.orders / d.customers)
        },
        {
          param: "Avg. Revenue per Customer",
          allvalue: revenueFormat(all.revenue / all.customers),
          avalue: revenueFormat(a.revenue / a.customers),
          bvalue: revenueFormat(b.revenue / b.customers),
          cvalue: revenueFormat(c.revenue / c.customers),
          show: true,
          // dvalue: revenueFormat(d.revenue / d.customers)
        },
        {
          param: "Avg. Order Value",
          allvalue: revenueFormat(all.revenue / all.orders),
          avalue: revenueFormat(a.revenue / a.orders),
          bvalue: revenueFormat(b.revenue / b.orders),
          cvalue: revenueFormat(c.revenue / c.orders),
          show: true,
          // dvalue: revenueFormat(d.revenue / d.orders)
        },
        {
          param: "Avg. Days for First Order",
          allvalue: all.latency ? avgDaysFormat(all.latency / 24) : null,
          avalue: a.latency ? avgDaysFormat(a.latency / 24) : null,
          bvalue: b.latency ? avgDaysFormat(b.latency / 24) : null,
          cvalue: c.latency ? avgDaysFormat(c.latency / 24) : null,
          show: all.latency | a.latency | c.latency ? true : false,
          // dvalue: avgDaysFormat(d.latency / 24)
        },
        {
          param: "Avg. First Order Value",
          allvalue: all.aov_1 ? revenueFormat(all.aov_1) : "NA",
          avalue: a.aov_1 ? revenueFormat(a.aov_1) : "NA",
          bvalue: b.aov_1 ? revenueFormat(b.aov_1) : "NA",
          cvalue: c.aov_1 ? revenueFormat(c.aov_1) : "NA",
          show: true,
          // dvalue: revenueFormat(d.aov_1)
        },
        {
          param: "Avg. Days for Second Order",
          allvalue: secondOrderCondition(all)
            ? avgDaysFormat(all.days_gap_1)
            : "NA",
          avalue: secondOrderCondition(a) ? avgDaysFormat(a.days_gap_1) : "NA",
          bvalue: secondOrderCondition(b) ? avgDaysFormat(b.days_gap_1) : "NA",
          cvalue: secondOrderCondition(c) ? avgDaysFormat(c.days_gap_1) : "NA",
          show: true,
          // dvalue: secondOrderCondition(d) ? avgDaysFormat(d.days_gap_1) : 'NA'
        },
        {
          param: "Avg. Second Order Value",
          allvalue: secondOrderCondition(all) ? revenueFormat(all.aov_2) : "NA",
          avalue: secondOrderCondition(a) ? revenueFormat(a.aov_2) : "NA",
          bvalue: secondOrderCondition(b) ? revenueFormat(b.aov_2) : "NA",
          cvalue: secondOrderCondition(c) ? revenueFormat(c.aov_2) : "NA",
          show: true,
          // dvalue: secondOrderCondition(d) ? revenueFormat(d.aov_2) : 'NA'
        },
        {
          param: "Avg. Items per Order",
          allvalue: all.items ? (
            <NumericLabel params={paramOptions4}>
              {all.items / all.orders}
            </NumericLabel>
          ) : (
            "NA"
          ),
          avalue: a.items ? (
            <NumericLabel params={paramOptions4}>
              {a.items / a.orders}
            </NumericLabel>
          ) : (
            "NA"
          ),
          bvalue: b.items ? (
            <NumericLabel params={paramOptions4}>
              {b.items / b.orders}
            </NumericLabel>
          ) : (
            "NA"
          ),
          cvalue: c.items ? (
            <NumericLabel params={paramOptions4}>
              {c.items / c.orders}
            </NumericLabel>
          ) : (
            "NA"
          ),
          show: true,
          // dvalue: <NumericLabel params={paramOptions4}>{d.items / d.orders}</NumericLabel>
        },
        {
          param: "Avg. Monthly Visits",
          allvalue: all.avg_monthly_visits ? (
            <NumericLabel params={paramOptions4}>
              {all.avg_monthly_visits}
            </NumericLabel>
          ) : (
            "NA"
          ),
          avalue: a.avg_monthly_visits ? (
            <NumericLabel params={paramOptions4}>
              {a.avg_monthly_visits}
            </NumericLabel>
          ) : (
            "NA"
          ),
          bvalue: b.avg_monthly_visits ? (
            <NumericLabel params={paramOptions4}>
              {b.avg_monthly_visits}
            </NumericLabel>
          ) : (
            "NA"
          ),
          cvalue: c.avg_monthly_visits ? (
            <NumericLabel params={paramOptions4}>
              {c.avg_monthly_visits}
            </NumericLabel>
          ) : (
            "NA"
          ),
          show: true,
          // dvalue: <NumericLabel params={paramOptions4}>{d.avg_monthly_visits}</NumericLabel>
        },
        {
          param: "Top Product (by Revenue)",
          allvalue: all.top_product ? all.top_product : "NA",
          avalue: a.top_product ? a.top_product : "NA",
          bvalue: b.top_product ? b.top_product : "NA",
          cvalue: c.top_product ? c.top_product : "NA",
          show: true,
          // dvalue: d.top_product
        },
        {
          param: "",
          allvalue: (
            <ViewDetailsButton
              gaAction={CustomerGroup(all.segment)().name}
              link={"/customer-analytics/all"}
            />
          ),
          avalue: (
            <ViewDetailsButton
              gaAction={CustomerGroup(a.segment)().name}
              link={"/customer-analytics/a"}
            />
          ),
          bvalue: (
            <ViewDetailsButton
              gaAction={CustomerGroup(b.segment)().name}
              link={"/customer-analytics/b"}
            />
          ),
          cvalue: (
            <ViewDetailsButton
              gaAction={CustomerGroup(c.segment)().name}
              link={"/customer-analytics/c"}
            />
          ),
          show: true,
          // dvalue: <ViewDetailsButton link={'/groups/d'}/>
        },
      ]
    : [];

  const theme = useTheme();
  return (
    <Box p={1} className={clsx("flex")}>
      <TableContainer
        component={Paper}
        style={{ maxHeight: max_window_height - 180 }}
      >
        <Table
          aria-label="sticky table"
          style={{ tableLayout: "fixed" }}
          stickyHeader
        >
          <TableHead>
            {tableHead.map((row, index) => (
              <TableRow style={{ background: theme.palette.primary.main }}>
                <CustomTableCell1
                  key={index}
                  style={{
                    background: theme.palette.primary.main,
                    color: theme.palette.common.white,
                  }}
                >
                  {row.param}
                </CustomTableCell1>
                <CustomTableCell
                  style={{
                    background: theme.palette.segment.high,
                    color: theme.palette.common.white,
                  }}
                >
                  {row.avalue}
                </CustomTableCell>
                <CustomTableCell
                  style={{
                    background: theme.palette.segment.mid,
                    color: theme.palette.common.white,
                  }}
                >
                  {row.bvalue}
                </CustomTableCell>
                <CustomTableCell
                  style={{
                    background: theme.palette.segment.low,
                    color: theme.palette.common.white,
                  }}
                >
                  {row.cvalue}
                </CustomTableCell>
                <CustomTableCell
                  style={{
                    background: theme.palette.primary.main,
                    color: theme.palette.common.white,
                  }}
                >
                  {row.allvalue}
                </CustomTableCell>
              </TableRow>
            ))}
          </TableHead>
          <TableBody style={{ height: "60%", overflowY: "scroll" }}>
            {tableData.map((row, index) =>
              tracking &&
              row.param === "Avg. Monthly Visits" &&
              row.param === " " ? null : row.show ? (
                <TableRow key={index}>
                  <CustomTableCell1>{row.param}</CustomTableCell1>
                  <CustomTableCell>{row.avalue}</CustomTableCell>
                  <CustomTableCell>{row.bvalue}</CustomTableCell>
                  <CustomTableCell>{row.cvalue}</CustomTableCell>
                  <CustomTableCell>{row.allvalue}</CustomTableCell>
                  {/*<CustomTableCell>{row.dvalue}</CustomTableCell>*/}
                </TableRow>
              ) : null
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

export default OverviewTable;
