import React from "react";
import { Box, Grid, Typography, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import Icons from "./icons";
import { OrangeFilledButton } from "./buttons";
import { GAEventTracker } from "..";
import { OpenInNew } from "@mui/icons-material";

// function HomepageFooter() {
//     const theme = useTheme();
//     return (
//         <Box style={{ backgroundColor: "#F2F6F9", textAlign: "center" }} p={2} pt={3} >
//             <Box>
//                 <Box>
//                     <Typography style={{ fontSize: "1rem", fontWeight: 700 }}>Looking for additional full-service e-commerce solutions? <a target={"_blank"} rel="noopener noreferrer" style={{ color: "#00B2FF", textDecoration: "none" }}
//                         href={'https://www.netelixir.com/'}>Visit NetElixir</a> redefine your search marketing strategy.</Typography><br/>
//                 </Box>
//                 {Icons.gdprLogo}
//                 <Grid container className="footSec" style={{ backgroundColor: "#F2F6F9" }}>
//                     <Grid item xs={7} id="copyRight">
//                         <Grid container spacing={2}>
//                             <Grid item xs={12}>
//                                 <Box className="Cpyrght">
//                                     <Typography component={'span'} color={'primary'}
//                                         style={{ color: "#666", fontSize: "0.875rem", fontFamily: '"proxima-nova", sans-serif' }}>
//                                         {'©'}&nbsp;{new Date().getFullYear()}&nbsp;
//                                         <a target={"_blank"} rel="noopener noreferrer" className={'text-decoration-none'}
//                                             href={'https://www.netelixir.com/'}
//                                             style={{ color: "#666" }}>NetElixir</a>. All Rights Reserved.
//                                     </Typography>
//                                     <Typography component={'span'}>&emsp;</Typography>
//                                     <Typography component={'span'} color={'primary'} style={{
//                                         color: "#666",
//                                         fontSize: "0.875rem",
//                                         fontFamily: '"proxima-nova", sans-serif'
//                                     }}>
//                                         <a href={'mailto:support@lxrinsights.com'} style={{ textDecoration: 'none', color: "#666" }}>support@lxrinsights.com</a>
//                                     </Typography>
//                                     <Typography component={'span'}>&emsp;&emsp;</Typography>
//                                     <Typography component={'span'} color={'primary'}
//                                         style={{ color: "#666", fontSize: "0.875rem", fontFamily: '"proxima-nova", sans-serif' }}>
//                                         <Link to={'/contact_us'} onClick={() => window.scrollTo(0, 0)}
//                                             style={{ color: "#666" }}
//                                             className={'text-decoration-none'}>Contact Us</Link>
//                                     </Typography>
//                                 </Box>
//                             </Grid>
//                         </Grid>
//                     </Grid>
//                     <Grid item xs={5} id="copyRight">
//                         <Box p={2} id="prvPly" style={{ textAlign: "right", float: "right", padding: 0 }}>
//                             <Grid container spacing={2} className="prvContent">
//                                 <Grid item xs={12} className="Cpyrght">
//                                     <Typography component={'span'} color={'primary'}
//                                         style={{ color: "#666", fontSize: "0.875rem", fontFamily: '"proxima-nova", sans-serif' }}>
//                                         <Link to={'/do-not-sell'} onClick={() => window.scrollTo(0, 0)}
//                                             style={{ color: "#666" }}
//                                             className={'text-decoration-none'}>Do not sell my personal information</Link>
//                                     </Typography>
//                                     <Typography component={'span'}>&emsp;&emsp;</Typography>
//                                     <Typography component={'span'} color={'primary'}
//                                         style={{ color: "#666", fontSize: "0.875rem", fontFamily: '"proxima-nova", sans-serif' }}>
//                                         <Link to={'/privacy-policy'} onClick={() => window.scrollTo(0, 0)}
//                                             style={{ color: "#666" }}
//                                             className={'text-decoration-none'}>Privacy Policy</Link>
//                                     </Typography>
//                                     <Typography component={'span'}>&emsp;&emsp;</Typography>
//                                     <Typography color={'primary'} component={'span'}
//                                         style={{ color: "#666", fontSize: "0.875rem", fontFamily: '"proxima-nova", sans-serif' }}>
//                                         <Link to={'/terms'} onClick={() => window.scrollTo(0, 0)}
//                                             style={{ color: "#666" }}
//                                             className={'text-decoration-none'}>Terms and Conditions</Link>
//                                     </Typography>
//                                 </Grid>
//                             </Grid>
//                         </Box>
//                     </Grid>
//                 </Grid>
//             </Box>
//         </Box>
//     )
// }

function HomepageFooter(props) {
  const { classes, handleDemoOpen, scorecard } = props;
  const theme = useTheme();
  return (
    <Box
      style={{
        backgroundColor: scorecard
          ? theme.palette.common.white
          : "rgb(242, 246, 249)",
      }}
    >
      <Box style={{ marginBottom: "-80px", position: "relative" }}>
        {scorecard ? (
          <Grid container>
            <Grid item xs={1.5}></Grid>
            <Grid item xs={9}>
              <Box className={classes.footerDesktop}>
                <Box>
                  <Box
                    className={"flex"}
                    style={{
                      height: "176px",
                      alignItems: "center",
                      textAlign: "left",
                      justifyContent: "center",
                    }}
                  >
                    <Typography
                      style={{
                        color: "#222222",
                        fontSize: "2rem",
                        fontWeight: 700,
                        fontFamily: '"proxima-nova", sans-serif',
                        paddingRight: "10px",
                      }}
                    >
                      Unlock insights and start
                      <br /> optimizing business performance.
                    </Typography>
                    <OrangeFilledButton
                      id="mktBtn"
                      className={classes.proximaNovaFont}
                      style={{
                        borderRadius: "35px",
                        border: "2px solid #F58120",
                        width: "270px",
                        height: "48px",
                      }}
                      variant="contained"
                    >
                      <a
                        href={"https://scorecard.lxrinsights.com/"}
                        target="_blank"
                        style={{ textDecoration: "none", color: "#ffffff" }}
                      >
                        Get Your Free Scorecard
                      </a>
                    </OrangeFilledButton>
                  </Box>
                  <Box
                    style={{
                      backgroundColor: "#EDEDED",
                      borderRadius: "0px 0px 20px 20px",
                      height: "74px",
                      alignItems: "center",
                      textAlign: "center",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Typography
                      style={{
                        fontSize: "1.25rem",
                        color: "#333",
                        fontFamily: '"proxima-nova", sans-serif',
                      }}
                    >
                      Looking for additional full-service e-commerce solutions ?{" "}
                      <span
                        style={{
                          color: theme.palette.common.cyan,
                          fontWeight: 600,
                          fontFamily: '"proxima-nova", sans-serif',
                        }}
                      >
                        Visit&nbsp;
                        <a
                          style={{
                            color: theme.palette.common.cyan,
                            fontWeight: 600,
                            fontFamily: '"proxima-nova", sans-serif',
                          }}
                          target={"_blank"}
                          rel="noopener noreferrer"
                          href={"https://www.netelixir.com/"}
                        >
                          NetElixir
                        </a>{" "}
                      </span>
                    </Typography>
                    &nbsp;
                    <span>
                      <a
                        target={"_blank"}
                        rel="noopener noreferrer"
                        style={{
                          textDecoration: "none",
                        }}
                        href={"https://www.netelixir.com/"}
                      >
                        <OpenInNew
                          style={{
                            color: theme.palette.secondary.light,
                            fontSize: "25px",
                          }}
                        />
                      </a>
                    </span>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={1.5}></Grid>
          </Grid>
        ) : (
          <Grid container>
            <Grid item xs={1.8}></Grid>
            <Grid item xs={8}>
              <Box className={classes.footerDesktop}>
                <Box>
                  <Box
                    className={"flex"}
                    style={{
                      height: "176px",
                      alignItems: "center",
                      textAlign: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Typography
                      style={{
                        color: "#222222",
                        fontSize: "2.1875rem",
                        fontWeight: 700,
                        fontFamily: '"proxima-nova", sans-serif',
                        paddingRight: "20px",
                      }}
                    >
                      Get Started With LXRInsights
                    </Typography>
                    <OrangeFilledButton
                      id="mktBtn"
                      className={classes.proximaNovaFont}
                      style={{
                        borderRadius: "35px",
                        border: "2px solid #F58120",
                        width: "170px",
                        height: "48px",
                      }}
                      onClick={() => {
                        handleDemoOpen();
                        GAEventTracker("Home Page", "Request Free Demo");
                      }}
                      variant="contained"
                    >
                      Try For Free
                    </OrangeFilledButton>
                  </Box>
                  <Box
                    style={{
                      backgroundColor: "#EDEDED",
                      borderRadius: "0px 0px 20px 20px",
                      height: "74px",
                      alignItems: "center",
                      textAlign: "center",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Typography
                      style={{
                        fontSize: "1.25rem",
                        color: "#333",
                        fontFamily: '"proxima-nova", sans-serif',
                      }}
                    >
                      Looking for additional full-service e-commerce solutions ?{" "}
                      <span
                        style={{
                          color: theme.palette.common.cyan,
                          fontWeight: 600,
                          fontFamily: '"proxima-nova", sans-serif',
                        }}
                      >
                        Visit&nbsp;
                        <a
                          style={{
                            color: theme.palette.common.cyan,
                            fontWeight: 600,
                            fontFamily: '"proxima-nova", sans-serif',
                          }}
                          target={"_blank"}
                          rel="noopener noreferrer"
                          href={"https://www.netelixir.com/"}
                        >
                          NetElixir
                        </a>{" "}
                      </span>
                    </Typography>
                    &nbsp;
                    <span>
                      <a
                        target={"_blank"}
                        rel="noopener noreferrer"
                        style={{
                          textDecoration: "none",
                        }}
                        href={"https://www.netelixir.com/"}
                      >
                        <OpenInNew
                          style={{
                            color: theme.palette.secondary.light,
                            fontSize: "25px",
                          }}
                        />
                      </a>
                    </span>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={2.2}></Grid>
          </Grid>
        )}
      </Box>
      <Box style={{ backgroundColor: "#192136", height: "400px" }}>
        <Box style={{ paddingTop: "120px" }}>
          <Grid container>
            <Grid
              item
              xs={3}
              style={{ textAlign: "right", paddingRight: "30px" }}
            >
              {Icons.LXRInsightsWhiteLogo}
              <br />
              <br />
              {/* {scorecard ? null : Icons.poweredByNetelixirLogo} */}
            </Grid>
            <Grid item xs={6.5}>
              <Grid container>
                <Grid item xs={6}>
                  <Box>
                    <Typography component={"span"}>
                      <Link
                        to={"/privacy-policy"}
                        onClick={() => window.scrollTo(0, 0)}
                        className={classes.hoverLink}
                      >
                        Privacy Policy
                      </Link>
                    </Typography>
                    <br />
                    <br />
                    <Typography component={"span"}>
                      <Link
                        to={"/terms"}
                        onClick={() => window.scrollTo(0, 0)}
                        className={classes.hoverLink}
                      >
                        Terms and Conditions
                      </Link>
                    </Typography>
                    <br />
                    <br />
                    <Typography component={"span"}>
                      <Link
                        to={"/do-not-sell"}
                        onClick={() => window.scrollTo(0, 0)}
                        className={classes.hoverLink}
                      >
                        Do not sell my personal information
                      </Link>
                    </Typography>
                    <br />
                    <br />
                    <Typography component={"span"}>
                      <Link
                        to={"/scorecard"}
                        onClick={() => window.scrollTo(0, 0)}
                        className={classes.hoverLink}
                      >
                        Scorecard
                      </Link>
                    </Typography>
                    <br />
                    <br />
                    <Typography component={"span"}>
                      <Link
                        to={"/contact_us"}
                        onClick={() => window.scrollTo(0, 0)}
                        className={classes.hoverLink}
                      >
                        Contact Us
                      </Link>
                    </Typography>
                    <br />
                    <br />
                    <Typography component={"span"}>
                      <a
                        href={"mailto:support@lxrinsights.com"}
                        style={{
                          textDecoration: "none",
                          color: theme.palette.secondary.light,
                          fontSize: "1.125rem",
                        }}
                      >
                        support@lxrinsights.com
                      </a>
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={1.5} />
                <Grid item xs={4.5}>
                  <Box className={"flex"}>
                    <span style={{ paddingRight: "30px" }}>{Icons.gdpr}</span>
                    {Icons.ccpa}
                  </Box>
                  <br />
                  <Typography component={"span"}>
                    <Link
                      to={"/gdpr-ccpa-compliance"}
                      onClick={() => window.scrollTo(0, 0)}
                      className={classes.hoverLink}
                    >
                      Read More about GDPR & CCPA
                    </Link>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={2.5} />
          </Grid>
        </Box>
      </Box>
      <Box
        style={{
          height: "60px",
          alignItems: "center",
          textAlign: "center",
          backgroundColor: theme.palette.common.white,
        }}
      >
        <Typography
          style={{ paddingTop: "15px", fontWeight: 600, color: "#222" }}
        >
          {"©"}&nbsp;{new Date().getFullYear()}&nbsp;&nbsp;|&nbsp;&nbsp;
          <a
            target={"_blank"}
            rel="noopener noreferrer"
            href={"https://www.netelixir.com/"}
            style={{ color: "#222", fontFamily: '"proxima-nova", sans-serif' }}
          >
            NetElixir
          </a>
        </Typography>
      </Box>
    </Box>
  );
}

export default HomepageFooter;
