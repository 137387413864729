import { useTheme } from "@emotion/react";
import { Box, Grid, Typography } from "@mui/material";
import { capitalCase } from "change-case";
import React from "react";
import NumericLabel from "react-pretty-numbers";
import { Link } from "react-router-dom/cjs/react-router-dom";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Pie,
  PieChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";
import channelGraphDummyData from "../assets/files/dummyData/channelGraphData.json";
import {
  default as deviceGraphDummyData,
  default as piechartGraphDummyData,
} from "../assets/files/dummyData/deviceGraphData.json";
import { OrangeFilledButton } from "./buttons";
import { CustomXAxisTick, CustomYAxisTick } from "./groupPage/graphs/hourGraph";
import {
  connectionsTrackingDataStatusURL,
  settingConnectionsURL,
} from "./sidebar";

const ChannelDummyComponent = ({ param }) => {
  const theme = useTheme();
  return (
    <Box
      style={{
        position: "relative",
        overflow: "hidden",
        userSelect: "none",
      }}
    >
      <Box style={{ filter: "blur(4px)" }}>
        <ResponsiveContainer
          width={"98%"}
          height={300}
          style={{ pointerEvents: "none" }}
        >
          <BarChart
            layout={"vertical"}
            data={channelGraphDummyData}
            margin={{ top: 30, left: 55 }}
          >
            <CartesianGrid strokeDasharray="7 7" horizontal={false} />
            <XAxis
              tick={<CustomXAxisTick />}
              type={"number"}
              stroke={"#E3E6F4"}
            />
            <YAxis
              tick={<CustomYAxisTick />}
              dataKey={"channel"}
              type={"category"}
              stroke={"#E3E6F4"}
            />
            <Bar
              isAnimationActive={false}
              dataKey={"orders"}
              fill={theme.palette.primary.main}
              fillOpacity={0.25}
              barSize={10}
            />
          </BarChart>
        </ResponsiveContainer>
      </Box>
      <Box
        style={{
          position: "absolute",
          top: "50%",
          left: "45%",
          transform: "translate(-50%, -50%)",
          textAlign: "center",
          color: "#ffffff",
          fontSize: "20px",
          zIndex: 2,
        }}
      >
        <Typography
          style={{
            color: theme.palette.primary.main,
            textAlign: "center",
            paddingTop: "40px",
          }}
          variant="body1"
        >
          Data in this section is not available{" "}
          {param === "dataStatus" ? null : "for the current tracking method."}
        </Typography>
        <br />
        <Typography
          style={{ color: theme.palette.primary.main, textAlign: "center" }}
          variant="body1"
        >
          <Link
            to={
              param === "dataStatus"
                ? connectionsTrackingDataStatusURL
                : settingConnectionsURL
            }
            style={{ textDecoration: "none" }}
          >
            <OrangeFilledButton style={{ borderRadius: 0 }}>
              &nbsp;
              {param === "dataStatus" ? "View Data Status" : "Add LXRTracking"}
              &nbsp;
            </OrangeFilledButton>
          </Link>
          &nbsp; for more details to show.
        </Typography>
      </Box>
    </Box>
  );
};

export const DeviceDummyComponent = ({ param }) => {
  const theme = useTheme();
  return (
    <Box
      style={{
        position: "relative",
        overflow: "hidden",
        userSelect: "none",
      }}
    >
      <Box style={{ filter: "blur(4px)" }}>
        <ResponsiveContainer
          width={"98%"}
          height={300}
          style={{ pointerEvents: "none" }}
        >
          <BarChart
            layout={"vertical"}
            data={deviceGraphDummyData}
            margin={{ top: 30, left: 55 }}
          >
            <CartesianGrid strokeDasharray="7 7" horizontal={false} />
            <XAxis
              tick={<CustomXAxisTick />}
              type={"number"}
              stroke={"#E3E6F4"}
            />
            <YAxis
              tick={<CustomYAxisTick />}
              dataKey={"channel"}
              type={"category"}
              stroke={"#E3E6F4"}
            />
            <Bar
              isAnimationActive={false}
              dataKey={"orders"}
              fill={theme.palette.primary.main}
              fillOpacity={0.25}
              barSize={10}
            />
          </BarChart>
        </ResponsiveContainer>
      </Box>
      <Box
        style={{
          position: "absolute",
          top: "50%",
          left: "45%",
          transform: "translate(-50%, -50%)",
          textAlign: "center",
          color: "#ffffff",
          fontSize: "20px",
          zIndex: 2,
        }}
      >
        <Typography
          style={{
            color: theme.palette.primary.main,
            textAlign: "center",
            paddingTop: "40px",
          }}
          variant="body1"
        >
          Data in this section is not available{" "}
          {param === "dataStatus" ? null : "for the current tracking method."}
        </Typography>
        <br />
        <Typography
          style={{ color: theme.palette.primary.main, textAlign: "center" }}
          variant="body1"
        >
          <Link
            to={
              param === "dataStatus"
                ? connectionsTrackingDataStatusURL
                : settingConnectionsURL
            }
            style={{ textDecoration: "none" }}
          >
            <OrangeFilledButton style={{ borderRadius: 0 }}>
              &nbsp;
              {param === "dataStatus" ? "View Data Status" : "Add LXRTracking"}
              &nbsp;
            </OrangeFilledButton>
          </Link>
          &nbsp; for more details to show.
        </Typography>
      </Box>
    </Box>
  );
};

export const PieChartDummyComponent = ({ property, currency, param }) => {
  const COLORS = ["#4E4E4E", "#747474", "#A4A4A4", "#FFDBC2"];
  const theme = useTheme();
  const revenueParams = {
    wholenumber: "ceil",
    locales: "en-US",
    currency: true,
    currencyIndicator: currency,
    commafy: true,
    justification: "L",
  };
  const orderParams = {
    commafy: true,
    wholenumber: "ceil",
    // shortFormat: true,
    shortFormatMinValue: 10000,
    justification: "L",
  };
  return (
    <Box
      style={{
        position: "relative",
        overflow: "hidden",
        userSelect: "none",
      }}
    >
      <Box style={{ filter: "blur(4px)", display: "flex" }}>
        <ResponsiveContainer
          width={"60%"}
          height={200}
          style={{ textAlign: "left" }}
        >
          <PieChart>
            <Pie
              isAnimationActive={false}
              data={piechartGraphDummyData}
              outerRadius={90}
              dataKey={"orders"}
              fill={"8884d8"}
              fillOpacity={0.3}
            >
              {piechartGraphDummyData.map((entry, index) => (
                <Cell
                  stroke={"none"}
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                />
              ))}
            </Pie>
          </PieChart>
        </ResponsiveContainer>
        {piechartGraphDummyData.length !== 0 ? (
          <Grid item md={3}>
            {piechartGraphDummyData.map((device, index) => (
              <Box
                className={"flex"}
                pb={3}
                style={{ alignItems: "center" }}
                key={index}
              >
                <div>
                  <Box
                    width={22}
                    height={20}
                    style={{ backgroundColor: COLORS[index], opacity: 0.3 }}
                  />
                </div>
                &emsp;
                <div>
                  <Typography
                    variant={"h4"}
                    style={{
                      color: theme.palette.common.black,
                      lineHeight: "19px",
                    }}
                  >
                    {capitalCase(device.device)}
                  </Typography>
                  <Box className={"flex"}>
                    <Typography
                      style={{
                        color: theme.palette.common.black,
                        opacity: 0.5,
                        lineHeight: "19px",
                      }}
                    >
                      {capitalCase("orders")} :&nbsp;
                    </Typography>
                    <Typography
                      style={{
                        color: theme.palette.common.black,
                        lineHeight: "19px",
                      }}
                    >
                      {property === "revenue" ? (
                        <NumericLabel params={revenueParams}>
                          {device["orders"]}
                        </NumericLabel>
                      ) : (
                        <NumericLabel params={orderParams}>
                          {device["orders"]}
                        </NumericLabel>
                      )}
                      ({Math.round((device["orders"] * 100) / 76)}%)
                    </Typography>
                  </Box>
                </div>
              </Box>
            ))}
          </Grid>
        ) : null}
      </Box>
      <Box
        style={{
          position: "absolute",
          top: "50%",
          left: "45%",
          transform: "translate(-50%, -50%)",
          textAlign: "center",
          color: "#ffffff",
          fontSize: "20px",
          zIndex: 2,
        }}
      >
        <Typography
          style={{
            color: theme.palette.primary.main,
            textAlign: "center",
            paddingTop: "40px",
          }}
          variant="body1"
        >
          Data in this section is not available{" "}
          {param === "dataStatus" ? null : "for the current tracking method."}
        </Typography>
        <br />
        <Typography
          style={{ color: theme.palette.primary.main, textAlign: "center" }}
          variant="body1"
        >
          <Link
            to={
              param === "dataStatus"
                ? connectionsTrackingDataStatusURL
                : settingConnectionsURL
            }
            style={{ textDecoration: "none" }}
          >
            <OrangeFilledButton style={{ borderRadius: 0 }}>
              &nbsp;
              {param === "dataStatus" ? "View Data Status" : "Add LXRTracking"}
              &nbsp;
            </OrangeFilledButton>
          </Link>
          &nbsp; for more details to show.
        </Typography>
      </Box>
    </Box>
  );
};

export default ChannelDummyComponent;
