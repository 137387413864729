import React from 'react';
import {Box, Typography, useTheme} from "@mui/material";
import moment from "moment"
import NumericLabel from "react-pretty-numbers"

function CustomTooltips({active, payload, label}) {
    const theme = useTheme();
    const paramOptions1={
        commafy: true,
        justification: 'L',
    }
    if (active) {
        const date = new Date(payload[0].payload.year, payload[0].payload.month - 1)
        return (
            <div>
                <Box border={'1px solid transparent'} borderRadius="8px">
                    <div style={{
                        backgroundColor: '#222',
                        padding:8,
                        color: theme.palette.common.white,
                        borderTopLeftRadius: 8,
                        borderTopRightRadius: 8
                    }}>
                        <Typography variant={"body2"}>{moment(date).format('MMM, YYYY')}</Typography>
                    </div>
                    <Box p={1} color={theme.palette.common.white}
                         style={{backgroundColor: '#000', borderBottomLeftRadius: 8, borderBottomRightRadius: 8}}>
                        <Typography variant={"body2"}><NumericLabel params={paramOptions1}>{payload[0].payload.orders}</NumericLabel></Typography>
                    </Box>
                </Box>
            </div>
        );
    }
    return null
}

export default CustomTooltips