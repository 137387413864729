import React from "react"
import {Box, Grid, Paper, Typography, useTheme} from "@mui/material";
import {cardShadow} from "../assets/jss/commonStyles";
import {Link} from "react-router-dom";

/*const useStyles = makeStyles((theme) => ({
    paginationRoot: {
        flexShrink: 0,
        marginLeft: theme.spacing(2.5)
    },
}))*/

function Footer(props) {
    const theme = useTheme();
    return (
        <Box boxShadow={cardShadow} style={{flexGrow: 1}}>
            <Paper>
                <Grid container>
                    <Grid item xs={6}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Box p={2} pb={1} style={{textAlign: "left"}}>
                                    <Typography component={'span'} color={'primary'} style={{
                                        opacity: 0.5,
                                        fontSize: 12,
                                        fontFamily: '"proxima-nova", sans-serif'
                                    }}>
                                        {'©'}&nbsp;{new Date().getFullYear()}&nbsp;
                                        <a target={"_blank"} rel="noopener noreferrer"
                                           className={'text-decoration-none'}
                                           href={'https://www.netelixir.com/'}
                                           style={{color: theme.palette.primary.main}}>NetElixir</a>
                                        &nbsp;Inc. All Rights Reserved.
                                    </Typography>
                                    <Typography component={'span'}>&emsp;&emsp;</Typography>
                                    <Typography component={'span'} color={'primary'} style={{
                                        opacity: 0.5,
                                        fontSize: 12,
                                        fontFamily: '"proxima-nova", sans-serif'
                                    }}>
                                        <a href={'mailto:support@lxrinsights.com'} style={{
                                            textDecoration: 'none',
                                            color: 'inherit'
                                        }}>support@lxrinsights.com</a>
                                    </Typography>
                                    <Typography component={'span'}>&emsp;&emsp;</Typography>
                                    <Typography component={'span'} color={'primary'} style={{
                                        opacity: 0.5,
                                        fontSize: 12,
                                        fontFamily: '"proxima-nova", sans-serif'
                                    }}>
                                        <Link to={'/contact_us'} onClick={() => window.scrollTo(0, 0)}
                                              style={{color: theme.palette.primary.main}}
                                              className={'text-decoration-none'}>Contact Us</Link>
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={1}/>
                    <Grid item xs={5}>
                        <Box p={2} pb={1} style={{textAlign: 'end'}}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography component={'span'} color={'primary'} style={{
                                        opacity: 0.5,
                                        fontSize: 12,
                                        fontFamily: '"proxima-nova", sans-serif'
                                    }}>
                                        <Link to={'/do-not-sell'} onClick={() => window.scrollTo(0, 0)}
                                              style={{color: theme.palette.primary.main}}
                                              className={'text-decoration-none'}>Do not sell my personal
                                            information</Link>
                                    </Typography>
                                    <Typography component={'span'}>&emsp;&emsp;</Typography>
                                    <Typography component={'span'} color={'primary'} style={{
                                        opacity: 0.5,
                                        fontSize: 12,
                                        fontFamily: '"proxima-nova", sans-serif'
                                    }}>
                                        <Link to={'/privacy-policy'} onClick={() => window.scrollTo(0, 0)}
                                              style={{color: theme.palette.primary.main}}
                                              className={'text-decoration-none'}>Privacy Policy</Link>
                                    </Typography>
                                    <Typography component={'span'}>&emsp;&emsp;</Typography>
                                    <Typography component={'span'} color={'primary'}
                                                style={{
                                                    opacity: 0.5,
                                                    fontSize: 12,
                                                    fontFamily: '"proxima-nova", sans-serif'
                                                }}>
                                        <Link to={'/terms'} onClick={() => window.scrollTo(0, 0)}
                                              style={{color: theme.palette.primary.main}}
                                              className={'text-decoration-none'}>Terms and Conditions</Link>
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
            </Paper>
        </Box>
    )
}

export default Footer