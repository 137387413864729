import { Typography } from "@mui/material";
import React from "react";

const GrayHeading4 = ({ children }) => {
  return (
    <Typography
      fontSize={"1rem"}
      fontWeight={500}
      fontFamily={'"Lato", sans-serif'}
      color="#747474"
      lineHeight={"19.2px"}
    >
      {children}
    </Typography>
  );
};

export default GrayHeading4;
