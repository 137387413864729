import React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { Box, FormControlLabel } from "@mui/material";
import LightGrayHeading4 from "./GlobalTexts/LightGrayHeading4";

const RadioGroupComponent = (props) => {
  const { radioButtonsList, value, handleChange, radioLabelStyleProps } = props;
  return (
    <RadioGroup
      aria-labelledby="demo-controlled-radio-buttons-group"
      name="controlled-radio-buttons-group"
      value={value}
      onChange={handleChange}
      row
    >
      {radioButtonsList?.map((each, index) => (
        <Box
          display="flex"
          alignItems="center"
          key={`radio-group-buttons-${index} -- ${index + index}`}
        >
          <Radio
            value={each?.value}
            sx={{
              color: value === each?.value ? "#9A9A9A" : "#D9D9D9", // color when selected or deselected
              "&.Mui-checked": {
                color: "#9A9A9A", // color when checked
              },
            }}
          />

          <LightGrayHeading4
            color={radioLabelStyleProps?.color}
            fontWeight={radioLabelStyleProps?.fontWeight}
            fontSize={radioLabelStyleProps?.fontSize}
            lineHeight={radioLabelStyleProps?.lineHeight}
          >
            {each?.text}
          </LightGrayHeading4>
        </Box>
      ))}
    </RadioGroup>
  );
};

export default RadioGroupComponent;
