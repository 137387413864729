import React, { useEffect } from "react";
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Text,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { Box, Grid, Typography, useTheme } from "@mui/material";
import { capitalCase } from "change-case";
import { border } from "../../../assets/jss/commonStyles";
import * as _ from "lodash";
import moment from "moment";
import { connect } from "react-redux";
import NumericLabel from "react-pretty-numbers";
import Icons from "../../icons";
import DateComponent from "../../../GlobalComponents/DateComponent";

export function CustomXAxisTick(props) {
  const { x, y, payload } = props;
  return (
    <g transform={`translate(${x},${y})`}>
      <Text
        x={0}
        y={0}
        textAnchor="middle"
        verticalAnchor="start"
        fill={"#1E334E"}
        style={{
          fontWeight: "normal",
          fontFamily: '"Lato", sans-serif',
          fontStyle: "normal",
          fontSize: 14,
        }}
      >
        {payload.value}
      </Text>
    </g>
  );
}
export function CustomYAxisTick(props) {
  const { x, y, payload } = props;
  return (
    <g transform={`translate(${x},${y})`}>
      <Text
        x={0}
        y={0}
        textAnchor="end"
        verticalAnchor="middle"
        fill={"#1E334E"}
        style={{
          fontWeight: "normal",
          fontFamily: '"Lato", sans-serif',
          fontStyle: "normal",
          fontSize: 14,
        }}
      >
        {payload.value}
      </Text>
    </g>
  );
}

export const CustomTooltip = ({ active, payload, label, currency }) => {
  const theme = useTheme();
  const orderParams = {
    commafy: true,
    wholenumber: "ceil",
    // shortFormat: true,
    shortFormatMinValue: 10000,
    justification: "L",
  };
  const revenueParams = {
    wholenumber: "ceil",
    locales: "en-US",
    currency: true,
    currencyIndicator: currency,
    commafy: true,
    justification: "L",
  };
  if (active && payload !== [] && payload !== null) {
    if (payload.length === 1) {
      if (payload[0].payload.compareDate) {
        return (
          <div className={"custom-tooltip"}>
            <Box
              p={1}
              style={{
                backgroundColor: theme.palette.primary.main,
                minWidth: 70,
              }}
              borderRadius="4px"
            >
              <Typography
                variant={"body2"}
                style={{ color: theme.palette.common.white, opacity: 0.5 }}
              >
                <DateComponent dateText={payload?.[0]?.payload?.compareDate} />
              </Typography>
              {payload[0].name === "orders" ? (
                <Typography
                  variant={"body2"}
                  style={{ color: theme.palette.common.white, opacity: 0.5 }}
                >
                  Order(s) :{" "}
                  <NumericLabel params={orderParams}>
                    {payload[0].value}
                  </NumericLabel>
                  &nbsp;(
                  {payload[0].value
                    ? Math.round(
                        (payload[0].value * 100) /
                          payload[0].payload.totalCompareOrders
                      )
                    : 0}
                  %)
                </Typography>
              ) : payload[0].name === "revenue" ? (
                <Typography
                  variant={"body2"}
                  style={{ color: theme.palette.common.white, opacity: 0.5 }}
                >
                  Revenue :{" "}
                  <NumericLabel params={revenueParams}>
                    {payload[0].value}
                  </NumericLabel>
                  &nbsp;(
                  {payload[0].value
                    ? Math.round(
                        (payload[0].value * 100) /
                          payload[0].payload.totalCompareRevenue
                      )
                    : 0}
                  %)
                </Typography>
              ) : (
                <Typography
                  variant={"body2"}
                  style={{ color: theme.palette.common.white, opacity: 0.5 }}
                >
                  Customer(s) :{" "}
                  <NumericLabel params={orderParams}>
                    {payload[0].value}
                  </NumericLabel>
                  &nbsp;(
                  {payload[0].value
                    ? Math.round(
                        (payload[0].value * 100) /
                          payload[0].payload.totalCompareCustomers
                      )
                    : 0}
                  %)
                </Typography>
              )}
            </Box>
          </div>
        );
      } else {
        return (
          <div className={"custom-tooltip"}>
            <Box
              p={1}
              style={{
                backgroundColor: theme.palette.primary.main,
                minWidth: 70,
              }}
              borderRadius="4px"
            >
              <Typography
                variant={"body2"}
                style={{ color: theme.palette.common.white }}
              >
                <DateComponent dateText={payload?.[0]?.payload.date} />
              </Typography>
              {payload[0].name === "orders" ? (
                <Typography
                  variant={"body2"}
                  style={{ color: theme.palette.common.white }}
                >
                  Order(s) :{" "}
                  <NumericLabel params={orderParams}>
                    {payload[0].value}
                  </NumericLabel>
                  &nbsp;(
                  {payload[0].value
                    ? Math.round(
                        (payload[0].value * 100) /
                          payload[0].payload.totalOrders
                      )
                    : 0}
                  %)
                </Typography>
              ) : payload[0].name === "revenue" ? (
                <Typography
                  variant={"body2"}
                  style={{ color: theme.palette.common.white }}
                >
                  Revenue :{" "}
                  <NumericLabel params={revenueParams}>
                    {payload[0].value}
                  </NumericLabel>
                  &nbsp;(
                  {payload[0].value
                    ? Math.round(
                        (payload[0].value * 100) /
                          payload[0].payload.totalRevenue
                      )
                    : 0}
                  %)
                </Typography>
              ) : (
                <Typography
                  variant={"body2"}
                  style={{ color: theme.palette.common.white }}
                >
                  Customer(s) :{" "}
                  <NumericLabel params={orderParams}>
                    {payload[0].value}
                  </NumericLabel>
                  &nbsp;(
                  {payload[0].value
                    ? Math.round(
                        (payload[0].value * 100) /
                          payload[0].payload.totalCustomers
                      )
                    : 0}
                  %)
                </Typography>
              )}
            </Box>
          </div>
        );
      }
    } else if (payload.length === 2) {
      const diff = payload[0].value - payload[1].value;
      return (
        <div className={"custom-tooltip"}>
          <Box
            p={1}
            style={{
              backgroundColor: theme.palette.primary.main,
              minWidth: 70,
            }}
            borderRadius="4px"
          >
            <Grid container>
              <Grid item>
                <Typography
                  variant={"body2"}
                  style={{ color: theme.palette.common.white }}
                >
                  <DateComponent dateText={payload?.[0]?.payload?.date} />
                </Typography>
                {payload[0].name === "orders" ? (
                  <Typography
                    variant={"body2"}
                    style={{ color: theme.palette.common.white }}
                  >
                    Order(s) :{" "}
                    <NumericLabel params={orderParams}>
                      {payload[0].value}
                    </NumericLabel>
                    &nbsp;(
                    {payload[0].value
                      ? Math.round(
                          (payload[0].value * 100) /
                            payload[0].payload.totalOrders
                        )
                      : 0}
                    %)
                  </Typography>
                ) : payload[0].name === "revenue" ? (
                  <Typography
                    variant={"body2"}
                    style={{ color: theme.palette.common.white }}
                  >
                    Revenue :{" "}
                    <NumericLabel params={revenueParams}>
                      {payload[0].value}
                    </NumericLabel>
                    &nbsp;(
                    {payload[0].value
                      ? Math.round(
                          (payload[0].value * 100) /
                            payload[0].payload.totalRevenue
                        )
                      : 0}
                    %)
                  </Typography>
                ) : (
                  <Typography
                    variant={"body2"}
                    style={{ color: theme.palette.common.white }}
                  >
                    Customer(s) :{" "}
                    <NumericLabel params={orderParams}>
                      {payload[0].value}
                    </NumericLabel>
                    &nbsp;(
                    {payload[0].value
                      ? Math.round(
                          (payload[0].value * 100) /
                            payload[0].payload.totalCustomers
                        )
                      : 0}
                    %)
                  </Typography>
                )}
                <Typography
                  variant={"body2"}
                  style={{ color: theme.palette.common.white, opacity: 0.5 }}
                >
                  <DateComponent
                    dateText={payload?.[1]?.payload?.compareDate}
                  />
                </Typography>
                {payload[0].name === "orders" ? (
                  <Typography
                    variant={"body2"}
                    style={{ color: theme.palette.common.white, opacity: 0.5 }}
                  >
                    Order(s) :{" "}
                    <NumericLabel params={orderParams}>
                      {payload[1].value}
                    </NumericLabel>
                    &nbsp;(
                    {payload[1].value
                      ? Math.round(
                          (payload[1].value * 100) /
                            payload[1].payload.totalCompareOrders
                        )
                      : 0}
                    %)
                  </Typography>
                ) : payload[0].name === "revenue" ? (
                  <Typography
                    variant={"body2"}
                    style={{ color: theme.palette.common.white, opacity: 0.5 }}
                  >
                    Revenue :{" "}
                    <NumericLabel params={revenueParams}>
                      {payload[1].value}
                    </NumericLabel>
                    &nbsp;(
                    {payload[1].value
                      ? Math.round(
                          (payload[1].value * 100) /
                            payload[1].payload.totalCompareRevenue
                        )
                      : 0}
                    %)
                  </Typography>
                ) : (
                  <Typography
                    variant={"body2"}
                    style={{ color: theme.palette.common.white, opacity: 0.5 }}
                  >
                    Customer(s) :{" "}
                    <NumericLabel params={orderParams}>
                      {payload[1].value}
                    </NumericLabel>
                    &nbsp;(
                    {payload[1].value
                      ? Math.round(
                          (payload[1].value * 100) /
                            payload[1].payload.totalCompareCustomers
                        )
                      : 0}
                    %)
                  </Typography>
                )}
              </Grid>
              <Grid item alignItems={"flex-start"}>
                {payload[1].value !== 0 ? (
                  diff > 0 ? (
                    <Typography variant={"h5"} style={{ color: "#27AE60" }}>
                      &nbsp;
                      <span style={{ fontWeight: 700 }}>
                        {Math.abs(Math.round((diff * 100) / payload[1].value))}%
                      </span>
                      &nbsp;
                      {Icons.greenLineArrow}
                    </Typography>
                  ) : diff < 0 ? (
                    <Typography variant={"h5"} style={{ color: "#eb5757" }}>
                      &nbsp;
                      <span style={{ fontWeight: 700 }}>
                        {Math.abs(Math.round((diff * 100) / payload[1].value))}%
                      </span>
                      &nbsp;
                      {Icons.redLineArrow}
                    </Typography>
                  ) : null
                ) : null}
              </Grid>
            </Grid>
          </Box>
        </div>
      );
    }
  }
  return null;
};

function GroupTrendChart(props) {
  const theme = useTheme();
  const { property, data, compareData, application_states } = props;
  const currency = application_states.currency;
  const orderParams = {
    commafy: true,
    wholenumber: "ceil",
    // shortFormat: true,
    shortFormatMinValue: 10000,
    justification: "L",
  };
  const revenueParams = {
    wholenumber: "ceil",
    locales: "en-US",
    currency: true,
    currencyIndicator: currency,
    commafy: true,
    justification: "L",
  };
  const monthCompare = application_states.monthCompare;
  let totalMetric, compareTotalMetric;
  let graphData, graphCompareData;
  if (property === "orders") {
    graphData = _.map(
      _.mapValues(_.groupBy(data.orders, "date"), (d) => _.sumBy(d, "orders")),
      (orders, date) => ({
        date: moment(date).format(),
        orders: orders,
        totalOrders: _.sumBy(data.orders, "orders"),
      })
    );
    graphCompareData = _.map(
      _.mapValues(_.groupBy(compareData.orders, "date"), (d) =>
        _.sumBy(d, "orders")
      ),
      (orders, date) => ({
        compareDate: moment(date).format(),
        compareOrders: orders,
        totalCompareOrders: _.sumBy(compareData.orders, "orders"),
      })
    );
  } else if (property === "revenue") {
    graphData = _.map(
      _.mapValues(_.groupBy(data.orders, "date"), (d) => _.sumBy(d, "revenue")),
      (revenue, date) => ({
        date: moment(date).format(),
        revenue: revenue,
        totalRevenue: _.sumBy(data.orders, "revenue"),
      })
    );
    graphCompareData = _.map(
      _.mapValues(_.groupBy(compareData.orders, "date"), (d) =>
        _.sumBy(d, "revenue")
      ),
      (revenue, date) => ({
        compareDate: moment(date).format(),
        compareRevenue: revenue,
        totalCompareRevenue: _.sumBy(compareData.orders, "revenue"),
      })
    );
  } else {
    graphData = _.map(
      _.mapValues(_.groupBy(data.orders, "date"), (d) =>
        _.uniqBy(d, "customers")
      ),
      (customers, date) => ({
        date: moment(date).format(),
        customerIDs: _.uniqBy(
          _.flatten(_.map(customers, (d) => _.values(d.customers)))
        ),
        customers: _.uniqBy(
          _.flatten(_.map(customers, (d) => _.values(d.customers)))
        ).length
          ? _.uniqBy(_.flatten(_.map(customers, (d) => _.values(d.customers))))
              .length
          : _.sumBy(customers, "customers"),
      })
    );
    graphData.forEach((t) => {
      t.totalCustomers = _.sumBy(graphData, "customers");
    });
    graphCompareData = _.map(
      _.mapValues(_.groupBy(compareData.orders, "date"), (d) =>
        _.uniqBy(d, "customers")
      ),
      (customers, date) => ({
        compareDate: moment(date).format(),
        compareCustomerIDs: _.uniqBy(
          _.flatten(_.map(customers, (d) => _.values(d.customers)))
        ),
        compareCustomers: _.uniqBy(
          _.flatten(_.map(customers, (d) => _.values(d.customers)))
        ).length
          ? _.uniqBy(_.flatten(_.map(customers, (d) => _.values(d.customers))))
              .length
          : _.sumBy(customers, "customers"),
      })
    );
    graphCompareData.forEach((t) => {
      t.totalCompareCustomers = _.sumBy(graphCompareData, "compareCustomers");
    });
  }
  graphData = _.sortBy(graphData, ["date"]);
  graphCompareData = _.sortBy(graphCompareData, ["compareDate"]);
  let finalTrendGraphData = !monthCompare
    ? graphData
    : graphData.length >= graphCompareData.length
    ? graphData.map((item, i) => Object.assign({}, item, graphCompareData[i]))
    : graphCompareData.map((item, i) => Object.assign({}, item, graphData[i]));
  if (monthCompare && graphData.length > 0) {
    finalTrendGraphData.forEach((d, i) => {
      /*if (compareData.orders.length > 0 && !d.compareDate) {
                d.compareDate = moment(finalTrendGraphData[i - 1].compareDate).add(1, 'days')
                if (property === 'orders') {
                    d.totalCompareOrders = _.sumBy(compareData.orders, 'orders')
                    d.compareOrders = 0
                } else if (property === 'revenue') {
                    d.totalCompareRevenue = _.sumBy(compareData.revenue, 'revenue')
                    d.compareRevenue = 0
                } else {
                    d.totalCompareCustomers = _.sumBy(graphData, 'customers')
                    d.compareCustomers = 0
                }
            }*/
      if (d.date) {
      } else {
        d.date = moment(finalTrendGraphData[i - 1].date).add(1, "days");
        /*if (property === 'orders') {
                    d.totalOrders = _.sumBy(data.orders, 'orders')
                    d.orders = 0
                } else if (property === 'revenue') {
                    d.totalRevenue = _.sumBy(data.revenue, 'revenue')
                    d.revenue = 0
                } else {
                    d.totalCustomers = _.sumBy(graphData, 'customers')
                    d.customers = 0
                }*/
      }
    });
  }
  totalMetric =
    property === "customers"
      ? _.uniqBy(_.flatten(_.map(data.orders, (d) => _.values(d.customers))))
          .length
      : graphData.length > 0
      ? _.sumBy(finalTrendGraphData, property)
      : 0;
  compareTotalMetric =
    property === "customers"
      ? _.uniqBy(
          _.flatten(_.map(compareData.orders, (d) => _.values(d.customers)))
        ).length
      : graphCompareData.length > 0
      ? _.sumBy(finalTrendGraphData, `compare${capitalCase(property)}`)
      : 0;
  let totalDiff = totalMetric - compareTotalMetric;
  compareTotalMetric = compareTotalMetric ? compareTotalMetric : 0;
  // graphCompareData = monthCompare ? graphCompareData : []
  useEffect(() => {}, [props]);
  return (
    <Box>
      <Box p={3} py={2} borderBottom={border}>
        <Grid container>
          <Grid item xs={6}>
            <Typography
              variant={"h4"}
              style={{
                fontWeight: 700,
              }}
            >
              {capitalCase(property)} Over Time
            </Typography>
          </Grid>
          <Grid item xs={6} style={{ textAlign: "end" }}>
            {!monthCompare ? (
              <Typography color={"primary"}>
                <span style={{ fontWeight: 700 }}>
                  Total {capitalCase(property)}
                </span>{" "}
                :&nbsp;
                {property === "revenue" ? (
                  <NumericLabel params={revenueParams}>
                    {totalMetric}
                  </NumericLabel>
                ) : (
                  <NumericLabel params={orderParams}>
                    {totalMetric}
                  </NumericLabel>
                )}
              </Typography>
            ) : (
              <Box style={{ textAlign: "end" }}>
                <Typography component={"span"} color={"primary"}>
                  <span style={{ fontWeight: 700 }}>
                    Total {capitalCase(property)}
                  </span>{" "}
                  :
                  <span style={{ opacity: 0.5 }}>
                    &nbsp;
                    {property === "revenue" ? (
                      <NumericLabel params={revenueParams}>
                        {compareTotalMetric}
                      </NumericLabel>
                    ) : (
                      <NumericLabel params={orderParams}>
                        {compareTotalMetric}
                      </NumericLabel>
                    )}
                    &nbsp;|&nbsp;
                  </span>
                  {property === "revenue" ? (
                    <NumericLabel params={revenueParams}>
                      {totalMetric}
                    </NumericLabel>
                  ) : (
                    <NumericLabel params={orderParams}>
                      {totalMetric}
                    </NumericLabel>
                  )}
                  &nbsp;
                </Typography>
                {compareTotalMetric ? (
                  totalDiff > 0 ? (
                    <Typography component={"span"} style={{ color: "#27AE60" }}>
                      &nbsp;
                      <span style={{ fontWeight: 700 }}>
                        {Math.abs(
                          Math.round((totalDiff * 100) / compareTotalMetric)
                        )}
                        %
                      </span>
                      &nbsp;
                      {Icons.greenLineArrow}
                    </Typography>
                  ) : totalDiff < 0 ? (
                    <Typography component={"span"} style={{ color: "#eb5757" }}>
                      &nbsp;
                      <span style={{ fontWeight: 700 }}>
                        {Math.abs(
                          Math.round((totalDiff * 100) / compareTotalMetric)
                        )}
                        %
                      </span>
                      &nbsp;
                      {Icons.redLineArrow}
                    </Typography>
                  ) : (
                    <Typography
                      component={"span"}
                      color={"primary"}
                      style={{ opacity: 0.5 }}
                    >
                      &ensp;-
                    </Typography>
                  )
                ) : (
                  <Typography
                    component={"span"}
                    color={"primary"}
                    style={{ opacity: 0.5 }}
                  >
                    &ensp;-
                  </Typography>
                )}
              </Box>
            )}
          </Grid>
        </Grid>
      </Box>
      {data.orders.length > 0 ? (
        <ResponsiveContainer width={"95%"} height={325}>
          <LineChart data={finalTrendGraphData}>
            <XAxis
              xAxisId={"duration"}
              dataKey={(e) => {
                return moment(e.date).format("DD");
              }}
              tick={<CustomXAxisTick />}
            />
            <XAxis
              hide={true}
              xAxisId={"compare"}
              dataKey={(e) => {
                return moment(e.date).format("DD");
              }}
              tick={<CustomXAxisTick />}
            />
            <YAxis tick={<CustomYAxisTick />} />
            <CartesianGrid />
            <Tooltip
              content={<CustomTooltip currency={currency} />}
              allowEscapeViewBox={{ x: false, y: false }}
            />
            <Line
              xAxisId={"duration"}
              isAnimationActive={false}
              strokeWidth={2}
              dataKey={property}
              dot={{ strokeWidth: 2 }}
              stroke={theme.palette.secondary.light}
              activeDot={{ r: 8 }}
            />
            <Line
              xAxisId={"compare"}
              isAnimationActive={false}
              strokeOpacity={0.5}
              strokeWidth={2}
              dataKey={(e) => {
                return e[`compare${capitalCase(property)}`];
              }}
              stroke={theme.palette.primary.main}
              activeDot={{ r: 8 }}
            />
          </LineChart>
        </ResponsiveContainer>
      ) : (
        <Box
          pt={5}
          style={{ minHeight: 300, alignItems: "center", textAlign: "center" }}
        >
          <Typography>No data to display</Typography>
        </Box>
      )}
    </Box>
  );
}

const mapStateToProps = (state) => ({
  application_states: state.application_states,
});
export default connect(mapStateToProps, null)(GroupTrendChart);
