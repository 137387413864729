import { Button, IconButton, ToggleButton } from "@mui/material";
import { withStyles } from "@mui/styles";

export const TransparentButton = withStyles((theme) => ({
  root: {
    backgroundColor: "transparent",
    color: theme.palette.primary.main,
    textTransform: "uppercase",
    fontWeight: "bold",
    outline: "none",
    padding: "10px 30px",
    fontSize: "1rem",
    borderRadius: "30px",
    "&:hover": {},
    [theme.breakpoints.down(960)]: {
      fontSize: "12px",
      padding: "6px",
    },
  },
}))(Button);

export const OrangeFilledButton = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.secondary.light,
    boxShadow: "none",
    color: theme.palette.common.white,
    textTransform: "none",
    outline: "none",
    fontSize: "1rem",
    padding: 7,
    fontWeight: 700,
    borderRadius: "6px",
    "&:hover": {
      backgroundColor: theme.palette.hoverColors.orange,
      color: theme.palette.common.white,
    },
  },
}))(Button);

export const GreenFilledButton = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.nx.green,
    boxShadow: "none",
    color: theme.palette.common.white,
    textTransform: "none",
    outline: "none",
    fontSize: "1rem",
    padding: 7,
    fontWeight: 700,
    borderRadius: "6px",
    "&:hover": {
      backgroundColor: theme.palette.nx.green,
      color: theme.palette.common.white,
    },
  },
}))(Button);

export const BlueButton = withStyles((theme) => ({
  root: {
    backgroundColor: "#00A3FF",
    boxShadow: "none",
    color: theme.palette.common.white,
    textTransform: "none",
    outline: "none",
    fontSize: "1rem",
    padding: 7,
    fontWeight: 700,
    borderRadius: "6px",
    "&:hover": {
      backgroundColor: "#00A3FF",
      color: theme.palette.common.white,
    },
  },
}))(Button);

export const GreyFilledButton = withStyles((theme) => ({
  root: {
    backgroundColor: "#e3e6f4",
    color: theme.palette.primary.main,
    textTransform: "none",
    outline: "none",
    fontSize: "1rem",
    "&:hover": {
      backgroundColor: "#e3e6f4",
      color: theme.palette.primary.main,
    },
  },
}))(Button);

export const BlueFilledButton = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    textTransform: "none",
    outline: "none",
    borderRadius: 0,
    fontSize: "1rem",
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      opacity: 0.5,
      color: theme.palette.common.white,
    },
  },
}))(Button);

export const SimpleButton = withStyles((theme) => ({
  root: {
    backgroundColor: "transparent",
    color: theme.palette.primary.main,
    textTransform: "none",
    fontWeight: "normal",
    outline: "none",
    padding: 4,
    minWidth: 0,
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
}))(Button);

export const BorderedIconButton = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.secondary.contrastText,
    color: theme.palette.common.white,
    border: "1px Solid transparent",
    borderRadius: "50%",
    fontSize: 24,
    outline: "none",
    "&:hover": {
      fontSize: 32,
    },
  },
}))(IconButton);

export const OutlinedButton = withStyles((theme) => ({
  root: {
    color: theme.palette.primary.main,
    border: "1px solid #1E334E",
    textTransform: "none",
    fontSize: 16,
  },
}))(Button);

export const CustomToggleButton = withStyles((theme) => ({
  root: {
    fontSize: 14,
    paddingTop: 3,
    textTransform: "none",
    paddingBottom: 3,
    color: theme.palette.primary.main,
    border: "1px solid #1E334E",
    "&$selected, &$selected:hover, &$selected:focus": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      opacity: 0.5,
    },
  },
  selected: {},
  hover: {},
}))(ToggleButton);

export const OrangeOutlinedButton = withStyles((theme) => ({
  root: {
    color: theme.palette.secondary.light,
    border: `1px solid ${theme.palette.secondary.light}`,
    textTransform: "none",
    fontSize: 16,
    borderRadius: 0,
    backgroundColor: "#fff",
    "&:hover": {
      backgroundColor: theme.palette.common.white,
    },
  },
}))(Button);

export const RedOutlinedButton = withStyles((theme) => ({
  root: {
    color: theme.palette.common.error,
    textTransform: "none",
    fontSize: 14,
    padding:'6px',
    borderRadius: 0,
    border:`1px solid ${theme.palette.common.error}`,
    backgroundColor: theme.palette.common.white,
    "&:hover": {
      backgroundColor: theme.palette.common.white,
      opacity: 0.8,
    },
  },
}))(Button);

export const WhiteFilledButton = withStyles((theme) => ({
  root: {
    textTransform: "none",
    backgroundColor: theme.palette.common.white,
    borderRadius: 0,
    color: theme.palette.secondary.light,
    fontWeight: 600,
    "&:hover": {
      backgroundColor: theme.palette.common.white,
      opacity: 0.5,
    },
    border:`1px solid ${theme.palette.secondary.light}`
  },
}))(Button);

export const OrangeButton = withStyles(theme => ({
  root: {
      backgroundColor: "#FFF4EB73",
      boxShadow:'none',
      color: theme.palette.secondary.light,
      textTransform: "none",
      outline: 'none',
      fontSize: "1rem",
      padding: 7,
      fontWeight:700,
      borderRadius:'6px',
      "&:hover": {
          backgroundColor: theme.palette.secondary.light,
          color: theme.palette.common.white,
      },
      disable: {
        backgroundColor: theme.palette.secondary.light,
        color: theme.palette.common.white,
    }
  }
}))(Button);