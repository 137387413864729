import React from 'react';
import {refresh_token} from "../actions/actions"
import {connect} from 'react-redux';


class DispatchActions extends React.Component {
    componentDidMount() {
        if(this.props.loginAuthentication.logged_in) {
            this.props.refresh_token()
        }
        try {
            setInterval(async () => {
                if (this.props.loginAuthentication.logged_in) {
                    await this.props.refresh_token()
                }
            }, 55*60*1000);
        } catch (e) {
            // console.log("e", e)
        }
    }
    render() {
        return null
    }
}

const mapStateToProps = (state) => ({
    refreshToken: state.refreshToken,
    loginAuthentication: state.loginAuthentication
});
const mapDispatchToProps = (dispatch) => ({
    refresh_token: () => dispatch(refresh_token())
});

export default connect(mapStateToProps, mapDispatchToProps)(DispatchActions);