import { Box, Button, Grid, Typography, useTheme } from "@mui/material";
import clsx from "clsx";
import React from "react";
import NumericLabel from "react-pretty-numbers";
import { Link } from "react-router-dom";
import { border } from "../../assets/jss/commonStyles";
import { GAEventTracker } from "../../index";
import DataNode from "../dataNode";
import CustomerGroup from "../getGroupDetails";
import Icons from "../icons";
import PredictionSidebar from "../predictionSidebar";

function BlockDetails(props) {
  const {
    classes,
    segmentDetail,
    predictionList,
    repurchase3Months,
    churnCustomers,
  } = props;
  const [predictionSidebar, setPredictionSidebar] = React.useState(false);
  const handlePredictionSidebarClose = () => {
    setPredictionSidebar(false);
  };
  const handlePredictionSidebarOpen = () => {
    setPredictionSidebar(true);
  };
  const theme = useTheme();
  const paramOptions4 = {
    wholenumber: "ceil",
    justification: "L",
  };

  return (
    <React.Fragment>
      <Box pl={3} px={1} borderBottom={border}>
        <Grid container alignItems={"center"}>
          <Grid item xs={9} className={"flex"}>
            <Box px={0.5} pt={3}>
              <Typography variant={"h5"}>Avg.&nbsp;Days</Typography>
            </Box>
            <div className={classes.arrowRight}>
              <div className={classes.arrowRightDiv} />
            </div>
            <Box display={"flex"} px={1} pb={1} pt={1.5}>
              {segmentDetail.latency ? (
                <DataNode
                  title={"First Order"}
                  value={
                    <>
                      <NumericLabel params={paramOptions4}>
                        {Math.abs(segmentDetail.latency / 24)}
                      </NumericLabel>
                      <Typography
                        component={"span"}
                        variant={"h5"}
                        style={{ fontWeight: 700 }}
                      >
                        &nbsp;days
                      </Typography>
                    </>
                  }
                  borderReq={true}
                />
              ) : null}

              {segmentDetail.days_gap_1 !== 0 &&
              segmentDetail.days_gap_1 !== "" &&
              segmentDetail.orders / segmentDetail.customers > 1.05 ? (
                <DataNode
                  title={"Second Order"}
                  value={
                    <>
                      <NumericLabel params={paramOptions4}>
                        {segmentDetail.days_gap_1}
                      </NumericLabel>
                      <Typography
                        component={"span"}
                        variant={"h5"}
                        style={{ fontWeight: 700 }}
                      >
                        &nbsp;days
                      </Typography>
                    </>
                  }
                  borderReq={true}
                />
              ) : null}
              <DataNode
                title={"Recency"}
                value={
                  segmentDetail.avg_recency ? (
                    <>
                      <NumericLabel params={paramOptions4}>
                        {segmentDetail.avg_recency}
                      </NumericLabel>
                      <Typography
                        component={"span"}
                        variant={"h5"}
                        style={{ fontWeight: 700 }}
                      >
                        &nbsp;days
                      </Typography>
                    </>
                  ) : (
                    "NA"
                  )
                }
                borderReq={false}
              />
            </Box>
          </Grid>
          <Grid item xs={2} className={"flex"}>
            <Link
              to={
                // `/groups/${segmentDetail.segment}`

                `/customer-analytics/${segmentDetail?.segment}`
              }
              className={clsx("text-decoration-none")}
              style={{ color: theme.palette.primary.main }}
            >
              <Typography
                variant={"body2"}
                style={{ textDecoration: "underline", marginLeft: "15px" }}
                onClick={() => {
                  GAEventTracker(
                    "Overview",
                    "View More",
                    CustomerGroup(segmentDetail.segment)().name
                  );
                }}
              >
                View More
              </Typography>
            </Link>
          </Grid>
          <Grid item xs={1}>
            <Box borderLeft={border} p={1} pl={0}>
              <Button
                id={segmentDetail.segment === "all" ? "tour3rdStep" : null}
                className={classes.magicWand}
                onClick={() => {
                  handlePredictionSidebarOpen();
                  GAEventTracker(
                    "Overview",
                    "Magic Wand",
                    CustomerGroup(segmentDetail.segment)().name
                  );
                }}
              >
                {Icons.magicWand}
              </Button>
            </Box>
            <PredictionSidebar
              repurchase3Months={repurchase3Months}
              churnCustomers={churnCustomers}
              predictionList={predictionList}
              segmentDetail={segmentDetail}
              open={predictionSidebar}
              onClose={handlePredictionSidebarClose}
            />
          </Grid>
        </Grid>
      </Box>
    </React.Fragment>
  );
}

export function BlockDetailsFullWidth(props) {
  const {
    classes,
    segmentDetail,
    repurchase3Months,
    predictionList,
    churnCustomers,
  } = props;
  const [predictionSidebar, setPredictionSidebar] = React.useState(false);
  const handlePredictionSidebarClose = () => {
    setPredictionSidebar(false);
  };
  const handlePredictionSidebarOpen = () => {
    setPredictionSidebar(true);
  };
  const theme = useTheme();
  const paramOptions4 = {
    wholenumber: "ceil",
    justification: "L",
  };
  return (
    <>
      <Box pl={3} px={4} borderBottom={border}>
        <Grid container>
          <Grid item xs={10} className={"flex"} alignItems={"center"}>
            <Box>
              <Typography variant={"h5"} gutterBottom={true}>
                Avg. Days
              </Typography>
            </Box>
            <div className={classes.arrowRight}>
              <div className={classes.arrowRightDiv} />
            </div>
            <Box display={"flex"} px={2} py={1}>
              {segmentDetail.latency ? (
                <DataNode
                  title={"First Order"}
                  value={
                    <>
                      <NumericLabel params={paramOptions4}>
                        {Math.abs(segmentDetail.latency / 24)}
                      </NumericLabel>
                      <Typography
                        component={"span"}
                        variant={"h5"}
                        style={{ fontWeight: 700 }}
                      >
                        &nbsp;days
                      </Typography>
                    </>
                  }
                  borderReq={true}
                />
              ) : null}
              {segmentDetail.days_gap_1 !== 0 &&
              segmentDetail.days_gap_1 !== "" &&
              segmentDetail.orders / segmentDetail.customers > 1.05 ? (
                <DataNode
                  title={"Second Order"}
                  value={
                    <>
                      <NumericLabel params={paramOptions4}>
                        {segmentDetail.days_gap_1}
                      </NumericLabel>
                      <Typography
                        component={"span"}
                        variant={"h5"}
                        style={{ fontWeight: 700 }}
                      >
                        &nbsp;days
                      </Typography>
                    </>
                  }
                  borderReq={true}
                />
              ) : null}
              <DataNode
                title={"Recency"}
                value={
                  segmentDetail.avg_recency ? (
                    <>
                      <NumericLabel params={paramOptions4}>
                        {segmentDetail.avg_recency}
                      </NumericLabel>
                      <Typography
                        component={"span"}
                        variant={"h5"}
                        style={{ fontWeight: 700 }}
                      >
                        &nbsp;days
                      </Typography>
                    </>
                  ) : (
                    "NA"
                  )
                }
                borderReq={false}
              />
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Grid
              container
              alignItems={"center"}
              style={{ textAlign: "end", marginTop: "3px" }}
              spacing={1}
            >
              <Grid item xs={10}>
                <Link
                  to={`/customer-analytics/${segmentDetail?.segment}`}
                  className={clsx("text-decoration-none")}
                  style={{ color: theme.palette.primary.main }}
                >
                  <Typography
                    variant={"body2"}
                    style={{ textDecoration: "underline" }}
                    onClick={() => {
                      GAEventTracker(
                        "Overview",
                        "View More",
                        CustomerGroup(segmentDetail.segment)().name
                      );
                    }}
                  >
                    View More
                  </Typography>
                </Link>
              </Grid>
              <Grid item xs={2}>
                <Box borderLeft={border} p={2} pl={0}>
                  <Button
                    className={classes.magicWand}
                    onClick={() => {
                      handlePredictionSidebarOpen();
                      GAEventTracker(
                        "Overview",
                        "Magic Wand",
                        CustomerGroup(segmentDetail.segment)().name
                      );
                    }}
                  >
                    {Icons.magicWand}
                  </Button>
                </Box>
                <PredictionSidebar
                  repurchase3Months={repurchase3Months}
                  churnCustomers={churnCustomers}
                  predictionList={predictionList}
                  segmentDetail={segmentDetail}
                  open={predictionSidebar}
                  onClose={handlePredictionSidebarClose}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default BlockDetails;
