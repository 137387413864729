import * as types from "../actions/actionTypes";
import moment from "moment";
import * as _ from "lodash";
import {StoredSegmentData} from "../actions/actions";
import {defaultMetric} from "../components/customerTrend/customerTrendMetricsGraph";
import {conversionPathFilterObj} from "../pages/customerAnalyticsPage";

export function application_states(
  state = {
    isLoading: false,
    overviewCompare: false,
    loginPopup: false,
    monthCompare: false,
    segmentPageTab: "customers",
    customerAnalyticsSegmentTabName: "a",
    currency: "USD",
    currencySymbol: "$",
    reportPageTab: "a",
    reportButtonValue: "",
    reportButtonValueApplied: false,
    filter_name: "",
    aStart: "",
    aEnd: "",
    aCompareStart: "",
    aCompareEnd: "",
    bStart: "",
    bEnd: "",
    bCompareStart: "",
    bCompareEnd: "",
    cStart: "",
    cEnd: "",
    cCompareStart: "",
    cCompareEnd: "",
    // dStart: '', dEnd: '', dCompareStart: '', dCompareEnd: '',
    allStart: "",
    allEnd: "",
    allCompareStart: "",
    allCompareEnd: "",
    analyticsCompare: false,
    // googleAnalyticsCompare: false,
    // redealAnalyticsCompare: false,
    // facebookAnalyticsCompare: false,
    googleAnalyticsStart: moment(new Date()).subtract(7, "days"),
    googleAnalyticsEnd: moment(new Date()).subtract(1, "days"),
    redealAnalyticsStart: moment(new Date()).subtract(7, "days"),
    redealAnalyticsEnd: moment(new Date()).subtract(1, "days"),
    facebookAnalyticsStart: moment(new Date()).subtract(7, "days"),
    facebookAnalyticsEnd: moment(new Date()).subtract(1, "days"),
    googleAnalyticsCompareStart: moment(new Date()).subtract(14, "days"),
    googleAnalyticsCompareEnd: moment(new Date()).subtract(8, "days"),
    redealAnalyticsCompareStart: moment(new Date()).subtract(14, "days"),
    redealAnalyticsCompareEnd: moment(new Date()).subtract(8, "days"),
    facebookAnalyticsCompareStart: moment(new Date()).subtract(14, "days"),
    facebookAnalyticsCompareEnd: moment(new Date()).subtract(8, "days"),
    customerURL: false,
    cusEmailDownload: false,
    pushParam: "all",
    pushPriorityParam: "all",
    retryReMarket: false,
    retryReMarketSource: "",
    showPasswordPopup: true,
    window_height: window.innerHeight,
    shopifyDataProgress: 0,
  },
  action
) {
  switch (action.type) {
    case types.OVERVIEW_COMPARE:
      let { overcompvalue } = action;
      return Object.assign({}, state, {
        overviewCompare: overcompvalue,
      });
    case types.MONTH_COMPARE:
      let { monthcompvalue } = action;
      return Object.assign({}, state, {
        monthCompare: monthcompvalue,
      });
    case types.LOGIN_POPUP_CHANGE:
      let { loginpopup } = action;
      return Object.assign({}, state, {
        loginPopup: loginpopup,
      });
    case types.ANALYTICS_MONTH_COMPARE:
      let { analyticsMonthCompare } = action;
      return Object.assign({}, state, {
        analyticsCompare: analyticsMonthCompare,
      });
    case types.SET_A_GROUP_DATES:
      let { aStart, aEnd, aCompareStart, aCompareEnd } = action;
      return Object.assign({}, state, {
        aStart: aStart,
        aEnd: aEnd,
        aCompareStart: aCompareStart,
        aCompareEnd: aCompareEnd,
      });
    case types.SET_B_GROUP_DATES:
      let { bStart, bEnd, bCompareStart, bCompareEnd } = action;
      return Object.assign({}, state, {
        bStart: bStart,
        bEnd: bEnd,
        bCompareStart: bCompareStart,
        bCompareEnd: bCompareEnd,
      });
    case types.SET_C_GROUP_DATES:
      let { cStart, cEnd, cCompareStart, cCompareEnd } = action;
      return Object.assign({}, state, {
        cStart: cStart,
        cEnd: cEnd,
        cCompareStart: cCompareStart,
        cCompareEnd: cCompareEnd,
      });
    /*case types.SET_D_GROUP_DATES:
                let {dStart, dEnd, dCompareStart, dCompareEnd} = action;
                return Object.assign({}, state, {
                    dStart: dStart,
                    dEnd: dEnd,
                    dCompareStart: dCompareStart,
                    dCompareEnd: dCompareEnd,
                });*/
    case types.SET_ALL_GROUP_DATES:
      let { allStart, allEnd, allCompareStart, allCompareEnd } = action;
      return Object.assign({}, state, {
        allStart: allStart,
        allEnd: allEnd,
        allCompareStart: allCompareStart,
        allCompareEnd: allCompareEnd,
      });
    case types.SET_SEGMENT_PAGE_TAB:
      let { pageTabValue } = action;
      return Object.assign({}, state, {
        segmentPageTab: pageTabValue,
      });
    case types.SET_CUSTOMER_ANALYTICS_SEGMENT_NAME:
      return {
        ...state,
        ...action.payload,
      };

    case types.SET_REPORT_PAGE_TAB:
      let { reportPageTabValue } = action;
      return Object.assign({}, state, {
        reportPageTab: reportPageTabValue,
      });
    case types.SET_CUSTOMER_URL:
      let { valueURL } = action;
      return Object.assign({}, state, {
        customerURL: valueURL,
      });
    case types.SET_CUSTOMER_EMAIL_DOWNLOAD:
      let { valueDownload } = action;
      return Object.assign({}, state, {
        cusEmailDownload: valueDownload,
      });
    case types.SET_REPORT_BUTTON:
      let { buttonValue, filter_name } = action;
      return Object.assign({}, state, {
        reportButtonValue: buttonValue,
        filter_name: filter_name,
      });
    case types.SET_SHOW_PASSWORD_POPUP:
      let { passwordPopupValue } = action;
      return Object.assign({}, state, {
        showPasswordPopup: passwordPopupValue,
      });
    case types.SET_SHOPIFY_DATA_PROGRESS:
      let { shopifyDataProgressValue } = action;
      return Object.assign({}, state, {
        shopifyDataProgress: shopifyDataProgressValue,
      });
    case types.SET_GOOGLE_ANALYTICS_DATES:
      let {
        googleAnalyticsStart,
        googleAnalyticsEnd,
        googleAnalyticsCompareStart,
        googleAnalyticsCompareEnd,
      } = action;
      return Object.assign({}, state, {
        googleAnalyticsStart: googleAnalyticsStart,
        googleAnalyticsEnd: googleAnalyticsEnd,
        googleAnalyticsCompareStart: googleAnalyticsCompareStart,
        googleAnalyticsCompareEnd: googleAnalyticsCompareEnd,
      });
    case types.SET_REDEAL_ANALYTICS_DATES:
      let {
        redealAnalyticsStart,
        redealAnalyticsEnd,
        redealAnalyticsCompareStart,
        redealAnalyticsCompareEnd,
      } = action;
      return Object.assign({}, state, {
        redealAnalyticsStart: redealAnalyticsStart,
        redealAnalyticsEnd: redealAnalyticsEnd,
        redealAnalyticsCompareStart: redealAnalyticsCompareStart,
        redealAnalyticsCompareEnd: redealAnalyticsCompareEnd,
      });
    case types.SET_FACEBOOK_ANALYTICS_DATES:
      let {
        facebookAnalyticsStart,
        facebookAnalyticsEnd,
        facebookAnalyticsCompareStart,
        facebookAnalyticsCompareEnd,
      } = action;
      return Object.assign({}, state, {
        facebookAnalyticsStart: facebookAnalyticsStart,
        facebookAnalyticsEnd: facebookAnalyticsEnd,
        facebookAnalyticsCompareStart: facebookAnalyticsCompareStart,
        facebookAnalyticsCompareEnd: facebookAnalyticsCompareEnd,
      });
    case types.SET_CURRENCY_FIELDS:
      let { currency, currencySymbol } = action;
      return Object.assign({}, state, {
        currency: currency,
        currencySymbol: currencySymbol,
      });
    case types.PUSH_PARAM_CHANGE:
      let { pushParamValue } = action;
      return Object.assign({}, state, {
        pushParam: pushParamValue,
      });
    case types.PUSH_PRIORITY_PARAM_CHANGE:
      let { pushPriorityParamValue } = action;
      return Object.assign({}, state, {
        pushPriorityParam: pushPriorityParamValue,
      });
    case types.SET_RETRY_RE_MARKET:
      let { retryReMarket, retryReMarketSource } = action;
      return Object.assign({}, state, {
        retryReMarket: retryReMarket,
        retryReMarketSource: retryReMarketSource,
      });
    case types.SET_WINDOW_HEIGHT:
      let { screen_height } = action;
      return Object.assign({}, state, {
        window_height: screen_height,
      });
    case types.SET_REPORT_BUTTON_APPLIED:
      let { reportButtonApplied } = action;
      return Object.assign({}, state, {
        reportButtonValueApplied: reportButtonApplied,
      });
    default:
      return state;
  }
}

export function remarket_page_states(
  state = {
    pageTab: "a",
    userFilter: { segment: "a" },
    nonZeroData: {},
    recencyRangeValues: [],
    frequencyRangeValues: [],
    monetaryRangeValues: [],
    aovRangeValues: [],
    ppvRangeValues: [],
    hvcScoreRangeValues: [],
    clvKey: false,
    hvcKey: false,
    allRecencyValue: [],
    allFrequencyValue: [],
    allMonetaryValue: [],
    allAovValue: [],
    allPpvValue: [],
    allHvcScoreValue: [],
    segment_a: true,
    segment_b: false,
    segment_c: false,
    pred_single_repeat_buyer: false,
    pred_repeat_buyer_2m: false,
    pred_churn: false,
    recency: [],
    frequency: [],
    monetary: [],
    aov: [],
    ppv: [],
    hvcScore: [],
    filterRecencyValue: [],
    filterFrequencyValue: [],
    filterMonetaryValue: [],
    filterAovValue: [],
    filterPpvValue: [],
    filterHvcScoreValue: [],
    filter_segment_a: true,
    filter_segment_b: false,
    filter_segment_c: false,
    remarket_list_name: "",
    // recencyRangeValues:,
  },
  action
) {
  switch (action.type) {
    case types.SET_REMARKET_PAGE_INITIAL_VALUES:
      let {
        nonZeroData,
        recencyRangeValues,
        frequencyRangeValues,
        monetaryRangeValues,
        aovRangeValues,
        ppvRangeValues,
        hvcScoreRangeValues,
        clvKey,
        hvcKey,
        allRecencyValue,
        allFrequencyValue,
        allMonetaryValue,
        allAovValue,
        allPpvValue,
        allHvcScoreValue,
      } = action;
      return Object.assign({}, state, {
        nonZeroData: nonZeroData,
        recencyRangeValues: recencyRangeValues,
        frequencyRangeValues: frequencyRangeValues,
        monetaryRangeValues: monetaryRangeValues,
        aovRangeValues: aovRangeValues,
        ppvRangeValues: ppvRangeValues,
        hvcScoreRangeValues: hvcScoreRangeValues,
        clvKey: clvKey,
        hvcKey: hvcKey,
        allRecencyValue: allRecencyValue,
        allFrequencyValue: allFrequencyValue,
        allMonetaryValue: allMonetaryValue,
        allAovValue: allAovValue,
        allPpvValue: allPpvValue,
        allHvcScoreValue: allHvcScoreValue,
        recency: allRecencyValue,
        frequency: allFrequencyValue,
        monetary: allMonetaryValue,
        aov: allAovValue,
        ppv: allPpvValue,
        hvcScore: allHvcScoreValue,
        filterRecencyValue: allRecencyValue,
        filterFrequencyValue: allFrequencyValue,
        filterMonetaryValue: allMonetaryValue,
        filterAovValue: allAovValue,
        filterPpvValue: allPpvValue,
        filterHvcScoreValue: allHvcScoreValue,
      });
    case types.SET_USER_FILTER:
      let { value } = action;
      return Object.assign({}, state, {
        userFilter: Object.assign({}, state.userFilter, value),
      });
    case types.SET_REMARKET_LIST_NAME:
      let { remarket_list_name } = action;
      return Object.assign({}, state, {
        remarket_list_name: remarket_list_name,
      });
    case types.SET_PAGE_TAB:
      let { pageTabValue } = action;
      return Object.assign({}, state, {
        pageTab: pageTabValue,
      });
    case types.SET_RESET_USER_FILTER:
      return Object.assign({}, state, {
        segment_a: true,
        segment_b: true,
        segment_c: true,
        // pred_single_repeat_buyer: false,
        // pred_repeat_buyer_2m: false,
        // pred_churn: false,
        recency: state.allRecencyValue,
        frequency: state.allFrequencyValue,
        monetary: state.allMonetaryValue,
        aov: state.allAovValue,
        ppv: state.allPpvValue,
        hvcScore: state.allHvcScoreValue,
      });
    case types.SET_APPLIED_FILTER_VALUES:
      let {
        recencyValue,
        frequencyValue,
        monetaryValue,
        aovValue,
        ppvValue,
        hvcScoreValue,
        segmentAValue,
        segmentBValue,
        segmentCValue,
      } = action;
      return Object.assign({}, state, {
        segment_a: segmentAValue,
        segment_b: segmentBValue,
        segment_c: segmentCValue,
        recency: recencyValue,
        frequency: frequencyValue,
        monetary: monetaryValue,
        aov: aovValue,
        ppv: ppvValue,
        hvcScore: hvcScoreValue,
      });
    case types.SET_RESET_VALUES_TO_ALL:
      return Object.assign({}, state, {
        segment_a: state.pageTab === "a" || state.pageTab === "all",
        segment_b: state.pageTab === "b" || state.pageTab === "all",
        segment_c: state.pageTab === "c" || state.pageTab === "all",
        pred_single_repeat_buyer: false,
        pred_repeat_buyer_2m: false,
        pred_churn: false,
        recency: state.allRecencyValue,
        frequency: state.allFrequencyValue,
        monetary: state.allMonetaryValue,
        aov: state.allAovValue,
        ppv: state.allPpvValue,
        hvcScore: state.allHvcScoreValue,
        filter_segment_a: state.pageTab === "a" || state.pageTab === "all",
        filter_segment_b: state.pageTab === "b" || state.pageTab === "all",
        filter_segment_c: state.pageTab === "c" || state.pageTab === "all",
        filterRecencyValue: state.allRecencyValue,
        filterFrequencyValue: state.allFrequencyValue,
        filterMonetaryValue: state.allMonetaryValue,
        filterAovValue: state.allAovValue,
        filterPpvValue: state.allPpvValue,
        filterHvcScoreValue: state.allHvcScoreValue,
      });
    case types.SET_RESET_VALUES_TO_FILTER:
      return Object.assign({}, state, {
        segment_a: state.filter_segment_a,
        segment_b: state.filter_segment_b,
        segment_c: state.filter_segment_c,
        recency: state.filterRecencyValue,
        frequency: state.filterFrequencyValue,
        monetary: state.filterMonetaryValue,
        aov: state.filterAovValue,
        ppv: state.filterPpvValue,
        hvcScore: state.filterHvcScoreValue,
      });
    case types.SET_FILTER_VALUES:
      let { filterObj } = action;
      return Object.assign({}, state, {
        segment_a: filterObj?.segment_a,
        segment_b: filterObj?.segment_b,
        segment_c: filterObj?.segment_c,
        pred_single_repeat_buyer: filterObj?.pred_single_repeat_buyer,
        pred_repeat_buyer_2m: filterObj?.pred_repeat_buyer_2m,
        pred_churn: filterObj?.pred_churn,
        recency: [
          filterObj?.recencyValue?.[0]
            ? filterObj?.recencyValue?.[0]
            : _.min(state?.recencyRangeValues),
          filterObj?.recencyValue?.[1]
            ? filterObj?.recencyValue?.[1]
            : _.max(state?.recencyRangeValues),
        ],
        frequency: [
          filterObj?.frequencyValue?.[0]
            ? filterObj?.frequencyValue?.[0]
            : _.min(state?.frequencyRangeValues),
          filterObj?.frequencyValue?.[1]
            ? filterObj?.frequencyValue?.[1]
            : _.max(state?.frequencyRangeValues),
        ],
        monetary: [
          filterObj?.monetaryValue?.[0]
            ? filterObj?.monetaryValue?.[0]
            : Math.floor(_.min(state?.monetaryRangeValues)),
          filterObj?.monetaryValue?.[1]
            ? filterObj?.monetaryValue?.[1]
            : Math.ceil(_.max(state?.monetaryRangeValues)),
        ],
        aov: [
          filterObj?.aovValue?.[0]
            ? filterObj?.aovValue?.[0]
            : Math.floor(_.min(state?.aovRangeValues)),
          filterObj?.aovValue?.[1]
            ? filterObj?.aovValue?.[1]
            : Math.ceil(_.max(state?.aovRangeValues)),
        ],
        ppv: [
          filterObj?.ppvValue?.[0]
            ? filterObj?.ppvValue?.[0]
            : Math.floor(_.min(state?.ppvRangeValues)),
          filterObj?.ppvValue?.[1]
            ? filterObj?.ppvValue?.[1]
            : Math.ceil(_.max(state?.ppvRangeValues)),
        ],
        hvcScore: [
          filterObj?.hvcScoreValue?.[0]
            ? filterObj?.hvcScoreValue?.[0]
            : Math.floor(_.min(state?.hvcScoreRangeValues)),
          filterObj?.hvcScoreValue?.[1]
            ? filterObj?.hvcScoreValue?.[1]
            : Math.ceil(_.max(state?.hvcScoreRangeValues)),
        ],
        filter_segment_a: filterObj?.segment_a,
        filter_segment_b: filterObj?.segment_b,
        filter_segment_c: filterObj?.segment_c,
        filterRecencyValue: [
          filterObj?.recencyValue?.[0]
            ? filterObj?.recencyValue?.[0]
            : _.min(state?.recencyRangeValues),
          filterObj?.recencyValue?.[1]
            ? filterObj?.recencyValue?.[1]
            : _.max(state?.recencyRangeValues),
        ],
        filterFrequencyValue: [
          filterObj?.frequencyValue?.[0]
            ? filterObj?.frequencyValue?.[0]
            : _.min(state?.frequencyRangeValues),
          filterObj?.frequencyValue?.[1]
            ? filterObj?.frequencyValue?.[1]
            : _.max(state?.frequencyRangeValues),
        ],
        filterMonetaryValue: [
          filterObj?.monetaryValue?.[0]
            ? filterObj?.monetaryValue?.[0]
            : Math.floor(_.min(state?.monetaryRangeValues)),
          filterObj?.monetaryValue?.[1]
            ? filterObj?.monetaryValue?.[1]
            : Math.ceil(_.max(state?.monetaryRangeValues)),
        ],
        filterAovValue: [
          filterObj?.aovValue?.[0]
            ? filterObj?.aovValue?.[0]
            : Math.floor(_.min(state?.aovRangeValues)),
          filterObj?.aovValue?.[1]
            ? filterObj?.aovValue?.[1]
            : Math.ceil(_.max(state?.aovRangeValues)),
        ],
        filterPpvValue: [
          filterObj?.ppvValue?.[0]
            ? filterObj?.ppvValue?.[0]
            : Math.floor(_.min(state?.ppvRangeValues)),
          filterObj?.ppvValue?.[1]
            ? filterObj?.ppvValue?.[1]
            : Math.ceil(_.max(state?.ppvRangeValues)),
        ],
        filterHvcScoreValue: [
          filterObj?.hvcScoreValue?.[0]
            ? filterObj?.hvcScoreValue?.[0]
            : Math.floor(_.min(state?.hvcScoreRangeValues)),
          filterObj?.hvcScoreValue?.[1]
            ? filterObj?.hvcScoreValue?.[1]
            : Math.ceil(_.max(state?.hvcScoreRangeValues)),
        ],
      });
    default:
      return state;
  }
}

export function filterStates(
  state = {
    filterAll: {},
    filterA: {},
    filterB: {},
    filterC: {},
    conversionPathFilterObj,
  },
  action
) {
  switch (action.type) {
    case types.SET_ALL_FILTER:
      let { allFilter } = action;
      return Object.assign({}, state, {
        filterAll: allFilter,
      });
    case types.SET_A_FILTER:
      let { aFilter } = action;
      return Object.assign({}, state, {
        filterA: aFilter,
      });
    case types.SET_B_FILTER:
      let { bFilter } = action;
      return Object.assign({}, state, {
        filterB: bFilter,
      });
    case types.SET_C_FILTER:
      let { cFilter } = action;
      return Object.assign({}, state, {
        filterC: cFilter,
      });

    case types.SET_CONVERSION_PATH_FILTER_OPTIONS_LIST:
      return {
        ...state,
        conversionPathFilterObj: action.payload,
      };
    default:
      return state;
  }
}

export function loginAuthentication(
  state = {
    isLoading: false,
    error: false,
    errorMessage: "",
    displayed_form: "",
    logged_in: localStorage.getItem("access") ? true : false,
    username: "",
  },
  action
) {
  switch (action.type) {
    case types.LOGIN_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
        error: false,
        errorMessage: "",
      });
    case types.LOGIN_SUCCESS:
      let { resultData } = action;
      localStorage.setItem("MFAEnable", resultData.user.mfa_enabled);
      localStorage.setItem("Firstname", resultData.user.first_name);
      localStorage.setItem("websitename", resultData.user.website_name);
      localStorage.setItem("access", resultData.access);
      localStorage.setItem("refresh", resultData.refresh);
      return Object.assign({}, state, {
        isLoading: false,
        ...action.resultData,
        logged_in: true,
        displayed_form: "",
        username: resultData.user.username,
      });
    case types.LOGIN_CLEAR_STATE:
      return Object.assign({}, state, {
        isLoading: false,
        error: false,
      });
    case types.LOGIN_FAILURE:
      // let {failureData} = action;
      return Object.assign({}, state, {
        isLoading: false,
        ...action.failureData,
        error: true,
        errorMessage: "Invalid Credentials",
      });
    case types.LOGOUT:
      localStorage.removeItem("expPage");
      localStorage.removeItem("MFAEnable");
      localStorage.removeItem("Firstname");
      localStorage.removeItem("websitename");
      localStorage.removeItem("MFAVerified");
      localStorage.removeItem("access");
      localStorage.removeItem("refresh");
      localStorage.removeItem("property_id");
      localStorage.removeItem("expChannel");
      localStorage.removeItem("ExpType");
      localStorage.removeItem("preExpId");
      localStorage.removeItem("business_property_id")
      localStorage.removeItem("shopify_re_auth")
      localStorage.removeItem("selectedAudienceId")
      localStorage.removeItem("navigationBackUrl")
      for (let i = 0; i < Object.keys(StoredSegmentData).length; i) {
        delete StoredSegmentData[Object.keys(StoredSegmentData)[i]];
      }
      return Object.assign({}, state, {
        isLoading: false,
        logged_in: false,
        username: "",
        error: false,
        errorMessage: " ",
      });
    default:
      return state;
  }
}

export function signUpReducer(
  state = {
    isLoading: false,
    message: "",
  },
  action
) {
  switch (action.type) {
    case types.SIGN_UP_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
      });
    case types.SIGN_UP_SUCCESS:
      let { resultData } = action;
      localStorage.setItem("access", resultData.access);
      localStorage.setItem("refresh", resultData.refresh);
      return Object.assign({}, state, {
        isLoading: false,
        ...action.resultData,
      });
    case types.SIGN_UP_CLEAR_STATE:
      return Object.assign({}, state, {
        isLoading: false,
        error: false,
        ...action.responseData,
      });
    case types.SIGN_UP_FAILURE:
      return Object.assign({}, state, {
        isLoading: false,
        ...action.resultData,
        message: "Sign up Failed",
      });
    default:
      return state;
  }
}

export function forgotPasswordReducer(
  state = {
    isLoading: false,
    message: "",
  },
  action
) {
  switch (action.type) {
    case types.FORGOT_PASSWORD_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
      });
    case types.FORGOT_PASSWORD_SUCCESS:
      // let {resultData} = action;
      return Object.assign({}, state, {
        isLoading: false,
        ...action.resultData,
      });
    case types.FORGOT_PASSWORD_CLEAR_STATE:
      // let {responseData} = action;
      return Object.assign({}, state, {
        isLoading: false,
        ...action.responseData,
      });
    case types.FORGOT_PASSWORD_FAILURE:
      return Object.assign({}, state, {
        isLoading: false,
        ...action.resultData,
        // message: "Sign up Failed",
      });
    default:
      return state;
  }
}

export function resetPasswordReducer(
  state = {
    isLoading: false,
    message: "",
  },
  action
) {
  switch (action.type) {
    case types.RESET_PASSWORD_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
      });
    case types.RESET_PASSWORD_SUCCESS:
      // let {resultData} = action;
      return Object.assign({}, state, {
        isLoading: false,
        ...action.resultData,
      });
    case types.RESET_PASSWORD_FAILURE:
      return Object.assign({}, state, {
        isLoading: false,
        ...action.resultData,
        // message: "Sign up Failed",
      });
    default:
      return state;
  }
}

export function websiteDetailsReducer(
  state = {
    isLoading: false,
    status: "",
  },
  action
) {
  switch (action.type) {
    case types.WEBSITE_DETAILS_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
      });
    case types.WEBSITE_DETAILS_SUCCESS:
      // let {response} = action;
      return Object.assign({}, state, {
        isLoading: false,
        ...action.response,
      });
    case types.WEBSITE_DETAILS_FAILURE:
      return Object.assign({}, state, {
        isLoading: false,
        status: "failed",
      });
    default:
      return state;
  }
}

export function shopifyIntegrateReducer(
  state = {
    isLoading: false,
    status: "",
  },
  action
) {
  switch (action.type) {
    case types.SHOPIFY_INTEGRATE_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
      });
    case types.SHOPIFY_INTEGRATE_SUCCESS:
      let { response } = action;
      window.location.assign(response.data.auth_url);
      return Object.assign({}, state, {
        isLoading: false,
        ...action.response,
      });
    case types.SHOPIFY_INTEGRATE_FAILURE:
      return Object.assign({}, state, {
        isLoading: false,
        ...action.resultData,
        status: "failed",
      });
    default:
      return state;
  }
}

export function bigcomIntegrateReducer(
  state = {
    isLoading: false,
    status: "",
  },
  action
) {
  switch (action.type) {
    case types.BIGCOM_INTEGRATE_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
      });
    case types.BIGCOM_INTEGRATE_SUCCESS:
      let { response } = action;
      window.location.assign(response.data.auth_url);
      return Object.assign({}, state, {
        isLoading: false,
        ...action.response,
      });
    case types.BIGCOM_INTEGRATE_FAILURE:
      return Object.assign({}, state, {
        isLoading: false,
        status: "failed",
      });
    default:
      return state;
  }
}

export function emailVerification(
  state = {
    isLoading: false,
    message: "",
  },
  action
) {
  switch (action.type) {
    case types.EMAIL_VERIFICATION_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
      });
    case types.EMAIL_VERIFICATION_SUCCESS:
      //   let { resultData } = action;
      return Object.assign({}, state, {
        isLoading: false,
        ...action.resultData,
      });
    case types.EMAIL_VERIFICATION_FAILURE:
      return Object.assign({}, state, {
        isLoading: false,
        message: "Email Verfication Failed",
      });
    default:
      return state;
  }
}

export function emailResend(
  state = {
    isLoading: false,
    message: "",
  },
  action
) {
  switch (action.type) {
    case types.EMAIL_RESEND_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
      });
    case types.EMAIL_RESEND_SUCCESS:
      //   let { resultData } = action;
      return Object.assign({}, state, {
        isLoading: false,
        ...action.resultData,
      });
    case types.EMAIL_RESEND_FAILURE:
      return Object.assign({}, state, {
        isLoading: false,
        message: "Resending Email Failed",
      });
    default:
      return state;
  }
}

export function helpCenter(
  state = {
    isLoading: false,
    message: "",
  },
  action
) {
  switch (action.type) {
    case types.HELPCENTER_QUERY_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
      });
    case types.HELPCENTER_QUERY_SUCCESS:
      //   let { resultData } = action;
      return Object.assign({}, state, {
        isLoading: false,
        ...action.resultData,
      });
    case types.HELPCENTER_QUERY_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        message: "Fetching search query failed",
      });
    default:
      return state;
  }
}

export function dataStatusOrderReducer(
  state = {
    isLoading: false,
    message: "",
  },
  action
) {
  switch (action.type) {
    case types.DATA_STATUS_ORDER_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
      });
    case types.DATA_STATUS_ORDER_SUCCESS:
      //   let { resultData } = action;
      return Object.assign({}, state, {
        isLoading: false,
        ...action.resultData,
      });
    case types.DATA_STATUS_ORDER_FAILURE:
      return Object.assign({}, state, {
        isLoading: false,
        message: "Fetching Data Status Order Failed",
      });
    default:
      return state;
  }
}

export function dataStatusOrderItemsReducer(
  state = {
    isLoading: false,
    message: "",
  },
  action
) {
  switch (action.type) {
    case types.DATA_STATUS_ORDER_ITEM_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
      });
    case types.DATA_STATUS_ORDER_ITEM_SUCCESS:
      //   let { resultData } = action;
      return Object.assign({}, state, {
        isLoading: false,
        ...action.resultData,
      });
    case types.DATA_STATUS_ORDER_ITEM_FAILURE:
      return Object.assign({}, state, {
        isLoading: false,
        message: "Fetching Data Status Order Items Failed",
      });
    default:
      return state;
  }
}

export function dataStatusTrackingInfoReducer(
  state = {
    isLoading: false,
    message: "",
  },
  action
) {
  switch (action.type) {
    case types.DATA_STATUS_TRACKINGINFO_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
      });
    case types.DATA_STATUS_TRACKINGINFO_SUCCESS:
      //   let { resultData } = action;
      return Object.assign({}, state, {
        isLoading: false,
        ...action.resultData,
      });
    case types.DATA_STATUS_TRACKINGINFO_FAILURE:
      return Object.assign({}, state, {
        isLoading: false,
        message: "Fetching Data Status Tracking Info Failed",
      });
    default:
      return state;
  }
}

export function trackingScript(
  state = {
    isLoading: false,
  },
  action
) {
  switch (action.type) {
    case types.TRACKING_SCRIPT_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
      });
    case types.TRACKING_SCRIPT_SUCCESS:
      //   const { resultData } = action;
      return Object.assign({}, state, {
        isLoading: false,
        ...action.resultData,
      });
    case types.TRACKING_SCRIPT_FAILURE:
      return Object.assign({}, state, {
        isLoading: false,
        message: "Fetching Tracking Script Failed",
      });
    default:
      return state;
  }
}

export function businessProfile(
  state = {
    isLoading: false,
  },
  action
) {
  switch (action.type) {
    case types.BUSINESS_PROFILE_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
      });
    case types.BUSINESS_PROFILE_SUCCESS:
      //   const { resultData } = action;
      return Object.assign({}, state, {
        isLoading: false,
        ...action.resultData,
      });
    case types.BUSINESS_PROFILE_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        message: "Fetching Business Profile Failed",
      });
    default:
      return state;
  }
}

export function refreshToken(
  state = {
    isLoading: false,
    message: "",
  },
  action
) {
  switch (action.type) {
    case types.REFRESH_TOKEN_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
      });
    case types.REFRESH_TOKEN_SUCCESS:
      let { resultData } = action;
      localStorage.setItem("access", resultData.access);
      localStorage.setItem("refresh", resultData.refresh);
      return Object.assign({}, state, {
        isLoading: false,
        ...action.resultData,
      });
    case types.REFRESH_TOKEN_FAILURE:
      return Object.assign({}, state, {
        isLoading: false,
        message: "Token Refresh Failed",
      });
    default:
      return state;
  }
}

export function passwordChange(
  state = {
    isLoading: false,
    message: "",
  },
  action
) {
  switch (action.type) {
    case types.PASSWORD_CHANGE_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
      });
    case types.PASSWORD_CHANGE_SUCCESS:
      // const {resultData} = action;
      return Object.assign({}, state, {
        isLoading: false,
        message: "Success",
        ...action.resultData,
      });
    case types.PASSWORD_CLEAR_STATE:
      return Object.assign({}, state, {
        isLoading: false,
        message: "",
      });
    case types.PASSWORD_CHANGE_FAILURE:
      return Object.assign({}, state, {
        isLoading: false,
        message: "Failed",
        ...action.resultData,
      });
    default:
      return state;
  }
}

export function requestFreeDemoReducer(
  state = {
    isLoading: false,
    message: "",
  },
  action
) {
  switch (action.type) {
    case types.REQUEST_FREE_DEMO_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
      });
    case types.REQUEST_FREE_DEMO_SUCCESS:
      // const {resultData} = action;
      return Object.assign({}, state, {
        isLoading: false,
        message: "Success",
        ...action.resultData,
      });
    case types.REQUEST_FREE_DEMO_FAILURE:
      return Object.assign({}, state, {
        isLoading: false,
        message: "Failed",
      });
    default:
      return state;
  }
}

export function userProfile(
  state = {
    isLoading: false,
    username: "",
    accid: "",
  },
  action
) {
  switch (action.type) {
    case types.USER_DETAILS_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
      });
    case types.USER_DETAILS_SUCCESS:
      const { resultData } = action;
      return Object.assign({}, state, {
        isLoading: false,
        ...action.resultData,
        username: resultData.username,
      });
    case types.USER_DETAILS_FAILURE:
      return Object.assign({}, state, {
        isLoading: false,
      });
    default:
      return state;
  }
}

export function overview_reducer(
  state = {
    isLoading: false,
  },
  action
) {
  switch (action.type) {
    case types.OVERVIEW_DATA_REQUESTED:
      return Object.assign({}, state, {
        isLoading: true,
      });
    case types.OVERVIEW_DATA_RECEIVED:
      let { data } = action;
      return Object.assign({}, state, {
        isLoading: false,
        ...action.data,
        totalRevenue: _.find(data.data, { segment: "all" })
          ? _.sumBy(data.data, "revenue") / 2
          : _.sumBy(data.data, "revenue"),
        totalCustomers: _.find(data.data, { segment: "all" })
          ? _.sumBy(data.data, "customers") / 2
          : _.sumBy(data.data, "customers"),
        totalOrders: _.find(data.data, { segment: "all" })
          ? _.sumBy(data.data, "orders") / 2
          : _.sumBy(data.data, "orders"),
      });
    case types.OVERVIEW_DATA_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
      });
    default:
      return state;
  }
}

export function segment_order_reducer(
  state = {
    isLoading: false,
  },
  action
) {
  switch (action.type) {
    case types.SEGMENT_ORDER_DATA_REQUESTED:
      return Object.assign({}, state, {
        isLoading: true,
      });
    case types.SEGMENT_ORDER_DATA_RECEIVED:
      // _.map(temp1,d=> _.zipObject(temp2,d))
      const { resultData } = action;
      const { data, columns } = resultData.orders;
      const dataObj = _.map(data, (d) => _.zipObject(columns, d));
      return Object.assign({}, state, {
        isLoading: false,
        ...action.resultData,
        orders: dataObj,
      });
    case types.SEGMENT_ORDER_DATA_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
      });
    default:
      return state;
  }
}

export function segment_compare_order_reducer(
  state = {
    isLoading: false,
  },
  action
) {
  switch (action.type) {
    case types.SEGMENT_COMPARE_ORDER_DATA_REQUESTED:
      return Object.assign({}, state, {
        isLoading: true,
      });
    case types.SEGMENT_COMPARE_ORDER_DATA_RECEIVED:
      // _.map(temp1,d=> _.zipObject(temp2,d))
      const { resultData } = action;
      const { data, columns } = resultData.orders;
      const dataObj = _.map(data, (d) => _.zipObject(columns, d));
      return Object.assign({}, state, {
        isLoading: false,
        ...action.resultData,
        orders: dataObj,
      });
    case types.SEGMENT_COMPARE_ORDER_DATA_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
      });
    default:
      return state;
  }
}

export function segment_path_reducer(
  state = {
    isLoading: false,
  },
  action
) {
  switch (action.type) {
    case types.SEGMENT_PATH_DATA_REQUESTED:
      return Object.assign({}, state, {
        isLoading: true,
      });
    case types.SEGMENT_PATH_DATA_RECEIVED:
      const { resultData } = action;
      return Object.assign({}, state, {
        isLoading: false,
        ...action.resultData,
        totalConversion: resultData.total_path_conv
          ? resultData.total_path_conv
          : _.sumBy(resultData.converted_paths, "conversion"),
        orderedPaths: _.orderBy(
          resultData.converted_paths,
          ["conversion"],
          ["desc"]
        ),
      });
    case types.SEGMENT_PATH_DATA_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
      });
    default:
      return state;
  }
}

export function segment_compare_path_reducer(
  state = {
    isLoading: false,
  },
  action
) {
  switch (action.type) {
    case types.SEGMENT_COMPARE_PATH_DATA_REQUESTED:
      return Object.assign({}, state, {
        isLoading: true,
      });
    case types.SEGMENT_COMPARE_PATH_DATA_RECEIVED:
      const { resultData } = action;
      return Object.assign({}, state, {
        isLoading: false,
        ...action.resultData,
        totalConversion: resultData.total_path_conv
          ? resultData.total_path_conv
          : _.sumBy(resultData.converted_paths, "conversion"),
        orderedPaths: _.orderBy(
          resultData.converted_paths,
          ["conversion"],
          ["desc"]
        ),
      });
    case types.SEGMENT_COMPARE_PATH_DATA_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
      });
    default:
      return state;
  }
}

export function segment_product_reducer(
  state = {
    isLoading: false,
  },
  action
) {
  switch (action.type) {
    case types.SEGMENT_PRODUCT_DATA_REQUESTED:
      return Object.assign({}, state, {
        isLoading: true,
      });
    case types.SEGMENT_PRODUCT_DATA_RECEIVED:
      const { resultData } = action;
      let { columns } = resultData.products;
      const { data } = resultData.products;
      let dataObj = _.map(data, (d) => _.zipObject(columns, d));

      return Object.assign({}, state, {
        isLoading: false,
        ...action.resultData,
        orderedProducts: _.orderBy(dataObj, ["revenue"], ["desc"]),
        totalRevenue: resultData.total_revenue
          ? resultData.total_revenue
          : _.sumBy(dataObj, "revenue"),
      });
    case types.SEGMENT_PRODUCT_DATA_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
      });
    default:
      return state;
  }
}

export function segment_compare_product_reducer(
  state = {
    isLoading: false,
  },
  action
) {
  switch (action.type) {
    case types.SEGMENT_COMPARE_PRODUCT_DATA_REQUESTED:
      return Object.assign({}, state, {
        isLoading: true,
      });
    case types.SEGMENT_COMPARE_PRODUCT_DATA_RECEIVED:
      const { resultData } = action;
      let { columns } = resultData.products;
      const { data } = resultData.products;
      let dataObj = _.map(data, (d) => _.zipObject(columns, d));

      return Object.assign({}, state, {
        isLoading: false,
        ...action.resultData,
        orderedProducts: _.orderBy(dataObj, ["revenue"], ["desc"]),
        totalRevenue: resultData.total_revenue
          ? resultData.total_revenue
          : _.sumBy(dataObj, "revenue"),
      });
    case types.SEGMENT_COMPARE_PRODUCT_DATA_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
      });
    default:
      return state;
  }
}

export function population_data_reducer(
  state = {
    isLoading: false,
  },
  action
) {
  switch (action.type) {
    case types.POPULATION_DATA_REQUESTED:
      return Object.assign({}, state, {
        isLoading: true,
      });
    case types.POPULATION_DATA_RECEIVED:
      // const {resultData} = action;
      return Object.assign({}, state, {
        isLoading: false,
        ...action.resultData,
      });
    case types.POPULATION_DATA_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
      });
    default:
      return state;
  }
}

export function user_segment_reducer(
  state = {
    isLoading: false,
  },
  action
) {
  switch (action.type) {
    case types.USER_SEGMENT_DATA_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
      });
    case types.USER_SEGMENT_DATA_SUCCESS:
      let { userData } = action;
      const { data, columns } = userData.users;
      let dataObj = _.map(data, (d) => _.zipObject(columns, d));
      // dataObj .map(v => Object.assign(v, {aov: (v.monetory_value/v.frequency).toFixed(2)}))
      return Object.assign({}, state, {
        isLoading: false,
        ...action.userData,
        users: dataObj,
      });
    case types.USER_SEGMENT_DATA_FAILURE:
      return Object.assign({}, state, {
        isLoading: false,
      });
    case types.USER_SEGMENT_DATA_RESET:
      return {
        isLoading: false,
      };
    default:
      return state;
  }
}

export function notification_data_reducer(
  state = {
    isLoading: false,
  },
  action
) {
  switch (action.type) {
    case types.NOTIFICATION_DATA_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
      });
    case types.NOTIFICATION_DATA_SUCCESS:
      // let {notificationData} = action;
      return Object.assign({}, state, {
        isLoading: false,
        ...action.notificationData,
      });
    case types.NOTIFICATION_DATA_FAILURE:
      return Object.assign({}, state, {
        isLoading: false,
      });
    default:
      return state;
  }
}

export function auth_url_data_reducer(
  state = {
    isLoading: false,
  },
  action
) {
  switch (action.type) {
    case types.AUTH_URL_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
      });
    case types.AUTH_URL_RECEIVED:
      // let {authData} = action;
      return Object.assign({}, state, {
        isLoading: false,
        ...action.authData,
      });
    case types.AUTH_URL_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
      });
    default:
      return state;
  }
}

export function fb_auth_url_data_reducer(
  state = {
    isLoading: false,
  },
  action
) {
  switch (action.type) {
    case types.FB_AUTH_URL_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
      });
    case types.FB_AUTH_URL_RECEIVED:
      // let {authData} = action;
      return Object.assign({}, state, {
        isLoading: false,
        ...action.authData,
      });
    case types.FB_AUTH_URL_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
      });
    default:
      return state;
  }
}

export function google_auth_url_data_reducer(
  state = {
    isLoading: false,
  },
  action
) {
  switch (action.type) {
    case types.GOOGLE_REAUTH_URL_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
      });
    case types.GOOGLE_REAUTH_URL_RECEIVED:
      // let {authData} = action;
      return Object.assign({}, state, {
        isLoading: false,
        ...action.authData,
      });
    case types.GOOGLE_REAUTH_URL_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
      });
    default:
      return state;
  }
}

export function auth_var_data_reducer_ga(
  state = {
    isLoading: false,
  },
  action
) {
  switch (action.type) {
    case types.GA_AUTH_VAR_SENT_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
      });
    case types.GA_AUTH_VAR_SENT_SUCCESS:
      // let {authResponse} = action;
      return Object.assign({}, state, {
        isLoading: false,
        ...action.authResponse,
      });
    case types.GA_AUTH_VAR_SENT_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
      });
    case types.GA_AUTH_VAR_RESET_STATE:
      return {
        isLoading: false,
      }
    
    default:
      return state;
  }
}

export function auth_var_data_reducer_fb(
  state = {
    isLoading: false,
  },
  action
) {
  switch (action.type) {
    case types.FB_AUTH_VAR_SENT_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
      });
    case types.FB_AUTH_VAR_SENT_SUCCESS:
      // let {authResponse} = action;
      return Object.assign({}, state, {
        isLoading: false,
        ...action.authResponse,
      });
    case types.FB_AUTH_VAR_SENT_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
      });
    default:
      return state;
  }
}

export function set_gaid_reducer(
  state = {
    isLoading: false,
  },
  action
) {
  switch (action.type) {
    case types.GAID_SENT_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
      });
    case types.GAID_SENT_SUCCESS:
      // let {responseData} = action;
      return Object.assign({}, state, {
        isLoading: false,
        ...action.responseData,
      });
    case types.GAID_SENT_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
      });
    default:
      return state;
  }
}

export function set_fbid_reducer(
  state = {
    isLoading: false,
  },
  action
) {
  switch (action.type) {
    case types.FBID_SENT_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
      });
    case types.FBID_SENT_SUCCESS:
      // let {responseData} = action;
      return Object.assign({}, state, {
        isLoading: false,
        ...action.responseData,
      });
    case types.FBID_SENT_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        success: false,
      });
    default:
      return state;
  }
}

export function set_redealid_reducer(
  state = {
    isLoading: false,
  },
  action
) {
  switch (action.type) {
    case types.REDEALID_SENT_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
      });
    case types.REDEALID_SENT_SUCCESS:
      // let {responseData} = action;
      return Object.assign({}, state, {
        isLoading: false,
        ...action.responseData,
      });
    case types.REDEALID_SENT_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
      });
    default:
      return state;
  }
}

export function terms_accepted_reducer(
  state = {
    isLoading: false,
  },
  action
) {
  switch (action.type) {
    case types.TERMS_ACCEPTED_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
      });
    case types.TERMS_ACCEPTED_SUCCESS:
      // let {responseData} = action;
      return Object.assign({}, state, {
        isLoading: false,
        ...action.responseData,
      });
    case types.TERMS_ACCEPTED_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
      });
    default:
      return state;
  }
}

export function push_audiences_reducer(
  state = {
    isLoading: false,
    status: "",
  },
  action
) {
  switch (action.type) {
    case types.PUSH_AUDIENCE_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
      });
    case types.PUSH_AUDIENCE_SUCCESS:
      // let {responseData} = action;
      return Object.assign({}, state, {
        isLoading: false,
        ...action.responseData,
        status: "Success",
      });
    case types.PUSH_AUDIENCE_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        status: "Failed",
      });
    case types.PUSH_AUDIENCE_RESET:
      return {
        isLoading: false,
        status: "",
      };
    default:
      return state;
  }
}

export function push_audiences_fb_reducer(
  state = {
    isLoading: false,
  },
  action
) {
  switch (action.type) {
    case types.FB_PUSH_AUDIENCE_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
      });
    case types.FB_PUSH_AUDIENCE_SUCCESS:
      // let {responseData} = action;
      return Object.assign({}, state, {
        isLoading: false,
        ...action.responseData,
      });
    case types.FB_PUSH_AUDIENCE_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
      });
    default:
      return state;
  }
}

export function list_audiences_reducer(
  state = {
    isLoading: false,
    reduxRequest: {},
  },
  action
) {
  switch (action.type) {
    case types.LIST_AUDIENCE_REQUEST:
      let { request } = action;
      return Object.assign({}, state, {
        isLoading: true,
        reduxRequest: request,
      });
    case types.LIST_AUDIENCE_SUCCESS:
      // let {responseData} = action;
      return Object.assign({}, state, {
        isLoading: false,
        ...action.responseData,
      });
    case types.LIST_AUDIENCE_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
      });
    case types.LIST_AUDIENCE_RESET:
      return {
        isLoading: false,
        reduxRequest: {},
      };
    default:
      return state;
  }
}

export function customer_report_custom_reducer(
  state = {
    isLoading: false,
  },
  action
) {
  switch (action.type) {
    case types.CUSTOMER_REPORT_CUSTOM_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
      });
    case types.CUSTOMER_REPORT_CUSTOM_SUCCESS:
      let { responseData } = action;
      window.location.assign(
        process.env.REACT_APP_AppUrl
          ? `${process.env.REACT_APP_AppUrl}/download/order_report/${responseData.report_name}`
          : `/download/order_report/${responseData.report_name}`
      );
      return Object.assign({}, state, {
        isLoading: false,
        ...responseData,
      });
    case types.CUSTOMER_REPORT_CUSTOM_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
      });
    default:
      return state;
  }
}

export function delete_channel_reducer(
  state = {
    isLoading: false,
  },
  action
) {
  switch (action.type) {
    case types.DELETE_CONNECTION_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
      });
    case types.DELETE_CONNECTION_SUCCESS:
      // let {responseData} = action;
      return Object.assign({}, state, {
        isLoading: false,
        ...action.responseData,
      });
    case types.DELETE_CONNECTION_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
      });
    default:
      return state;
  }
}

export function delete_custom_filter_reducer(
  state = {
    isLoading: false,
    status: "",
  },
  action
) {
  switch (action.type) {
    case types.DELETE_CUSTOMER_FILTER_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
      });
    case types.DELETE_CUSTOMER_FILTER_SUCCESS:
      // let {resultData} = action;
      return Object.assign({}, state, {
        isLoading: false,
        ...action.resultData,
      });
    case types.DELETE_CUSTOMER_FILTER_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
      });
    case types.CLEAR_DELETE_FILTER:
      return Object.assign({}, state, {
        isLoading: false,
        ...action.responseData,
      });
    default:
      return state;
  }
}

export function get_custom_filter_audience_reducer(
  state = {
    isLoading: false,
    status: "",
  },
  action
) {
  switch (action.type) {
    case types.AUDIENCES_CUSTOMER_FILTER_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
      });
    case types.AUDIENCES_CUSTOMER_FILTER_SUCCESS:
      // let {resultData} = action;
      return Object.assign({}, state, {
        isLoading: false,
        ...action.resultData,
      });
    case types.AUDIENCES_CUSTOMER_FILTER_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
      });
    case types.CLEAR_AUDIENCES_FILTER:
      return Object.assign({}, state, {
        isLoading: false,
        ...action.responseData,
      });
    default:
      return state;
  }
}

export function redeal_analytics_data_reducer(
  state = {
    isLoading: false,
  },
  action
) {
  switch (action.type) {
    case types.REDEAL_ANALYTICS_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
      });
    case types.REDEAL_ANALYTICS_SUCCESS:
      let { responseData } = action;
      const { data, columns } = responseData.audience_analytics;
      const dataObj = _.map(data, (d) => _.zipObject(columns, d));
      return Object.assign({}, state, {
        isLoading: false,
        ...action.responseData,
        redeal_analytics: dataObj,
      });
    case types.REDEAL_ANALYTICS_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
      });
    default:
      return state;
  }
}

export function redeal_compare_analytics_data_reducer(
  state = {
    isLoading: false,
  },
  action
) {
  switch (action.type) {
    case types.REDEAL_COMPARE_ANALYTICS_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
      });
    case types.REDEAL_COMPARE_ANALYTICS_SUCCESS:
      let { responseData } = action;
      const { data, columns } = responseData.audience_analytics;
      const dataObj = _.map(data, (d) => _.zipObject(columns, d));
      return Object.assign({}, state, {
        isLoading: false,
        ...action.responseData,
        redeal_analytics: dataObj,
      });
    case types.REDEAL_COMPARE_ANALYTICS_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
      });
    default:
      return state;
  }
}

export function googlead_analytics_data_reducer(
  state = {
    isLoading: false,
    synchedAudienceAnalytics: {},
  },
  action
) {
  switch (action.type) {
    case types.GOOGLEAD_ANALYTICS_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
      });
    case types.GOOGLEAD_ANALYTICS_SUCCESS:
      let { responseData } = action;
      const { data, columns } = responseData.audience_analytics;
      const dataObj = _.map(data, (d) => _.zipObject(columns, d));
      return {
        ...state,
        isLoading: false,
        ...action.responseData,
        google_analytics: dataObj,
        synchedAudienceAnalytics: responseData,
      };
    case types.GOOGLEAD_ANALYTICS_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
      });
    default:
      return state;
  }
}

export function googlead_compare_analytics_data_reducer(
  state = {
    isLoading: false,
    synchedAudienceCompareGoogleAnalytics: {},
  },
  action
) {
  switch (action.type) {
    case types.GOOGLEAD_COMPARE_ANALYTICS_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
      });
    case types.GOOGLEAD_COMPARE_ANALYTICS_SUCCESS:
      let { responseData } = action;
      const { data, columns } = responseData.audience_analytics;
      const dataObj = _.map(data, (d) => _.zipObject(columns, d));
      return {
        ...state,
        isLoading: false,
        ...action.responseData,
        google_analytics: dataObj,
        synchedAudienceCompareGoogleAnalytics: responseData,
      };
    case types.GOOGLEAD_COMPARE_ANALYTICS_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
      });
    default:
      return state;
  }
}

export function googlead_active_audience_reducer(
  state = {
    isLoading: false,
    active_audience: [],
  },
  action
) {
  switch (action.type) {
    case types.GOOGLEAD_ACTIVE_LIST_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
      });
    case types.GOOGLEAD_ACTIVE_LIST_SUCCESS:
      let { responseData } = action;
      const { data, columns } = responseData.result;
      const dataObj = _.map(data, (d) => _.zipObject(columns, d));
      return Object.assign({}, state, {
        isLoading: false,
        ...action.responseData,
        active_audience: dataObj,
      });
    case types.GOOGLEAD_ACTIVE_LIST_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        active_audience: [],
      });
    default:
      return state;
  }
}

export function single_buyer_repeat_reducer(
  state = {
    isLoading: false,
  },
  action
) {
  switch (action.type) {
    case types.SINGLE_BUYER_REPEAT_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
      });
    case types.SINGLE_BUYER_REPEAT_SUCCESS:
      // let {responseData} = action;
      // const {data, columns} = responseData.redeal_analytics;
      // const dataObj = _.map(data, d => _.zipObject(columns, d));
      return Object.assign({}, state, {
        isLoading: false,
        ...action.responseData,
        // redeal_analytics: dataObj
      });
    case types.SINGLE_BUYER_REPEAT_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
      });
    default:
      return state;
  }
}

export function repeat_next_3months_reducer(
  state = {
    isLoading: false,
  },
  action
) {
  switch (action.type) {
    case types.REPEAT_NEXT_3M_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
      });
    case types.REPEAT_NEXT_3M_SUCCESS:
      // let {responseData} = action;
      // const {data, columns} = responseData.redeal_analytics;
      // const dataObj = _.map(data, d => _.zipObject(columns, d));
      return Object.assign({}, state, {
        isLoading: false,
        ...action.responseData,
        // redeal_analytics: dataObj
      });
    case types.REPEAT_NEXT_3M_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
      });
    default:
      return state;
  }
}

export function visitor_to_customer_reducer(
  state = {
    isLoading: false,
  },
  action
) {
  switch (action.type) {
    case types.VISITOR_TO_CUSTOMER_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
      });
    case types.VISITOR_TO_CUSTOMER_SUCCESS:
      // let {responseData} = action;
      // const {data, columns} = responseData.redeal_analytics;
      // const dataObj = _.map(data, d => _.zipObject(columns, d));
      return Object.assign({}, state, {
        isLoading: false,
        ...action.responseData,
        // redeal_analytics: dataObj
      });
    case types.VISITOR_TO_CUSTOMER_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
      });
    default:
      return state;
  }
}

export function predictions_summary_reducer(
  state = {
    isLoading: false,
  },
  action
) {
  switch (action.type) {
    case types.PREDICTIONS_SUMMARY_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
      });
    case types.PREDICTIONS_SUMMARY_SUCCESS:
      let { responseData } = action;
      const { data, columns } = responseData.data;
      const dataObj = _.map(data, (d) => _.zipObject(columns, d));
      return Object.assign({}, state, {
        isLoading: false,
        ...action.responseData,
        current_prediction_status: dataObj,
      });
    case types.PREDICTIONS_SUMMARY_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
      });
    default:
      return state;
  }
}

export function audience_history_data_reducer(
  state = {
    isLoading: false,
    audience_history: {},
  },
  action
) {
  switch (action.type) {
    case types.AUDIENCE_HISTORY_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
      });
    case types.AUDIENCE_HISTORY_SUCCESS:
      let { responseData } = action;
      const { data, columns } = responseData.audience_history;
      const dataObj = _.map(data, (d) => _.zipObject(columns, d));
      return Object.assign({}, state, {
        isLoading: false,
        ...responseData,
        audience_history: dataObj,
      });
    case types.AUDIENCE_HISTORY_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
      });
    default:
      return state;
  }
}

export function update_replace_existing_list_reducer(
  state = {
    isLoading: false,
  },
  action
) {
  switch (action.type) {
    case types.UPDATE_REPLACE_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
      });
    case types.UPDATE_REPLACE_SUCCESS:
      let { responseData } = action;
      // const {data, columns} = responseData.audience_history;
      // const dataObj = _.map(data, d => _.zipObject(columns, d));
      return Object.assign({}, state, {
        isLoading: false,
        ...responseData,
        // audience_history: dataObj
      });
    case types.UPDATE_REPLACE_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
      });
    default:
      return state;
  }
}

export function update_replace_existing_list_fb_reducer(
  state = {
    isLoading: false,
  },
  action
) {
  switch (action.type) {
    case types.FB_UPDATE_REPLACE_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
      });
    case types.FB_UPDATE_REPLACE_SUCCESS:
      let { responseData } = action;
      // const {data, columns} = responseData.audience_history;
      // const dataObj = _.map(data, d => _.zipObject(columns, d));
      return Object.assign({}, state, {
        isLoading: false,
        ...responseData,
        // audience_history: dataObj
      });
    case types.FB_UPDATE_REPLACE_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
      });
    default:
      return state;
  }
}

export function edit_audience_reducer(
  state = {
    isLoading: false,
    success: "",
  },
  action
) {
  switch (action.type) {
    case types.EDIT_AUDIENCE_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
      });
    case types.EDIT_AUDIENCE_SUCCESS:
      let { responseData } = action;
      return Object.assign({}, state, {
        isLoading: false,
        ...responseData,
      });
    case types.EDIT_AUDIENCE_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
      });
    default:
      return state;
  }
}

export function contactus_reducer(
  state = {
    isLoading: false,
    message: "",
  },
  action
) {
  switch (action.type) {
    case types.CONTACTUS_FORM_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
      });
    case types.CONTACTUS_FORM_SUCCESS:
      //   const { resultData } = action;
      return Object.assign({}, state, {
        isLoading: false,
        message: "Success",
        ...action.resultData,
      });
    case types.CONTACTUS_FORM_CLEAR_STATE:
      return Object.assign({}, state, {
        isLoading: false,
        message: "",
      });
    case types.CONTACTUS_FORM_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        ...action.resultData,
        message: "Failed",
      });
    default:
      return state;
  }
}

export function productTourReducer(
  state = {
    run: false,
    stepIndex: 0,
  },
  action
) {
  switch (action.type) {
    case types.PRODUCT_TOUR_RUN:
      let { tourRunValue } = action;
      return Object.assign({}, state, {
        run: tourRunValue,
      });
    case types.PRODUCT_TOUR_RUN_END:
      return Object.assign({}, state, {
        run: false,
      });
    case types.PRODUCT_TOUR_RUN_START:
      return Object.assign({}, state, {
        run: true,
      });
    case types.PRODUCT_TOUR_STEP_INDEX:
      let { tourStepIndexValue } = action;
      return Object.assign({}, state, {
        stepIndex: tourStepIndexValue,
      });
    default:
      return state;
  }
}

export function customerTrendReducer(
  state = {
    isLoading: false,
    selectedMetricValue: defaultMetric,
    graphDataObj: {},
  },
  action
) {
  switch (action.type) {
    case types.CUSTOMER_TREND_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
      });
    case types.CUSTOMER_TREND_SUCCESS:
      //   let { resultData } = action;
      return Object.assign({}, state, {
        isLoading: false,
        ...action.resultData,
      });
    case types.CUSTOMER_TREND_FAILURE:
      return Object.assign({}, state, {
        isLoading: false,
      });

    case types.CUSTOMER_TREND_SELECTED_METRIC_VALUE:
      return {
        ...state,
        ...action.payload,
      };

    case types.CUSTOMER_TREND_GRAPH_DATA_OBJ:
      return {
        ...state,
        graphDataObj: action.payload,
      };

    default:
      return state;
  }
}

export function churn_prediction_reducer(
  state = {
    isLoading: false,
  },
  action
) {
  switch (action.type) {
    case types.CHURN_PREDICTION_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
      });
    case types.CHURN_PREDICTION_SUCCESS:
      //   let { responseData } = action;
      return Object.assign({}, state, {
        isLoading: false,
        ...action.responseData,
      });
    case types.CHURN_PREDICTION_FAILURE:
      return Object.assign({}, state, {
        isLoading: false,
      });
    default:
      return state;
  }
}

export function downtime_banner_reducer(
  state = {
    isLoading: false,
  },
  action
) {
  switch (action.type) {
    case types.DOWNTIME_BANNER_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
      });
    case types.DOWNTIME_BANNER_SUCCESS:
      //   let { responseData } = action;
      return Object.assign({}, state, {
        isLoading: false,
        ...action.responseData,
      });
    case types.DOWNTIME_BANNER_FAILURE:
      return Object.assign({}, state, {
        isLoading: false,
      });
    default:
      return state;
  }
}

export function save_custom_filter_reducer(
  state = {
    isLoading: false,
    message: "",
  },
  action
) {
  switch (action.type) {
    case types.SAVE_CUSTOM_FILTER_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
      });
    case types.SAVE_CUSTOM_FILTER_SUCCESS:
      const { resultData } = action;
      if (resultData.status === "success") {
        let filterArray = {
          filter_id: resultData.data.id,
          filter_name: resultData.data.filter_name,
          type: resultData.data.type,
          segment_a: resultData.data.segment_a,
          segment_b: resultData.data.segment_b,
          segment_c: resultData.data.segment_c,
          recencyValue: [
            resultData.data.min_recency,
            resultData.data.max_recency,
          ],
          frequencyValue: [
            resultData.data.min_frequency,
            resultData.data.max_frequency,
          ],
          monetaryValue: [
            resultData.data.min_monetary,
            resultData.data.max_monetary,
          ],
          aovValue: [resultData.data.min_aov, resultData.data.max_aov],
          ppvValue: [resultData.data.min_ppv, resultData.data.max_ppv],
          hvcScoreValue: [
            resultData.data.min_hvc_score,
            resultData.data.max_hvc_score,
          ],
          pred_single_repeat_buyer: resultData.data.pred_single_repeat_buyer,
          pred_repeat_buyer_2m: resultData.data.pred_repeat_buyer_2m,
          pred_churn: resultData.data.pred_churn,
          filter_stats: resultData.data.filter_stats,
          last_updated: resultData.data.last_updated,
        };
        return Object.assign({}, state, {
          isLoading: false,
          message: "Success",
          ...action.resultData,
          filterArray,
        });
      } else {
        return Object.assign({}, state, {
          isLoading: false,
          message: "Failed",
          ...action.resultData,
        });
      }
    case types.CLEAR_CUSTOM_FILTER:
      // const {responseData} = action;
      return Object.assign({}, state, {
        isLoading: false,
        message: "",
        filterArray: {},
        ...action.responseData,
      });
    case types.SAVE_CUSTOM_FILTER_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        message: "Failed",
      });
    default:
      return state;
  }
}

export function fetch_custom_filter_reducer(
  state = {
    isLoading: false,
    message: "",
    filterArray: [],
    isViewHistoryButtonClicked: false,
    selectedAudienceData: {},
    selectedSynchedAudienceData: {},
    editSyncedAudienceResponse: {},
    syncStatusEnableDisableResponse: {},
    isRemarketPopUpOpen: false,
    isSuccessModalOpen: {
      value: false,
      message: "",
    },
    isFailureModalOpen: {
      value: false,
      message: "",
    },
  },
  action
) {
  switch (action.type) {
    case types.FETCH_CUSTOM_FILTER_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
      });
    case types.CREATE_NEW_FITLER_OBJECT:
      const {
        recencyValue,
        frequencyValue,
        monetaryValue,
        aovValue,
        ppvValue,
        hvcScoreValue,
        segmentAValue,
        segmentBValue,
        segmentCValue,
        pred_repeat_buyer_2m,
        pred_churn,
        pred_single_repeat_buyer,
        calc_total_customers,
        calc_total_orders,
        calc_orders_per_customer,
        calc_rev_per_customer,
        avg_hvcScore,
      } = action;
      let filterObjArray = {
        filter_id: "applied_filter",
        filter_name: "applied_filter",
        type: "do_not_display",
        segment_a: segmentAValue,
        segment_b: segmentBValue,
        segment_c: segmentCValue,
        recencyValue: recencyValue,
        frequencyValue: frequencyValue,
        monetaryValue: monetaryValue,
        aovValue: aovValue,
        ppvValue: ppvValue,
        hvcScoreValue: hvcScoreValue,
        pred_single_repeat_buyer: pred_single_repeat_buyer,
        pred_repeat_buyer_2m: pred_repeat_buyer_2m,
        pred_churn: pred_churn,
        filter_stats: {
          customer_count: calc_total_customers,
          order_count: calc_total_orders,
          orders_per_customer: calc_orders_per_customer,
          revenue_per_customer: calc_rev_per_customer,
          avg_hvc_score: avg_hvcScore,
          unique_email_count: calc_total_customers,
          last_updated: new Date().toUTCString(),
        },
        last_updated: new Date(),
      };
      state.filterArray = _.reject(state.filterArray, function (o) {
        return o.filter_id === "applied_filter";
      });
      return Object.assign({}, state, {
        isLoading: false,
        message: "Success",
        ...state.filterArray.push(filterObjArray),
      });
    case types.FETCH_CUSTOM_FILTER_SUCCESS:
      const { resultData } = action;
      // console.log('hit');
      let filtersArray = resultData.data.map((filter, index) => ({
        filter_id: filter.id,
        filter_name: filter.filter_name,
        type: filter.type,
        segment_a: filter.segment_a,
        segment_b: filter.segment_b,
        segment_c: filter.segment_c,
        recencyValue: [filter.min_recency, filter.max_recency],
        frequencyValue: [filter.min_frequency, filter.max_frequency],
        monetaryValue: [filter.min_monetary, filter.max_monetary],
        aovValue: [filter.min_aov, filter.max_aov],
        ppvValue: [filter.min_ppv, filter.max_ppv],
        hvcScoreValue: [filter.min_hvc_score, filter.max_hvc_score],
        pred_single_repeat_buyer: filter.pred_single_repeat_buyer,
        pred_repeat_buyer_2m: filter.pred_repeat_buyer_2m,
        pred_churn: filter.pred_churn,
        filter_stats: filter.filter_stats,
        last_updated: filter.last_updated,
        filter_type: filter.static_filter?.filter_type,
      }));
      // console.log('filterArray',filterArray);
      return Object.assign({}, state, {
        isLoading: false,
        message: "Success",
        filterArray: filtersArray,
      });
    case types.ADD_CUSTOM_FILTER_OBJECT:
      const { data } = action;
      if (data.status === "success") {
        let newFilterObject = {
          filter_id: data.data.id,
          filter_name: data.data.filter_name,
          type: data.data.type,
          segment_a: data.data.segment_a,
          segment_b: data.data.segment_b,
          segment_c: data.data.segment_c,
          recencyValue: [data.data.min_recency, data.data.max_recency],
          frequencyValue: [data.data.min_frequency, data.data.max_frequency],
          monetaryValue: [data.data.min_monetary, data.data.max_monetary],
          aovValue: [data.data.min_aov, data.data.max_aov],
          ppvValue: [data.data.min_ppv, data.data.max_ppv],
          hvcScoreValue: [data.data.min_hvc_score, data.data.max_hvc_score],
          pred_single_repeat_buyer: data.data.pred_single_repeat_buyer,
          pred_repeat_buyer_2m: data.data.pred_repeat_buyer_2m,
          pred_churn: data.data.pred_churn,
          filter_stats: data.data.filter_stats,
          last_updated: data.data.last_updated,
        };
        return Object.assign({}, state, {
          isLoading: false,
          message: "Success",
          ...state.filterArray.push(newFilterObject),
        });
      } else {
        return Object.assign({}, state, {});
      }
    case types.FETCH_CUSTOM_FILTER_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        message: "Failed",
      });

    case types.SELECTED_AUDIENCE_DATA:
      return {
        ...state,
        ...action.payload,
      };

    case types.SYNCHED_AUDIENCE_HISTORY_CLICKED:
      return {
        ...state,
        ...action.payload,
      };

    case types.SELECTED_SYNCHED_AUDIENCE_DATA:
      return {
        ...state,
        ...action.payload,
      };

    case types.FETCH_CUSTOM_FILTER_RESET:
      return {
        ...state,
        filterArray: [],
      };

    case types.SYNCED_AUDIENCE_EDIT_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case types.SYNCED_AUDIENCE_EDIT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        message: "Success",
        editSyncedAudienceResponse: action.resultData,
      };

    case types.SYNCED_AUDIENCE_EDIT_FAILED:
      return {
        ...state,
        isLoading: false,
        message: "Failed",
        editSyncedAudienceResponse: action.resultData,
      };

    case types.SYNCED_AUDIENCE_EDIT_RESET:
      return {
        ...state,
        editSyncedAudienceResponse: {},
      };
    case types.SYNCED_AUDIENCE_ENABLE_DISABLE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case types.SYNCED_AUDIENCE_ENABLE_DISABLE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        message: "Success",
        syncStatusEnableDisableResponse: action.resultData,
      };
    case types.SYNCED_AUDIENCE_ENABLE_DISABLE_FAILED:
      return {
        isLoading: false,
        message: "Failed",
        syncStatusEnableDisableResponse: action.resultData,
      };

    case types.SYNCED_AUDIENCE_ENABLE_DISABLE_RESET:
      return {
        ...state,
        syncStatusEnableDisableResponse: {},
      };

    case types.SUCCESS_MODAL_VALUE:
      return {
        ...state,
        isSuccessModalOpen: action.payload,
      };
    case types.FAILURE_MODAL_VALUE:
      return {
        ...state,
        isFailureModalOpen: action.payload,
      };

    case types.REMARKET_POPUP_STATUS:
      return {
        ...state,
        isRemarketPopUpOpen: action.payload,
      };

    default:
      return state;
  }
}

export function getCustomFilterAudiencesReducer(
  state = {
    isLoading: false,
    message: "",
    customFilterAudiencesList: [],
  },
  action
) {
  switch (action.type) {
    case types.GET_CUSTOM_FILTER_AUDIENCE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case types.GET_CUSTOM_FILTER_AUDIENCE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        customFilterAudiencesList: action?.resultData?.data,
      };

    case types.GET_CUSTOM_FILTER_AUDIENCE_FAILED:
      return {
        ...state,
        isLoading: false,
        message: "Failed",
      };

    case types.GET_CUSTOM_FILTER_AUDIENCE_RESET:
      return {
        ...state,
        customFilterAudiencesList: [],
      };

    default:
      return state;
  }
}

export function rename_filter_reducer(
  state = {
    isLoading: false,
    message: "",
  },
  action
) {
  switch (action.type) {
    case types.RENAME_FILTER_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
      });
    case types.RENAME_FILTER_SUCCESS:
      // const {resultData} = action;
      return Object.assign({}, state, {
        isLoading: false,
        message: "Success",
        ...action.resultData,
      });
    case types.RENAME_FILTER_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        message: "Failed",
      });
    default:
      return state;
  }
}

export function generateContentReducer(
  state = {
    isLoading: false,
    message: "",
  },
  action
) {
  switch (action.type) {
    case types.GENERATE_CONTENT_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
      });
    case types.GENERATE_CONTENT_SUCCESS:
      // let {resultData} = action;
      return Object.assign({}, state, {
        isLoading: false,
        ...action.responseData,
      });
    case types.GENERATE_CONTENT_FAILURE:
      return Object.assign({}, state, {
        isLoading: false,
        message: "failed",
      });
    default:
      return state;
  }
}

export function generateMoreContentReducer(
  state = {
    isLoading: false,
    message: "",
  },
  action
) {
  switch (action.type) {
    case types.GENERATE_MORE_CONTENT_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
      });
    case types.GENERATE_MORE_CONTENT_SUCCESS:
      // let {resultData} = action;
      return Object.assign({}, state, {
        isLoading: false,
        ...action.responseData,
      });
    case types.GENERATE_MORE_CONTENT_FAILURE:
      return Object.assign({}, state, {
        isLoading: false,
        message: "failed",
      });
    default:
      return state;
  }
}

export function shopifyAccessTokenReducer(
  state = {
    isLoading: false,
    message: "",
  },
  action
) {
  switch (action.type) {
    case types.SHOPIFY_ACCESS_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
      });
    case types.SHOPIFY_ACCESS_SUCCESS:
      //   let { resultData } = action;
      return Object.assign({}, state, {
        isLoading: false,
        ...action.authResponse,
        message: "success",
      });
    case types.SHOPIFY_ACCESS_FAILURE:
      return Object.assign({}, state, {
        isLoading: false,
        ...action.resultData,
        message: "failed",
      });
    default:
      return state;
  }
}

export function shopifyDataStatusOrderReducer(
  state = {
    isLoading: false,
    message: "",
  },
  action
) {
  switch (action.type) {
    case types.SHOPIFY_DATA_STATUS_ORDER_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
      });
    case types.SHOPIFY_DATA_STATUS_ORDER_SUCCESS:
      //   let { resultData } = action;
      return Object.assign({}, state, {
        isLoading: false,
        ...action.resultData,
      });
    case types.SHOPIFY_DATA_STATUS_ORDER_FAILURE:
      return Object.assign({}, state, {
        isLoading: false,
        message: "Fetching Shopify Data Status Order Failed",
      });
    default:
      return state;
  }
}

export function shopifyDataStatusOrderItemsReducer(
  state = {
    isLoading: false,
    message: "",
  },
  action
) {
  switch (action.type) {
    case types.SHOPIFY_DATA_STATUS_ORDER_ITEM_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
      });
    case types.SHOPIFY_DATA_STATUS_ORDER_ITEM_SUCCESS:
      //   let { resultData } = action;
      return Object.assign({}, state, {
        isLoading: false,
        ...action.resultData,
      });
    case types.SHOPIFY_DATA_STATUS_ORDER_ITEM_FAILURE:
      return Object.assign({}, state, {
        isLoading: false,
        message: "Fetching Shopify Data Status Order Items Failed",
      });
    default:
      return state;
  }
}

export function shopifyDataStatusTrackingInfoReducer(
  state = {
    isLoading: false,
    message: "",
  },
  action
) {
  switch (action.type) {
    case types.SHOPIFY_DATA_STATUS_TRACKINGINFO_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
      });
    case types.SHOPIFY_DATA_STATUS_TRACKINGINFO_SUCCESS:
      //   let { resultData } = action;
      return Object.assign({}, state, {
        isLoading: false,
        ...action.resultData,
      });
    case types.SHOPIFY_DATA_STATUS_TRACKINGINFO_FAILURE:
      return Object.assign({}, state, {
        isLoading: false,
        message: "Fetching Shopify Data Status Tracking Info Failed",
      });
    default:
      return state;
  }
}

export function shopifyDataProgressReducer(
  state = {
    isLoading: false,
    message: "",
  },
  action
) {
  switch (action.type) {
    case types.SHOPIFY_DATA_PROGRESS_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
      });
    case types.SHOPIFY_DATA_PROGRESS_SUCCESS:
      let { resultData } = action;
      return Object.assign({}, state, {
        isLoading: false,
        resultData,
      });
    case types.SHOPIFY_DATA_PROGRESS_FAILURE:
      return Object.assign({}, state, {
        isLoading: false,
        message: "failed",
      });
    default:
      return state;
  }
}

export function fetchAndClusterReducer(
  state = {
    isLoading: false,
    message: "",
  },
  action
) {
  switch (action.type) {
    case types.FETCH_AND_CLUSTER_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
      });
    case types.FETCH_AND_CLUSTER_SUCCESS:
      // let {resultData} = action;
      return Object.assign({}, state, {
        isLoading: false,
        ...action.responseData,
        message: "success",
      });
    case types.FETCH_AND_CLUSTER_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        message: "failed",
      });
    default:
      return state;
  }
}

export function productBundleReducer(
  state = {
    isLoading: false,
    message: "",
  },
  action
) {
  switch (action.type) {
    case types.PRODUCT_BUNDLE_DATA_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
      });
    case types.PRODUCT_BUNDLE_DATA_SUCCESS:
      let { resultData } = action;
      return Object.assign({}, state, {
        isLoading: false,
        product_bundles: resultData,
        ...action.resultData,
      });
    case types.PRODUCT_BUNDLE_DATA_FAILURE:
      return Object.assign({}, state, {
        isLoading: false,
        ...action.resultData,
      });
    default:
      return state;
  }
}

export function shopifyHMACreducer(
  state = {
    isLoading: false,
    message: "",
  },
  action
) {
  switch (action.type) {
    case types.SHOPIFY_APP_HMAC_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
      });
    case types.SHOPIFY_APP_HMAC_SUCCESS:
      //   let { resultData } = action;
      return Object.assign({}, state, {
        isLoading: false,
        ...action.resultData,
        message: "success",
      });
    case types.SHOPIFY_APP_HMAC_FAILURE:
      return Object.assign({}, state, {
        isLoading: false,
        ...action.resultData,
        message: "failed",
      });
    default:
      return state;
  }
}

export function shopifyBuIdAccessTokenReducer(
  state = {
    isLoading: false,
    message: "",
  },
  action
) {
  switch (action.type) {
    case types.SHOPIFY_BUID_ACCESS_TOKEN_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
      });
    case types.SHOPIFY_BUID_ACCESS_TOKEN_SUCCESS:
      //   let { resultData } = action;
      return Object.assign({}, state, {
        isLoading: false,
        ...action.authResponse,
        message: "success",
      });
    case types.SHOPIFY_BUID_ACCESS_TOKEN_FAILURE:
      return Object.assign({}, state, {
        isLoading: false,
        ...action.resultData,
        message: "failed",
      });
    default:
      return state;
  }
}

export function productDataReducer(
  state = {
    isLoading: false,
    message: "",
  },
  action
) {
  switch (action.type) {
    case types.PRODUCT_DATA_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
      });
    case types.PRODUCT_DATA_SUCCESS:
      let { resultData } = action;
      // console.log(resultData,"resultData")
      let columns = resultData.columns;
      const data = resultData.product_insights_data;
      let dataObj = _.map(data, (d) => _.zipObject(columns, d));
      return Object.assign({}, state, {
        isLoading: false,
        product_data: dataObj,
        ...action.resultData,
      });
    case types.PRODUCT_DATA_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        ...action.resultData,
      });
    default:
      return state;
  }
}

export function productCategoryDataReducer(
  state = {
    isLoading: false,
    message: "",
  },
  action
) {
  switch (action.type) {
    case types.PRODUCT_CATEGORY_DATA_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
      });
    case types.PRODUCT_CATEGORY_DATA_SUCCESS:
      let { resultData } = action;
      return Object.assign({}, state, {
        isLoading: false,
        data: resultData,
        status: resultData.status,
        message: "success",
      });
    case types.PRODUCT_CATEGORY_DATA_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        message: "failed",
        status: resultData.status,
      });
    default:
      return state;
  }
}

export function MFAEnableReducer(
  state = {
    isLoading: false,
    status: "",
  },
  action
) {
  switch (action.type) {
    case types.MFA_ENABLE_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
      });
    case types.MFA_ENABLE_SUCCESS:
      let { resultData } = action;
      return Object.assign({}, state, {
        isLoading: false,
        message: resultData.message,
        qrCode: resultData.qr_code_image,
        status: "success",
      });
    case types.MFA_ENABLE_FAILURE:
      return Object.assign({}, state, {
        isLoading: false,
        ...action.resultData,
        status: "failed",
      });
    default:
      return state;
  }
}

export function MFAVerficationReducer(
  state = {
    isLoading: false,
    status: "",
  },
  action
) {
  switch (action.type) {
    case types.MFA_VERIFICATION_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
      });
    case types.MFA_VERIFICATION_SUCCESS:
      // let { resultData } = action;
      return Object.assign({}, state, {
        isLoading: false,
        ...action.resultData,
        status: "success",
      });
    case types.MFA_VERIFICATION_FAILURE:
      return Object.assign({}, state, {
        isLoading: false,
        ...action.resultData,
        status: "failed",
      });
    default:
      return state;
  }
}

export function productPageMetaDataReducer(
  state = {
    isLoading: false,
  },
  action
) {
  switch (action.type) {
    case types.PRODUCT_PAGE_META_DATA_REQUESTED:
      return Object.assign({}, state, {
        isLoading: true,
      });
    case types.PRODUCT_PAGE_META_DATA_RECEIVED:
      // const {resultData} = action;
      return Object.assign({}, state, {
        isLoading: false,
        ...action.resultData,
      });
    case types.PRODUCT_PAGE_META_DATA_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
      });
    default:
      return state;
  }
}

export function priceHistogramReducer(
  state = {
    isLoading: false,
    message: "",
  },
  action
) {
  switch (action.type) {
    case types.PRICE_HISTOGRAM_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
      });
    case types.PRICE_HISTOGRAM_SUCCESS:
      // let { resultData } = action;
      return Object.assign({}, state, {
        isLoading: false,
        ...action.resultData,
        message: "success",
      });
    case types.PRICE_HISTOGRAM_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        ...action.resultData,
        message: "failed",
      });
    default:
      return state;
  }
}

export function productReportReducer(
  state = {
    isLoading: false,
  },
  action
) {
  switch (action.type) {
    case types.PRODUCT_REPORT_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
      });
    case types.PRODUCT_REPORT_SUCCESS:
      // const {resultData} = action;
      return Object.assign({}, state, {
        isLoading: false,
        ...action.resultData,
      });
    case types.PRODUCT_REPORT_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
      });
    default:
      return state;
  }
}

export function createExperimentReducer(
  state = {
    isLoading: false,
    status: "",
  },
  action
) {
  switch (action.type) {
    case types.CREATE_EXPERIMENT_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
      });
    case types.CREATE_EXPERIMENT_SUCCESS:
      // let {response} = action;
      return Object.assign({}, state, {
        isLoading: false,
        ...action.response,
      });
    case types.CREATE_EXPERIMENT_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        ...action.response,
        status: "failed",
      });
    default:
      return state;
  }
}

export function expAudienceReducer(
  state = {
    isLoading: false,
    status: "",
  },
  action
) {
  switch (action.type) {
    case types.EXP_AUDIENCE_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
      });
    case types.EXP_AUDIENCE_SUCCESS:
      // let {response} = action;
      return Object.assign({}, state, {
        isLoading: false,
        ...action.response.data,
      });
    case types.EXP_AUDIENCE_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        status: "failed",
      });
    default:
      return state;
  }
}

export function expCampaignReducer(
  state = {
    isLoading: false,
    status: "",
  },
  action
) {
  switch (action.type) {
    case types.EXP_CAMPAIGN_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
      });
    case types.EXP_CAMPAIGN_SUCCESS:
      // let { resultData } = action;
      return Object.assign({}, state, {
        isLoading: false,
        ...action.resultData,
      });
    case types.EXP_CAMPAIGN_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        status: "failed",
      });
    default:
      return state;
  }
}

export function expLaunchReducer(
  state = {
    isLoading: false,
    status: "",
  },
  action
) {
  switch (action.type) {
    case types.EXP_LAUNCH_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
      });
    case types.EXP_LAUNCH_SUCCESS:
      // let {resultData} = action;
      return Object.assign({}, state, {
        isLoading: false,
        ...action.response,
      });
    case types.EXP_LAUNCH_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        status: "failed",
      });
    default:
      return state;
  }
}

export function expBudgetReducer(
  state = {
    isLoading: false,
    status: "",
  },
  action
) {
  switch (action.type) {
    case types.EXP_BUDGET_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
      });
    case types.EXP_BUDGET_SUCCESS:
      // let { response } = action;
      return Object.assign({}, state, {
        isLoading: false,
        ...action.response,
      });
    case types.EXP_BUDGET_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        ...action.response,
        status: "failed",
      });
    default:
      return state;
  }
}

export function fetchExperimentReducer(
  state = {
    isLoading: false,
    status: "",
  },
  action
) {
  switch (action.type) {
    case types.FETCH_EXPERIMENT_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
      });
    case types.FETCH_EXPERIMENT_SUCCESS:
      // let {response} = action;
      return Object.assign({}, state, {
        isLoading: false,
        ...action.response,
      });
    case types.FETCH_EXPERIMENT_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        status: "failed",
      });
    default:
      return state;
  }
}

export function fetchAllExperimentListReducer(
  state = {
    isLoading: false,
    status: "",
  },
  action
) {
  switch (action.type) {
    case types.FETCH_ALL_EXPERIMENTS_LIST_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
      });
    case types.FETCH_ALL_EXPERIMENTS_LIST_SUCCESS:
      // let {response} = action;
      return Object.assign({}, state, {
        isLoading: false,
        ...action.resultData,
      });
    case types.FETCH_ALL_EXPERIMENTS_LIST_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        status: "failed",
      });
    default:
      return state;
  }
}

export function fetchExperimentExecutionStatus(
  state = {
    isLoading: false,
    status: "",
  },
  action
) {
  switch (action.type) {
    case types.FETCH_EXPERIMENT_EXECUTION_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
      });
    case types.FETCH_EXPERIMENT_EXECUTION_SUCCESS:
      // let {response} = action;
      return Object.assign({}, state, {
        isLoading: false,
        ...action.response,
      });
    case types.FETCH_EXPERIMENT_EXECUTION_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        status: "failed",
      });
    default:
      return state;
  }
}

export function fetchPredefinedExpReducer(
  state = {
    isLoading: false,
    status: "",
  },
  action
) {
  switch (action.type) {
    case types.FETCH_PREDEFINED_EXP_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
      });
    case types.FETCH_PREDEFINED_EXP_SUCCESS:
      let { resultData } = action;
      const data = resultData.data.map((item) => {
        const { ...rest } = item;
        return {
          ...rest,
          ...rest.predefined_experiment,
        };
      });
      return Object.assign({}, state, {
        isLoading: false,
        // ...action.resultData,
        data,
      });
    case types.FETCH_PREDEFINED_EXP_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        status: "failed",
      });
    default:
      return state;
  }
}

export function fetchExpResultTestControlStatsReducer(
  state = {
    isLoading: false,
    status: "",
  },
  action
) {
  switch (action.type) {
    case types.FETCH_EXP_RESULT_TEST_CONTROL_STATS_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
      });
    case types.FETCH_EXP_RESULT_TEST_CONTROL_STATS_SUCCESS:
      let { resultData } = action;
      return Object.assign({}, state, {
        isLoading: false,
        data: resultData,
      });
    case types.FETCH_EXP_RESULT_TEST_CONTROL_STATS_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        status: "failed",
      });
    default:
      return state;
  }
}

export function fetchExpResultCampStatsReducer(
  state = {
    isLoading: false,
    status: "",
  },
  action
) {
  switch (action.type) {
    case types.FETCH_EXP_RESULT_CAMP_STATS_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
      });
    case types.FETCH_EXP_RESULT_CAMP_STATS_SUCCESS:
      let { resultData } = action;
      return Object.assign({}, state, {
        isLoading: false,
        data: resultData,
      });
    case types.FETCH_EXP_RESULT_CAMP_STATS_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        status: "failed",
      });
    default:
      return state;
  }
}

export function fetchDatasourcesReducer(
  state = {
    isLoading: false,
    status: "",
  },
  action
) {
  switch (action.type) {
    case types.FETCH_DATA_SOURCE_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
      });
    case types.FETCH_DATA_SOURCE_SUCCESS:
      // let {response} = action;
      return Object.assign({}, state, {
        isLoading: false,
        ...action.resultData,
      });
    case types.FETCH_DATA_SOURCE_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        status: "failed",
      });
    default:
      return state;
  }
}

export function deleteDatasourcesReducer(
  state = {
    isLoading: false,
    status: "",
  },
  action
) {
  switch (action.type) {
    case types.DELETE_DATA_SOURCE_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
      });
    case types.DELETE_DATA_SOURCE_SUCCESS:
      // let {response} = action;
      return Object.assign({}, state, {
        isLoading: false,
        ...action.resultData,
      });
    case types.DELETE_DATA_SOURCE_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        status: "failed",
      });
    default:
      return state;
  }
}

export function deleteExperimentReducer(
  state = {
    isLoading: false,
    status: "",
  },
  action
) {
  switch (action.type) {
    case types.DELETE_EXPERIMENT_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
      });
    case types.DELETE_EXPERIMENT_SUCCESS:
      // let {response} = action;
      return Object.assign({}, state, {
        isLoading: false,
        ...action.response,
      });
    case types.DELETE_EXPERIMENT_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        ...action.response,
        status: "failed",
      });
    default:
      return state;
  }
}

export function fetchCustomerMigrationReducer(
  state = {
    isLoading: false,
    status: "",
  },
  action
) {
  switch (action.type) {
    case types.FETCH_CUSTOMER_MIGRATION_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
      });
    case types.FETCH_EXPFETCH_CUSTOMER_MIGRATION_SUCCESS:
      let { resultData } = action;
      return Object.assign({}, state, {
        isLoading: false,
        data: resultData,
      });
    case types.FETCH_CUSTOMER_MIGRATION_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        status: "failed",
      });
    default:
      return state;
  }
}

export function fetchInmarketAffinityReducer(
  state = {
    isLoading: false,
    message: "",
  },
  action
) {
  switch (action.type) {
    case types.INMARKET_AFFINITY_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
      });
    case types.INMARKET_AFFINITY_SUCCESS:
      //   let { resultData } = action;
      return Object.assign({}, state, {
        isLoading: false,
        ...action.resultData,
        message: "success",
      });
    case types.INMARKET_AFFINITY_FAILURE:
      return Object.assign({}, state, {
        isLoading: false,
        ...action.resultData,
        message: "failed",
      });
    default:
      return state;
  }
}

export function hashed_emails_reducer(
  state = {
    isLoading: false,
  },
  action
) {
  switch (action.type) {
    case types.REPORT_DOWNLOAD_ENTRY_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case types.REPORT_DOWNLOAD_ENTRY_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };

    case types.REPORT_DOWNLOAD_ENTRY_FAILURE:
      return {
        ...state,
        isLoading: false,
      };

    default:
      return state;
  }
}
