import React, {useEffect} from 'react'
import {Box, Button, Container, Dialog, Grid, Typography,} from "@mui/material";
import {connect} from "react-redux";
import LoginFormStyles from "../../assets/jss/loginFormStyles"
import Icons from "../icons";

function RequestDemo(props) {
    const classes = LoginFormStyles();
    // const theme = useTheme();
    const { requestFreeDemoReducer } = props;
    // const [first_name, setFirst_name] = React.useState('')
    // const [last_name, setLast_name] = React.useState('')
    // const [company_name, setCompany_name] = React.useState('')
    // const [email, setEmail] = React.useState('')
    // const [phone_number, setPhone_number] = React.useState('')

    /*const handle_demo_change = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        name === 'first_name' ?
            setFirst_name(value)
            :
            name === 'last_name' ?
                setLast_name(value)
                :
                name === 'company_name' ?
                    setCompany_name(value)
                    :
                    name === 'email' ?
                        setEmail(value)
                        :
                        setPhone_number(value)
    }*/
    const handleDemoClose = () => {
        props.onClose()
    }
    /*const verifyPhone = (numberGiven) => {
        var phoneNo = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
        if (numberGiven.match(phoneNo)) {
            return true
        } else {
            return false
        }
    };

    const verifyEmail = (emailGiven) => {
        var genEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/;
        if (emailGiven.match(genEmail)) {
            return true
        } else {
            return false
        }
    };
    const validateDemoForm = () => {
        return(
            first_name.length > 0 &&
            last_name.length > 0 &&
            company_name.length > 0 &&
            email.length > 0 &&
            phone_number.length > 0 &&
            verifyPhone(phone_number) &&
            verifyEmail(email)
        );
    }*/
    useEffect(() => { }, [props])
    useEffect(() => {
        const loadScriptByURL = (id, url, callback) => {
            const isScriptExist = document.getElementById(id);

            if (!isScriptExist) {
                var script = document.createElement("script");
                script.type = "text/javascript";
                script.src = url;
                script.id = id;
                script.onload = function () {
                    if (callback) callback();
                };
                document.body.appendChild(script);
            }

            if (isScriptExist && callback) callback();
        };

        // load the script by passing the URL
        loadScriptByURL("recaptcha-key", `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_RECAPTCHA_SITE_KEY}`, function () {
            return null;
        });
    }, []);
    /*const handleSubmit = (e, data) => {
        e.preventDefault();
        let formData= new FormData(e.target);
        if(process.env.REACT_APP_RUN_RECAPTCHA) {
            window.grecaptcha.ready(() => {
                window.grecaptcha.execute(process.env.REACT_APP_RECAPTCHA_SITE_KEY, {action: 'submit'}).then(token => {
                    formData['token'] = token;
                    data['token'] = token;
                    props.handle_demo(e, formData, data);
                    // props.handle_login(e, {email: username, password, token})
                });
            });
        }else{
            props.handle_demo(e, formData, data);
        }
    }*/
    return (
        <Dialog onClose={handleDemoClose} aria-labelledby="simple-dialog-title" open={props.popDemoOpen}
            PaperProps={{ square: true }} style={{ backgroundColor: 'transparent' }}>
            <Box style={{ backgroundColor: "#2F59C4" }}>
                <Grid container >
                    <Grid item xs={12} style={{ textAlign: 'end' }}>
                        <Button onClick={() => {
                            handleDemoClose();
                        }} style={{ textTransform: 'none' }}>
                            {Icons.closeIcon}
                        </Button>
                    </Grid>
                </Grid>
                <Container maxWidth="xs" component="main">
                    {/*<CssBaseline/>*/}
                    <iframe src={"https://go.marketing.netelixir.com/l/" + process.env.REACT_APP_REQUEST_DEMO_FORM_ID} title="Request Demo Form" width="100%" height="500" type="text/html" frameBorder="0" allowtransparency="true" class="requestDemo" style={{ border: 0, minWidth: 400, height: 500 }} />
                    {requestFreeDemoReducer.message ?
                        <>
                            {requestFreeDemoReducer.message === "Success" ?
                                <Typography className={classes.successFont}>
                                    Thank you for your interest, we will contact you shortly!
                                </Typography> :
                                <Typography className={classes.errorFont}>
                                    Sorry! Your Request Failed. Please Try Again
                                </Typography>
                            }
                        </>
                        : null}
                </Container>
            </Box>
        </Dialog>
    )
}

const mapStateToProps = (state) => ({
    requestFreeDemoReducer: state.requestFreeDemoReducer,
});

export default connect(mapStateToProps, null)(RequestDemo);