import makeStyles from '@mui/styles/makeStyles';

const LoginFormStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    headerClass: {
        color: theme.palette.secondary.dark,
    },
    avatar: {
        margin: theme.spacing(1),
        marginLeft: "auto",
        marginRight: "auto",
        backgroundColor: theme.palette.primary.main,
        fontSize: 24
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        fontSize: "1.25rem",
        margin: theme.spacing(3, 0, 2),
        backgroundColor: theme.palette.primary.main,
        borderRadius: 0
    },
    demoHeader:{
        textTransform: 'uppercase',
        color: theme.palette.primary.main
    },
    successFont:{
        color:theme.palette.success.main,
        textAlign:'center'
    },
    errorFont:{
        color:theme.palette.error.main,
        textAlign:'center'
    },
}))

export default LoginFormStyles;