import React, {useEffect} from "react";
import {Box, Breadcrumbs, ClickAwayListener, Grid, Typography, useTheme} from "@mui/material";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import CustomerGroup from "./getGroupDetails";
import {border, cardShadow} from "../assets/jss/commonStyles";
import Icons from "./icons"
// import {Link} from "react-router-dom";
import NotificationStatement, {CategoryHeader, notificationCategories} from "../components/notificationStatement"
import {SimpleButton} from "./buttons";
import * as _ from "lodash"
import moment from "moment"

function NotificationWindow(props) {
    const {classes, handlePopperClose, notificationData, handleNotificationDelete} = props;
    let notifications = _.map(_.groupBy(notificationData, 'notif_name'));
    let orderedNotifications = [];
    let keys;
    notifications = _.keyBy(notifications, function (d) {
        return d[0].notif_name
    })
    keys = Object.keys(notifications).sort((a, b) => notificationCategories[a] - notificationCategories[b])
    for (let i = 0; i < keys.length; i++) {
        orderedNotifications[keys[i]] = notifications[keys[i]]
    }
    const theme = useTheme()
    useEffect(() => {
        if (notificationData.length > 0) {
            //eslint-disable-next-line
            notifications = _.map(_.groupBy(notificationData, 'notif_name'))
            notifications = _.keyBy(notifications, function (d) {
                return d[0].notif_name
            })
            //eslint-disable-next-line
            keys = Object.keys(notifications).sort((a, b) => notificationCategories[a] - notificationCategories[b])
            for (let i = 0; i < keys.length; i++) {
                orderedNotifications[keys[i]] = notifications[keys[i]]
            }
        }
    }, [notificationData])
    return (
        <ClickAwayListener onClickAway={handlePopperClose}>
            <div style={{
                backgroundColor: theme.palette.common.white,
                minWidth: 250,
                maxWidth: 400,
                border: border,
                boxShadow: cardShadow,
                borderRadius: 4
            }}>
                <Box p={2.5} borderBottom={border} className={'flex'} justifyContent={'space-between'}>
                    <Typography style={{fontWeight:700}}>Notifications</Typography>
                    {/*<Box style={{color: theme.palette.primary.main, opacity: 0.5}}>
                        <SimpleButton onClick={()=>{deleteAllNotifications()}}>
                            {Icons.deleteIcon}
                        </SimpleButton>
                    </Box>*/}

                </Box>
                {_.values(orderedNotifications).length > 0 ?
                    orderedNotifications && _.values(orderedNotifications).map((notificationGroup, index) => {
                        // const category = notificationGroup[0].category === 'order' ? 'Orders Overtime' : notificationGroup[0].category === 'customer' ? 'Customers Overtime' : 'Revenue Overtime'
                        const category = CategoryHeader(notificationGroup[0].notif_name)().name
                        return (
                            <Box px={1}
                                 style={{backgroundColor: index % 2 === 0 ? 'rgb(226, 236, 243,0.2)' : theme.palette.common.white}}>
                                <Box py={2} px={1} borderBottom={border}>
                                    <Grid container>
                                        <Grid item xs={9}>
                                            <Breadcrumbs classes={{
                                                separator: classes.separatorClass
                                            }} separator={<NavigateNextIcon fontSize="small"/>} aria-label="breadcrumb">
                                                <Typography color="textPrimary" variant={'h5'}
                                                            style={{
                                                                opacity: 0.5,
                                                                fontWeight: 400
                                                            }}>
                                                    {/*{group}*/}
                                                    {CustomerGroup('all')().name}
                                                </Typography>
                                                <Typography color="textPrimary" variant={'h5'}
                                                            style={{fontWeight: 400}}>{category}</Typography>
                                            </Breadcrumbs>
                                        </Grid>
                                        <Grid item xs={3} style={{textAlign: 'end'}}>
                                            <Typography variant={'body2'} style={{opacity: 0.5}}>
                                                {moment(notificationGroup[0].created).format('MM/DD/yyyy')}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Box>
                                {notificationGroup.map((notification, index) => {
                                    const { statement, notifId} = NotificationStatement(notification)
                                    return (
                                        <Box p={1} py={2} justifyContent={'space-between'}>
                                            <Grid container alignItems={"center"}>
                                                <Grid item xs={9} lg={11}>
                                                    {/*<Typography color="textPrimary" variant={'h5'}
                                                        style={{fontWeight: 400}}>{notificationData.content} &ensp;
                                                <Link to={""} style={{
                                                    color: theme.palette.secondary.light,
                                                    fontWeight: 400
                                                }}>Help</Link>
                                            </Typography>*/}
                                                    {statement}
                                                    {/*<Link to={""} style={{
                                                        color: theme.palette.secondary.light,
                                                        fontWeight: 400,
                                                        fontSize: "0.875rem"
                                                    }}>Help</Link>*/}
                                                </Grid>
                                                <Grid item xs={3} lg={1} style={{textAlign: "end"}}>
                                                    <Box style={{color: theme.palette.primary.main, opacity: 0.5}}>
                                                        <SimpleButton onClick={() => {
                                                            handleNotificationDelete(notifId)
                                                        }}>
                                                            {Icons.deleteIcon}
                                                        </SimpleButton>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    )
                                })}
                            </Box>
                        )
                    }
                    )
                    :
                    <Box p={2} style={{textAlign: 'center'}}>
                        <Typography color={'primary'}>
                            No notifications to display
                        </Typography>
                    </Box>
                }
            </div>
        </ClickAwayListener>
    )
};

export default NotificationWindow