import React, { useEffect, useMemo, useState } from "react";
import _ from "lodash";
import HighchartsReact from "highcharts-react-official";
import { Box, Grid, ToggleButton, Tooltip, Typography } from "@mui/material";
import { withStyles } from "@mui/styles";
import { getChannelColor } from "../getGroupDetails";
import { connect } from "react-redux";
import { CustomSlider } from "../predictionPage/clvBlock";
import SliderValueLabel from "@mui/material/Slider/SliderValueLabel";
import DateComponent from "../../GlobalComponents/DateComponent";
import { InfoOutlined } from "@mui/icons-material";
import { useTheme } from "@emotion/react";

var Highcharts = require("highcharts");
require("highcharts/highcharts-more")(Highcharts);
require("highcharts/modules/exporting")(Highcharts);
require("highcharts/modules/sankey")(Highcharts);
require("highcharts/modules/accessibility")(Highcharts);

const CustomTouchpointSlider = (props) =>{

  const {expandedFilter, setExpandedFilter, sliderValue, StyledValueLabel, handleChange, maxPurchasePathValue} = props;
  useEffect(()=>{
  },[props]);
  return(
      <CustomSlider
          expandedFilter={expandedFilter}
          setExpandedFilter={setExpandedFilter}
          label={"Touchpoints"}
          name={"Touchpoints"}
          value={sliderValue}
          components={{
            ValueLabel: StyledValueLabel,
          }}
          onChange={handleChange}
          aria_labelledby={"maxPath-slider"}
          // valueLabelDisplay="none"
          max={maxPurchasePathValue}
          min={maxPurchasePathValue < 3 ? maxPurchasePathValue-1 : 3}
      />
  )
}
export const CustomToggleButton = withStyles((theme) => ({
  root: {
    minWidth: 90,
    textTransform: "none",
    fontSize: "0.875rem",
    border: "1px solid #afafaf",
    padding: "10px 20px",
    alignItems: "center",
    margin: "2% 0% 1%",
    backgroundColor: theme.palette.common.white,
    color: theme.palette.primary.main,
    "&$selected,&$selected:hover": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
  },
  selected: {},
}))(ToggleButton);

const StyledValueLabel = withStyles((theme) => ({
  offset: {
    top: -18,
  },
  label: {
    color: theme.palette.secondary.light,
  },
}))(SliderValueLabel);

const ConversionPathSankey = (props) => {
  const {
    data,
    compareData,
    application_states,
    firstPathAvgPurchaseTps,
    dataStartDate,
    dataEndDate,
  } = props;
  const chartRef = React.createRef();
  const monthCompare = application_states.monthCompare;

  const [expandedFilter, setExpandedFilter] = React.useState("");

  const maxPurchasePathValue = useMemo(() => {
    let touchValue = 20;
    if (data.orderedPaths && data.orderedPaths.length > 0) {
      touchValue = Math.min(
        _.maxBy(data.orderedPaths, (pathObj) => pathObj.path.length).path
          .length,
        20
      );
    }
    return touchValue;
  }, [data]);

  // let maxPurchasePathValue = 20;
  // if (data.orderedPaths && data.orderedPaths.length > 0) {
  //   maxPurchasePathValue = Math.min(
  //     _.maxBy(data.orderedPaths, (pathObj) => pathObj.path.length).path.length,
  //     20
  //   );

  // }

  const finalSliderValue =
    maxPurchasePathValue < firstPathAvgPurchaseTps
      ? maxPurchasePathValue
      : firstPathAvgPurchaseTps + 1 < 5
      ? firstPathAvgPurchaseTps
      : firstPathAvgPurchaseTps + 1;

  const [sliderValue, setSliderValue] = useState(finalSliderValue);
  const handleChange = (e, newValue) => {
    setSliderValue(newValue); // Update the slider value based on the slider selection
  };

  let newData = [];

  // Going through the paths and creating nodes by combining source and channel

  data?.orderedPaths?.forEach((orderedPath) => {
    if (orderedPath?.path?.length <= sliderValue) {
      let temp = { path: ["Start"], conversion: orderedPath?.conversion };
      orderedPath?.path?.forEach((pathItem, index) => {
        let tempNode = pathItem?.[0];
        if (pathItem?.[1]) {
          tempNode = pathItem?.[0] + " " + pathItem?.[1];
        }
        temp?.path.push(tempNode + index);
      });
      temp?.path.push("Conversion");
      newData?.push(temp);
    }
  });

  if (newData?.length === 0 && sliderValue < 20) {
    setSliderValue(sliderValue + 1);
  }

  // Create Edges for each path and add conversion with it
  let chartData = [];
  newData?.forEach((newDataItem) => {
    newDataItem?.path?.forEach((pathItem, index) => {
      if (index > 0) {
        chartData?.push([
          newDataItem?.path[index - 1],
          pathItem,
          newDataItem?.conversion,
        ]);
      }
    });
  });

  let finalChartDataArray = [];

  // Create a new object to store the final result
  let result = {};

  // Create a new array to store the final result
  let newfinalArray = [];

  // Loop through the chartData array and sum nodes with the same 'from' and 'to' values
  chartData.forEach(function (arr) {
    const from = arr[0];
    const to = arr[1];
    const value = arr[2];

    const key = from + "-" + to;

    if (result[key]) {
      result[key] += value;
    } else {
      result[key] = value;
    }
  });

  // Loop through the result object and create a new array with unique 'from' and 'to' values and their corresponding sum
  Object.keys(result).forEach(function (key) {
    const [from, to] = key.split("-");
    const value = result[key];

    finalChartDataArray.push([from, to, value]);
  });

  // Create a new array to store the final result
  let uniquePaths = {};
  let nodeConversion = {};

  finalChartDataArray.forEach(function (arr) {
    const from = arr[0];
    const to = arr[1];
    const value = arr[2];

    const key = from + "-" + to;

    if (uniquePaths[key]) {
      uniquePaths[key] += value;
    } else {
      uniquePaths[key] = value;
    }

    let fromNode = from;

    if (!from.includes("Start")) {
      // Check if 'from' does not contain 'Start'
      fromNode = from.substring(0, from.length - 1); // Remove the last character from 'from' string
    }

    if (nodeConversion[fromNode]) {
      nodeConversion[fromNode] += value;
    } else {
      nodeConversion[fromNode] = value;
    }
  });

  //Loop through nodeConversion excluding start and sum all the values
  let totalSum = 0;

  Object.keys(nodeConversion).forEach((node) => {
    if (node !== "Start") {
      totalSum += nodeConversion[node];
    }
  });

  // Calculate 10% of sum

  let percentage = 0.1;

  // Loop through sorted finalChartDataArray from lower side, take a temp_value = 0

  // if temp_value += nodevalue, if temp_value <= 0.2*sum add that node in a otherList

  // breakthe loop when temp_value crosses 0.2*sum

  let temp_value = 0;
  let sortedKeys = Object.keys(nodeConversion).sort(
    (a, b) => nodeConversion[a] - nodeConversion[b]
  );
  let otherList1 = [];
  let notOtherList = [];

  sortedKeys.forEach((node) => {
    let nodeValue = nodeConversion[node];

    if (temp_value + nodeValue <= percentage * totalSum) {
      temp_value += nodeValue;
      otherList1.push(node);
    } else {
      notOtherList.push(node);
    }
  });

  // Loop through newfinalArray if from or to node belongs to the otherList, change to Others

  // Create a new array to store the final result

  finalChartDataArray.forEach(([from, to, value], i) => {
    if (!from.includes("Start")) {
      const lastDigit = from.charAt(from.length - 1);
      if (otherList1.includes(from.substring(0, from.length - 1))) {
        from = "Others" + lastDigit;
      }
    }

    const tolastDigit = to.charAt(to.length - 1);
    if (otherList1.includes(to.substring(0, to.length - 1))) {
      to = "Others" + tolastDigit;
    }

    finalChartDataArray[i] = [from, to, value];
  });

  // Find all the duplicate paths and sum their conversion values to create unique paths

  let newPaths = {};

  finalChartDataArray.forEach(([from, to, value]) => {
    let key = `${from}-${to}`;

    if (newPaths.hasOwnProperty(key)) {
      newPaths[key] += value;
    } else {
      newPaths[key] = value;
    }
  });

  newfinalArray = [];

  Object.keys(newPaths).forEach((key) => {
    if (newPaths.hasOwnProperty(key)) {
      let [from, to] = key.split("-");
      let value = newPaths[key];
      newfinalArray.push([from, to, value]);
    }
  });

  let seen = [];
  let nodes = [];

  newfinalArray.forEach(function (edge) {
    if (!seen.includes(edge[0]) && edge[0] !== "Start") {
      let n = edge[0].charAt(edge[0].length - 1);
      let c = "";
      if (edge[0].split(" ").length > 1) {
        let arr = edge[0].split(" ");
        for (let i = 0; i < arr.length - 1; i++) {
          if (i < arr.length - 2) {
            c += arr[i] + " ";
          } else {
            c += arr[i];
          }
        }
      } else {
        c += edge[0].substring(0, edge[0].length - 1);
      }
      let node = {
        id: edge[0],
        column: parseInt(n) + 1,
        name: edge[0].substring(0, edge[0].length - 1),
        color: getChannelColor(c),
      };
      nodes.push(node);
      seen.push(edge[0]);
    }
  });

  // For Compare Data

  let compareNewData = [];

  // Going through the paths and creating nodes by combining source and channel

  if (compareData.orderedPaths) {
    compareData.orderedPaths.forEach((orderedPath) => {
      if (orderedPath.path.length <= sliderValue) {
        let temp = { path: ["Start"], conversion: orderedPath.conversion };
        orderedPath.path.forEach((pathItem, index) => {
          let tempNode = pathItem[0];
          if (pathItem[1]) {
            tempNode = pathItem[0] + " " + pathItem[1];
          }
          temp.path.push(tempNode + index);
        });
        temp.path.push("Conversion");
        compareNewData.push(temp);
      }
    });
  }

  // Create Edges for each path and add conversion with it
  let compareChartData = [];
  compareNewData.forEach((compareNewDataItem) => {
    compareNewDataItem.path.forEach((pathItem, index) => {
      if (index > 0) {
        compareChartData.push([
          compareNewDataItem.path[index - 1],
          pathItem,
          compareNewDataItem.conversion,
        ]);
      }
    });
  });

  let comparefinalChartDataArray = [];

  // Create a new object to store the final result
  let compareresult = {};

  // Create a new array to store the final result
  let comparenewfinalArray = [];

  // Loop through the chartData array and sum nodes with the same 'from' and 'to' values
  compareChartData.forEach(function (arr) {
    const comparefrom = arr[0];
    const compareto = arr[1];
    const comparevalue = arr[2];

    const key = comparefrom + "-" + compareto;

    if (compareresult[key]) {
      compareresult[key] += comparevalue;
    } else {
      compareresult[key] = comparevalue;
    }
  });

  // Loop through the result object and create a new array with unique 'from' and 'to' values and their corresponding sum
  // Loop through the compareresult object and create a new array with unique 'from' and 'to' values and their corresponding sum
  Object.keys(compareresult).forEach(function (key) {
    const [comparefrom, compareto] = key.split("-");
    const comparevalue = compareresult[key];

    comparefinalChartDataArray.push([comparefrom, compareto, comparevalue]);
  });

  // Create a new array to store the final result
  let compareuniquePaths = {};
  let comparenodeConversion = {};

  // Loop through newfinalArray if from or to node belongs to the otherList, change to Others

  // Create a new array to store the final result

  comparefinalChartDataArray.forEach(
    ([comparefrom, compareto, comparevalue], i) => {
      if (!comparefrom.includes("Start")) {
        const lastDigit = comparefrom.charAt(comparefrom.length - 1);
        if (
          otherList1.includes(comparefrom.substring(0, comparefrom.length - 1))
        ) {
          comparefrom = "Others" + lastDigit;
        }
      }

      const tolastDigit = compareto.charAt(compareto.length - 1);
      if (otherList1.includes(compareto.substring(0, compareto.length - 1))) {
        compareto = "Others" + tolastDigit;
      }

      comparefinalChartDataArray[i] = [comparefrom, compareto, comparevalue];
    }
  );

  comparefinalChartDataArray.forEach(function (arr) {
    const comparefrom = arr[0];
    const compareto = arr[1];
    const comparevalue = arr[2];

    const key = comparefrom + "-" + compareto;

    if (compareuniquePaths[key]) {
      compareuniquePaths[key] += comparevalue;
    } else {
      compareuniquePaths[key] = comparevalue;
    }

    let fromNode = comparefrom;

    if (!comparefrom.includes("Start")) {
      // Check if 'from' does not contain 'Start'
      fromNode = comparefrom; // Remove the last character from 'from' string
    }

    if (comparenodeConversion[fromNode]) {
      comparenodeConversion[fromNode] += comparevalue;
    } else {
      comparenodeConversion[fromNode] = comparevalue;
    }
  });

  // Find all the duplicate paths and sum their conversion values to create unique paths

  let comparenewPaths = {};

  comparefinalChartDataArray.forEach(
    ([comparefrom, compareto, comparevalue]) => {
      let key = `${comparefrom}-${compareto}`;

      if (comparenewPaths.hasOwnProperty(key)) {
        comparenewPaths[key] += comparevalue;
      } else {
        comparenewPaths[key] = comparevalue;
      }
    }
  );

  comparenewfinalArray = [];

  Object.keys(comparenewPaths).forEach((key) => {
    if (comparenewPaths.hasOwnProperty(key)) {
      let [comparefrom, compareto] = key.split("-");
      let comparevalue = comparenewPaths[key];
      comparenewfinalArray.push([comparefrom, compareto, comparevalue]);
    }
  });

  let compareseen = [];
  let comparenodes = [];

  comparenewfinalArray.forEach(function (edge) {
    if (!seen.includes(edge[0]) && edge[0] !== "Start") {
      let n = edge[0].charAt(edge[0].length - 1);
      let c = "";
      if (edge[0].split(" ").length > 1) {
        let arr = edge[0].split(" ");
        for (let i = 0; i < arr.length - 1; i++) {
          if (i < arr.length - 2) {
            c += arr[i] + " ";
          } else {
            c += arr[i];
          }
        }
      } else {
        c += edge[0].substring(0, edge[0].length - 1);
      }
      let node = {
        id: edge[0],
        column: parseInt(n) + 1,
        name: edge[0],
        label: edge[0].substring(0, edge[0].length - 1),
        color: getChannelColor(c),
      };
      comparenodes.push(node);
      compareseen.push(edge[0]);
    }
  });

  nodes.push.apply(nodes, [
    {
      id: "Start",
      column: 0,
      color: "#478fd9",
    },
    {
      id: "Conversion",
      color: "#4AA459",
    },
  ]);

  const chartOptions = {
    title: {
      // text: "Conversion Flow",
      text: "",
      align: "left",
    },
    subtitle: {
      // text: "by number of orders",
      align: "left",
    },
    series: [
      {
        keys: ["from", "to", "weight"],
        data: newfinalArray,
        nodes: nodes,
        nodePadding: 25,
        type: "sankey",
        nodeWidth: 30,
        nodeHeight: 25,
        nodeFormat:
          '<span style="font-size: 12px; color: #000;">{point.name}</span>',
      },
    ],
    chart: {
      linkCurvature: "0",
      height: 500,
    },
    tooltip: {
      useHTML: true,
      backgroundColor: "#1e334e",
      style: {
        color: "#fff",
        borderRadius: "10px",
        border: "none",
        width: "350px",
        lineHeight: "15px",
      },
      headerFormat: "<table>",
      formatter: function () {
        const compareNode = monthCompare
          ? comparenewfinalArray.find(
              (node) => node[0] === this.point.from && node[1] === this.point.to
            )
          : null;

        let compareSumNode = {};
        let compareSum = 0;

        Object.keys(comparenodeConversion).forEach((key) => {
          const substring = key.substring(0, key.length - 1);
          if (
            !notOtherList.includes(substring) &&
            comparenodeConversion.hasOwnProperty(substring)
          ) {
            const compareSum =
              comparenodeConversion[key] + comparenodeConversion[substring];
            const nodeName = `Others${key.slice(-1)}`;
            if (compareSumNode.hasOwnProperty(nodeName)) {
              compareSumNode[nodeName] += compareSum;
            } else {
              compareSumNode[nodeName] = compareSum;
            }
          }
        });

        comparenodeConversion["Conversion"] = comparenodeConversion["Start"];

        for (let key in comparenodeConversion) {
          if (key === this.point.id) {
            compareSumNode = key;
            compareSum = comparenodeConversion[key];
            break;
          }
        }
        if (monthCompare) {
          if (this.point.fromNode) {
            const elseCount =
              compareNode && compareNode.length > 0 ? compareNode[2] : 0;
            const weightDifference = Math.round(
              ((this.point.weight - elseCount) * 100) / elseCount
            );
            let weightIcon = "";
            if (elseCount === 0) {
              weightIcon = "&nbsp;";
            } else if (weightDifference > 0) {
              weightIcon = `<span style="color:#27AE60">&nbsp;${weightDifference}% <i class="fas fa-arrow-up"/></i></span>`;
            } else if (weightDifference < 0) {
              weightIcon = `<span style="color:#eb5757">&nbsp;${Math.abs(
                weightDifference
              )}% <i class="fas fa-arrow-down"/></i></span>`;
            } else {
              weightIcon = `&nbsp;`;
            }

            return (
              `<tr><td>${this.point.fromNode.name}</td> &rarr; <td>${this.point.toNode.name}</td><strong style="padding-left: 20px;">${weightIcon}</strong></tr></br>` +
              `<tr><td>Orders</td> : <td><strong>${this.point.weight}</strong></td></tr></br>` +
              `<tr><td><span style="opacity: 0.5;">Orders</span></td> : <td><strong style="opacity: 0.5;">${elseCount}</strong></td></tr>`
            );
          } else {
            const sumDifference = Math.round(
              ((this.point.sum - compareSum) * 100) / compareSum
            );
            let sumIcon = "";
            if (compareSum === 0) {
              sumIcon = "&nbsp;";
            } else if (sumDifference > 0) {
              sumIcon = `<span style="color:#27AE60">&nbsp;${sumDifference}% <i class="fas fa-arrow-up"/></i></span>`;
            } else if (sumDifference < 0) {
              sumIcon = `<span style="color:#eb5757">&nbsp;${Math.abs(
                sumDifference
              )}% <i class="fas fa-arrow-down"/></i></span>`;
            } else {
              sumIcon = `&nbsp;`;
            }

            return (
              `<tr><td>${this.point.name}</td><strong style="padding-left: 20px;">${sumIcon}</strong></tr></br>` +
              `<tr><td>Orders</td> : <td><strong>${this.point.sum}</strong></td></tr></br>` +
              `<tr><td><span style="opacity: 0.5;">Orders</span></td> : <td><strong style="opacity: 0.5;">${
                compareSum ? compareSum : 0
              }</strong></td></tr>`
            );
          }
        } else {
          if (this.point.fromNode) {
            return (
              `<tr><td>${this.point.fromNode.name}</td> &rarr; <td>${this.point.toNode.name}</td></tr></br>` +
              `<tr><td>Orders</td> : <td><strong>${this.point.weight}</strong></td></tr></br>`
            );
          } else {
            return (
              `<tr><td>${this.point.name}</td></strong></td></tr></br>` +
              `<tr><td>Orders</td> : <td><strong>${this.point.sum}</strong></td></tr>`
            );
          }
        }
      },
      footerFormat: "</table>",
      followPointer: true,
    },
  };
  const theme = useTheme();

  useEffect(() => {
    setSliderValue(finalSliderValue);
  }, [finalSliderValue, maxPurchasePathValue]);

  return (
    <div>
      {finalChartDataArray.length > 0 ? (
        <React.Fragment>
          <Grid container>
            <Grid item xs={7}>
              <Box ml={2}>
                <Typography
                  style={{ fontSize: "1.125rem", fontWeight: 700 }}
                  color={"primary"}
                >
                  Conversion Flow
                </Typography>
                <Typography style={{ fontSize: "0.875rem" }} color={"primary"}>
                  by number of orders
                </Typography>
              </Box>
            </Grid>
            <Grid
              item
              xs={5}
              style={{
                paddingRight: "2rem",
                right: 0,
                zIndex: 1,
                float: "right",
              }}
            >
              <Grid container>
                <Grid item xs={6}>
                  <Box
                    style={{
                      float: "right",
                    }}
                  >
                    <Typography
                      color={"primary"}
                      style={{
                        // color: "#838383",
                        fontSize: "1rem",
                        padding: "5px 0px",
                        display: "flex",
                        alignItems: "center",
                        fontWeight: 700,
                      }}
                    >
                      Available Data&nbsp;
                      <Tooltip
                        title={"Showing Available Data for Date Range"}
                        arrow
                      >
                        <InfoOutlined style={{ fontSize: "1rem" }} />
                      </Tooltip>
                      &nbsp;:
                    </Typography>
                    <Typography
                      color={"primary"}
                      style={{
                        // color: "#838383",
                        fontSize: "0.875rem",
                      }}
                    >
                      <DateComponent dateText={dataStartDate} /> to{" "}
                      <DateComponent dateText={dataEndDate} />
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box style={{ float: "right" }}>
                    <Typography
                      style={{
                        marginBottom: 8,
                        color: theme.palette.primary.main,
                      }}
                    >
                      Selected TouchPoints:{" "}
                      <span style={{ border: "1px" }}>{sliderValue}</span>
                    </Typography>
                    <CustomTouchpointSlider
                        expandedFilter={expandedFilter} setExpandedFilter={setExpandedFilter} sliderValue={sliderValue}
                        StyledValueLabel={StyledValueLabel} handleChange={handleChange}
                        maxPurchasePathValue={maxPurchasePathValue}/>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <HighchartsReact
            highcharts={Highcharts}
            options={chartOptions}
            ref={chartRef}
            containerProps={{ className: "sankeygraph" }}
          />

          <p className="highcharts-description" style={{ textAlign: "center" }}>
            Includes Conversion Paths with a maximum of {sliderValue}{" "}
            touchpoints.
          </p>
        </React.Fragment>
      ) : (
        <Box
          pt={5}
          style={{
            minHeight: "20vh",
            alignItems: "center",
            textAlign: "center",
            marginTop: "10%",
          }}
        >
          <Typography>No Data to Display</Typography>
        </Box>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  application_states: state.application_states,
});
export default connect(mapStateToProps, null)(ConversionPathSankey);
