import React from "react"
import {Box, Grid, MenuItem, Select, Typography, useTheme} from "@mui/material";
import withStyles from '@mui/styles/withStyles';
import {border} from "../../../assets/jss/commonStyles";
import {Bar, BarChart, CartesianGrid, ResponsiveContainer, Text, Tooltip, XAxis, YAxis} from "recharts";
import * as _ from "lodash";
import {capitalCase} from "change-case"
import moment from "moment";
import {connect} from "react-redux";
import NumericLabel from "react-pretty-numbers";
import Icons from "../../icons";
import {
  set_a_group_filter,
  set_all_group_filter,
  set_b_group_filter,
  set_c_group_filter
} from "../../../actions/actions";
import {GAEventTracker} from "../../../index";
import CustomerGroup from "../../getGroupDetails";

export const weekOrder = {Monday: 1, Tuesday: 2, Wednesday: 3, Thursday: 4, Friday: 5, Saturday: 6, Sunday: 7};

export function CustomXAxisTick(props) {
    const {x, y, payload} = props;
    return (
        <g transform={`translate(${x},${y})`}>
            <Text x={0} y={0} textAnchor="middle" verticalAnchor="start" fill={"#1E334E"} style={{
                fontWeight: 'normal',
                fontFamily: '"Lato", sans-serif',
                fontStyle: 'normal',
                fontSize: 14,
            }}>{payload.value}</Text>
        </g>
    )
}

export function CustomYAxisTick(props) {
    const {x, y, payload} = props;
    return (
        <g transform={`translate(${x},${y})`}>
            <Text x={0} y={0} textAnchor="end" verticalAnchor="middle" fill={"#1E334E"} style={{
                fontWeight: 'normal',
                fontFamily: '"Lato", sans-serif',
                fontStyle: 'normal',
                fontSize: 14,
            }}>{payload.value}</Text>
        </g>
    )
}

const CustomDayHourTooltip = ({active, payload, label, currency}) => {
    const theme = useTheme();
    const orderParams = {
        commafy: true,
        wholenumber: 'ceil',
        // shortFormat: true,
        shortFormatMinValue: 10000,
        justification: 'L',
    }
    const revenueParams = {
        'wholenumber': 'ceil',
        'locales': 'en-US',
        currency: true,
        currencyIndicator: currency,
        'commafy': true,
        justification: 'L',
    }
    if (active && payload !== [] && payload !== null) {
        if (payload.length === 1) {
            return (
                <div className={'custom-tooltip'}>
                    <Box p={1} style={{backgroundColor: theme.palette.primary.main, minWidth: 70}} borderRadius="4px">
                        <Typography variant={'body2'} style={{color: theme.palette.common.white}}>
                            {payload[0].payload.day_of_week ? <>{capitalCase(payload[0].payload.day_of_week)}</> : <>{capitalCase(label)}</>}
                        </Typography>
                        {payload[0].name === 'orders' ?
                            <Typography variant={'body2'} style={{color: theme.palette.common.white}}>
                                Order(s) : <NumericLabel params={orderParams}>{payload[0].value}</NumericLabel>
                                &nbsp;({payload[0].value ? Math.round(payload[0].value * 100 / payload[0].payload.totalOrders) : 0}%)
                            </Typography>
                            :
                            payload[0].name === 'revenue' ?
                                <Typography variant={'body2'} style={{color: theme.palette.common.white}}>
                                    Revenue : <NumericLabel params={revenueParams}>{payload[0].value}</NumericLabel>
                                    &nbsp;({payload[0].value ? Math.round(payload[0].value * 100 / payload[0].payload.totalRevenue) : 0}%)
                                </Typography>
                                :
                                <Typography variant={'body2'} style={{color: theme.palette.common.white}}>
                                    Customer(s) : <NumericLabel params={orderParams}>{payload[0].value}</NumericLabel>
                                    &nbsp;({payload[0].value ? Math.round(payload[0].value * 100 / payload[0].payload.totalCustomers) : 0}%)
                                </Typography>

                        }
                    </Box>
                </div>
            );
        } else if (payload.length === 2) {
            const diff = payload[0].value - payload[1].value
            return (
                <div className={'custom-tooltip'}>
                    <Box p={1} style={{backgroundColor: theme.palette.primary.main, minWidth: 70}} borderRadius="4px">
                        <Grid container>
                            <Grid item>
                                <Typography variant={'body2'} style={{color: theme.palette.common.white}}>
                                    {payload[0].payload.day_of_week ? <>{capitalCase(payload[0].payload.day_of_week)}</> : <>{capitalCase(label)}</>}
                                </Typography>
                                {payload[0].name === 'orders' ?
                                    <Typography variant={'body2'} style={{color: theme.palette.common.white}}>
                                        Order(s) : <NumericLabel params={orderParams}>{payload[0].value}</NumericLabel>
                                        &nbsp;({payload[0].value ? Math.round(payload[0].value * 100 / payload[0].payload.totalOrders) : 0}%)
                                    </Typography>
                                    :
                                    payload[0].name === 'revenue' ?
                                        <Typography variant={'body2'} style={{color: theme.palette.common.white}}>
                                            Revenue : <NumericLabel params={revenueParams}>{payload[0].value}</NumericLabel>
                                            &nbsp;({payload[0].value ? Math.round(payload[0].value * 100 / payload[0].payload.totalRevenue) : 0}%)
                                        </Typography>
                                        :
                                        <Typography variant={'body2'} style={{color: theme.palette.common.white}}>
                                            Customer(s) : <NumericLabel
                                            params={orderParams}>{payload[0].value}</NumericLabel>
                                            &nbsp;({payload[0].value ? Math.round(payload[0].value * 100 / payload[0].payload.totalCustomers) : 0}%)
                                        </Typography>

                                }
                                {payload[0].name === 'orders' ?
                                    <Typography variant={'body2'} style={{color: theme.palette.common.white, opacity: 0.5}}>
                                        Order(s) : <NumericLabel params={orderParams}>{payload[1].value}</NumericLabel>
                                        &nbsp;({payload[1].value ? Math.round(payload[1].value * 100 / payload[1].payload.compareTotalOrders) : 0}%)
                                    </Typography>
                                    :
                                    payload[0].name === 'revenue' ?
                                        <Typography variant={'body2'}
                                                    style={{color: theme.palette.common.white, opacity: 0.5}}>
                                            Revenue : <NumericLabel params={revenueParams}>{payload[1].value}</NumericLabel>
                                            &nbsp;({payload[1].value ? Math.round(payload[1].value * 100 / payload[1].payload.compareTotalRevenue) : 0}%)
                                        </Typography>
                                        :
                                        <Typography variant={'body2'}
                                                    style={{color: theme.palette.common.white, opacity: 0.5}}>
                                            Customer(s) : <NumericLabel
                                            params={orderParams}>{payload[1].value}</NumericLabel>
                                            &nbsp;({payload[1].value ? Math.round(payload[1].value * 100 / payload[1].payload.compareTotalCustomers) : 0}%)
                                        </Typography>

                                }
                            </Grid>
                            <Grid item alignItems={"flex-start"}>
                                {payload[1].value !== 0 ?
                                    diff > 0 ?
                                        <Typography variant={'h5'} style={{color: '#27AE60'}}>
                                            &nbsp;<span style={{fontWeight:700}}>{Math.abs(Math.round(diff * 100 / payload[1].value))}%</span>&nbsp;
                                            {Icons.greenLineArrow}
                                        </Typography>
                                        :
                                        diff < 0 ?
                                            <Typography variant={'h5'} style={{color: '#eb5757'}}>
                                                &nbsp;<span style={{fontWeight:700}}>{Math.abs(Math.round(diff * 100 / payload[1].value))}%</span>&nbsp;
                                                {Icons.redLineArrow}
                                            </Typography>
                                            :
                                            null
                                    :
                                    null
                                }
                            </Grid>
                        </Grid>
                    </Box>
                </div>
            );
        }

    }
    return null
}


const CustomSelect = withStyles(theme => ({
    select: {
        minWidth: 100,
        textAlign: 'center'
    },
    icon: {
        top: 'calc(50% - 16px)',
        width: '2em',
        height: '2em',
        color: theme.palette.primary.main
    },
    outlined: {
        padding: '10px 14px',
        fontSize: '0.875rem'
    }
}))(Select)

function DayHourBarGraph(props) {
    const {selectedSeg, orderData, property, compareData, application_states, set_all_group_filter, set_a_group_filter, set_b_group_filter, set_c_group_filter} = props;
    const handleClick = (filterName, filterValue) => {
        switch (selectedSeg) {
            case 'all':
                set_all_group_filter(filterName, filterValue);
                break;
            case 'a':
                set_a_group_filter(filterName, filterValue);
                break;
            case 'b':
                set_b_group_filter(filterName, filterValue);
                break;
            case 'c':
                set_c_group_filter(filterName, filterValue);
                break;
            default:
                return null
        }
    }
    const monthCompare = application_states.monthCompare
    const currency = application_states.currency
    const [chart, setChart] = React.useState('hour')
    const handleChartChange = (e) => {
        setChart(e.target.value)
    }
    const theme = useTheme();
    return (
        <Box>
            <Box p={'8.5px'} px={3} borderBottom={border}>
                <Grid container alignItems={'center'}>
                    <Grid item xs={6}>
                        <Typography variant={"h4"}
                                    style={{
                                        fontWeight: 700
                                    }}>{capitalCase(property)} by {chart} (UTC)</Typography>
                    </Grid>
                    <Grid item xs={6} style={{textAlign: 'end'}}>
                        <CustomSelect variant={"outlined"} value={chart}
                                      onChange={(e) => {
                                          handleChartChange(e);
                                          GAEventTracker(CustomerGroup(selectedSeg)().name, 'Day Hour Bar Select', e.target.value)
                                      }}>
                            <MenuItem value={'hour'}>By hour</MenuItem>
                            <MenuItem value={'day'}>By day</MenuItem>
                        </CustomSelect>
                    </Grid>
                </Grid>
            </Box>
            {chart === 'hour' ?
                <HourGraph selectedSeg={selectedSeg} handleClick={handleClick} theme={theme} monthCompare={monthCompare}
                           orderData={orderData} currency={currency}
                           compareData={compareData}
                           property={property}/>
                :
                <BarDayGraph selectedSeg={selectedSeg} handleClick={handleClick} theme={theme}
                             monthCompare={monthCompare} orderData={orderData} currency={currency}
                             compareData={compareData}
                             property={property}/>
            }
        </Box>
    )
}

function HourGraph(props) {
    const {selectedSeg, theme, orderData, property, compareData, monthCompare, handleClick,currency} = props;
    const xLabels = new Array(24).fill(0).map((_, i) => `${i}`);
    let barChartD;
    let barChartData = [];
    let compareBarChartD;
    let compareBarChartData = [];
    if (property === "orders") {
        barChartD = _.map(_.groupBy(orderData.orders, "hour"), (dataArray, key) => ({
            "hour": key,
            "orders": _.sumBy(dataArray, "orders"),
            "totalOrders": _.sumBy(orderData.orders, "orders"),
        }));
        compareBarChartD = _.map(_.groupBy(compareData.orders, "hour"), (dataArray, key) => ({
            "hour": key,
            "compareOrders": _.sumBy(dataArray, "orders"),
            "compareTotalOrders": _.sumBy(compareData.orders, "orders"),
        }));
        /*tempBarChartData create dummy list for 24 hours*/
        barChartData = xLabels.map(hour => ({
            "hour": hour,
            "orders": 0,
            "totalOrders": 1,
        }));
        compareBarChartData = xLabels.map(hour => ({
            "hour": hour,
            "compareOrders": 0,
            "compareTotalOrders": 1,
        }));
        /*barChartData updates dummy list for 24 hours with orders*/
        barChartD.forEach(data => {
            barChartData = barChartData.map(dummyData => dummyData.hour === data.hour ? {
                    "hour": data.hour,
                    "orders": data.orders,
                    "totalOrders": data.totalOrders,
                } : dummyData
            )
        });
        compareBarChartD.forEach(data => {
            compareBarChartData = compareBarChartData.map(dummyData => dummyData.hour === data.hour ? {
                    "hour": data.hour,
                    "compareOrders": data.compareOrders,
                    "compareTotalOrders": data.compareTotalOrders,
                } : dummyData
            )
        });
    } else if (property === "revenue") {
        barChartD = _.map(_.groupBy(orderData.orders, "hour"), (dataArray, key) => ({
            "hour": key,
            "revenue": _.sumBy(dataArray, "revenue"),
            "totalRevenue": _.sumBy(orderData.orders, "revenue"),
        }));
        compareBarChartD = _.map(_.groupBy(compareData.orders, "hour"), (dataArray, key) => ({
            "hour": key,
            "compareRevenue": _.sumBy(dataArray, "revenue"),
            "compareTotalRevenue": _.sumBy(compareData.orders, "revenue"),
        }));
        /*tempBarChartData create dummy list for 24 hours*/
        barChartData = xLabels.map(hour => ({
            "hour": hour,
            "revenue": 0,
            "totalRevenue": 1,
        }));
        compareBarChartData = xLabels.map(hour => ({
            "hour": hour,
            "compareRevenue": 0,
            "compareTotalRevenue": 1,
        }));
        /*barChartData updates dummy list for 24 hours with orders*/
        barChartD.forEach(data => {
            barChartData = barChartData.map(dummyData => dummyData.hour === data.hour ? {
                    "hour": data.hour,
                    "revenue": data.revenue,
                    "totalRevenue": data.totalRevenue,
                } : dummyData
            )
        });
        compareBarChartD.forEach(data => {
            compareBarChartData = compareBarChartData.map(dummyData => dummyData.hour === data.hour ? {
                    "hour": data.hour,
                    "compareRevenue": data.compareRevenue,
                    "compareTotalRevenue": data.compareTotalRevenue,
                } : dummyData
            )
        });
    } else {
        barChartD = _.map(_.groupBy(orderData.orders, "hour"), (dataArray, key) => ({
            "hour": key,
            "customerIDs": _.uniqBy(_.flatten(_.map(dataArray, (d) => _.values(d.customers)))),
            "numberCustomers": _.uniqBy(_.flatten(_.map(dataArray, (d) => _.values(d.customers)))).length ? _.uniqBy(_.flatten(_.map(dataArray, (d) => _.values(d.customers)))).length : _.sumBy(dataArray, "customers"),
        }));
        compareBarChartD = _.map(_.groupBy(compareData.orders, "hour"), (dataArray, key) => ({
            "hour": key,
            "compareCustomerIDs": _.uniqBy(_.flatten(_.map(dataArray, (d) => _.values(d.customers)))),
            "compareNumberCustomers": _.uniqBy(_.flatten(_.map(dataArray, (d) => _.values(d.customers)))).length ? _.uniqBy(_.flatten(_.map(dataArray, (d) => _.values(d.customers)))).length : _.sumBy(dataArray, "customers"),
        }));
        /*tempBarChartData create dummy list for 24 hours*/
        barChartData = xLabels.map(hour => ({
            "hour": hour,
            "customerIDs": [],
            "customers": 0,
            "totalCustomers": 1
        }));
        compareBarChartData = xLabels.map(hour => ({
            "hour": hour,
            "compareCustomerIDs": [],
            "compareCustomers": 0,
            "compareTotalCustomers": 1
        }));
        /*barChartData updates dummy list for 24 hours with orders*/
        barChartD.forEach(data => {
            barChartData = barChartData.map(dummyData => dummyData.hour === data.hour ? {
                    "hour": data.hour,
                    "customerIDs": data.customerIDs,
                    "customers": data.numberCustomers,
                    "totalCustomers": _.sumBy(barChartD, "numberCustomers"),
                } : dummyData
            )
        });
        compareBarChartD.forEach(data => {
            compareBarChartData = compareBarChartData.map(dummyData => dummyData.hour === data.hour ? {
                    "hour": data.hour,
                    "compareCustomerIDs": data.compareCustomerIDs,
                    "compareCustomers": data.compareNumberCustomers,
                    "compareTotalCustomers": _.sumBy(compareBarChartD, "compareNumberCustomers"),
                } : dummyData
            )
        });
    }

    let finalHourGraphData = !monthCompare ? barChartData : barChartData.map((item, i) => Object.assign({}, item, compareBarChartData[i]))
    return (
        <div>
            <ResponsiveContainer width={'98%'} height={250}>
                <BarChart data={finalHourGraphData} margin={{top: 10, right: 20}} barGap={0} onClick={(payload) => {
                    if (barChartData[parseInt(payload.activeLabel, 10)][property] > 0) {
                        handleClick("hour", parseInt(payload.activeLabel, 10));
                        GAEventTracker(CustomerGroup(selectedSeg)().name, 'Hour Bar Graph')
                    }
                }}>
                    <CartesianGrid strokeDasharray="7 7" vertical={false}/>
                    <XAxis tick={<CustomXAxisTick/>} dataKey={'hour'} minTickGap={0} tickLine={false} stroke={'#E3E6F4'}/>
                    <YAxis tick={<CustomYAxisTick/>} axisLine={false} stroke={'#E3E6F4'}/>
                    <Tooltip content={<CustomDayHourTooltip currency={currency}/>}/>
                    <Bar isAnimationActive={false} dataKey={property} fill={'#FF8735'} barSize={6}/>
                    {monthCompare ? <Bar isAnimationActive={false} dataKey={(e) => {
                        return e[`compare${capitalCase(property)}`]
                    }} fill={theme.palette.primary.main} fillOpacity={0.25} barSize={6}/> : null}
                </BarChart>
            </ResponsiveContainer>
            <Box pl={8} position={'relative'}>
                <Typography variant={'body2'}>Hours</Typography>
            </Box>
        </div>
    )
}

function BarDayGraph(props) {
    const {selectedSeg, handleClick, theme, orderData, property, compareData, monthCompare, currency} = props;
    const dayOrders = orderData.orders;
    const compareDayOrders = compareData.orders;
    if (dayOrders) {
        dayOrders.forEach(t => {
            t.day_of_week = moment(t.date).format('dddd')
        })
    }
    if (compareDayOrders) {
        compareDayOrders.forEach(t => {
            t.day_of_week = moment(t.date).format('dddd')
        })
    }
    let dayBarChartValues, dayBarChartData;
    let compareDayBarChartValues, compareDayBarChartData;
    if (property === 'orders') {
        dayBarChartValues = _.mapValues(_.groupBy(dayOrders, "day_of_week"), d => _.sumBy(d, "orders"));
        dayBarChartData = _.map(_.keys(dayBarChartValues), d => ({
            "day_of_week": d,
            "orders": dayBarChartValues[d],
            "totalOrders": _.sumBy(dayOrders, "orders"),
        }));
        compareDayBarChartValues = _.mapValues(_.groupBy(compareDayOrders, "day_of_week"), d => _.sumBy(d, "orders"));
        compareDayBarChartData = _.map(_.keys(compareDayBarChartValues), d => ({
            "day_of_week": d,
            "compareOrders": compareDayBarChartValues[d],
            "compareTotalOrders": _.sumBy(compareDayOrders, "orders"),
        }));
    } else if (property === 'revenue') {
        dayBarChartValues = _.mapValues(_.groupBy(dayOrders, "day_of_week"), d => _.sumBy(d, "revenue"));
        dayBarChartData = _.map(_.keys(dayBarChartValues), d => ({
            "day_of_week": d,
            "revenue": dayBarChartValues[d],
            "totalRevenue": _.sumBy(dayOrders, "revenue"),
        }));
        compareDayBarChartValues = _.mapValues(_.groupBy(compareDayOrders, "day_of_week"), d => _.sumBy(d, "revenue"));
        compareDayBarChartData = _.map(_.keys(compareDayBarChartValues), d => ({
            "day_of_week": d,
            "compareRevenue": compareDayBarChartValues[d],
            "compareTotalRevenue": _.sumBy(compareDayOrders, "revenue"),
        }));
    } else {
        dayBarChartValues = _.mapValues(_.groupBy(dayOrders, "day_of_week"), d => _.uniqBy(d, "customers"));
        const customerDayData = _.map(_.keys(dayBarChartValues), d => ({
            "day_of_week": d,
            "customerIDs": _.uniqBy(_.flatten(_.map(dayBarChartValues[d], (d) => _.values(d.customers)))),
            "numberCustomers": _.uniqBy(_.flatten(_.map(dayBarChartValues[d], (d) => _.values(d.customers)))).length ? _.uniqBy(_.flatten(_.map(dayBarChartValues[d], (d) => _.values(d.customers)))).length : _.sumBy(dayBarChartValues[d], "customers"),
        }));
        dayBarChartData = _.map(customerDayData, (d) => ({
            "day_of_week": d.day_of_week,
            "customerIDs": d.customerIDs,
            "customers": d.numberCustomers,
            "totalCustomers": _.sumBy(customerDayData, "numberCustomers")
        }));
        compareDayBarChartValues = _.mapValues(_.groupBy(compareDayOrders, "day_of_week"), d => _.uniqBy(d, "customers"));
        const compareCustomerDayData = _.map(_.keys(compareDayBarChartValues), d => ({
            "day_of_week": d,
            "compareCustomerIDs": _.uniqBy(_.flatten(_.map(compareDayBarChartValues[d], (d) => _.values(d.customers)))),
            "compareNumberCustomers": _.uniqBy(_.flatten(_.map(compareDayBarChartValues[d], (d) => _.values(d.customers)))).length ? _.uniqBy(_.flatten(_.map(compareDayBarChartValues[d], (d) => _.values(d.customers)))).length : _.sumBy(compareDayBarChartValues[d], "customers"),
        }));
        compareDayBarChartData = _.map(compareCustomerDayData, (d) => ({
            "day_of_week": d.day_of_week,
            "compareCustomerIDs": d.compareCustomerIDs,
            "compareCustomers": d.compareNumberCustomers,
            "compareTotalCustomers": _.sumBy(compareCustomerDayData, "compareNumberCustomers")
        }));
    }
    // dayBarChartData.sort((a, b) => weekOrder[a.day_of_week] - weekOrder[b.day_of_week]);
    // compareDayBarChartData.sort((a, b) => weekOrder[a.day_of_week] - weekOrder[b.day_of_week]);
    // let finalDayGraphData = !monthCompare ? dayBarChartData : dayBarChartData.map((item, i) => Object.assign({}, item, compareDayBarChartData[i]))
    let finalDayGraphData = !monthCompare ? dayBarChartData : _.merge(_.keyBy(dayBarChartData, 'day_of_week'), _.keyBy(compareDayBarChartData, 'day_of_week'));
    finalDayGraphData = _.values(finalDayGraphData)
    finalDayGraphData.forEach(t => {
        // t[property] ? t[property] = t[property] : t[property] = 0
        t[property]=t[property]?t[property]:0
    })
    if (monthCompare) {
        finalDayGraphData.forEach(t => {
            t[`compare${capitalCase(property)}`] ? t[`compare${capitalCase(property)}`] = t[`compare${capitalCase(property)}`] : t[`compare${capitalCase(property)}`] = 0
        })
    }
    // let finalDayGraphData = !monthCompare ? dayBarChartData : _.map(dayBarChartData, (d) => {
    //     return _.assign(d, _.find(compareDayBarChartData, {day_of_week: d.day_of_week}))
    // })
    finalDayGraphData.sort((a, b) => weekOrder[a.day_of_week] - weekOrder[b.day_of_week])
    return (
        dayOrders.length > 0 ?
            <ResponsiveContainer width={'98%'} height={250} >
                <BarChart layout={'vertical'} data={finalDayGraphData} margin={{top: 10, right: 10}}
                          onClick={(payload) => {
                              const value = _.find(dayBarChartData, (o) => {
                                  return o.day_of_week === payload.activePayload[0].payload.day_of_week
                              })
                              if (value) {
                                  handleClick('day_of_week', payload.activePayload[0].payload.day_of_week);
                                  GAEventTracker(CustomerGroup(selectedSeg)().name, 'Day Bar Graph')
                              }
                          }}>
                    <CartesianGrid strokeDasharray="7 7" horizontal={false}/>
                    <XAxis tick={<CustomXAxisTick/>} type={'number'} stroke={'#E3E6F4'}/>
                    <YAxis tick={<CustomYAxisTick/>} dataKey={(e) => e.day_of_week.substring(0, 3)} type={'category'} stroke={'#E3E6F4'}/>
                    <Tooltip content={<CustomDayHourTooltip currency={currency}/>}/>
                    <Bar isAnimationActive={false} dataKey={property} fill={'#FF8735'} barSize={6}/>
                    {monthCompare ? <Bar isAnimationActive={false} dataKey={(e) => {
                        return e[`compare${capitalCase(property)}`]
                    }} fill={theme.palette.primary.main} fillOpacity={0.25} barSize={6}/> : null}
                </BarChart>
            </ResponsiveContainer>
            :
            <Box pt={5} style={{minHeight: 250, alignItems: "center", textAlign: "center"}}>
                <Typography>No data to display</Typography>
            </Box>
    )
}


const mapStateToProps = (state) => ({
    application_states: state.application_states,
})
const mapDispatchToProps = (dispatch) => ({
    set_all_group_filter: (filterName, filterValue) => dispatch(set_all_group_filter(filterName, filterValue)),
    set_a_group_filter: (filterName, filterValue) => dispatch(set_a_group_filter(filterName, filterValue)),
    set_b_group_filter: (filterName, filterValue) => dispatch(set_b_group_filter(filterName, filterValue)),
    set_c_group_filter: (filterName, filterValue) => dispatch(set_c_group_filter(filterName, filterValue))
})
export default connect(mapStateToProps, mapDispatchToProps)(DayHourBarGraph);