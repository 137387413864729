import React, {useEffect} from 'react'
import Datamap from 'datamaps/dist/datamaps.usa.min'
import {borderFill, defaultFill, highlightFill, maxFill, minFill, numbersFormat, useStyles} from "./geoOrdersGraph";
import d3 from "d3";
import * as _ from "lodash";
import {DefaultRegionData} from "../geoLocationBlock";
import {GAEventTracker} from "../../../index";
import CustomerGroup from "../../getGroupDetails";

function GeoOrdersPopulation(props) {
    //eslint-disable-next-line
    let datamap;
    const classes = useStyles();
    const {monthCompare, selectedSeg, regionData, compareRegionData, property, handleClick, currencySymbol} = props;
    let graphData = !monthCompare ? regionData : _.merge(_.keyBy(regionData, 'state'), _.keyBy(compareRegionData, 'state'));
    graphData = _.values(graphData);

    function linearPaletteScale(value) {
        const dataValues = _.remove(regionData.map(function (data) {
            if (data.divValue) {
                return data.divValue
            }
            return null
        }), undefined);
        const minVal = Math.min(...dataValues);
        const maxVal = Math.max(...dataValues);
        return d3.scale.linear().domain([minVal, maxVal]).range([minFill, maxFill])(value);
    }

    const total = Math.round(_.sumBy(regionData, "divValue"));
    const compareTotal = Math.round(_.sumBy(compareRegionData, "compareDivValue"));

    function reducedData() {
        const newData = graphData.reduce((object, data) => {
            object[data.code] = {
                region: data.state,
                value: data.divValue,
                fillColor: data.divValue ? linearPaletteScale(data.divValue) : defaultFill,
                population: data.population,
                compareValue: data.compareDivValue,
                share: Math.round(data.divValue * 100 / total),
                compareShare: Math.round(data.compareDivValue * 100 / compareTotal),
            };
            return object
        }, {});
        return Object.assign({}, DefaultRegionData, newData)
    }

    function renderMap() {
        return new Datamap({
            element: document.getElementById('datamap-container'),
            position: 'relative',
            scope: 'usa',
            // width: 580,
            projection: 'mercator',
            data: reducedData(),
            fills: {defaultFill: defaultFill},
            done: function (datamap) {
                datamap.svg.selectAll('.datamaps-subunit').on('click', function (geography) {
                    const region = _.find(regionData, function (o) {
                        return o.state === geography.properties.name;
                    });
                    if (region !== undefined) {
                        handleClick('region', geography.properties.name);
                        GAEventTracker(CustomerGroup(selectedSeg)().name, 'Geo Population graph')
                    }
                });
            },
            geographyConfig: {
                borderWidth: 0.5,
                borderColor: borderFill,
                highlightFillColor: highlightFill,
                popupTemplate: function (geography, data) {
                    if (!monthCompare) {
                        if (data && data.value) {
                            if (property === 'orders') {
                                return `<div class=" ${classes.customTooltip} hoverinfo "> ${data.region} <br>Order(s) per million : ${data.value} <br>Population : ${numbersFormat(data.population, 0)} </div>`;
                            } else if (property === 'revenue') {
                                return `<div class=" ${classes.customTooltip} hoverinfo "> ${data.region} <br>Revenue per million : ${currencySymbol} ${numbersFormat(data.value, 0)} <br>Population : ${numbersFormat(data.population, 0)} </div>`;
                            } else {
                                return `<div class=" ${classes.customTooltip} hoverinfo "> ${data.region} <br>Customer(s) per million : ${data.value} <br>Population : ${numbersFormat(data.population, 0)} </div>`;
                            }
                        } else {
                            return `<div class=" ${classes.customTooltip} hoverinfo "> ${geography.properties.name} </div>`;
                        }
                    } else {
                        if (data && data.value && data.compareValue) {
                            if (property === 'orders') {
                                if (data.value > data.compareValue) {
                                    return `<div class=" ${classes.customTooltip} hoverinfo "><span> ${data.region} <br>Order(s) per million : ${data.value} <br><div style="opacity: 0.5">Order(s) per million : ${data.compareValue} </div>Population : ${numbersFormat(data.population, 0)} </span><span style="color: #27AE60"> ${Math.abs(Math.round((data.value - data.compareValue) * 100 / data.compareValue))} % <i class="fas fa-arrow-up" style="font-size: 14px; vertical-align: middle;"/></span></div>`;
                                } else if (data.value < data.compareValue) {
                                    return `<div class=" ${classes.customTooltip} hoverinfo "><span> ${data.region} <br>Order(s) per million : ${data.value} <br><div style="opacity: 0.5">Order(s) per million : ${data.compareValue} </div>Population : ${numbersFormat(data.population, 0)} </span><span style="color:#eb5757"> ${Math.abs(Math.round((data.value - data.compareValue) * 100 / data.compareValue))} % <i class="fas fa-arrow-down" style="font-size: 14px;"/></span></div>`;
                                } else {
                                    return `<div class=" ${classes.customTooltip} hoverinfo "><span> ${data.region} <br>Order(s) per million : ${data.value} <br><div style="opacity: 0.5">Order(s) per million : ${data.compareValue} </div>Population : ${numbersFormat(data.population, 0)} </span></div>`;
                                }
                            } else if (property === 'revenue') {
                                if (data.value > data.compareValue) {
                                    return `<div class=" ${classes.customTooltip} hoverinfo "><span> ${data.region} <br>Revenue per million : ${currencySymbol} ${numbersFormat(data.value, 0)} <br><div style="opacity: 0.5">Revenue per million : ${currencySymbol} ${numbersFormat(data.compareValue, 0)} </div>Population : ${numbersFormat(data.population, 0)} </span><span style="color: #27AE60"> ${Math.abs(Math.round((data.value - data.compareValue) * 100 / data.compareValue))} % <i class="fas fa-arrow-up" style="font-size: 14px; vertical-align: middle;"/></span></div>`;
                                } else if (data.value < data.compareValue) {
                                    return `<div class=" ${classes.customTooltip} hoverinfo "><span> ${data.region} <br>Revenue per million : ${currencySymbol} ${numbersFormat(data.value, 0)} <br><div style="opacity: 0.5">Revenue per million : ${currencySymbol} ${numbersFormat(data.compareValue, 0)} </div>Population : ${numbersFormat(data.population, 0)} </span><span style="color:#eb5757"> ${Math.abs(Math.round((data.value - data.compareValue) * 100 / data.compareValue))} % <i class="fas fa-arrow-down" style="font-size: 14px;"/></span></div>`;
                                } else {
                                    return `<div class=" ${classes.customTooltip} hoverinfo "><span> ${data.region} <br>Revenue per million : ${currencySymbol} ${numbersFormat(data.value, 0)} <br><div style="opacity: 0.5">Revenue per million : ${currencySymbol} ${numbersFormat(data.compareValue, 0)} </div>Population : ${numbersFormat(data.population, 0)} </span></div>`;
                                }
                            } else {
                                if (data.value > data.compareValue) {
                                    return `<div class=" ${classes.customTooltip} hoverinfo "><span> ${data.region} <br>Customer(s) per million : ${data.value} <br><div style="opacity: 0.5">Customer(s) per million) : ${data.compareValue} </div>Population : ${numbersFormat(data.population, 0)} </span><span style="color: #27AE60"> ${Math.abs(Math.round((data.value - data.compareValue) * 100 / data.compareValue))} % <i class="fas fa-arrow-up" style="font-size: 14px; vertical-align: middle;"/></span></div>`;
                                } else if (data.value < data.compareValue) {
                                    return `<div class=" ${classes.customTooltip} hoverinfo "><span> ${data.region} <br>Customer(s) per million : ${data.value} <br><div style="opacity: 0.5">Customer(s) per million : ${data.compareValue} </div>Population : ${numbersFormat(data.population, 0)} </span><span style="color: #eb5757"> ${Math.abs(Math.round((data.value - data.compareValue) * 100 / data.compareValue))} % <i class="fas fa-arrow-down" style="font-size: 14px;"/></span></div>`;
                                } else {
                                    return `<div class=" ${classes.customTooltip} hoverinfo "><span> ${data.region} <br>Customer(s) per million : ${data.value} <br><div style="opacity: 0.5">Customer(s) per million : ${data.compareValue} </div>Population : ${numbersFormat(data.population, 0)} </span></div>`;
                                }
                            }
                        } else if (data && data.value) {
                            if (property === 'orders') {
                                return `<div class=" ${classes.customTooltip} hoverinfo " style="display: block"> ${data.region} <br>Order(s) per million : ${data.value} <br><div style="opacity: 0.5">Orders(s) per million : ${0}</div>Population : ${numbersFormat(data.population, 0)}</div>`;
                            } else if (property === 'revenue') {
                                return `<div class=" ${classes.customTooltip} hoverinfo " style="display: block"> ${data.region} <br>Revenue per million : ${currencySymbol} ${numbersFormat(data.value, 0)} <br><div style="opacity: 0.5">Revenue per million : ${currencySymbol} ${numbersFormat(0, 0)} </div>Population : ${numbersFormat(data.population, 0)} </div>`;
                            } else {
                                return `<div class=" ${classes.customTooltip} hoverinfo " style="display: block"> ${data.region} <br>Customer(s) per million : ${data.value} <br><div style="opacity: 0.5">Customer(s) per million : ${0} </div>Population : ${numbersFormat(data.population, 0)} </div>`;
                            }
                        } else if (data && data.compareValue) {
                            if (property === 'orders') {
                                return `<div class=" ${classes.customTooltip} hoverinfo "><span> ${data.region} <br>Order(s) per million : ${0} <br><div style="opacity: 0.5">Orders(s) per million : ${data.compareValue} </div>Population : ${numbersFormat(data.population, 0)} </span><span style="color: #eb5757"> ${Math.abs(Math.round((0 - data.compareValue) * 100 / data.compareValue))} % <i class="fas fa-arrow-down" style="font-size: 14px;"/></span></div>`;
                            } else if (property === 'revenue') {
                                return `<div class=" ${classes.customTooltip} hoverinfo "><span> ${data.region} <br>Revenue per million : ${currencySymbol} ${numbersFormat(0, 0)} <br><div style="opacity: 0.5">Revenue per million : ${currencySymbol} ${numbersFormat(data.compareValue, 0)} </div>Population : ${numbersFormat(data.population, 0)} </span><span style="color: #eb5757"> ${Math.abs(Math.round((0 - data.compareValue) * 100 / data.compareValue))} % <i class="fas fa-arrow-down" style="font-size: 14px;"/></span></div>`;
                            } else {
                                return `<div class=" ${classes.customTooltip} hoverinfo "><span> ${data.region} <br>Customer(s) per million : ${0} <div style="opacity: 0.5">Customer(s) per million : ${data.compareValue} </div>Population : ${numbersFormat(data.population, 0)}} </span><span style="color: #eb5757"> ${Math.abs(Math.round((0 - data.compareValue) * 100 / data.compareValue))} % <i class="fas fa-arrow-down" style="font-size: 14px;"/></span></div>`;
                            }
                        } else {
                            return `<div class=" ${classes.customTooltip} hoverinfo "> ${geography.properties.name} </div>`;
                        }
                    }
                },
                popupOnHover: true
            }
        })
    }

    function currentScreenWidth() {
        return window.innerWidth ||
            document.documentElement.clientWidth ||
            document.body.clientWidth;
    }

    useEffect(() => {
        d3.select('.datamap').remove();
        const mapContainer = d3.select('#datamap-container');
        const initialScreenWidth = currentScreenWidth();
        const containerWidth = (initialScreenWidth < 580) ?
            {height: (initialScreenWidth * 0.5625) + 'px'}
            : { height: "400px", width: currentScreenWidth() * 0.4 + "px" };
        mapContainer.style(containerWidth);
    //eslint-disable-next-line
        datamap = renderMap();
    }, [regionData]);
    return (
        <div id={"datamap-container"}/>
    )
}

export default GeoOrdersPopulation