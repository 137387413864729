import React, {useEffect} from 'react'
import Datamap from 'datamaps/dist/datamaps.usa.min'
import Worldmap from "datamaps/dist/datamaps.world.min"
import {borderFill, defaultFill, highlightFill, maxFill, minFill, numbersFormat, useStyles} from "./geoOrdersGraph";
import d3 from "d3";
import * as _ from "lodash";
import {DefaultRegionData} from "../geoLocationBlock";
import {GAEventTracker} from "../../../index";
import CustomerGroup from "../../getGroupDetails";

var countries = require('country-data').countries;

function GeoOrdersCustomer(props) {
    //eslint-disable-next-line
    let datamap;
    const classes = useStyles();
    const { monthCompare, selectedSeg, regionData, compareRegionData, property, handleClick, countryData, compareCountryData, graphRegion, currencySymbol } = props;
    let graphRegionData = !monthCompare ? regionData : _.merge(_.keyBy(regionData, 'state'), _.keyBy(compareRegionData, 'state'));
    let graphCountryData = !monthCompare ? countryData : _.merge(_.keyBy(countryData, 'country'), _.keyBy(compareCountryData, 'country'));
    graphRegionData = _.values(graphRegionData);
    graphCountryData = _.values(graphCountryData);
    let graphData = graphRegion === 'usa' ? graphRegionData : graphCountryData;

    function linearPaletteScale(value) {
        const dataValues = (graphRegion === 'usa' ? regionData : countryData).map(function (data) {
            return data.value / data.numberCustomers
        });
        const minVal = Math.min(...dataValues);
        const maxVal = Math.max(...dataValues);
        return d3.scale.linear().domain([minVal, maxVal]).range([minFill, maxFill])(value);
    }

    graphData.forEach(d => {
        d.count = parseFloat((d.value / d.numberCustomers).toFixed(1));
        d.compareCount = parseFloat((d.compareValue / d.compareNumberCustomers).toFixed(1))
    });

    const total = Math.round(_.sumBy(graphData, "count"));
    const compareTotal = Math.round(_.sumBy(graphData, "compareCount"));

    function reducedData() {
        const newData = graphData.reduce((object, data) => {
            object[(graphRegion === 'usa' ? data.code : countries[data.code].alpha3)] = {
                region: graphRegion === 'usa' ? data.regionName : data.countryName,
                value: data.count,
                share: Math.round(data.count * 100 / total),
                fillColor: data.count ? linearPaletteScale(data.value / data.numberCustomers) : defaultFill,
                population: data.numberCustomers ? data.numberCustomers : 0,
                compareValue: data.compareCount,
                comparePopulation: data.compareNumberCustomers ? data.compareNumberCustomers : 0,
                compareShare: Math.round(data.compareCount * 100 / compareTotal),
            };
            return object
        }, {});
        return Object.assign({}, DefaultRegionData, newData)
    }

    function renderMap() {
        return (graphRegion === 'usa') ?
            new Datamap({
                element: document.getElementById('datamap-container'),
                position: 'relative',
                scope: 'usa',
                // width: 580,
                projection: 'mercator',
                data: reducedData(),
                fills: { defaultFill: defaultFill },
                done: function (datamap) {
                    datamap.svg.selectAll('.datamaps-subunit').on('click', function (geography) {
                        const region = _.find(regionData, function (o) {
                            return o.regionName === geography.properties.name;
                        });
                        if (region !== undefined) {
                            handleClick('region', geography.properties.name);
                            GAEventTracker(CustomerGroup(selectedSeg)().name, 'Geo Customers graph')
                        }
                    });
                },
                geographyConfig: {
                    borderWidth: 0.5,
                    borderColor: borderFill,
                    highlightFillColor: highlightFill,
                    popupTemplate: function (geography, data) {
                        if (!monthCompare) {
                            if (data && data.value) {
                                if (property === 'orders') {
                                    return `<div class="${classes.customTooltip} hoverinfo "> ${data.region} <br>Order(s) per customer : ${data.value} <br>Customer(s) : ${numbersFormat(data.population, 0)} </div>`;
                                } else {
                                    return `<div class="${classes.customTooltip} hoverinfo "> ${data.region} <br>Revenue per customer : ${currencySymbol} ${numbersFormat(data.value, 0)} <br>Customer(s) : ${numbersFormat(data.population, 0)} </div>`;
                                }
                            } else {
                                return `<div class=" ${classes.customTooltip} hoverinfo "> ${geography.properties.name} </div>`;
                            }
                        } else {
                            if (data && data.value && data.compareValue) {
                                if (property === 'orders') {
                                    if (data.value > data.compareValue) {
                                        return `<div class=" ${classes.customTooltip} hoverinfo "><span> ${data.region} <br>Order(s) per customer : ${data.value} <br>Customer(s) : ${numbersFormat(data.population, 0)} <div style="opacity: 0.5">Order(s) per customer : ${data.compareValue} <br>Customer(s) : ${numbersFormat(data.comparePopulation, 0)} </div></span><span style="color: #27AE60"> ${Math.abs(Math.round((data.value - data.compareValue) * 100 / data.compareValue))} % <i class="fas fa-arrow-up" style="font-size: 14px; vertical-align: middle;"/></span></div>`;
                                    } else if (data.value < data.compareValue) {
                                        return `<div class=" ${classes.customTooltip} hoverinfo "><span> ${data.region} <br>Order(s) per customer : ${data.value} <br>Customer(s) : ${numbersFormat(data.population, 0)} <div style="opacity: 0.5">Order(s) per customer : ${data.compareValue} </div>Customer(s) : ${numbersFormat(data.population, 0)} </span><span style="color:#eb5757"> ${Math.abs(Math.round((data.value - data.compareValue) * 100 / data.compareValue))} % <i class="fas fa-arrow-down" style="font-size: 14px;"/></span></div>`;
                                    } else {
                                        return `<div class=" ${classes.customTooltip} hoverinfo "><span> ${data.region} <br>Order(s) per customer : ${data.value} <br>Customer(s) : ${numbersFormat(data.population, 0)} <div style="opacity: 0.5">Order(s) per customer : ${data.compareValue} </div>Customer(s) : ${numbersFormat(data.population, 0)} </span></div>`;
                                    }
                                } else {
                                    if (data.value > data.compareValue) {
                                        return `<div class=" ${classes.customTooltip} hoverinfo "><span> ${data.region} <br>Revenue per customer : ${currencySymbol} ${numbersFormat(data.value, 0)} <br>Customer(s) : ${numbersFormat(data.population, 0)} <div style="opacity: 0.5">Revenue per customer : ${currencySymbol} ${numbersFormat(data.compareValue, 0)} <br>Customer(s) : ${numbersFormat(data.comparePopulation, 0)} </div></span><span style="color: #27AE60"> ${Math.abs(Math.round((data.value - data.compareValue) * 100 / data.compareValue))} % <i class="fas fa-arrow-up" style="font-size: 14px; vertical-align: middle;"/></span></div>`;
                                    } else if (data.value < data.compareValue) {
                                        return `<div class="${classes.customTooltip} hoverinfo "><span> ${data.region} <br>Revenue per customer : ${currencySymbol} ${numbersFormat(data.value, 0)} <br>Customer(s) : ${numbersFormat(data.population, 0)} <div style="opacity: 0.5">Revenue per customer : ${currencySymbol} ${numbersFormat(data.compareValue, 0)} <br>Customer(s) : ${numbersFormat(data.comparePopulation, 0)} </div></span><span style="color:#eb5757"> ${Math.abs(Math.round((data.value - data.compareValue) * 100 / data.compareValue))} % <i class="fas fa-arrow-down" style="font-size: 14px;"/></span></div>`;
                                    } else {
                                        return `<div class="${classes.customTooltip} hoverinfo "><span> ${data.region} <br>Revenue per customer : ${currencySymbol} ${numbersFormat(data.value, 0)} <br>Customer(s) : ${numbersFormat(data.population, 0)} <div style="opacity: 0.5">Revenue per customer : ${currencySymbol} ${numbersFormat(data.compareValue, 0)} <br>Customer(s) : ${numbersFormat(data.comparePopulation, 0)} </div></span></div>`;
                                    }
                                }
                            } else if (data && data.value) {
                                if (property === 'orders') {
                                    return `<div class="${classes.customTooltip} hoverinfo " style="display: block"> ${data.region} <br>Order(s) per customer : ${data.value} <br>Customer(s) : ${numbersFormat(data.population, 0)} <br><div style="opacity: 0.5">Orders(s) per customer : ${0} <br>Customer(s) : ${numbersFormat(data.comparePopulation, 0)} </div></div>`;
                                } else {
                                    return `<div class="${classes.customTooltip} hoverinfo " style="display: block"> ${data.region} <br>Revenue per customer : ${currencySymbol} ${numbersFormat(data.value, 0)} <br>Customer(s) : ${numbersFormat(data.population, 0)} <br><div style="opacity: 0.5">Revenue per customer : ${currencySymbol + numbersFormat(0, 0)} <br>Customer(s) : ${numbersFormat(data.comparePopulation, 0)} </div></div>`;
                                }
                            } else if (data && data.compareValue) {
                                if (property === 'orders') {
                                    return `<div class=" ${classes.customTooltip} hoverinfo "><span> ${data.region} <br>Order(s) per customer : ${0} <br>Customer(s) : ${numbersFormat(data.population, 0)} <div style="opacity: 0.5">Orders(s) per customer : ${data.compareValue} <br>Customer(s) : ${numbersFormat(data.comparePopulation, 0)} </div></span><span style="color: #eb5757"> ${Math.abs(Math.round((0 - data.compareValue) * 100 / data.compareValue))} % <i class="fas fa-arrow-down" style="font-size: 14px;"/> </span></div>`;
                                } else {
                                    return `<div class=" ${classes.customTooltip} hoverinfo "><span> ${data.region} <br>Revenue per customer : ${currencySymbol} ${numbersFormat(0, 0)} <br>Customer(s) : ${numbersFormat(data.population, 0)} <div style="opacity: 0.5">Revenue per customer : ${currencySymbol} ${numbersFormat(data.compareValue, 0)} <br>Customer(s) : ${numbersFormat(data.comparePopulation, 0)} </div></span><span style="color: #eb5757"> ${Math.abs(Math.round((0 - data.compareValue) * 100 / data.compareValue))} % <i class="fas fa-arrow-down" style="font-size: 14px;"/> </span></div>`;
                                }
                            } else {
                                return `<div class=" ${classes.customTooltip} hoverinfo "> ${geography.properties.name} </div>`;
                            }
                        }
                    },
                    popupOnHover: true
                }
            }) :
            new Worldmap({
                element: document.getElementById('datamap-container'),
                position: 'relative',
                scope: 'world',
                // width: 580,
                projection: 'mercator',
                data: reducedData(),
                fills: { defaultFill: defaultFill },
                done: function (datamap) {
                    datamap.svg.selectAll('.datamaps-subunit').on('click', function (geography) {
                        const country = _.find(countryData, function (o) {
                            return countries[o.code].alpha3 === geography.id;
                        });
                        if (country !== undefined) {
                            handleClick('country', country['country']);
                            GAEventTracker(CustomerGroup(selectedSeg)().name, 'Geolocation graph')
                        }
                    });
                },
                geographyConfig: {
                    borderWidth: 0.5,
                    borderColor: borderFill,
                    highlightFillColor: highlightFill,
                    popupTemplate: function (geography, data) {
                        if (!monthCompare) {
                            if (data && data.value) {
                                if (property === 'orders') {
                                    return `<div class=" ${classes.customTooltip} hoverinfo "> ${data.region} <br>Order(s) per customer : ${data.value} <br>Customer(s) : ${numbersFormat(data.population, 0)}</div>`;
                                } else {
                                    return `<div class=" ${classes.customTooltip} hoverinfo "> ${data.region} <br>Revenue per customer : ${currencySymbol} ${numbersFormat(data.value, 0)} <br>Customer(s) : ${numbersFormat(data.population, 0)} </div>`;
                                }
                            } else {
                                return `<div class=" ${classes.customTooltip} hoverinfo "> ${geography.properties.name} </div>`;
                            }
                        } else {
                            if (data && data.value && data.compareValue) {
                                if (property === 'orders') {
                                    if (data.value > data.compareValue) {
                                        return `<div class="${classes.customTooltip} hoverinfo "><span> ${data.region} <br>Order(s) per customer : ${data.value} <br>Customer(s) : ${numbersFormat(data.population, 0)} <div style="opacity: 0.5">Order(s) per customer : ${data.compareValue} <br>Customer(s) : ${numbersFormat(data.comparePopulation, 0)} </div></span><span style="color: #27AE60"> ${Math.abs(Math.round((data.value - data.compareValue) * 100 / data.compareValue))} % <i class="fas fa-arrow-up" style="font-size: 14px; vertical-align: middle;"/> </span></div>`;
                                    } else if (data.value < data.compareValue) {
                                        return `<div class=" ${classes.customTooltip} hoverinfo "><span> ${data.region} <br>Order(s) per customer : ${data.value} <br>Customer(s) : ${numbersFormat(data.population, 0)} <div style="opacity: 0.5">Order(s) per customer : ${data.compareValue} </div>Customer(s) : ${numbersFormat(data.population, 0)} </span><span style="color:#eb5757">${Math.abs(Math.round((data.value - data.compareValue) * 100 / data.compareValue))} % <i class="fas fa-arrow-down" style="font-size: 14px;"/></span></div>`;
                                    } else {
                                        return `<div class=" ${classes.customTooltip} hoverinfo "><span> ${data.region} <br>Order(s) per customer : ${data.value} <br>Customer(s) : ${numbersFormat(data.population, 0)} <div style="opacity: 0.5">Order(s) per customer : ${data.compareValue} </div>Customer(s) : ${numbersFormat(data.population, 0)} </span></div>`;
                                    }
                                } else {
                                    if (data.value > data.compareValue) {
                                        return `<div class="${classes.customTooltip} hoverinfo "><span> ${data.region} <br>Revenue per customer : ${currencySymbol} ${numbersFormat(data.value, 0)} <br>Customer(s) : ${numbersFormat(data.population, 0)} <div style="opacity: 0.5">Revenue per customer : ${currencySymbol} ${numbersFormat(data.compareValue, 0)} <br>Customer(s) : ${numbersFormat(data.comparePopulation, 0)} </div></span><span style="color: #27AE60"> ${Math.abs(Math.round((data.value - data.compareValue) * 100 / data.compareValue))} % <i class="fas fa-arrow-up" style="font-size: 14px; vertical-align: middle;"/> </span></div>`;
                                    } else if (data.value < data.compareValue) {
                                        return `<div class="${classes.customTooltip} hoverinfo "><span> ${data.region} <br>Revenue per customer : ${currencySymbol} ${numbersFormat(data.value, 0)} <br>Customer(s) : ${numbersFormat(data.population, 0)} <div style="opacity: 0.5">Revenue per customer : ${currencySymbol} ${numbersFormat(data.compareValue, 0)} <br>Customer(s) : ${numbersFormat(data.comparePopulation, 0)} </div></span><span style="color:#eb5757"> ${Math.abs(Math.round((data.value - data.compareValue) * 100 / data.compareValue))} % <i class="fas fa-arrow-down" style="font-size: 14px;"/></span></div>`;
                                    } else {
                                        return `<div class=" ${classes.customTooltip} hoverinfo "><span> ${data.region} <br>Revenue per customer : ${currencySymbol} ${numbersFormat(data.value, 0)} <br>Customer(s) : ${numbersFormat(data.population, 0)} <div style="opacity: 0.5">Revenue per customer : ${currencySymbol} ${numbersFormat(data.compareValue, 0)} <br>Customer(s) : ${numbersFormat(data.comparePopulation, 0)} </div></span></div>`;
                                    }
                                }
                            } else if (data && data.value) {
                                if (property === 'orders') {
                                    return `<div class="${classes.customTooltip} hoverinfo " style="display: block"> ${data.region} <br>Order(s) per customer : ${data.value} <br>Customer(s) : ${numbersFormat(data.population, 0)} <br><div style="opacity: 0.5">Orders(s) per customer : ${0} <br>Customer(s) : ${numbersFormat(data.comparePopulation, 0)} </div></div>`;
                                } else {
                                    return `<div class="${classes.customTooltip} hoverinfo " style="display: block"> ${data.region} <br>Revenue per customer : ${currencySymbol} ${numbersFormat(data.value, 0)} <br>Customer(s) : ${numbersFormat(data.population, 0)} <br><div style="opacity: 0.5">Revenue per customer : ${currencySymbol} ${numbersFormat(0, 0)} <br>Customer(s) : ${numbersFormat(data.comparePopulation, 0)} </div></div>`;
                                }
                            } else if (data && data.compareValue) {
                                if (property === 'orders') {
                                    return `<div class=" ${classes.customTooltip} hoverinfo "><span> ${data.region} <br>Order(s) per customer : ${0} <br>Customer(s) : ${numbersFormat(data.population, 0)} <div style="opacity: 0.5">Orders(s) per customer : ${data.compareValue} <br>Customer(s) : ${numbersFormat(data.comparePopulation, 0)} </div></span><span style="color: #eb5757"> ${Math.abs(Math.round((0 - data.compareValue) * 100 / data.compareValue))} % <i class="fas fa-arrow-down" style="font-size: 14px;"/> </span></div>`;
                                } else {
                                    return `<div class=" ${classes.customTooltip} hoverinfo "><span> ${data.region} <br>Revenue per customer : ${currencySymbol} ${numbersFormat(0, 0)} <br>Customer(s) : ${numbersFormat(data.population, 0)} <div style="opacity: 0.5">Revenue per customer : ${currencySymbol} ${numbersFormat(data.compareValue, 0)} <br>Customer(s) : ${numbersFormat(data.comparePopulation, 0)} </div></span><span style="color: #eb5757"> ${Math.abs(Math.round((0 - data.compareValue) * 100 / data.compareValue))} % <i class="fas fa-arrow-down" style="font-size: 14px;"/></span></div>`;
                                }
                            } else {
                                return `<div class=" ${classes.customTooltip} hoverinfo "> ${geography.properties.name} </div>`;
                            }
                        }
                    },
                    popupOnHover: true
                }
            })
    }

    function currentScreenWidth() {
        return window.innerWidth ||
            document.documentElement.clientWidth ||
            document.body.clientWidth;
    }

    useEffect(() => {
        d3.select('.datamap').remove();
        const mapContainer = d3.select('#datamap-container');
        const initialScreenWidth = currentScreenWidth();
        const containerWidth = (initialScreenWidth < 580) ?
            // {width: initialScreenWidth + 'px', height: (initialScreenWidth * 0.5625) + 'px'} :
            // {width: '580px', height: '400px'};
            { height: (initialScreenWidth * 0.5625) + 'px' }
            : { height: "400px", width: currentScreenWidth() * 0.4 + "px" };
        mapContainer.style(containerWidth);
        //eslint-disable-next-line
        datamap = renderMap();
    }, [regionData]);
    return (
        <div id={"datamap-container"} />
    )
}

export default GeoOrdersCustomer