import React, {useEffect} from "react";
import {DialogBox} from "./fbReauthenticatePopup";
import {connect} from "react-redux";
import {getCookie} from "./cookiePoilcy";
import * as _ from "lodash";


const GoogleReauthenticatePopup=(props)=>{
  const {userProfile,setFbopen} = props;
  const [googleReautheticatePopup, setGoogleReautheticatePopup] = React.useState(false);
  let buID=userProfile.bu_id;
  const setCookie = () => {
    let date = new Date();
    date.setTime(date.getTime() + 24 * 60 * 60 * 1000);
    document.cookie = `lxrin_googlereauth = LXRI.${userProfile.bu_id}; expires = ${date.toGMTString()}`;
  };
  useEffect(() => {
    if(userProfile.ga_token_expiry_flag){
      const checkCookie = () => {
        let lxrinfb_cookie = _.split(getCookie("lxrin_googlereauth"), ".");
        if (lxrinfb_cookie[1] === buID.toString()) {
            setGoogleReautheticatePopup(false);
        } else {
            setGoogleReautheticatePopup(true);
        }
      };
      checkCookie()
    }
  }, [userProfile]);
    return(<React.Fragment>
        {userProfile.ga_token_expiry_flag ? (
        <DialogBox
          popUp={googleReautheticatePopup}
          setPopUp={setGoogleReautheticatePopup}
          buID={userProfile.bu_id}
          setCookie={setCookie}
          title={'Google Ads'}
          setFbopen={setFbopen}
          fbflag={userProfile.fb_token_expiry_flag}
        />
      ) : null}
    </React.Fragment>)
}

const mapStateToProps = (state) => ({
    userProfile: state.userProfile,
});

export default connect(mapStateToProps,null)(GoogleReauthenticatePopup);