import makeStyles from '@mui/styles/makeStyles';
import {border} from "./commonStyles";
import grp1 from "../images/AddLXRTracking/grp1.png"
import grp2 from "../images/AddLXRTracking/grp2.png"

const App_URL = process.env.REACT_APP_AppUrl;
const GroupPageStyles = makeStyles((theme) => ({
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    marginLeft: theme.spacing(7),
    [theme.breakpoints.up(600)]: {
      marginLeft: theme.spacing(11),
    },
    paddingLeft: "15px",
    backgroundColor: theme.palette.common.white,
  },
  overviewStyles: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.primary.main,
    border: border,
    borderRadius: 2,
  },
  headerStyles: {
    padding: 16,
    paddingBottom:12,
    border: border,
    backgroundColor: theme.palette.backgroundColors.overview,
  },
  avgIconStyles: {
    backgroundColor: theme.palette.primary.main,
    padding: 8,
    borderRadius: 4,
  },
  insightsHeader: {
    backgroundColor: "#F9FAFF",
    paddingTop: 8,
    paddingBottom: 8,
    minHeight: 120,
  },
  datePicker: {
    color: theme.palette.primary.main,
    border: "none",
    outline: "none",
    fontFamily: '"Lato", sans-serif',
    fontWeight: 400,
    fontSize: 16,
    cursor: "pointer",
  },
  monthHeader: {
    position: "sticky",
    top: 70,
    zIndex: 9,
    margin: 8,
    border: "none",
  },
  dateheader: {
    backgroundColor: theme.palette.backgroundColors.overview,
    display: "flex",
    width: "100%",
  },
  inputFont: {
    fontSize: "0.875rem",
  },
  applyButton: {
    backgroundColor: theme.palette.secondary.light,
    fontSize: 16,
    border: "none",
    color: theme.palette.common.white,
  },
  cancelButton: {
    backgroundColor: "transparent",
    fontSize: 16,
    color: theme.palette.primary.main,
    border: "1px solid #DDDCDB",
  },
  insightsStyles: {
    border: "1px solid #C0C0C0",
    borderRadius: "6px",
  },
  insightsDetails: {
    borderTop: "1px solid #C0C0C0",
    height: "192px !important",
    overflowX: "hidden",
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
      width: "0.4em",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
      webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.1)",
      borderRadius: "8px",
    },
  },
  topPaidPath: {
    alignItems: "center",
    textAlign: "center",
    height: "240px !important",
    border: "1px solid #C0C0C0",
    borderRadius: "6px",
    backgroundColor: theme.palette.backgroundColors.overview,
  },
  scrollbar: {
    "&::-webkit-scrollbar": {
      width: "0.4em",
      height: "0.4em",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
      webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.1)",
      borderRadius: "6px",
    },
  },
  timelinecontainer: {
    width: "200px",
  },
  timelinecontent: {
    borderRadius: "5px",
    backgroundColor: theme.palette.common.white,
    color: theme.palette.primary.main,
    fontSize: "0.875rem",
    padding: "2px",
  },
  gptcard:{
    marginTop:'8px',
    padding:'16px 16px 16px 16px',
    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
    minHeight:'40px'
  },
  topPathPlaceholder:{
    background: `url(${App_URL}${grp1}) center no-repeat`,
    alignItems: "center",
    textAlign: "center",
    height: "240px !important",
    border: "1px solid #C0C0C0",
    borderRadius: "6px",
    backgroundColor: theme.palette.backgroundColors.overview,
  },
  conversionPathBlurred:{
    background: `url(${App_URL}${grp2}) center no-repeat`,
    alignItems: "center",
    textAlign: "center",
    height:'500px'
  }
}));

export default GroupPageStyles;