import React from "react"
import * as _ from "lodash";
import DateRangePicker from 'react-bootstrap-daterangepicker';
import moment from "moment"
import "../../assets/jss/customDateRangePicker.css"
import {Typography} from "@mui/material";

export default function BSelectPicker(props) {
    const {overviewData, start, end, classes, onCallBack, onShow, label} = props;
    const groupData = _.find(overviewData, {'segment': 'b'}) ? _.find(overviewData, {'segment': 'b'}) : null
    return (
        <DateRangePicker
            onShow={onShow}
            initialSettings={{
                parentEl: "#daterangepicker",
                opens: 'center',
                applyButtonClasses: classes.applyButton,
                cancelButtonClasses: classes.cancelButton,
                startDate: start.toDate(),
                endDate: end.toDate(),
                minDate: moment(new Date(groupData.data_start)).toDate(),
                maxDate: moment(new Date(groupData.data_end)).toDate(),
                autoApply: true,
                alwaysShowCalendars: true,
                ranges: {
                    'Last Month': [
                        moment(new Date(groupData.data_end)).subtract(0, 'month').startOf('month').toDate(),
                        moment(new Date(groupData.data_end)).subtract(0, 'month').endOf('month').toDate(),
                    ],
                    'Last 3 Months': [
                        moment(new Date(groupData.data_end)).subtract(2, 'month').startOf('month').toDate(),
                        moment(new Date(groupData.data_end)).subtract(0, 'month').endOf('month').toDate(),
                    ],
                    'Last 6 Months': [
                        moment(new Date(groupData.data_end)).subtract(5, 'month').startOf('month').toDate(),
                        moment(new Date(groupData.data_end)).subtract(0, 'month').endOf('month').toDate(),
                    ],
                },
            }}
            onCallback={(start, end) => {
                onCallBack(start, end, false)
            }}
        >
            <Typography style={{cursor: "pointer"}}>{label}</Typography>
        </DateRangePicker>
    )
}

export function BLeftSelectPicker(props) {
    const {overviewData, start, end, classes, onCallBack, onShow, label} = props;
    const groupData = _.find(overviewData, {'segment': 'b'}) ? _.find(overviewData, {'segment': 'b'}) : null
    return (
        <DateRangePicker
            onShow={onShow}
            initialSettings={{
                parentEl: "#daterangepicker",
                opens: 'left',
                applyButtonClasses: classes.applyButton,
                cancelButtonClasses: classes.cancelButton,
                startDate: start.toDate(),
                endDate: end.toDate(),
                minDate: moment(new Date(groupData.data_start)).toDate(),
                maxDate: moment(new Date(groupData.data_end)).toDate(),
                autoApply: true,
                alwaysShowCalendars: true,
                ranges: {
                    'Last Month': [
                        moment(new Date(groupData.data_end)).subtract(0, 'month').startOf('month').toDate(),
                        moment(new Date(groupData.data_end)).subtract(0, 'month').endOf('month').toDate(),
                    ],
                    'Last 3 Months': [
                        moment(new Date(groupData.data_end)).subtract(2, 'month').startOf('month').toDate(),
                        moment(new Date(groupData.data_end)).subtract(0, 'month').endOf('month').toDate(),
                    ],
                    'Last 6 Months': [
                        moment(new Date(groupData.data_end)).subtract(5, 'month').startOf('month').toDate(),
                        moment(new Date(groupData.data_end)).subtract(0, 'month').endOf('month').toDate(),
                    ],
                },
            }}
            onCallback={(start, end) => {
                onCallBack(start, end, false)
            }}
        >
            <Typography style={{cursor: "pointer"}}>{label}</Typography>
        </DateRangePicker>
    )
}

export function BComparePicker(props) {
    const {onShow, classes, start, end, onCallBack, overviewData, label} = props
    const groupData = _.find(overviewData, {'segment': 'b'}) ? _.find(overviewData, {'segment': 'b'}) : null
    return (
        <DateRangePicker
            onShow={onShow}
            initialSettings={{
                parentEl: "#daterangepicker",
                opens: 'left',
                applyButtonClasses: classes.applyButton,
                cancelButtonClasses: classes.cancelButton,
                alwaysShowCalendars: true,
                autoApply: true,
                startDate: moment(start).toDate(),
                endDate: moment(end).toDate(),
                minDate: moment(new Date(groupData.data_start)).toDate(),
                maxDate: moment(new Date(groupData.data_end)).toDate(),
            }}
            onCallback={(compareStart, compareEnd) => {
                onCallBack(compareStart, compareEnd, false)
            }}
        >
            <Typography style={{cursor: "pointer"}}>{label}</Typography>
        </DateRangePicker>
    )
}