import { MenuItem, Select } from "@mui/material";
import React from "react";

const SelectComponent = (props) => {
  const { optionsList, value, changeLabelText, changeBorderColor, onChange } =
    props;

  return (
    <Select
      value={value}
      onChange={onChange}
      size="small"
      displayEmpty
      inputProps={{ "aria-label": "Without label" }}
      renderValue={(value) => {
        if (changeLabelText) {
          return changeLabelText;
        }

        return value;
      }}
      sx={{
        padding: 0,
        borderRadius: 1,
        border: 0,
        width: "13rem",
        fontSize: "14px",
        color: "#A4A4A4",

        "&:hover": {
          "&& fieldset": {
            border: changeBorderColor
              ? `1px solid ${changeBorderColor}`
              : "1px solid #C6C6C6",
          },
        },
        "&:focus": {
          "&& fieldset": {
            border: changeBorderColor
              ? `1px solid ${changeBorderColor}`
              : "1px solid #C6C6C6",
          },
        },
        "&.Mui-focused": {
          "&& fieldset": {
            border: changeBorderColor
              ? `1px solid ${changeBorderColor}`
              : "1px solid #C6C6C6",
          },
        },
      }}
    >
      {optionsList?.map((each, index) => (
        <MenuItem
          value={each?.value}
          key={`SA-Analytics-metric-options-${each?.id}-${index}`}
          sx={{
            color: "#A4A4A4",
          }}
        >
          {each?.label}
        </MenuItem>
      ))}
    </Select>
  );
};

export default SelectComponent;
