import React from 'react'
import {connect} from "react-redux";
import {Box, Grid, MenuItem, Select, Typography} from "@mui/material";
import withStyles from '@mui/styles/withStyles';
import {border} from "../../assets/jss/commonStyles";
import {capitalCase} from "change-case"
import GeoOrdersGraph from "./graphs/geoOrdersGraph"
import GeoOrdersCustomer from "./graphs/geoOrdersCustomer"
import GeoOrdersPopulation from "./graphs/geoOrdersPopulation"
import * as _ from "lodash"
import GeoLocationTable from "./geoLocationTable"
import {set_a_group_filter, set_all_group_filter, set_b_group_filter, set_c_group_filter} from "../../actions/actions";
import {GAEventTracker} from "../../index";
import CustomerGroup from "../getGroupDetails";
import Icons from '../icons';
import NumericLabel from "react-pretty-numbers";


var countries = require('country-data').countries

const CustomSelect = withStyles(theme => ({
    select: {
        minWidth: 100,
        textAlign: 'center',
    },
    icon: {
        top: 'calc(50% - 16px)',
        width: '2em',
        height: '2em',
        color: theme.palette.primary.main
    },
    outlined: {
        padding: '10px 14px',
        fontSize: '0.875rem'
    }
}))(Select)

export const DefaultRegionData = [
    {'regionName': 'Alabama', 'code': 'AL', 'value': 0,},
    {'regionName': 'Alaska', 'code': 'AK', 'value': 0},
    {'regionName': 'Arizona', 'code': 'AZ', 'value': 0},
    {'regionName': 'Arkansas', 'code': 'AR', 'value': 0},
    {'regionName': 'California', 'code': 'CA', 'value': 0},
    {'regionName': 'Colorado', 'code': 'CO', 'value': 0},
    {'regionName': 'Connecticut', 'code': 'CT', 'value': 0},
    {'regionName': 'Delaware', 'code': 'DE', 'value': 0},
    {'regionName': 'District of Columbia', 'code': 'DC', 'value': 0},
    {'regionName': 'Florida', 'code': 'FL', 'value': 0},
    {'regionName': 'Georgia', 'code': 'GA', 'value': 0},
    {'regionName': 'Hawaii', 'code': 'HI', 'value': 0},
    {'regionName': 'Idaho', 'code': 'ID', 'value': 0},
    {'regionName': 'Illinois', 'code': 'IL', 'value': 0},
    {'regionName': 'Indiana', 'code': 'IN', 'value': 0},
    {'regionName': 'Iowa', 'code': 'IA', 'value': 0},
    {'regionName': 'Kansas', 'code': 'KS', 'value': 0},
    {'regionName': 'Kentucky', 'code': 'KY', 'value': 0},
    {'regionName': 'Louisiana', 'code': 'LA', 'value': 0},
    {'regionName': 'Maine', 'code': 'ME', 'value': 0},
    {'regionName': 'Maryland', 'code': 'MD', 'value': 0},
    {'regionName': 'Massachusetts', 'code': 'MA', 'value': 0},
    {'regionName': 'Michigan', 'code': 'MI', 'value': 0},
    {'regionName': 'Minnesota', 'code': 'MN', 'value': 0},
    {'regionName': 'Mississippi', 'code': 'MS', 'value': 0},
    {'regionName': 'Missouri', 'code': 'MO', 'value': 0},
    {'regionName': 'Montana', 'code': 'MT', 'value': 0},
    {'regionName': 'Nebraska', 'code': 'NE', 'value': 0},
    {'regionName': 'Nevada', 'code': 'NV', 'value': 0},
    {'regionName': 'New Hampshire', 'code': 'NH', 'value': 0},
    {'regionName': 'New Jersey', 'code': 'NJ', 'value': 0},
    {'regionName': 'New Mexico', 'code': 'NM', 'value': 0},
    {'regionName': 'New York', 'code': 'NY', 'value': 0},
    {'regionName': 'North Carolina', 'code': 'NC', 'value': 0},
    {'regionName': 'North Dakota', 'code': 'ND', 'value': 0},
    {'regionName': 'Ohio', 'code': 'OH', 'value': 0},
    {'regionName': 'Oklahoma', 'code': 'OK', 'value': 0},
    {'regionName': 'Oregon', 'code': 'OR', 'value': 0},
    {'regionName': 'Pennsylvania', 'code': 'PA', 'value': 0},
    {'regionName': 'Rhode Island', 'code': 'RI', 'value': 0},
    {'regionName': 'South Carolina', 'code': 'SC', 'value': 0},
    {'regionName': 'South Dakota', 'code': 'SD', 'value': 0},
    {'regionName': 'Tennessee', 'code': 'TN', 'value': 0},
    {'regionName': 'Texas', 'code': 'TX', 'value': 0},
    {'regionName': 'Utah', 'code': 'UT', 'value': 0},
    {'regionName': 'Vermont', 'code': 'VT', 'value': 0},
    {'regionName': 'Virginia', 'code': 'VA', 'value': 0},
    {'regionName': 'Washington', 'code': 'WA', 'value': 0},
    {'regionName': 'West Virginia', 'code': 'WV', 'value': 0},
    {'regionName': 'Wisconsin', 'code': 'WI', 'value': 0},
    {'regionName': 'Wyoming', 'code': 'WY', 'value': 0}
];

export const codeMap = _.invert({
    "AL": "Alabama",
    "AK": "Alaska",
    "AZ": "Arizona",
    "AR": "Arkansas",
    "CA": "California",
    "CO": "Colorado",
    "CT": "Connecticut",
    "DE": "Delaware",
    "DC": "District of Columbia",
    "FL": "Florida",
    "GA": "Georgia",
    "HI": "Hawaii",
    "ID": "Idaho",
    "IL": "Illinois",
    "IN": "Indiana",
    "IA": "Iowa",
    "KS": "Kansas",
    "KY": "Kentucky",
    "LA": "Louisiana",
    "ME": "Maine",
    "MD": "Maryland",
    "MA": "Massachusetts",
    "MI": "Michigan",
    "MN": "Minnesota",
    "MS": "Mississippi",
    "MO": "Missouri",
    "MT": "Montana",
    "NE": "Nebraska",
    "NV": "Nevada",
    "NH": "New Hampshire",
    "NJ": "New Jersey",
    "NM": "New Mexico",
    "NY": "New York",
    "NC": "North Carolina",
    "ND": "North Dakota",
    "OH": "Ohio",
    "OK": "Oklahoma",
    "OR": "Oregon",
    "PA": "Pennsylvania",
    "PR": "Puerto Rico",
    "RI": "Rhode Island",
    "SC": "South Carolina",
    "SD": "South Dakota",
    "TN": "Tennessee",
    "TX": "Texas",
    "UT": "Utah",
    "VT": "Vermont",
    "VA": "Virginia",
    "WA": "Washington",
    "WV": "West Virginia",
    "WI": "Wisconsin",
    "WY": "Wyoming"
});



export var sanitizeStateName = function (name) {
    if (!_.isString(name)) {
        return null;
    }

    // bad whitespace remains bad whitespace e.g. "O  hi o" is not valid
    name = name.trim().toLowerCase().replace(/[^a-z\s]/g, '').replace(/\s+/g, ' ');

    var tokens = name.split(/\s+/);
    tokens = _.map(tokens, function (token) {
        return token.charAt(0).toUpperCase() + token.slice(1);
    });

    // account for District of Columbia
    if (tokens.length > 2) {
        tokens[1] = tokens[1].toLowerCase();
    }

    name = tokens.join(' ');
    return codeMap[name] ? name : null;
};

function GeoLocationBlock(props) {
    const {graphRegion, selectedSeg, property, orderData, compareData, application_states, population_data_reducer, set_all_group_filter, set_a_group_filter, set_b_group_filter, set_c_group_filter} = props;
    const handleClick = (filterName, filterValue) => {
        switch (selectedSeg) {
            case 'all':
                set_all_group_filter(filterName, filterValue);
                break;
            case 'a':
                set_a_group_filter(filterName, filterValue);
                break;
            case 'b':
                set_b_group_filter(filterName, filterValue);
                break;
            case 'c':
                set_c_group_filter(filterName, filterValue);
                break;
            default:
                return null
        }
    }
    const monthCompare = application_states.monthCompare;
    const currency = application_states.currency;
    const currencySymbol = application_states.currencySymbol;
    const [chart, setChart] = React.useState('property')
    const handleChartChange = (e) => {
        setChart(e.target.value)
    }
    let geoGraphData, compareGeoGraphData, countryGraphData, compareCountryGraphData;
    if (property === "orders") {
        geoGraphData = _(orderData.orders).groupBy('region').map((objs, key) => ({
            regionName: key,
            value: _.sumBy(objs, 'orders'),
            code: codeMap[sanitizeStateName(key)],
            state: key,
            country: countries[objs[0].country_code].name
        })).value()
        countryGraphData = _.map(_.mapValues(_.groupBy(orderData.orders, "country_code"), d => _.sumBy(d, "orders")), (orders, country_code) => ({
            "countryName": countries[country_code].name,
            "value": orders,
            "code": country_code,
            "country": countries[country_code].name
        }));
        compareGeoGraphData = _(compareData.orders).groupBy('region').map((objs, key) => ({
            regionName: key,
            compareValue: _.sumBy(objs, 'orders'),
            code: codeMap[sanitizeStateName(key)],
            state: key,
            country: countries[objs[0].country_code].name
        })).value()
        compareCountryGraphData = _.map(_.mapValues(_.groupBy(compareData.orders, "country_code"), d => _.sumBy(d, "orders")), (orders, country_code) => ({
            "countryName": countries[country_code].name,
            "compareValue": orders,
            "code": country_code,
            "country": countries[country_code].name
        }));
    } else {
        if (property === "revenue") {
            geoGraphData = _(orderData.orders).groupBy('region').map((objs, key) => ({
                regionName: key,
                value: _.sumBy(objs, 'revenue'),
                code: codeMap[sanitizeStateName(key)],
                state: key,
                country: countries[objs[0].country_code].name
            })).value()
            countryGraphData = _.map(_.mapValues(_.groupBy(orderData.orders, "country_code"), d => _.sumBy(d, "revenue")), (revenue, country_code) => ({
                "countryName": countries[country_code].name,
                "value": revenue,
                "code": country_code,
                "country": countries[country_code].name
            }));
            compareGeoGraphData = _(compareData.orders).groupBy('region').map((objs, key) => ({
                regionName: key,
                compareValue: _.sumBy(objs, 'revenue'),
                code: codeMap[sanitizeStateName(key)],
                state: key,
                country: countries[objs[0].country_code].name
            })).value()
            compareCountryGraphData = _.map(_.mapValues(_.groupBy(compareData.orders, "country_code"), d => _.sumBy(d, "revenue")), (revenue, country_code) => ({
                "countryName": countries[country_code].name,
                "compareValue": revenue,
                "code": country_code,
                "country": countries[country_code].name
            }));
        } else {
            if (chart === 'customers') {
                setChart('property');
            }
            geoGraphData = _(orderData.orders).groupBy('region').map((objs, key) => ({
                regionName: key,
                customerIDs: _.uniqBy(_.flatten(_.map(_.uniqBy(objs, "customers"), (d) => _.values(d.customers)))),
                value: _.uniqBy(_.flatten(_.map(_.uniqBy(objs, "customers"), (d) => _.values(d.customers)))).length ? _.uniqBy(_.flatten(_.map(_.uniqBy(objs, "customers"), (d) => _.values(d.customers)))).length : _.sumBy(_.uniqBy(objs, "customers"), "customers"),
                code: codeMap[sanitizeStateName(key)],
                state: key,
                country: countries[objs[0].country_code].name
            })).value()
            countryGraphData = _.map(_.mapValues(_.groupBy(orderData.orders, "country_code"), d => _.uniqBy(d, "customers")), (customers, country_code) => ({
                "countryName": countries[country_code].name,
                "customerIDs": _.uniqBy(_.flatten(_.map(customers, (d) => _.values(d.customers)))),
                "value": _.uniqBy(_.flatten(_.map(customers, (d) => _.values(d.customers)))).length ? _.uniqBy(_.flatten(_.map(customers, (d) => _.values(d.customers)))).length : _.sumBy(customers, "customers"),
                "code": country_code,
                "country": countries[country_code].name
            }));
            compareGeoGraphData = _(compareData.orders).groupBy('region').map((objs, key) => ({
                regionName: key,
                compareCustomerIDs: _.uniqBy(_.flatten(_.map(_.uniqBy(objs, "customers"), (d) => _.values(d.customers)))),
                compareValue: _.uniqBy(_.flatten(_.map(_.uniqBy(objs, "customers"), (d) => _.values(d.customers)))).length ? _.uniqBy(_.flatten(_.map(_.uniqBy(objs, "customers"), (d) => _.values(d.customers)))).length : _.sumBy(_.uniqBy(objs, "customers"), "customers"),
                code: codeMap[sanitizeStateName(key)],
                state: key,
                country: countries[objs[0].country_code].name
            })).value()
            compareCountryGraphData = _.map(_.mapValues(_.groupBy(compareData.orders, "country_code"), d => _.uniqBy(d, "customers")), (customers, country_code) => ({
                "countryName": countries[country_code].name,
                "compareCustomerIDs": _.uniqBy(_.flatten(_.map(customers, (d) => _.values(d.customers)))),
                "compareValue": _.uniqBy(_.flatten(_.map(customers, (d) => _.values(d.customers)))).length ? _.uniqBy(_.flatten(_.map(customers, (d) => _.values(d.customers)))).length : _.sumBy(customers, "customers"),
                "code": country_code,
                "country": countries[country_code].name
            }));
        }
    }
    let mergeData = _.map(geoGraphData)
    let compareMergeData = _.map(compareGeoGraphData)
    let mergeCountryData = _.map(countryGraphData)
    let compareMergeCountryData = _.map(compareCountryGraphData)
    let customerGraphData = _(orderData.orders).groupBy('region').map((objs, key) => ({
        regionName: key,
        customerIDs: _.uniqBy(_.flatten(_.map(_.uniqBy(objs, "customers"), (d) => _.values(d.customers)))),
        numberCustomers: _.uniqBy(_.flatten(_.map(_.uniqBy(objs, "customers"), (d) => _.values(d.customers)))).length ? _.uniqBy(_.flatten(_.map(_.uniqBy(objs, "customers"), (d) => _.values(d.customers)))).length : _.sumBy(_.uniqBy(objs, "customers"), "customers"),
        code: codeMap[sanitizeStateName(key)],
        state: key,
        country: countries[objs[0].country_code].name
    })).value()
    let customerCountryGraphData = _.map(_.mapValues(_.groupBy(orderData.orders, "country_code"), d => _.uniqBy(d, "customers")), (orders, country_code) => ({
        "countryName": countries[country_code].name,
        "customerIDs": _.uniqBy(_.flatten(_.map(orders, (d) => _.values(d.customers)))),
        "numberCustomers": _.uniqBy(_.flatten(_.map(orders, (d) => _.values(d.customers)))).length ? _.uniqBy(_.flatten(_.map(orders, (d) => _.values(d.customers)))).length : _.sumBy(orders, "customers"),
        "code": country_code,
        "country": countries[country_code].name,
    }));
    let compareCustomerGraphData = _(compareData.orders).groupBy('region').map((objs, key) => ({
        regionName: key,
        compareCustomerIDs: _.uniqBy(_.flatten(_.map(_.uniqBy(objs, "customers"), (d) => _.values(d.customers)))),
        compareNumberCustomers: _.uniqBy(_.flatten(_.map(_.uniqBy(objs, "customers"), (d) => _.values(d.customers)))).length ? _.uniqBy(_.flatten(_.map(_.uniqBy(objs, "customers"), (d) => _.values(d.customers)))).length : _.sumBy(_.uniqBy(objs, "customers"), "customers"),
        code: codeMap[sanitizeStateName(key)],
        state: key,
        country: countries[objs[0].country_code].name
    })).value()
    let compareCustomerCountryGraphData = _.map(_.mapValues(_.groupBy(compareData.orders, "country_code"), d => _.uniqBy(d, "customers")), (orders, country_code) => ({
        "countryName": countries[country_code].name,
        "compareCustomerIDs": _.uniqBy(_.flatten(_.map(orders, (d) => _.values(d.customers)))),
        "compareNumberCustomers": _.uniqBy(_.flatten(_.map(orders, (d) => _.values(d.customers)))).length ? _.uniqBy(_.flatten(_.map(orders, (d) => _.values(d.customers)))).length : _.sumBy(orders, "customers"),
        "code": country_code,
        "country": countries[country_code].name,
    }));
    customerGraphData = _.map(mergeData, function (letter) {
        return _.merge(letter, _.find(customerGraphData, {'state': letter.state}));
    });
    customerCountryGraphData = _.map(mergeCountryData, function (letter) {
        return _.merge(letter, _.find(customerCountryGraphData, {'country': letter.country}));
    });
    compareCustomerGraphData = _.map(compareMergeData, function (letter) {
        return _.merge(letter, _.find(compareCustomerGraphData, {'state': letter.state}));
    });
    compareCustomerCountryGraphData = _.map(compareMergeCountryData, function (letter) {
        return _.merge(letter, _.find(compareCustomerCountryGraphData, {'country': letter.country}));
    });
    let initialPopulationData = _.map(mergeData, function (item) {
        return _.merge(item, _.find(population_data_reducer.us_population, {'state': item.state}));
    });
    let compareInitialPopulationData = _.map(compareMergeData, function (item) {
        return _.merge(item, _.find(population_data_reducer.us_population, {'state': item.state}));
    });
    let populationGraphData, comparePopulationGraphData;
    if (property === "orders") {
        populationGraphData = _.map(_.filter(initialPopulationData, d => d.state !== "Others"), (d) => ({
            "orders": d.value,
            "state": d.state,
            "code": codeMap[sanitizeStateName(d.state)],
            "population": d.population,
            "divValue": parseFloat((d.value * 1000000 / d.population).toFixed(1))
        }));
        comparePopulationGraphData = _.map(_.filter(compareInitialPopulationData, d => d.state !== "Others"), (d) => ({
            "compareOrders": d.compareValue,
            "state": d.state,
            "code": codeMap[sanitizeStateName(d.state)],
            "population": d.population,
            "compareDivValue": parseFloat((d.compareValue * 1000000 / d.population).toFixed(1))
        }));
    } else {
        if (property === "revenue") {
            populationGraphData = _.map(_.filter(initialPopulationData, d => d.state !== "Others"), (d) => ({
                "revenue": d.value,
                "state": d.state,
                "code": codeMap[sanitizeStateName(d.state)],
                "population": d.population,
                "divValue": parseFloat((d.value * 1000000 / d.population).toFixed(1))
            }));
            comparePopulationGraphData = _.map(_.filter(compareInitialPopulationData, d => d.state !== "Others"), (d) => ({
                "compareRevenue": d.compareValue,
                "state": d.state,
                "code": codeMap[sanitizeStateName(d.state)],
                "population": d.population,
                "compareDivValue": parseFloat((d.compareValue * 1000000 / d.population).toFixed(1))
            }));
        } else {
            populationGraphData = _.map(_.filter(initialPopulationData, d => d.state !== "Others"), (d) => ({
                "customers": d.value,
                "state": d.state,
                "code": codeMap[sanitizeStateName(d.state)],
                "population": d.population,
                "divValue": parseFloat((d.value / (d.population / 1000000).toFixed(0)).toFixed(1))
            }));
            comparePopulationGraphData = _.map(_.filter(compareInitialPopulationData, d => d.state !== "Others"), (d) => ({
                "compareCustomers": d.compareValue,
                "state": d.state,
                "code": codeMap[sanitizeStateName(d.state)],
                "population": d.population,
                "compareDivValue": parseFloat((d.compareValue / (d.population / 1000000).toFixed(0)).toFixed(1))
            }));
        }
    }
   
    const paramOptions5 = {
        commafy: true,
        wholenumber: 'ceil',
        shortFormat: true,
        shortFormatMinValue: 1000,
        justification: 'L',
    }
    /*const orderParams = {
        commafy: true,
        wholenumber: 'ceil',
        shortFormat: true,
        shortFormatMinValue: 10000,
        justification: 'L',
    }
    const revenueParams = {
        'wholenumber': 'ceil',
        'locales': 'en-US',
        currency: true,
        currencyIndicator: currency,
        'commafy': true,
        justification: 'L',
    }
    */
    let graphRegionData = !monthCompare ? geoGraphData : _.merge(_.keyBy(geoGraphData, 'state'), _.keyBy(compareGeoGraphData, 'state'));
    let graphCountryData = !monthCompare ? countryGraphData : _.merge(_.keyBy(countryGraphData, 'country'), _.keyBy(compareCountryGraphData, 'country'));
    graphRegionData = _.values(graphRegionData)
    graphCountryData = _.values(graphCountryData)
    let graphData = graphRegion === 'usa' ? graphRegionData : graphCountryData

    const orderDataValues = (graphRegion === "usa" ? geoGraphData : countryGraphData).map(
      function (data) {
        return data.value;
      }
    );
    const orderMinVal = Math.min(...orderDataValues);
    const orderMaxVal = Math.max(...orderDataValues);

    const orderPopdataValues = _.remove(populationGraphData.map(function (data) {
            if (data.divValue) {
                return data.divValue
            }
            return null
        }), undefined);
    const orderPopMinVal = Math.min(...orderPopdataValues);
    const orderPopMaxVal = Math.max(...orderPopdataValues);

     const ordercustomerDataValues = (
       graphRegion === "usa" ? customerGraphData : customerCountryGraphData
     ).map(function (data) {
       return data.value / data.numberCustomers;
     });
     const orderCustomerMinVal = Math.min(...ordercustomerDataValues);
     const orderCustomerMaxVal = Math.max(...ordercustomerDataValues);

    return (
        <Box>
            <Box p={2} borderBottom={border}>
                <Typography variant={"h4"} style={{fontWeight: 700}}>
                    {capitalCase(property)} by Location
                </Typography>
            </Box>
            <Grid container alignItems={"stretch"}>
                <Grid item md={6}>
                    <Grid container style={{textAlign: "end"}}>
                        <Grid item xs={12}>
                            <Box pt={1} pr={2}>
                                <CustomSelect variant={"outlined"} value={chart}
                                              onChange={(e) => {
                                                  handleChartChange(e);
                                                  GAEventTracker(CustomerGroup(selectedSeg)().name, 'Geolocation select', e.target.value)
                                              }}>
                                    <MenuItem value={'property'}>{capitalCase(property)}</MenuItem>
                                    {graphRegion === 'usa' ?
                                        <MenuItem value={'population'}>{capitalCase(property)}/Population</MenuItem>
                                        :
                                        null
                                    }
                                    {property !== 'customers' ?
                                        <MenuItem value={'customers'}>{capitalCase(property)}/Customer</MenuItem>
                                        :
                                        null
                                    }
                                </CustomSelect>
                            </Box>
                        </Grid>
                    </Grid>
                    <Box position={'relative'}>
                        {chart === 'property' ?
                            <GeoOrdersGraph monthCompare={monthCompare} selectedSeg={selectedSeg}
                                            handleClick={handleClick} regionData={geoGraphData}
                                            compareRegionData={compareGeoGraphData} property={property}
                                            countryData={countryGraphData} graphRegion={graphRegion}
                                            compareCountryData={compareCountryGraphData} currencySymbol={currencySymbol}/>
                            :
                            chart === 'population' ?
                                <GeoOrdersPopulation monthCompare={monthCompare} selectedSeg={selectedSeg}
                                                     handleClick={handleClick} currencySymbol={currencySymbol}
                                                     regionData={populationGraphData}
                                                     compareRegionData={comparePopulationGraphData}
                                                     property={property}/>
                                :
                                <GeoOrdersCustomer monthCompare={monthCompare} selectedSeg={selectedSeg}
                                                   handleClick={handleClick} regionData={customerGraphData}
                                                   compareRegionData={compareCustomerGraphData} property={property}
                                                   countryData={customerCountryGraphData} graphRegion={graphRegion}
                                                   compareCountryData={compareCustomerCountryGraphData} currencySymbol={currencySymbol}/>
                        }
                    </Box>
                    {graphData.length>0?
                        chart==='property'?
                        <Grid container style={{padding:'18px',top:"-20px",position:"relative"}}>
                            <Grid item xs={12}>
                                {Icons.regionGraphLegend}<br/>
                                        <Typography color={'primary'} variant={'h5'} component={"span"} >{property==="revenue"?currencySymbol:null}<NumericLabel param={paramOptions5}>{property==="revenue"?Math.round(orderMinVal):orderMinVal}</NumericLabel></Typography>
                                        <Typography variant={"h5"} color={'primary'} style={{left:'9.2rem',position:'relative'}} component={"span"}>{property==="revenue"?currencySymbol:null}<NumericLabel param={paramOptions5}>{property==="revenue"?Math.round(orderMaxVal):orderMaxVal}</NumericLabel></Typography>
                        </Grid>
                        </Grid>:
                        chart==='population'?
                            <Grid container style={{padding:'18px',top:"-20px",position:"relative"}}>
                                <Grid item xs={12}>
                                    {Icons.regionGraphLegend}<br/>
                                            <Typography color={'primary'} variant={'h5'} component={"span"} >{property==="revenue"?currencySymbol:null}<NumericLabel param={paramOptions5}>{property==="revenue"?Math.round(orderPopMinVal):orderPopMinVal}</NumericLabel></Typography>
                                            <Typography variant={"h5"} color={'primary'} component={"span"} style={{left:'9.2rem',position:'relative'}}>{property==="revenue"?currencySymbol:null}<NumericLabel param={paramOptions5}>{property==="revenue"?Math.round(orderPopMaxVal):orderPopMaxVal}</NumericLabel></Typography>
                                </Grid>
                            </Grid>:
                            <Grid container style={{padding:'18px',top:"-20px",position:"relative"}}>
                                <Grid item xs={12}>
                                    {Icons.regionGraphLegend}<br/>
                                            <Typography color={'primary'} variant={'h5'} component={"span"} >{property==="revenue"?currencySymbol:null}<NumericLabel param={paramOptions5}>{property==="orders"?orderCustomerMinVal.toFixed(1):property==="revenue"?Math.round(orderCustomerMinVal):Math.ceil(orderCustomerMinVal)}</NumericLabel></Typography>
                                            <Typography variant={"h5"} color={'primary'} component={"span"} style={{left:'9.2rem',position:'relative'}} >{property==="revenue"?currencySymbol:null}<NumericLabel param={paramOptions5}>{property==="orders"?orderCustomerMaxVal.toFixed(1):property==="revenue"?Math.round(orderCustomerMaxVal):Math.ceil(orderCustomerMaxVal)}</NumericLabel></Typography>
                                </Grid>
                            </Grid>
                    :null}
                </Grid>
                <Grid item md={6} style={{borderLeft: border}}>
                    <Box p={1}>
                        <GeoLocationTable monthCompare={monthCompare} property={property} propertyData={geoGraphData}
                                          propertyCountryData={countryGraphData} populationData={populationGraphData}
                                          customerData={customerGraphData} currency={currency}
                                          customerCountryData={customerCountryGraphData}
                                          comparePropertyData={compareGeoGraphData}
                                          comparePropertyCountryData={compareCountryGraphData}
                                          comparePopulationData={comparePopulationGraphData} graphRegion={graphRegion}
                                          compareCustomerData={compareCustomerGraphData}
                                          compareCustomerCountryData={compareCustomerCountryGraphData} chart={chart}/>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )

}

const mapStateToProps = (state) => ({
    application_states: state.application_states,
    population_data_reducer: state.population_data_reducer
})
const mapDispatchToProps = (dispatch) => ({
    set_all_group_filter: (filterName, filterValue) => dispatch(set_all_group_filter(filterName, filterValue)),
    set_a_group_filter: (filterName, filterValue) => dispatch(set_a_group_filter(filterName, filterValue)),
    set_b_group_filter: (filterName, filterValue) => dispatch(set_b_group_filter(filterName, filterValue)),
    set_c_group_filter: (filterName, filterValue) => dispatch(set_c_group_filter(filterName, filterValue))
})
export default connect(mapStateToProps, mapDispatchToProps)(GeoLocationBlock)