import React, { useEffect, useMemo, useState } from "react";
import HighchartsReact from "highcharts-react-official";
import { Box, ToggleButton, Typography, Grid, Tooltip } from "@mui/material";
import { InfoOutlined } from "@mui/icons-material";
import { withStyles } from "@mui/styles";
import { getChannelColor } from "../getGroupDetails";
import { connect } from "react-redux";
import DateComponent from "../../GlobalComponents/DateComponent";

var Highcharts = require("highcharts");
require("highcharts/highcharts-more")(Highcharts);
require("highcharts/modules/exporting")(Highcharts);
require("highcharts/modules/sankey")(Highcharts);
require("highcharts/modules/accessibility")(Highcharts);

export const CustomToggleButton = withStyles((theme) => ({
  root: {
    minWidth: 90,
    textTransform: "none",
    fontSize: "0.875rem",
    border: "1px solid #afafaf",
    padding: "10px 20px",
    alignItems: "center",
    margin: "2% 0% 1%",
    backgroundColor: theme.palette.common.white,
    color: theme.palette.primary.main,
    "&$selected,&$selected:hover": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
  },
  selected: {},
}))(ToggleButton);

const ConversionPathCuSankey = (props) => {
  const { data, compareData, application_states, dataStartDate, dataEndDate } =
    props;

  const chartRef = React.createRef();
  const monthCompare = application_states.monthCompare;

  const [chartOptionsData, setChartOptionsData] = useState({});

  let compareNewData = [];

  function createData(data) {
    let localNewData = [];
    data.forEach((orderedPath) => {
      let temp = { path: ["Start"], conversion: orderedPath.conversion };
      orderedPath.path.forEach((path) => {
        let tempNode = path[0];
        if (path[1]) {
          tempNode = path[0] + " " + path[1];
        }
        temp.path.push(tempNode);
      });
      temp.path.push("Conversion");
      localNewData.push(temp);
    });
    return localNewData;
  }

  const newData = useMemo(() => {
    return createData(data.orderedPaths);
  }, [data]);

  // Create Edges for each path and add conversion with it
  let chartData = [];

  newData?.forEach((data) => {
    data.path.forEach((path, index) => {
      if (index > 0) {
        chartData.push([data.path[index - 1], path, data.conversion]);
      }
    });
  });

  const updatedData = [];
  let finalChartDataObj = {};
  let finalChartDataArray = [];

  chartData.forEach((i) => {
    let path_str = i[0] + "_" + i[1];
    if (finalChartDataObj[path_str]) {
      finalChartDataObj[path_str] += i[2];
    } else {
      finalChartDataObj[path_str] = i[2];
    }
  });

  Object.keys(finalChartDataObj).forEach((j) => {
    const path_arr = j.split("_");
    const arr = [path_arr[0], path_arr[1], finalChartDataObj[j]];
    finalChartDataArray.push(arr);
  });

  // ------------------------------

  // Create a new object to store the final result
  let newresult = {};

  // Create a new array to store the final result
  let newfinalArray = [];

  finalChartDataArray
    .sort(function (a, b) {
      return a[2] - b[2];
    })
    .forEach(function (arr) {
      if (arr[0] === arr[1]) {
        if (arr[0] === "") return; // skip if both are empty strings
      } else {
        updatedData.push(arr);
      }
    });

  // Loop through the finalChartDataArray array and sum nodes with the same 'from' and 'to' values
  updatedData.forEach(function (arr) {
    const from = arr[0];
    const to = arr[1];
    const value = arr[2];

    const key = from + "-" + to;

    if (newresult[key]) {
      newresult[key] += value;
    } else {
      newresult[key] = value;
    }
  });

  // Create a new array to store the final result
  let uniquePaths = {};
  let nodeConversion = {};

  updatedData.forEach(function (arr) {
    const from = arr[0];
    const to = arr[1];
    const value = arr[2];

    const key = from + "-" + to;

    if (uniquePaths[key]) {
      uniquePaths[key] += value;
    } else {
      uniquePaths[key] = value;
    }

    if (nodeConversion[from]) {
      nodeConversion[from] += value;
    } else {
      nodeConversion[from] = value;
    }
  });

  // Loop through the unique paths object and create a new array with unique 'from' and 'to' values and their corresponding sum
  Object.keys(uniquePaths).forEach(function (key) {
    const [from, to] = key.split("-");
    const value = uniquePaths[key];

    newfinalArray.push([from, to, value]);
  });

  //Loop through nodeConversion excluding start and sum all the values
  let totalSum = 0;
  for (let node in nodeConversion) {
    if (node !== "Start") {
      totalSum += nodeConversion[node];
    }
  }

  //Calculate 10% of sum

  let percentage = 0.1;

  // Loop through sorted nodeConversion from lower side, take a temp_value = 0

  // if temp_value += nodevalue, if temp_value <= 0.2*sum add that node in a otherList

  // breakthe loop when temp_value crosses 0.2*sum

  let temp_value = 0;
  let sortedKeys = Object.keys(nodeConversion).sort(
    (a, b) => nodeConversion[a] - nodeConversion[b]
  );
  let otherList1 = [];
  let notOtherList = [];

  sortedKeys.forEach((node) => {
    let nodeValue = nodeConversion[node];

    if (temp_value + nodeValue <= percentage * totalSum) {
      temp_value += nodeValue;
      otherList1.push(node);
    } else {
      notOtherList.push(node);
    }
  });

  newfinalArray.forEach((arr, i) => {
    let [from, to, value] = arr;

    if (otherList1.includes(from)) {
      from = "Others";
    }

    if (otherList1.includes(to)) {
      to = "Others";
    }

    newfinalArray[i] = [from, to, value];
  });

  let newPaths = {};

  newfinalArray.forEach(([from, to, value]) => {
    let key = `${from}-${to}`;

    if (newPaths.hasOwnProperty(key)) {
      newPaths[key] += value;
    } else {
      newPaths[key] = value;
    }
  });

  newfinalArray = [];

  Object.keys(newPaths).forEach((key) => {
    if (newPaths.hasOwnProperty(key)) {
      let [from, to] = key.split("-");
      let value = newPaths[key];
      newfinalArray.push([from, to, value]);
    }
  });

  // For Compare Data

  if (compareData?.orderedPaths) {
    compareNewData = createData(compareData?.orderedPaths);
  }

  // For Compare Data Create Edges for each path and add conversion with it

  let compareChartData = [];

  compareNewData.forEach((data) => {
    data.path.forEach((path, index) => {
      if (index > 0) {
        compareChartData.push([data.path[index - 1], path, data.conversion]);
      }
    });
  });

  // For Compare Data

  const compareupdatedData = [];

  let comparefinalChartDataObj = {};
  let comparefinalChartDataArray = [];

  compareChartData.forEach((item) => {
    const path_str = item[0] + "_" + item[1];
    if (comparefinalChartDataObj[path_str]) {
      comparefinalChartDataObj[path_str] += item[2];
    } else {
      comparefinalChartDataObj[path_str] = item[2];
    }
  });

  Object.keys(comparefinalChartDataObj).forEach((key) => {
    if (comparefinalChartDataObj.hasOwnProperty(key)) {
      const path_arr = key.split("_");
      const arr = [path_arr[0], path_arr[1], comparefinalChartDataObj[key]];
      comparefinalChartDataArray.push(arr);
    }
  });

  // For Compare Data

  // Create a new object to store the final result
  let comparenewresult = {};

  // Create a new array to store the final result
  comparefinalChartDataArray
    .sort(function (a, b) {
      return a[2] - b[2];
    })
    .forEach(function (arr) {
      if (arr[0] === arr[1]) {
        if (arr[0] === "") return; // skip if both are empty strings
      } else {
        compareupdatedData.push(arr);
      }
    });

  // Loop through the compareupdatedData array and sum nodes with the same 'from' and 'to' values
  const compareuniquePaths = {};
  const comparenodeConversion = {};

  compareupdatedData.forEach(function (arr) {
    const comparefrom = arr[0];
    const compareto = arr[1];
    const comparevalue = arr[2];

    const key = comparefrom + "-" + compareto;

    if (comparenewresult[key]) {
      comparenewresult[key] += comparevalue;
    } else {
      comparenewresult[key] = comparevalue;
    }

    if (compareuniquePaths[key]) {
      compareuniquePaths[key] += comparevalue;
    } else {
      compareuniquePaths[key] = comparevalue;
    }

    if (comparenodeConversion[comparefrom]) {
      comparenodeConversion[comparefrom] += comparevalue;
    } else {
      comparenodeConversion[comparefrom] = comparevalue;
    }
  });

  // For Compare Data
  let comparenewfinalArray = [];

  // Loop through the unique paths object and create a new array with unique 'from' and 'to' values and their corresponding sum
  Object.keys(compareuniquePaths).forEach(function (key) {
    const [comparefrom, compareto] = key.split("-");
    const comparevalue = compareuniquePaths[key];
    comparenewfinalArray.push([comparefrom, compareto, comparevalue]);
  });

  // Loop through newfinalArray if from or to node belongs to the otherList, change to Others

  // Create a new array to store the final result

  comparenewfinalArray.forEach((item, i) => {
    let [comparefrom, compareto, comparevalue] = item;

    if (otherList1.includes(comparefrom)) {
      comparefrom = "Others";
    }

    if (otherList1.includes(compareto)) {
      compareto = "Others";
    }

    comparenewfinalArray[i] = [comparefrom, compareto, comparevalue];
  });

  // Find all the duplicate paths and sum their conversion values to create unique paths

  let comparenewPaths = {};

  comparenewfinalArray.forEach(([comparefrom, compareto, comparevalue]) => {
    let comparekey = `${comparefrom}-${compareto}`;

    if (comparenewPaths.hasOwnProperty(comparekey)) {
      comparenewPaths[comparekey] += comparevalue;
    } else {
      comparenewPaths[comparekey] = comparevalue;
    }
  });

  comparenewfinalArray = [];

  Object.keys(comparenewPaths).forEach((comparekey) => {
    if (comparenewPaths.hasOwnProperty(comparekey)) {
      let [comparefrom, compareto] = comparekey.split("-");
      let comparevalue = comparenewPaths[comparekey];
      comparenewfinalArray.push([comparefrom, compareto, comparevalue]);
    }
  });

  let seen = [];
  let nodes = [];

  const columnNodeCondition = newfinalArray?.some(
    (each) => each?.[0] === "Direct" || each?.[0] === "Paid Search Google"
  );

  newfinalArray.forEach(function (edge) {
    if (!seen.includes(edge[0]) && edge[0] !== "Start") {
      let c = "";
      if (edge[0].split(" ").length > 1) {
        let arr = edge[0].split(" ");
        for (let i = 0; i < arr.length - 1; i++) {
          if (i < arr.length - 2) {
            c += arr[i] + " ";
          } else {
            c += arr[i];
          }
        }
      } else {
        c += edge[0];
      }
      let node = {
        column: columnNodeCondition
          ? edge[0] === "Direct" || edge[0] === "Paid Search Google"
            ? 1
            : 2
          : 1,
        id: edge[0],
        name: edge[0],
        color: getChannelColor(c),
      };
      nodes.push(node);
      seen.push(edge[0]);
    }
  });

  nodes.push.apply(nodes, [
    {
      column: 0,
      id: "Start",
      color: "#478fd9",
    },
    {
      id: "Conversion",
      color: "#4AA459",
    },
  ]);

  // ------------------------------

  useEffect(() => {
    const chartOptions1 = {
      title: {
        // text: "Cumulative Flow",
        text: "",
        align: "left",
      },
      subtitle: {
        // text: "by number of orders",
        align: "left",
      },
      series: [
        {
          keys: ["from", "to", "weight"],
          nodes: nodes,
          data: newfinalArray,
          nodePadding: 25,
          type: "sankey",
          nodeWidth: 30,
          nodeHeight: 25,
          nodeFormat:
            '<span style="font-size: 12px; color: #000;">{point.name}</span>',
        },
      ],
      chart: {
        linkCurvature: "0",
        height: 500,
      },
      tooltip: {
        useHTML: true,
        backgroundColor: "#1e334e",
        style: {
          color: "#fff",
          borderRadius: "10px",
          border: "none",
          boxShadow: "none",
          width: "350px",
          lineHeight: "15px",
        },
        headerFormat: "<table>",
        formatter: function () {
          const compareNode = monthCompare
            ? comparenewfinalArray?.find(
                (node) =>
                  node[0] === this.point.from && node[1] === this.point.to
              )
            : null;

          let compareSumNode = null;
          let compareSum = null;
          let compareothersnodeConversion = {};

          Object.keys(comparenodeConversion).forEach((key) => {
            if (!notOtherList.includes(key)) {
              compareSumNode = "Others";
              compareSum = compareSum || 0; // Initialize compareSum if not set
              compareSum += comparenodeConversion[key];
            } else {
              compareSumNode = key;
              compareSum = comparenodeConversion[key];
            }

            compareothersnodeConversion[compareSumNode] = compareSum;
          });

          compareothersnodeConversion["Conversion"] =
            compareothersnodeConversion["Start"];

          Object.keys(compareothersnodeConversion).forEach((key) => {
            if (key === this.point.name) {
              compareSumNode = key;
              compareSum = compareothersnodeConversion[key];
            }
          });

          if (monthCompare) {
            if (this.point.from) {
              const elseCount =
                compareNode && compareNode?.length > 0 ? compareNode?.[2] : 0;
              const weightDifference = Math.round(
                ((this.point.weight - elseCount) * 100) / elseCount
              );
              let weightIcon = "";
              if (elseCount === 0) {
                weightIcon = "&nbsp;";
              } else if (weightDifference > 0) {
                weightIcon = `<span style="color:#27AE60">&nbsp;${weightDifference}% <i class="fas fa-arrow-up"/></i></span>`;
              } else if (weightDifference < 0) {
                weightIcon = `<span style="color:#eb5757">&nbsp;${Math.abs(
                  weightDifference
                )}% <i class="fas fa-arrow-down"/></i></span>`;
              } else {
                weightIcon = `&nbsp;`;
              }
              return (
                `<tr><td>${this.point.from}</td> &rarr; <td>${this.point.to}</td><strong style="padding-left: 20px;">${weightIcon}</strong></tr></br>` +
                `<tr><td>Orders</td> : <td><strong>${this.point.weight}</strong></td></tr></br>` +
                `<tr><td><span style="opacity: 0.5;">Orders</span></td> : <td><strong style="opacity: 0.5;">${
                  compareNode?.[2] ? compareNode?.[2] : null
                }</strong></td></tr>`
              );
            } else {
              const sumDifference = Math.round(
                ((this.point.sum - compareSum) * 100) / compareSum
              );
              let sumIcon = "";
              if (compareSum === 0) {
                sumIcon = `&nbsp;`;
              } else if (sumDifference > 0) {
                sumIcon = `<span style="color:#27AE60">&nbsp;${sumDifference}% <i class="fas fa-arrow-up"/></i></span>`;
              } else if (sumDifference < 0) {
                sumIcon = `<span style="color:#eb5757">&nbsp;${Math.abs(
                  sumDifference
                )}% <i class="fas fa-arrow-down"/></i></span>`;
              } else {
                sumIcon = `&nbsp;`;
              }
              return (
                `<tr><td>${this.point.name}</td><strong style="padding-left: 20px;">${sumIcon}</strong></tr></br>` +
                `<tr><td>Orders</td> : <td><strong>${this.point.sum}</strong></td></tr></br>` +
                `<tr><td><span style="opacity: 0.5;">Orders</span></td> : <td><strong style="opacity: 0.5;">${
                  compareSum ? compareSum : null
                }</strong></td></tr>`
              );
            }
          } else {
            if (this.point.from) {
              return (
                `<tr><td>${this.point.from}</td> &rarr; <td>${this.point.to}</td></tr></br>` +
                `<tr><td>Orders</td> : <td><strong>${this.point.weight}</strong></td></tr></br>`
              );
            } else {
              return (
                `<tr><td>${this.point.name}</td></strong></td></tr></br>` +
                `<tr><td>Orders</td> : <td><strong>${this.point.sum}</strong></td></tr>`
              );
            }
          }
        },
        footerFormat: "</table>",
        followPointer: true,
      },
    };

    setChartOptionsData(chartOptions1);
  }, [data, compareData]);

  return (
    <div>
      <br />
      {newfinalArray.length > 0 ? (
        <React.Fragment>
          <Grid container>
            <Grid item xs={7}>
              <Box ml={2}>
                <Typography
                  style={{ fontSize: "1.125rem", fontWeight: 700 }}
                  color={"primary"}
                >
                  Conversion Flow
                </Typography>
                <Typography style={{ fontSize: "0.875rem" }} color={"primary"}>
                  by number of orders
                </Typography>
              </Box>
            </Grid>
            <Grid
              item
              xs={5}
              style={{
                paddingRight: "2rem",
                right: 0,
                zIndex: 1,
                float: "right",
              }}
            >
              <Grid container>
                <Grid item xs={12}>
                  <Box
                    style={{
                      float: "right",
                    }}
                  >
                    <Typography
                      color={"primary"}
                      style={{
                        // color: "#838383",
                        fontSize: "1rem",
                        padding: "5px 0px",
                        display: "flex",
                        alignItems: "center",
                        fontWeight: 700,
                      }}
                    >
                      Available Data&nbsp;
                      <Tooltip
                        title={"Showing Available Data for Date Range"}
                        arrow
                      >
                        <InfoOutlined style={{ fontSize: "1rem" }} />
                      </Tooltip>
                      &nbsp;:
                    </Typography>
                    <Typography
                      color={"primary"}
                      style={{
                        // color: "#838383",
                        fontSize: "0.875rem",
                      }}
                    >
                      <DateComponent dateText={dataStartDate} /> to{" "}
                      <DateComponent dateText={dataEndDate} />
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <HighchartsReact
            highcharts={Highcharts}
            options={chartOptionsData}
            ref={chartRef}
            containerProps={{ className: "sankeygraph" }}
          />
        </React.Fragment>
      ) : (
        <Box
          pt={5}
          style={{
            minHeight: "20vh",
            alignItems: "center",
            textAlign: "center",
            marginTop: "10%",
          }}
        >
          <Typography>No Data to Display</Typography>
        </Box>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  application_states: state.application_states,
});
export default connect(mapStateToProps, null)(ConversionPathCuSankey);
