import React, { useEffect } from "react";
import {
  Box,
  CircularProgress,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Table,
  TableCell,
  TableFooter,
  TableRow,
  Typography,
  useTheme,
  Tooltip,
} from "@mui/material";
import { makeStyles, withStyles } from "@mui/styles";
import { border, cardShadow } from "../../assets/jss/commonStyles";
import { channels, getChannelColor } from "../getGroupDetails";
import { PathSectionNode } from "../pathNode";
import Icons from "../icons";
import * as _ from "lodash";
import {
  FirstPage,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  LastPage,
  InfoOutlined,
} from "@mui/icons-material";
import { connect } from "react-redux";
import NumericLabel from "react-pretty-numbers";
import moment from "moment";
import {
  CustomPagination,
  CustomSelect,
} from "../customComponents/customTable";
import DateComponent from "../../GlobalComponents/DateComponent";

const orderParams = {
  commafy: true,
  wholenumber: "ceil",
  shortFormatMinValue: 10000,
  justification: "L",
};
const CustomPaper = withStyles((theme) => ({
  outlined: {
    border: border,
  },
  rounded: {
    borderRadius: 6,
  },
}))(Paper);

const CustomIconButton = withStyles((theme) => ({
  root: {
    fontSize: 16,
  },
}))(IconButton);

const useStyles = makeStyles((theme) => ({
  basketBackGround: {
    backgroundColor: theme.palette.backgroundColors.basket,
    color: theme.palette.common.white,
    paddingLeft: 8,
    paddingRight: 8,
    paddingBottom: 5,
    paddingTop: 5,
    minWidth: 100,
  },
  compareBasketBackGround: {
    backgroundColor: theme.palette.primary.main,
    opacity: 0.5,
    color: theme.palette.common.white,
    paddingLeft: 8,
    paddingRight: 8,
    paddingBottom: 5,
    paddingTop: 5,
    minWidth: 100,
  },
  table: {
    minWidth: "100%",
  },
  paginationRoot: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));

const CompressPath = (path) => {
  let compressedPath = [];
  let node;
  let count = 1;
  node = path.path[0];
  for (let i = 0; i < path.path.length - 1; i++) {
    if (
      path.path[i][0] === path.path[i + 1][0] &&
      path.path[i][1] === path.path[i + 1][1]
    ) {
      count++;
    } else {
      compressedPath.push([node[0], node[1], count]);
      node = path.path[i + 1];
      count = 1;
    }
  }
  compressedPath.push([node[0], node[1], count]);
  return compressedPath;
};

export function TablePaginationActions(props) {
  const classes = useStyles();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.paginationRoot}>
      <CustomIconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        <FirstPage />
      </CustomIconButton>
      <CustomIconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        <KeyboardArrowLeft />
      </CustomIconButton>
      <CustomIconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        <KeyboardArrowRight />
      </CustomIconButton>
      <CustomIconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        <LastPage />
      </CustomIconButton>
    </div>
  );
}

function ConversionPathSection(props) {
  const {
    data,
    compareData,
    application_states,
    durationFrom,
    durationTo,
    compareFrom,
    compareTo,
    dataStartDate,
    dataEndDate,
  } = props;
  const classes = useStyles();
  const monthCompare = application_states.monthCompare;
  const [page, setPage] = React.useState(1);
  const [loading, setLoading] = React.useState(true);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  let paths = _.map(data.orderedPaths, (d, i) => ({
    path: d.path,
    pathString: d.path.toString(),
    conversion: d.conversion,
    rank: i + 1,
  }));
  let comparePaths = _.map(compareData.orderedPaths, (d, i) => ({
    path: d.path,
    pathString: d.path.toString(),
    compareConversion: d.conversion,
    compareRank: i + 1,
  }));
  let pathCount = paths.length;
  let comparePathCount = comparePaths.length;
  const theme = useTheme();
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    if (rowsPerPage !== event.target.value) {
      setPage(1);
    }
  };
  let finalPathData;
  finalPathData = !monthCompare
    ? paths
    : _.map(paths, (d) => {
        return _.assign(d, _.find(comparePaths, { pathString: d.pathString }));
      });
  for (let i = 0; i < finalPathData.length; i++) {
    delete finalPathData[i]["pathString"];
  }
  useEffect(() => {
    handle_loading();
    //eslint-disable-next-line
  }, [props]);
  const noOfPages = Math.ceil(pathCount / rowsPerPage);
  const indexOfLastPost = page * rowsPerPage;
  const indexOfFirstPost = indexOfLastPost - rowsPerPage + 1;

  function handle_loading() {
    if (data.isLoading === false && compareData.isLoading === false) {
      if (data.orderedPaths) {
        if (
          moment(application_states[durationFrom]).diff(
            moment(data.start, "DD-MM-yyyy"),
            "days"
          ) === 0 &&
          moment(application_states[durationTo]).diff(
            moment(data.end, "DD-MM-yyyy"),
            "days"
          ) === 0
        ) {
          if (monthCompare) {
            if (compareData.orderedPaths) {
              if (
                moment(application_states[compareFrom]).diff(
                  moment(compareData.start, "DD-MM-yyyy"),
                  "days"
                ) === 0 &&
                moment(application_states[compareTo]).diff(
                  moment(compareData.end, "DD-MM-yyyy"),
                  "days"
                ) === 0
              ) {
                setLoading(false);
                return null;
              }
            }
          } else {
            setLoading(false);
            return null;
          }
        }
      }
    }
    setLoading(true);
    return null;
  }

  return !loading ? (
    <Box m={1} boxShadow={cardShadow}>
      <CustomPaper variant={"outlined"}>
        <Box px={3} py={2} borderBottom={border}>
          <Grid container alignItems={"center"}>
            <Grid item xs={6}>
              <Typography
                variant={"h4"}
                style={{
                  color: theme.palette.primary.main,
                  fontWeight: 700,
                }}
              >
                Conversion Paths
              </Typography>
              <Typography
                variant={"h5"}
                style={{
                  color: theme.palette.primary.main,
                }}
              >
                by number of orders
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Box
                style={{
                  float: "right",
                }}
              >
                <Typography
                  color={"primary"}
                  style={{
                    // color: "#838383",
                    fontSize: "1rem",
                    padding: "5px 0px",
                    display: "flex",
                    alignItems: "center",
                    fontWeight: 700,
                  }}
                >
                  Available Data&nbsp;
                  <Tooltip
                    title={"Showing Available Data for Date Range"}
                    arrow
                  >
                    <InfoOutlined style={{ fontSize: "1rem" }} />
                  </Tooltip>
                  &nbsp;:
                </Typography>
                <Typography
                  color={"primary"}
                  style={{
                    // color: "#838383",
                    fontSize: "0.875rem",
                  }}
                >
                  <DateComponent dateText={dataStartDate} /> to{" "}
                  <DateComponent dateText={dataEndDate} />
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={2} style={{ textAlign: "end" }}>
              {!monthCompare ? (
                <Typography color={"primary"}>
                  <span style={{ fontWeight: 700 }}>Total Paths :</span>&nbsp;
                  <NumericLabel params={orderParams}>{pathCount}</NumericLabel>
                </Typography>
              ) : (
                <Typography component={"span"} color={"primary"}>
                  <span style={{ fontWeight: 700 }}>Total Paths</span> :&nbsp;
                  <span style={{ opacity: 0.5 }}>
                    &nbsp;
                    <NumericLabel params={orderParams}>
                      {comparePathCount}
                    </NumericLabel>
                    &nbsp;|&nbsp;
                  </span>
                  <NumericLabel params={orderParams}>{pathCount}</NumericLabel>
                </Typography>
              )}
            </Grid>
          </Grid>
        </Box>
        <Box py={2} px={4}>
          <Grid container>
            <Grid item xs={12} style={{ display: "flex", flexWrap: "wrap" }}>
              {channels.map((channel, index) => PathsLegend(channel, index))}
            </Grid>
          </Grid>
        </Box>
        {paths.length > 0 ? (
          (rowsPerPage > 0
            ? paths.slice((page - 1) * rowsPerPage, page * rowsPerPage)
            : paths
          ).map((path, index) => {
            let finalPath = CompressPath(path);
            return (
              <div key={index}>
                <Box className={"flex"} ml={2} my={2} alignItems={"flexStart"}>
                  {monthCompare ? (
                    <Box
                      className={"flex"}
                      pr={2}
                      my={1}
                      style={{ minWidth: 70 }}
                    >
                      {path.rank < 10 ? (
                        <Typography color={"primary"}>
                          0{path.rank}&ensp;
                        </Typography>
                      ) : (
                        <Typography color={"primary"}>
                          {path.rank}&ensp;
                        </Typography>
                      )}
                      {path.compareRank ? (
                        path.rank < path.compareRank ? (
                          <Typography style={{ color: "#27AE60" }}>
                            {Math.abs(path.rank - path.compareRank)}&nbsp;
                            {Icons.greenLineArrow}
                          </Typography>
                        ) : path.rank > path.compareRank ? (
                          <Typography style={{ color: "#EB5757" }}>
                            {Math.abs(path.rank - path.compareRank)}&nbsp;
                            {Icons.redLineArrow}
                          </Typography>
                        ) : (
                          <Typography
                            color={"primary"}
                            style={{ opacity: 0.5 }}
                          >
                            &ensp;-
                          </Typography>
                        )
                      ) : (
                        <Typography color={"primary"} style={{ opacity: 0.5 }}>
                          &ensp;-
                        </Typography>
                      )}
                    </Box>
                  ) : (
                    <Box
                      pr={2}
                      my={1}
                      style={{ minWidth: monthCompare ? 70 : 0 }}
                    >
                      {path.rank < 10 ? (
                        <Typography>0{path.rank}</Typography>
                      ) : (
                        <Typography>{path.rank}</Typography>
                      )}
                    </Box>
                  )}
                  <Box style={{ display: "flex", flexWrap: "wrap" }}>
                    {finalPath.map((node, index) => (
                      <Box mb={1} mr={1} key={index}>
                        <PathSectionNode pathArray={node} />
                      </Box>
                    ))}
                  </Box>
                  {monthCompare && path.compareConversion ? (
                    <Box display={"block"} mr={1}>
                      <Grid
                        container
                        className={classes.compareBasketBackGround}
                        alignItems={"center"}
                      >
                        <Grid item>
                          <Typography
                            variant={"h5"}
                            style={{ fontWeight: 700 }}
                          >
                            {path.compareConversion}&ensp;(
                            {Math.round(
                              (path.compareConversion * 100) /
                                compareData.totalConversion
                            )}
                            %)&ensp;
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Box>{Icons.basketWhite}</Box>
                        </Grid>
                      </Grid>
                    </Box>
                  ) : null}
                  <Box display={"block"}>
                    <Grid
                      container
                      className={classes.basketBackGround}
                      alignItems={"center"}
                    >
                      <Grid item>
                        <Typography variant={"h5"} style={{ fontWeight: 700 }}>
                          {path.conversion}&ensp;(
                          {Math.round(
                            (path.conversion * 100) / data.totalConversion
                          )}
                          %)&ensp;
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Box>{Icons.basketWhite}</Box>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </div>
            );
          })
        ) : (
          <Box
            pt={5}
            style={{
              minHeight: 250,
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <Typography>No data to display</Typography>
          </Box>
        )}
        <Box px={2}>
          <Table className={classes.table}>
            <TableFooter>
              <TableRow>
                {/* <Grid container> */}
                <TableCell colSpan={8} style={{ borderBottom: 0, padding: 0 }}>
                  {/* <Grid item xs={8} style={{paddingTop:'5px'}}> */}
                  <Typography
                    variant={"h5"}
                    color={"primary"}
                    component={"span"}
                  >
                    Rows / page:&nbsp;
                  </Typography>
                  <CustomSelect
                    value={rowsPerPage}
                    onChange={handleChangeRowsPerPage}
                    component={"span"}
                    disableUnderline={true}
                    variant="standard"
                    style={{ fontSize: "0.875rem" }}
                  >
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={25}>25</MenuItem>
                    <MenuItem value={50}>50</MenuItem>
                  </CustomSelect>
                  <Typography
                    variant={"h5"}
                    color={"primary"}
                    component={"span"}
                  >
                    &nbsp;Showing: {indexOfFirstPost}-
                    {noOfPages === page ? pathCount : indexOfLastPost} Of{" "}
                    {pathCount}
                  </Typography>
                  {/* </Grid> */}
                </TableCell>
                {/* <Grid item xs={4} style={{justifyContent: "right"}}> */}
                <TableCell colSpan={4} style={{ borderBottom: 0 }}>
                  <CustomPagination
                    count={noOfPages}
                    page={page}
                    onChange={handleChangePage}
                    siblingCount={0}
                    boundaryCount={2}
                  />
                </TableCell>
                {/* </Grid> */}
                {/* </Grid> */}
              </TableRow>
            </TableFooter>
          </Table>
        </Box>
      </CustomPaper>
    </Box>
  ) : (
    <Box style={{ minHeight: 150 }}>
      <CircularProgress
        size={100}
        style={{
          position: "relative",
          left: "50%",
          top: "50%",
          color: theme.palette.secondary.light,
        }}
      />
    </Box>
  );
}

export function PathsLegend(channel, index) {
  return (
    <Box className={"flex"} key={index}>
      <Box
        width={30}
        height={15}
        style={{ backgroundColor: getChannelColor(channel) }}
      />
      <Typography variant={"h5"}>&ensp;{channel}&emsp;</Typography>
    </Box>
  );
}

const mapStateToProps = (state) => ({
  application_states: state.application_states,
});
export default connect(mapStateToProps, null)(ConversionPathSection);
