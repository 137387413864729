import React, {useEffect} from "react";
import {Box, Grid, Typography} from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import {connect} from "react-redux";
import {handle_login, login_popup, request_free_demo,} from "../actions/actions";
import HomePageStyles from "../assets/jss/homePageStyles";
import Header from "../components/headers/header";
import LoginForm from "../components/forms/logInForm";
import RequestDemo from "../components/forms/requestDemo";
import YoutubeEmbed from "../components/YoutubeEmbed";
import ReactGA from "react-ga4";
import {GAEventTracker} from "../index";
import Icons from "../components/icons";
import {OrangeFilledButton} from "../components/buttons";
import HomepageFooter from "../components/homepageFooter";
import HomepageFooterMobile from "../components/homePageFooterMobile";
import "../App.css";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const CustomTypography2 = withStyles((theme) => ({
  root: {
    color: theme.palette.common.white,
    fontSize: "1.5vw",
    [theme.breakpoints.down(960)]: {
      fontSize: "2vw",
    },
    fontWeight: 500,
    letterSpacing: 1,
    width: "100%",
    justifyContent: "center",
    fontFamily: '"proxima-nova", sans-serif',
  },
}))(Typography);

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
    slidesToSlide: 1 // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 768 },
    items: 2,
    slidesToSlide: 1 // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 767, min: 0 },
    items: 1,
    slidesToSlide: 1 // optional, default to 1.
  }
};

function HomePage(props) {
  const classes = HomePageStyles();
  const { loginAuthentication, application_states, login_popup, fromShopify } = props;
  const [popLoginOpen, setPopLoginOpen] = React.useState(
    application_states.loginPopup
  );
  const [popDemoOpen, setPopDemoOpen] = React.useState(false);
  // const [tabValue, setTabValue] = React.useState(0);
  // const [renderMsg, setRenderMsg] = React.useState(false);
  const handleLoginOpen = async () => {
    await setPopLoginOpen(true);
    await login_popup(true);
  };
  const handleDemoOpen = async () => {
    await setPopDemoOpen(true);
  };
  const handleLoginClose = async () => {
    await setPopLoginOpen(false);
    await login_popup(false);
  };
  const handleDemoClose = async () => {
    await setPopDemoOpen(false);
  };
  // const onHeaderTyped = () => {
  //   setTimeout(function () {
  //     setRenderMsg(true);
  //   }, 1000);
  // };
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname + window.location.hash,
    });
  }, []);

  useEffect(() => { }, [props]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const handle_login = async (e, data) => {
    // e.preventDefault();
    await props.handle_login(e, data);
    if (!loginAuthentication.logged_in) {
      await handleLoginOpen();
    } else {
      await handleLoginClose();
    }
  };
  // const handleTabChange = (event, newValue) => {
  //   setTabValue(newValue);
  // };
  const handle_demo = async (e, data, data2) => {
    e.preventDefault();
    props.request_free_demo(e, data2);
    await handleDemoOpen();
  };
  const screenWidth = window.innerWidth;

  useEffect(()=>{},[screenWidth])

  return (
    <>
      <Header fromShopify={fromShopify} />
      <div className={"flex"}>
        <Grid container>
          <Grid item xs={12}>
            <div className={classes.content}>
              <div className={classes.toolbar} />
              <section id={"videoSection"} className={classes.headerSection}>
                {Icons.heroBanner}
                <Grid container>
                <Grid
                  item
                  xs={12}
                  lg={12}
                  md={12}
                  id={'headerContent'}
                  style={{ padding: "0 0 6%" }} className={classes.headerContent}
                >
                  <Box px={3}>
                    <Typography
                      color={"primary"}
                      className={classes.sameRevenue}
                      id='sameRevenue'
                    >
                      MORE REVENUE<span className={classes.revenueDot}>.</span><br />
                      SAME SPEND<span className={classes.revenueDot}>.</span>
                    </Typography>
                    <Typography
                    id='mediaRevenue'
                      variant={"h3"}
                      className={classes.mediaRevenue}
                    >
                      You can earn significantly more in paid media revenue using our AI-powered digital marketing platform.
                      <br />For a limited time, enjoy a 2 month free trial for a risk-free investment. Find out if you qualify.
                      <br /><br />
                    </Typography>
                    <Typography
                      variant={"h4"}
                      className={classes.brands}
                      id='brands'
                    >
                      Over 600 brands have trusted NetElixir’s insights, including<span className={classes.contentDot}>:</span>
                    </Typography>
                  </Box>
                  <Carousel
                    swipeable={false}
                    draggable={false}
                    showDots={false}
                    responsive={responsive}
                    ssr={true} // means to render carousel on server-side.
                    slidesToSlide={1}
                    infinite={true}
                    arrows={false}
                    autoPlay={true}
                    autoPlaySpeed={300}
                    keyBoardControl={true}
                    customTransition="transform 300ms ease-in-out"
                    transitionDuration={3000}
                    containerClass="carousel-container"
                    removeArrowOnDeviceType={["tablet", "mobile"]}
                    dotListClass="custom-dot-list-style"
                    itemClass="carousel-item-padding-20-px"
                  >
                    <div>{Icons.amikaLogo}</div>
                    <div>{Icons.elfLogo}</div>
                    <div>{Icons.evanycLogo}</div>
                    <div>{Icons.lenovoLogo}</div>
                    <div>{Icons.rocklerLogo}</div>
                    <div>{Icons.stevenSingerLogo}</div>
                  </Carousel>

                </Grid>
                </Grid>
              </section>
              <div>
                <section style={{ marginTop: "0rem" }}>
                  <Box>
                    <Box
                      pl={5}
                      p={2.5}
                      style={{ padding: "5px 0 60px" }}
                      className={classes.containerSec}
                    >
                      <Grid
                        container
                        style={{ textAlign: "center" }}
                        className={classes.containerSec}
                      >
                        <Grid item xs={12}>
                          <Typography
                            color={"#072B51"}
                            className={classes.latestFeatures}
                            id='latestFeatures'
                          >
                            Get Started With LXRInsights Today<span className={classes.contentDot}>:</span>
                          </Typography>
                          <Grid container spacing={2}>
                            <Grid
                              item
                              xs={12}
                              md={12}
                              lg={12}
                              xl={12}
                            >
                              <OrangeFilledButton
                                id="mktBtn"
                                className={classes.proximaNovaFont}
                                style={{
                                  borderRadius: "35px",
                                  border: "2px solid #F58120",
                                  minWidth: "192px",
                                  margin: "10px 0",
                                }}
                                onClick={() => {
                                  handleDemoOpen();
                                  GAEventTracker(
                                    "Home Page",
                                    "Request Free Demo"
                                  );
                                }}
                                variant="contained"
                              >
                                <Box
                                  px={2}
                                  py={1.5}
                                  style={{ fontWeight: 700, padding: 0, fontSize: 20 }}
                                >
                                  Try For Free*
                                </Box>
                              </OrangeFilledButton>
                            </Grid>
                            <CustomTypography2 className={classes.highVle} style={{ color: "#000", fontSize: "14px", margin: "10px auto" }}>
                              *Terms and conditions may apply. Free trial is available only to select clients.
                            </CustomTypography2>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Box>
                    <Grid className="hghVlue" style={{ background: "#F2F6F9" }}>
                      <Grid
                        container
                        style={{ background: "#F2F6F9", padding: "50px 0px" }}
                        className={classes.containerSec}
                      >
                        <Grid item xs={12} lg={4}>
                          <Typography
                            className={classes.benefits}
                          >
                            Benefits of LXRInsights
                          </Typography>
                          <p className={classes.keyBenefits}>We maximize your ROAS by targeting, engaging, and winning your high-value customers using our AI-powered digital marketing platform. Beyond more paid search revenue, key benefits of LXRInsights include:</p>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          lg={8}
                        >
                          <Box px={3}>
                            <Typography
                              variant={"h3"}
                              className={classes.benefitsList}
                              style={{ lineHeight: 1.5, color: "#000", fontSize: "18px" }}
                            >
                              <ul>
                                <li><strong>Control over your own data:</strong> LXRI operates within your owned first-party data so you’re not relying on any other third-party platforms.</li>
                                <li><strong>Ongoing audience insights:</strong> As an AI algorithm, LXRI is constantly learning and identifying ongoing insights about your high-value customers to better inform your growth strategy.</li>
                                <li><strong>Pre-populate high-value audience signals:</strong> Google is now offering their own high-value customer signals to inform Performance Max campaigns; supercharge Google’s signals by providing your own.</li>
                                <li><strong>Build custom audiences:</strong> Replace Google’s similar audience with a curated audience that leverages in-market, affinity, and LXRI data points to build your own lookalike audience for continual growth.</li>
                                <li><strong>Pioneers in AI:</strong> By partnering with NetElixir, you’re getting an e-commerce growth partner with nearly two decades of experience, who has invested years into building AI models that learn from your owned data.</li>
                              </ul><br />
                            </Typography>
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid className="hghVlue" style={{ background: "#FFF" }}>
                      <Grid
                        container
                        style={{ background: "#FFF", padding: "60px 0px 20px" }}
                        className={classes.containerSec}
                      >
                        <Grid
                          item
                          xs={12}
                          lg={12}
                        >
                          <Typography
                            className={classes.marketPlatform}
                            style={{ textAlign: "center" }}
                          >
                            AI-Powered Marketing Platform
                          </Typography>
                          <p className={classes.maximize} style={{ textAlign: "center" }}>NetElixir has been developing our AI technology for the past five years to offer you a revenue-generating product unlike anything else on the market. We’re constantly improving our solution to ensure it’s right for your brand’s needs.</p>

                          <Box px={3}>
                            <Typography
                              variant={"h3"}
                              className={classes.marketingPlatform}
                            >
                              <ul>
                                <li style={{ paddingBottom: "10px" }}><strong>High-Value Customer Score:</strong> flexibility to determine what makes a high-value customer for your brand based on purchase recency, frequency, AOV, and product diversity</li>
                                <li style={{ paddingBottom: "10px" }}><strong>Audience Remarketing:</strong> one-click integration to add high-value audiences to your Google, Meta, and soon to be Microsoft campaigns to keep your retargeting audience updated with your newest and best customers</li>
                                <li><strong>Shopify Integration:</strong> a seamless Shopify integration means LXRI’s AI algorithm can share actionable insights more quickly by learning from your historical data</li>
                              </ul><br />
                            </Typography>
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Box
                      px={5}
                      pb={4}
                      className={classes.formWork}
                    >
                      <Grid container className={classes.benefits}>
                        <Grid item xs={12} style={{ textAlign: "center" }}>
                          <Typography
                            color={"primary"}
                            className={classes.impact}
                          >
                            The Impact of LXRInsights
                          </Typography>
                          <Typography
                            color={"primary"}
                            className={classes.platformWork}
                          >
                            How Does The Platform Work?
                          </Typography>
                          <p className={classes.strategy} style={{ textAlign: "center" }}>As long as you have a product to sell online you can count on LXRI to find your high-value customers and bring them straight to your site, revolutionizing your customer acquisition and engagement strategy.</p>
                          <Grid
                            container
                            spacing={1}
                            style={{ padding: "5% 0 5%" }}
                          >
                            <Grid item xs={12} lg={7}>
                              <Box px={2}>
                                <div className="App">
                                  <YoutubeEmbed embedId="rokGy0huYEA" />
                                </div>
                              </Box>
                            </Grid>
                            <Grid item xs={12} lg={5}>
                              <Box className="frstPty">
                                <p className={classes.marketingPlatform}><strong>Additional Features</strong></p>
                                <Typography
                                  variant={"h3"}
                                  id="frtPty"
                                  className={classes.marketingPlatform}
                                  style={{ lineHeight: 1.5 }}
                                >
                                  <ul style={{ textAlign: "left" }}>
                                    <li>Predictive analytics into customers’ lifetime value over next twelve months and which channels they are most likely to convert from</li>
                                    <li>Forecasts which of your existing customers are likely to be a repeat buyer in the next two months</li>
                                    <li>Uses machine learning to create lookalike audiences based on behavior and patterns of current high-value customers to win more brand loyalists and can replace Google’s deprecated similar audiences</li>
                                    <li>Identifies actionable insights to optimize your customer acquisition and engagement strategy to improve ROI</li>
                                    <li>Future-proof technology relies on your first-party data to create journey maps and audiences</li>
                                  </ul>
                                </Typography>
                              </Box>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Box>
                    <div
                      className="arrowDown"
                      style={{
                        borderLeft: "50px solid transparent",
                        borderRight: "50px solid transparent",
                        borderTop: "50px solid #fff",
                        position: "absolute",
                        left: "47%",
                      }}
                    ></div>
                    <Grid className="hghVlue" style={{ background: "#F2F6F9" }}>
                      <Box
                        pl={5}
                        p={2.5}
                        style={{ background: "#F2F6F9", padding: "5% 0 0" }}
                        className={classes.containerSec}
                      >
                      </Box>
                      <Grid
                        container
                        style={{ background: "#F2F6F9", padding: "5% 0 2%" }}
                        className={classes.containerSec}
                      >
                        <Grid item xs={12} lg={4}>
                          <Box px={2}>{Icons.facesBanner}</Box>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          lg={8}
                          style={{ padding: "0 0 6%" }}
                        >
                          <Box px={3}>
                            <Typography
                              color={"primary"}
                              className={classes.highValueCust}
                            >
                              Why High-Value Customers?
                            </Typography>
                            <Typography
                              variant={"h3"}
                              className={classes.sameSpend}
                            >
                              High-value customers are your brand loyalists, who spend 3-5X more on average than other shoppers. By feeding high-value audience signals to your paid media campaigns, you train those algorithms to target customers who bring the most value to your bottom line. Targeting and winning high-value customers yields a 2-5% increase in revenue at the same spend.
                              <br /><br />
                              <span
                                className={classes.facesLink}
                                style={{
                                  color: "#000",
                                  fontSize: "20px",
                                }}
                              >
                                <a
                                  target={"_blank"}
                                  rel="noopener noreferrer"
                                  style={{ color: "#000", fontWeight: "700", textDecoration: "none", lineHeight: "2em" }}
                                  href={
                                    "https://www.netelixir.com/faces/"
                                  }
                                >
                                  Meet Your Industry’s High-Value Customer {Icons.facesArrow}
                                </a>{" "}
                              </span><br />
                              <br />
                              <span
                                className={classes.facesLink}
                                style={{
                                  color: "#000",
                                  fontSize: "20px",
                                }}
                              >
                                <a
                                  target={"_blank"}
                                  rel="noopener noreferrer"
                                  style={{ color: "#000", fontWeight: "700", textDecoration: "none" }}
                                  href={
                                    "https://www.netelixir.com/who-is-your-high-value-customer/"
                                  }
                                >
                                  Defining High-Value Customers {Icons.facesArrow}
                                </a>{" "}
                              </span>
                            </Typography>
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Box>
                </section>
                {screenWidth > 600 ? (
                  <HomepageFooter  classes={classes} handleDemoOpen={handleDemoOpen}/>
                ) : (
                  <HomepageFooterMobile classes={classes} handleDemoOpen={handleDemoOpen}/>
                )}
                {/* <HomepageFooter/> */}
              </div>
            </div>
          </Grid>
        </Grid>
        <LoginForm
          handle_login={handle_login}
          popLoginOpen={popLoginOpen}
          onClose={handleLoginClose}
        />
        <RequestDemo
          handle_demo={handle_demo}
          popDemoOpen={popDemoOpen}
          onClose={handleDemoClose}
        />
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  loginAuthentication: state.loginAuthentication,
  application_states: state.application_states,
  requestFreeDemoReducer: state.requestFreeDemoReducer,
});
const mapDispatchToProps = (dispatch) => ({
  handle_login: (e, data) => dispatch(handle_login(e, data)),
  request_free_demo: (e, data) => dispatch(request_free_demo(e, data)),
  login_popup: (loginpopup) => dispatch(login_popup(loginpopup)),
});
export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
