import React, {useEffect} from "react"
import {Box, Grid, Typography, useTheme} from "@mui/material"
import * as _ from "lodash"
import {Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import {CustomXAxisTick, CustomYAxisTick} from "./graphs/groupTrendChart";
import {scaleSqrt} from "d3-scale"
import {border} from "../../assets/jss/commonStyles";
import Icons from "../icons";
import {connect} from "react-redux";
import NumericLabel from "react-pretty-numbers";
import {numbersFormat} from "./graphs/geoOrdersGraph";

const d3 = require('d3')

const orderParams = {
    commafy: true,
    wholenumber: 'ceil',
    shortFormatMinValue: 10000,
    justification: 'L',
}
const CustomGraphTooltip = ({active, payload, label}) => {
    const theme = useTheme();
    if (active && payload !== [] && payload !== null) {
        return (
            <React.Fragment>
            <div className={'custom-tooltip'}>
                <Box p={1} style={{backgroundColor: theme.palette.primary.main, minWidth: 70}} borderRadius="4px">
                    <Grid container>
                        <Grid item>
                            <Typography variant={'body2'} style={{color: theme.palette.common.white}}>
                                {payload[0].payload.label}
                            </Typography>
                            <Typography variant={'body2'} style={{color: theme.palette.common.white}}>
                                Quantity Sold : <NumericLabel params={orderParams}>{payload[0].value}</NumericLabel>
                            </Typography>
                            {payload[0].payload.compareUnits || payload[0].payload.compareUnits === 0 ?
                                <Typography variant={'body2'} style={{color: theme.palette.common.white, opacity: 0.5}}>
                                    Quantity Sold : <NumericLabel
                                    params={orderParams}>{payload[0].payload.compareUnits}</NumericLabel>
                                </Typography>
                                : null}
                        </Grid>
                        {payload[0].payload.compareUnits ?
                            <Grid item>
                                {payload[0].value > payload[0].payload.compareUnits ?
                                    <Typography variant={'h5'} style={{color: '#27AE60'}}>
                                        &nbsp;
                                        <span style={{fontWeight:700}}>{Math.abs(Math.round((payload[0].payload.compareUnits - payload[0].value) * 100 / payload[0].payload.compareUnits))}%</span>&nbsp;
                                        {Icons.greenLineArrow}
                                    </Typography>
                                    :
                                    payload[0].value < payload[0].payload.compareUnits ?
                                        <Typography variant={'h5'} style={{color: '#eb5757'}}>
                                            &nbsp;
                                            <span style={{fontWeight:700}}>{Math.abs(Math.round((payload[0].payload.compareUnits - payload[0].value) * 100 / payload[0].payload.compareUnits))}%</span>&nbsp;
                                            {Icons.redLineArrow}
                                        </Typography>
                                        :
                                        null
                                }
                            </Grid> : null}
                    </Grid>
                </Box>
            </div>
        </React.Fragment>
        );}
    return null
}

function PriceHistogram(props) {
    const {data, compareData, application_states} = props
    let monthCompare = application_states.monthCompare;
    let currencySymbol = application_states.currencySymbol;
    useEffect(() => {
    }, [application_states])
    const theme = useTheme()
    let finalGraphData1, compareFinalGraphData1;
    finalGraphData1 = _.map(data.orderedProducts, (d) => ({
        quantity: d.quantity,
        price: d.price
    }))
    finalGraphData1 = _.filter(finalGraphData1, function (o) {
        return o.price !== 0;
    });
    if (monthCompare) {
        compareFinalGraphData1 = _.map(compareData.orderedProducts, (d) => ({
            quantity: d.quantity,
            price: d.price
        }))
    }
    // const productLength = finalGraphData1.length
    const priceList = _.uniq(finalGraphData1.map(t => {
        return t.price
    }))
    const minPrice = _.floor(_.min(priceList))
    const maxPrice = _.ceil(_.max(priceList))
    const diffPrice = maxPrice - minPrice
    // const [numBins, setNumBins] = React.useState(diffPrice < 1 ? 1 : diffPrice < 50 ? diffPrice : 50)
    let numBins = diffPrice < 1 ? 1 : diffPrice < 50 ? diffPrice : 50;
    const scale = scaleSqrt();
    let quantityBucket, compareQuantityBucket, histGenerator, bins;
    let graphData = [];
    quantityBucket = new Array(numBins).fill(0)
    compareQuantityBucket = new Array(numBins).fill(0)
    histGenerator = d3.layout.histogram()  // create layout object
        .bins(numBins);  // number of thresholds; this will create 19+1 bins

    bins = histGenerator(data.orderedProducts.map(d => {
        return d.price
    }));
    for (let i = 0; i < finalGraphData1.length; i++) {
        for (let j = 0; j < numBins; j++) {
            if ((bins[j]["x"] <= finalGraphData1[i]["price"]) && ((bins[j]["x"] + bins[j]["dx"]) >= finalGraphData1[i]["price"])) {
                quantityBucket[j] = quantityBucket[j] + finalGraphData1[i]["quantity"]
            }
        }
    }
    if (monthCompare) {
        for (let i = 0; i < compareFinalGraphData1.length; i++) {
            for (let j = 0; j < numBins; j++) {
                if ((bins[j]["x"] <= compareFinalGraphData1[i]["price"]) && ((bins[j]["x"] + bins[j]["dx"]) >= compareFinalGraphData1[i]["price"])) {
                    compareQuantityBucket[j] = compareQuantityBucket[j] + compareFinalGraphData1[i]["quantity"]
                }
            }
        }
    }
    for (let i = 0; i < numBins; i++) {
        graphData[i] = monthCompare ? {label: '', units: 0, compareUnits: 0} : {label: '', units: 0}
        let min = Math.round(bins[i]['x'])
        let max = Math.round(bins[i]['x'] + bins[i]['dx'])
        let count = quantityBucket[i]

        let compareCount = compareQuantityBucket[i]
        graphData[i]['label'] = currencySymbol + numbersFormat(min, 2) + ' - ' + currencySymbol + numbersFormat(max, 2)
        graphData[i]['units'] = count
        if (monthCompare) {
            graphData[i]['compareUnits'] = compareCount
        }
    }
    
    return (
        <React.Fragment>
            <Box p={2} borderBottom={border}>
                <Typography variant={"h4"} style={{color: theme.palette.primary.main, fontWeight: 700}}>
                    Quantity Sold by Price Ranges
                </Typography>
            </Box>
            <Box p={2}>
                {finalGraphData1.length > 0 ?
                    <ResponsiveContainer width={'98%'} height={500}>
                        <BarChart data={graphData} margin={{bottom: 20, left: 20}}>
                            <CartesianGrid strokeDasharray="7 7"/>
                            <XAxis tick={<CustomXAxisTick/>} interval={"preserveStartEnd"}
                                   label={{value: 'Price Range', position: 'insideBottom', offset: -10, fill: "#1E334E"}}
                                   dataKey={'label'}
                                   type={'category'}/>
                            <YAxis tick={<CustomYAxisTick/>}
                                   label={{value: 'Quantity Sold', angle: -90, position: 'insideLeft', offset: -10 , fill: "#1E334E"}}
                                    type={"number"} scale={scale}/>
                            <Tooltip content={<CustomGraphTooltip/>}/>
                            <Bar isAnimationActive={false} dataKey={'units'} fill={'#FF8735'} barSize={10}/>
                            {monthCompare ?
                                <Bar isAnimationActive={false} dataKey={'compareUnits'}
                                     fill={theme.palette.primary.main}
                                     fillOpacity={0.25} barSize={10}/> : null}
                        </BarChart>
                    </ResponsiveContainer>
                    :
                    <Box pt={5} style={{minHeight: 250, alignItems: "center", textAlign: "center"}}>
                        <Typography>No data to display</Typography>
                    </Box>
                }
            </Box>
        </React.Fragment>
    )
}


const mapStateToProps = (state) => ({
    application_states: state.application_states
});
export default connect(mapStateToProps, null)(PriceHistogram)