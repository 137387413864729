import moment from "moment";
import React from "react";

const DateComponent = ({ dateText }) => {
  return (
    <span>{dateText ? moment(dateText).format("MMM D, YYYY") : null}</span>
  );
};

export default DateComponent;
