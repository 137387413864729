import React from "react"
import {Box, Typography} from "@mui/material";
import {Link} from "react-router-dom";
import EUAndUK from "../../assets/files/LXRInsights - Data Processing Addendum - EU and UK Customers.pdf"
import DPAforUS from "../../assets/files/LXRInsights - Data Processing Addendum - US Customers.pdf"
import {GAEventTracker} from "../..";


function TermsContent(props) {
    const {popup} = props;
    return (
        <React.Fragment >
            <Typography variant={popup ? 'h5' : 'body1'}>
            NetElixir, Inc. (“NetElixir”), a digital marketing and analytics company, with its headquarters
            in Princeton, New Jersey, United States of America, makes available for use, its proprietary
            customer intelligence platform, LXRInsights, and its associated website&nbsp;
            <Link to={"/"}>https://www.lxrinsights.com/</Link>&nbsp;(collectively, the “Services”).
            <br/><br/>
            This Terms and Conditions Agreement, along with the entirety the operative Data Processing Addendum, 
            (collectively, “Agreement”) is entered into by NetElixir and the individual or entity executing this Agreement (“You” 
            or “Your”) and governs how You are allowed to use the Services.
            <br/><br/>
            NetElixir reserves the right, in its sole discretion and without any obligation or prior notice,
            to change, delete or amend the Services and/or this Agreement, or any referenced policy, at any
            time.
            <br/><br/>
            </Typography>
            <Typography style={{fontWeight:700}}>1. Scope of Services</Typography>
            <br/>
            <Box pl={1}>
                <Typography variant={popup ? 'h5' : 'body1'}>
                    LXRInsights is a data analytics tool that permits You to view, in tabulated and graphics
                    forms, various metrics about Your customers’ online purchases through Your website(s).
                    The metrics are collected through a pixel which is installed onto Your website(s) via a
                    code snippet (the “Pixel Code”). Once installed, certain data about the purchases made
                    by Your clients through Your website(s) is collected and transmitted to NetElixir.
                    <br/><br/>
                    For each customer whose data is collected in this manner, LXRInsights generates a
                    Customer ID number which is then used to organize each customer along with its
                    respective purchase information, which includes but is not limited to: date of purchase,
                    amount of purchase, item(s) purchased, referrer URL, price(s), timezone, timestamps,
                    tax, shipping data and more. Additionally, certain Personally Identifiable Information
                    (“PII”) may be collected, such as: email address, IP address(es), physical and/or
                    mailing address(es), phone number(s), and other personal demographic and device
                    information. Certain PII is hashed and is not directly accessible by You through the
                    Services, but may be integrated with other third party marketing platforms such as, but
                    not limited to, Google Adwords or Meta.
                    <br/><br/>
                    Once the data is collected, LXRInsights processes the data using its unique algorithms
                    and displays the key metrics such as value categories and purchase patterns for use by
                    You to assist with various product and marketing decisions.
                    <br/><br/>
                    NetElixir reserves the right to modify, change, or discontinue the Services or any
                    feature without notice.
                    <br/><br/>
                </Typography>
            </Box>
            <Typography style={{fontWeight:700}}>2. Term and Termination</Typography>
            <br/>
            <Box pl={1}>
                    <Typography variant={popup ? 'h5' : 'body1'}>
                    When You first access the Services and/or create or log into an account to access
                    LXRInsights, whichever occurs first, the Agreement between You and NetElixir is formed,
                    and the term of the Agreement (“Term”) will begin. The Term will continue for as long as
                    You have an active account on, or access to, the Services.
                    <br/><br/>
                    Either party may terminate this Agreement at any time by providing notice. NetElixir may
                    provide notice of termination to You by using the contact information of Your account.
                    All terminations will be deemed effective on the date of the notice of termination, or
                    upon the payment of any outstanding invoice, fee, or bill, whichever occurs last.
                    <br/><br/>
                    Without prejudice to any of its other rights, NetElixir may terminate this Agreement
                    immediately if You fail to comply with any terms and conditions of this Agreement. In
                    such an event, NetElixir may deny and terminate Your access to the Services.
                    <br/><br/>
                    In no event shall any termination, irrespective of reason, relieve You of the obligation
                    to pay any fee or cost that was due for any period prior to the effective date of
                    termination, if applicable.
                    <br/><br/>
                    Upon termination, You acknowledge and agree that:
                    </Typography>
                    <ol>
                        <li>
                            <Typography variant={popup ? 'h5' : 'body1'}>
                                NetElixir may revoke Your access to the Services upon either a) receipt of a
                                notice of termination, or b) termination of this Agreement.
                            </Typography>
                        </li>
                        <li>
                            <Typography variant={popup ? 'h5' : 'body1'}>
                                NetElixir may permanently delete Your information and all of the data
                                associated with Your account.
                            </Typography>
                        </li>
                        <li>
                            <Typography variant={popup ? 'h5' : 'body1'}>
                                You will disable and remove all LXRInsights Pixel Code, references, or links
                                from Your website(s) for which the Services were used, within three (3) days
                                of termination.
                            </Typography>
                        </li>
                        <li>
                            <Typography variant={popup ? 'h5' : 'body1'}>
                                If applicable, either You will pay, or permit and authorize NetElixir to
                                charge a payment method on file with Your account, any outstanding invoices,
                                fees or bills for Your use of the Services within ten (10) days of
                                termination.
                            </Typography>
                        </li>
                    </ol>
            </Box>
            <Typography style={{fontWeight:700}}>3. Fees</Typography>
            <br/>
            <Box pl={1}>
                <Typography variant={popup ? 'h5' : 'body1'}>
                    NetElixir may offer access to an account to the Services either with or without fee, at
                    its sole discretion. If You elect to purchase a paid subscription, account, add-on or
                    other such product of the Services, You agree to pay the costs, fees, and taxes, either
                    one time or recurring as specified at the time of purchase.
                    <br/><br/>
                </Typography>
            </Box>
            <Typography style={{fontWeight:700}}>4. Payments and Refunds</Typography>
            <br/>
            <Box pl={1}>
                <Typography variant={popup ? 'h5' : 'body1'}>
                    If applicable, for as long as You have an LXRInsights account with a paid subscription
                    or product, or otherwise have an outstanding balance, You will provide a valid payment
                    method in the form of either a credit card or bank account ACH details. You will update
                    the information for any payment method that expires or is otherwise no longer valid. By
                    providing NetElixir with these payment method details, You represent and warrant that
                    You are authorized to use such payment method and that charges to that payment method
                    will not be rejected. Furthermore, You expressly permit NetElixir to charge or otherwise
                    debit such payment method for invoices, fees or bills charged to Your account.
                    <br/><br/>
                    If NetElixir is unable to process a credit card payment we will notify You using the
                    email address on file and attempt to charge that card two (2) additional times before
                    suspending access to the LXRInsights account.
                    <br/><br/>
                    If an ACH transaction is declined or reversed, NetElixir will attempt to contact You
                    before suspending access to the LXRInsights account.
                    <br/><br/>
                    Payments for access to the Services, subscription or other product, as well as any
                    billable time accrued, if applicable, are non-refundable.
                    <br/><br/>
                </Typography>
            </Box>
            <Typography style={{fontWeight:700}}>5. Change to Billing and Fees</Typography>
            <br/>
            <Box pl={1}>
                <Typography variant={popup ? 'h5' : 'body1'}>
                    NetElixir may change its pricing structure or billing processes at any time by posting a
                    new pricing structure through the Services website and/or sending a notification to You
                    via email.
                    <br/><br/>
                </Typography>
            </Box>
            <Typography style={{fontWeight:700}}>6. Representations and Warranties</Typography>
            <br/>
            <Box pl={1}>
                <Typography variant={popup ? 'h5' : 'body1'}>
                    By using the Services, You represent and warrant that:
                </Typography>
                <ol>
                <Typography variant={popup ? 'h5' : 'body1'}>
                        <li>
                            If an individual, You are at least eighteen (18) years old;
                        </li>
                        <li>
                            If a business, You are duly organized, validly existing and in good standing as
                            a corporation or other entity as represented herein under the laws of its
                            jurisdiction of incorporation, organization or chartering;
                        </li>
                        <li>
                            You are in compliance with all applicable laws, rules and regulations;
                        </li>
                        <li>You have the full right, power and authority to enter into this Agreement, to
                            grant the rights and licenses granted hereunder and to perform its obligations
                            hereunder;
                        </li>
                        <li>
                            You will provide accurate, complete and up-to-date billing and/or contact
                            information;
                        </li>
                        <li>
                            You are not or based in any country or region that is subject to a U.S.
                            government embargo, has been designated as a “terrorist-supporting” country, or
                            are listed as a sanctioned person, group, entity or organization by the U.S.
                            Department of the Treasury Office of Foreign Assets Control; and
                        </li>
                        <li>
                            You acknowledge and agree to the terms and conditions of this Agreement.
                        </li>
                </Typography>
                </ol>
            </Box>
            <Typography style={{fontWeight:700}}>7. Accounts and Passwords</Typography>
            <br/>
            <Box pl={1}>
                <Typography variant={popup ? 'h5' : 'body1'}>
                    You are responsible for keeping the credentials used to access the Services, and
                    specifically the LXRInsights account, confidential and secure. You are responsible for
                    any activities performed within that account, whether or not You have authorized said
                    activity. You agree to immediately notify NetElixir if You suspect unauthorized access
                    or use of Your organization’s access to the Services.
                    <br/><br/>
                    NetElixir is not responsible for any losses due to stolen, hacked, or otherwise
                    compromised credentials. NetElixir does not have access to passwords and, for security
                    reasons, will never request Your password via email, telephone, or any other form of
                    correspondence.
                    <br/><br/>
                </Typography>
            </Box>
            <Typography style={{fontWeight:700}}>8. Additional Organization Accounts</Typography>
            <br/>
            <Box pl={1}>
                <Typography variant={popup ? 'h5' : 'body1'}>
                    As the primary user and/or account holder of the Services, You agree that You are
                    responsible for the actions, including any fees associated with those actions, of
                    additional users created in association with the account and/or subscription.
                    <br/><br/>
                </Typography>
            </Box>
            <Typography style={{fontWeight:700}}>9. Information Provided by You to NetElixir</Typography>
            <br/>
            <Box pl={1}>
                <Typography variant={popup ? 'h5' : 'body1'}>
                    You represent and warrant that You have permission to share, provide or otherwise send
                    to NetElixir, any and all information used in the Services. Specifically, You
                    acknowledge and agree that the information provided by You to NetElixir, including the
                    information collected by the Pixel Code, is clearly defined and referenced in the
                    applicable agreement, terms and conditions or other similar policy(ies) with Your
                    customers, visitors and/or users, and that such transmission, collection and use of the
                    information is compliant with applicable laws and regulations. Any requests by Your
                    customers for PII or removal requests are solely Your responsibility to process and
                    adhere to.
                    <br/><br/>
                    You retain ownership of any content and information You upload to the Services.
                    NetElixir may use or disclose Your information (including any personal information
                    therein) only as described in this Agreement, Privacy Policy, Cookie Policy, or any
                    other contract You or Your organization has entered into with NetElixir.
                    <br/><br/>
                </Typography>
            </Box>
            <br/>
            <Typography style={{fontWeight:700}}>10. Your Obligations</Typography>
            <br/>
            <Box pl={1}>
                <Typography variant={popup ? 'h5' : 'body1'}>
                You are solely responsible to protect the data and data rights of Your customers and of other users to Your website, and to comply 
                with all applicable foreign, Federal, State or local privacy laws and/or regulations. 
                Your responsibilities in this regard include, but are not limited to, properly seeking and obtaining valid consent to:
                </Typography>
                <ol>
                <Typography variant={popup ? 'h5' : 'body1'}>
                        <li>
                        Deploy a third-party tracking service and all tools used to effectuate the Services, which shall include pixels, code snippets and similar technology.
                        </li>
                        <li>
                        Permit NetElixir to process and analyze all data acquired through use of its Services to provide its Services.
                        </li>
                        <li>
                        Permit NetElixir’s use of all such acquired data in relation to its Remarketing Features that provide different customer audiences 
                        such as High-Value Customers and allows you to push these audiences to different marketing channels like Google Ads and Meta for retargeting purposes.
                        The aim is to boost your marketing efforts by retaining valuable customers and acquiring new ones who share similarities with your High-Value Customers,
                        and any similar service modules which may be used to analyze, sort and/or process customer information.
                        </li>
                        <li>Permit NetElixir to use all such acquired data, in anonymized form, in relation to its publications, promotional materials, advertising items and newsletters. 
                            You release NetElixir from all liability and agrees to hold it harmless, for any charge, lawsuit, damage, penalty, fee, or fine, for the breach of any data 
                            or for the violation of any law that occurs in connection with the Services provided in this Agreement.
                        </li>
                </Typography>
                </ol>
            </Box>
            <Typography style={{fontWeight:700}}>11. NetElixir Information Rights</Typography>
            <br/>
            <Box pl={1}>
                <Typography variant={popup ? 'h5' : 'body1'}>
                    NetElixir retains the right to complete and unfettered access to the data and analytics
                    gathered and/or generated through use of the Services. NetElixir reserves the right to
                    use or transfer, aggregate or otherwise process anonymized versions of such information
                    without compensation, citation or limitation, in connection with its operations
                    including, but not limited to, the rights to: copy, transmit, distribute, publicly
                    display, edit, reproduce, reformat and translate such information.
                    <br/><br/>
                </Typography>
            </Box>
            <Typography style={{fontWeight:700}}>12. License and Intellectual Property</Typography>
            <br/>
            <Box pl={1}>
                <Typography variant={popup ? 'h5' : 'body1'}>
                    Subject to the terms and conditions of this Agreement, NetElixir grants You a limited,
                    revocable, non-exclusive, non-sublicensable license to access and use the Services.
                    <br/><br/>
                    NetElixir is and will remain the sole and exclusive owner of all right, title and
                    interest in and to the Services and all intellectual property therein. You will not, nor
                    will You allow any third party to copy, modify, reverse engineer, or otherwise duplicate
                    the Services or violate any of NetElixir’s rights herein.
                    <br/><br/>
                </Typography>
            </Box>
            <Typography style={{fontWeight:700}}>13. Copyrights and Trademarks</Typography>
            <br/>
            <Box pl={1}>
                <Typography variant={popup ? 'h5' : 'body1'}>
                    No copyrighted material may be downloaded, reused, copied, distributed, modified,
                    transferred, reposted, reproduced, displayed, transmitted, disseminated, published,
                    broadcast, sold, circulated or otherwise used without expressly stated permission,
                    either in such materials or within this notice, without the express written permission
                    of NetElixir.
                    <br/><br/>
                    Materials provided by NetElixir may be copied and distributed only for use in
                    conjunction with the Services. Materials copied must remain unchanged and contain the
                    following notice in a clearly visible location: “© NetElixir, Inc. All Rights Reserved.”
                    This permission to copy and distribute materials does not extend to materials owned by
                    other content providers that may appear within the Services.
                    <br/><br/>
                    Reproduction, distribution or copying any copyrighted materials within the Services for
                    commercial purposes is strictly prohibited without the express written permission of
                    NetElixir. To request permission to use these materials in a manner which is otherwise
                    prohibited by the terms of this section, please contact legal@netelixir.com.
                    <br/><br/>
                    Use of any service marks is prohibited without written permission from NetElixir. Third
                    party trademarks, service marks, logos and trade names appearing within the Services are
                    the property of their respective owners.
                    <br/><br/>
                    Upon termination of this Agreement, Your permission to use any copyrighted materials or
                    service marks in any way, even those otherwise allowed, automatically terminates and You
                    must immediately destroy or remove any copies You have made.
                    <br/><br/>
                </Typography>
            </Box>
            <Typography style={{fontWeight:700}}>14. Assignment</Typography>
            <br/>
            <Box pl={1}>
                <Typography variant={popup ? 'h5' : 'body1'}>
                    NetElixir may assign its rights under this Agreement to any party without Your consent.
                    This Agreement shall inure to the benefit of the NetElixir, its successors and assigns.
                    You may not assign, convey, subcontract or delegate Your rights, duties or obligations
                    hereunder without the express, written consent of NetElixir. Such consent shall not be
                    unreasonably withheld.
                    <br/><br/>
                </Typography>
            </Box>
            <Typography style={{fontWeight:700}}>15. Privacy and Cookie Policy</Typography>
            <br/>
            <Box pl={1}>
                <Typography variant={popup ? 'h5' : 'body1'}>
                    NetElixir maintains a Privacy Policy, which includes a Cookies Policy, accessible via
                    the Services website, <Link
                    to={"/privacy-policy"}>https://www.lxrinsights.com/#/privacy-policy</Link>, and is
                    hereby incorporated into this Agreement by reference.
                    <br/><br/>
                </Typography>
            </Box>
            <Typography style={{fontWeight:700}}>16. Conflict, Law and Venue</Typography>
            <br/>
            <Box pl={1}>
                <Typography variant={popup ? 'h5' : 'body1'}>
                    In the event of any conflict between the terms and conditions of this Agreement and the
                    specific terms contained within a separate contract or agreement between the parties,
                    the specific terms and conditions shall prevail.
                    <br/><br/>
                    Any dispute regarding this Agreement, it’s underlying policies, or the Services, shall
                    be governed by and construed in accordance with the laws of the State of New York,
                    without regard to choice of law provisions, and in the court of competent jurisdiction
                    in the state of New Jersey. The application of the United Nations Convention on
                    Contracts for the International Sale of Goods is expressly excluded.
                    <br/><br/>
                    Should any part of this Agreement be held invalid or unenforceable by a court, that
                    portion shall be construed consistent with the applicable law and the remaining portions
                    shall remain in full force and effect.
                    <br/><br/>
                </Typography>
            </Box>
            <Typography style={{fontWeight:700}}>17. Conduct and Compliance</Typography>
            <br/>
            <Box pl={1}>
                    <Typography variant={popup ? 'h5' : 'body1'}>
                    You acknowledge that the use of the Services is in compliance with all applicable laws
                    and regulations. When sharing information about third-party persons (someone other than
                    Yourself), including the act of adding additional users to Your organization’s
                    subscription or account, it is Your responsibility to ensure that You have received
                    valid consent of the individual(s) involved before sharing this information, including
                    consent to transmit this data across borders as necessary to reach NetElixir’s servers,
                    or those of the applicable providers and/or vendors, based in the United States.
                    <br/><br/>
                    You agree not to use the Services to:
                    </Typography>
                    <ol>
                    <Typography variant={popup ? 'h5' : 'body1'}>
                        <li>
                            Upload, submit, post or otherwise transmit content that is unlawful, defamatory,
                            abusive, threatening, libelous, tortuous, harassing, vulgar, pornographic,
                            obscene or otherwise indecent, or that could constitute or encourage conduct
                            that could be considered a criminal offense, give rise to civil liability or
                            otherwise violate any law;
                        </li>
                        <li>
                            Upload, submit, post or otherwise transmit content that infringes or otherwise
                            violates the rights of any third-party; including without limitation privacy
                            rights and proprietary rights;
                        </li>
                        <li>
                            Upload, submit, post or otherwise transmit content that contains viruses,
                            intentionally corrupted files or any other similar software or programs that may
                            cause harm the operation of another's computer;
                        </li>
                        <li>
                            Upload, submit, post or otherwise transmit content that is of consistently poor
                            quality or that is deemed inappropriate as determined by the sole discretion of
                            NetElixir;
                        </li>
                        <li>
                            Impersonate or otherwise misrepresent Your affiliation with any other person or
                            entity;
                        </li>
                        <li>
                            Harm minors;
                        </li>
                        <li>
                            Harass or stalk another individual;
                        </li>
                        <li>
                            Store or collect personal data about other persons or entities that have
                            provided content; or
                        </li>
                        <li>
                            Violate any applicable federal, state, local or international law or regulation.
                        </li>
                    </Typography>
                    </ol>
                    <Typography variant={popup ? 'h5' : 'body1'}>
                        You further agree that You will not use any spider, robot or other automated device or
                        program, methodology or algorithm having similar processes or functionality, or any
                        manual process to copy or monitor any pages or data from the Services, or any other
                        NetElixir website, unless such pages or sites are specifically designated for such
                        purpose by NetElixir.
                        <br/><br/>
                        You expressly agree to not use the Services and the information, metrics or other data
                        contained therein, for the purposes of competing with, or providing services to
                        competitors of, NetElixir or any of its Services.
                        <br/><br/>
                    </Typography>
            </Box>
            <Typography style={{fontWeight:700}}>18. Disclaimer of Liability</Typography>
            <br/>
            <Box pl={1}>
                <Typography variant={popup ? 'h5' : 'body1'}>
                    To the maximum extent permitted by law, You assume full responsibility for the use of
                    the information described or contained within the Services, as well as for any loss that
                    results from Your use of the Services, including any files or website code downloaded
                    from or provided for use related to the Services. NetElixir is not liable for any
                    indirect, incidental, punitive, special, exemplary or consequential damages under any
                    circumstances, even if such damages are based on negligence or if NetElixir has been
                    advised of the possibility of such damages.
                    <br/><br/>
                    NetElixir is not responsible for the behavior or impacts of any third parties,
                    recommended services, service providers, or other users of the Services.
                    <br/><br/>
                </Typography>
            </Box>
            <Typography style={{fontWeight:700}}>19. Disclaimer of Warranties</Typography>
            <br/>
            <Box pl={1}>
                <Typography variant={popup ? 'h5' : 'body1'}>
                    To the maximum extent permitted by law, the Services are provided as-is. Except as
                    expressly stated in this agreement, NetElixir does not provide any warranties or
                    conditions of any kind in relation to the Services, either express or implied. This
                    includes, but is not limited to, warranties of merchantability and fitness for a
                    particular purpose, which are, to the fullest extent permitted by law, excluded from the
                    Agreement.
                    <br/><br/>
                </Typography>
            </Box>
            <Typography style={{fontWeight:700}}>20. Indemnification</Typography>
            <br/>
            <Box pl={1}>
                    <Typography variant={popup ? 'h5' : 'body1'}>
                    You agree to protect, defend, indemnify and hold harmless, NetElixir, its directors,
                    officers, employees, agents and representatives from and against any and all loss, cost,
                    expense, claim, and action of any kind or nature whatsoever (including court costs and
                    reasonable attorneys’ fees), arising out of, or related to, any breach or alleged breach
                    of this Agreement, and specifically against:
                    </Typography>
                    <ol>
                    <Typography variant={popup ? 'h5' : 'body1'}>
                        <li>
                            Any and all claims of any intellectual property infringement related to the
                            information You provide through the Services, collect, share or allow gathering
                            or generation from;
                        </li>
                        <li>
                            Your use of the Services;
                        </li>
                        <li>
                            Your violation of any laws or regulations;
                        </li>
                        <li>
                            Third-party claims that You or someone using Your account credentials or
                            passwords did something that, if true, would violate any of these terms,
                        </li>
                        <li>
                            Any misrepresentations made by You; or
                        </li>
                        <li>
                            A breach of any representations You’ve made in this Agreement.
                        </li>
                    </Typography>
                    </ol>
            </Box>
            <Typography style={{fontWeight:700}}>21. Data Processing Addendum</Typography>
            <Box pl={1}>
                <ul>
                <Typography variant={popup ? 'h5' : 'body1'}>
                        <li>                       
                        <a
                        href={EUAndUK}
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={ GAEventTracker('DPA - EU and UK', 'Terms and Conditions')}
                        >
                        EU and UK Customers
                        </a>
                        </li>
                        <li>
                        <a
                        href={DPAforUS}
                        rel="noopener noreferrer"
                        target="_blank"
                        onClick={ GAEventTracker('DPA - US', 'Terms and Conditions')}
                        >
                        US Customers
                        </a>
                        </li>
                </Typography>
                </ul>
            </Box>
            <Typography style={{fontWeight:700}}>22. Questions and Comments</Typography>
            <Box pl={1}>
                 <Typography variant={popup ? 'h5' : 'body1'}>
                    Please direct questions or comments regarding this Terms and Conditions Agreement,
                    or other practices pertaining to NetElixir or the Services by:
                </Typography>
                <ul>
                <Typography variant={popup ? 'h5' : 'body1'}>
                        <li>
                            <span style={{fontWeight:700}}>Email: </span>legal@netelixir.com
                        </li>
                        <li>
                            <span style={{fontWeight:700}}>Mail: </span>NetElixir, Inc. 3 Independence Way, Suite #203, Princeton, NJ 08540
                            USA
                        </li>
                        <li>
                            <span style={{fontWeight:700}}>Phone: </span>(609) 356-5112
                        </li>
                </Typography>
                </ul>
            </Box>
        </React.Fragment>
    )
}

export default TermsContent