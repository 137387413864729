import { Box, Button, Dialog, Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import Icons from "./icons";
import { border } from "../assets/jss/commonStyles";
import { OrangeFilledButton } from "./buttons";
import { connect } from "react-redux";
// import { get_auth_url_fb } from "../actions/actions";
import * as _ from "lodash";
import { getCookie } from "./cookiePoilcy";
import { Link } from "react-router-dom";
import { marketingChannelsValue, settingConnectionsURL } from "./sidebar";

const FBReauthenticatePopup = (props) => {
  const { userProfile } = props;
  const [fbReautheticatePopup, setFbReautheticatePopup] = React.useState(false);
  let buID = userProfile.bu_id;
  const setCookie = () => {
    let date = new Date();
    date.setTime(date.getTime() + 24 * 60 * 60 * 1000);
    document.cookie = `lxrin_fbreauth = LXRI.${
      userProfile.bu_id
    }; expires = ${date.toGMTString()}`;
  };
  useEffect(() => {
    if (userProfile.fb_token_expiry_flag) {
      const checkCookie = () => {
        let lxrinfb_cookie = _.split(getCookie("lxrin_fbreauth"), ".");
        if (lxrinfb_cookie[1] === buID.toString()) {
          setFbReautheticatePopup(false);
        } else {
          setFbReautheticatePopup(true);
        }
      };
      checkCookie();
    }
  }, [userProfile]);
  return (
    <React.Fragment>
      {userProfile.fb_token_expiry_flag ? (
        <DialogBox
          popUp={fbReautheticatePopup}
          setPopUp={setFbReautheticatePopup}
          buID={userProfile.bu_id}
          setCookie={setCookie}
          title={"Meta Ads"}
          fbflag={userProfile.fb_token_expiry_flag}
        />
      ) : null}
    </React.Fragment>
  );
};

export const DialogBox = (props) => {
  const { popUp, setPopUp, setCookie, title, fbflag, setFbopen } = props;

  const handleReauth = (title) => {
    if (title === "Google Ads") {
      localStorage.setItem("reauth", "google");
    }
    if (title === "Meta Ads") {
      localStorage.setItem("reauth", "fb");
    }
  };
  const handlepopup = () => {
    if (title === "Google Ads" && fbflag) {
      localStorage.setItem("fbopen", "true");
      setFbopen("true");
    }
    if (title === "Meta Ads") localStorage.removeItem("fbopen");
  };
  return (
    <Dialog
      open={popUp}
      PaperProps={{ square: true }}
      style={{ minHeight: 200, minWidth: 500 }}
    >
      <Box pl={2} borderBottom={border}>
        <Grid container>
          <Grid item xs={10}>
            <Typography
              variant={"body1"}
              style={{
                fontWeight: 700,
                marginTop: 10,
              }}
            >
              {title} Re-Authenticate
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Button
              onClick={() => {
                setPopUp(false);
                setCookie();
                handlepopup(title);
              }}
              style={{
                minWidth: 0,
                float: "right",
              }}
            >
              {Icons.closeIcon}
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Box p={2}>
        <Box pb={1}>
          <Typography
            varaint={"h5"}
            color={"primary"}
            style={{ marginLeft: "10px" }}
          >
            Your {title} {title === "Meta Ads" ? "Business" : null} account
            authentication is expired.
            <br /> Please re-authenticate your account to continue using&nbsp;
            {title} Audiences.
          </Typography>
        </Box>
        <Box
          p={1}
          borderTop={border}
          style={{ flexGrow: 1, textAlign: "center" }}
        >
          <Link
            to={{
              pathname: settingConnectionsURL,
              search: marketingChannelsValue,
            }}
            style={{ textDecoration: "none" }}
          >
            <OrangeFilledButton
              onClick={() => {
                setPopUp(false);
                setCookie();
                handleReauth(title);
                handlepopup(title);
              }}
            >
              Re-Authenticate
            </OrangeFilledButton>
          </Link>
        </Box>
      </Box>
    </Dialog>
  );
};

const mapStateToProps = (state) => ({
  userProfile: state.userProfile,
});

export default connect(mapStateToProps, null)(FBReauthenticatePopup);
