import React, { useEffect, useMemo } from "react";
import { Box, Grid, Typography, useTheme } from "@mui/material";
import { border } from "../../../assets/jss/commonStyles";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Pie,
  PieChart,
  ResponsiveContainer,
  Text,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import * as _ from "lodash";
import { capitalCase } from "change-case";
import { connect } from "react-redux";
import NumericLabel from "react-pretty-numbers";
import Icons from "../../icons";
import {
  set_a_group_filter,
  set_all_group_filter,
  set_b_group_filter,
  set_c_group_filter,
} from "../../../actions/actions";
import { GAEventTracker } from "../../../index";
import CustomerGroup from "../../getGroupDetails";
import { numbersFormat } from "./geoOrdersGraph";
import { Link } from "react-router-dom";
import { OrangeFilledButton, WhiteFilledButton } from "../../buttons";
import deviceGraphDummyData from "../../../assets/files/dummyData/deviceGraphData.json"
import { DeviceDummyComponent, PieChartDummyComponent } from "../../dummyComponent";


const COLORS = ["#FF8040", "#FFAB72", "#FFC39A", "#FFDBC2"];

function CustomXAxisTick(props) {
  const { x, y, payload } = props;
  return (
    <g transform={`translate(${x},${y})`}>
      <Text
        x={0}
        y={0}
        textAnchor="middle"
        verticalAnchor="start"
        fill={"#1E334E"}
        style={{
          fontWeight: "normal",
          fontFamily: '"Lato", sans-serif',
          fontStyle: "normal",
          fontSize: 14,
        }}
      >
        {capitalCase(payload.value)}
      </Text>
    </g>
  );
}

function CustomYAxisTick(props) {
  const { x, y, payload } = props;
  return (
    <g transform={`translate(${x},${y})`}>
      <Text
        x={0}
        y={0}
        textAnchor="end"
        verticalAnchor="middle"
        fill={"#1E334E"}
        style={{
          fontWeight: "normal",
          fontFamily: '"Lato", sans-serif',
          fontStyle: "normal",
          fontSize: 14,
        }}
      >
        {payload.value > 10000
          ? numbersFormat(payload.value, 0)
          : payload.value}
      </Text>
    </g>
  );
}
const CustomDeviceTooltip = ({ active, payload, label, currency }) => {
  const theme = useTheme();
  const orderParams = {
    commafy: true,
    wholenumber: "ceil",
    // shortFormat: true,
    shortFormatMinValue: 10000,
    justification: "L",
  };
  const revenueParams = {
    wholenumber: "ceil",
    locales: "en-US",
    currency: true,
    currencyIndicator: currency,
    commafy: true,
    justification: "L",
  };
  if (active && payload !== [] && payload !== null) {
    if (payload.length === 1) {
      return (
        <div className={"custom-tooltip"}>
          <Box
            p={1}
            style={{
              backgroundColor: theme.palette.primary.main,
              minWidth: 70,
            }}
            borderRadius="4px"
          >
            <Typography
              variant={"body2"}
              style={{ color: theme.palette.common.white }}
            >
              {capitalCase(payload[0].payload.device)}
            </Typography>
            {payload[0].name === "orders" ? (
              <Typography
                variant={"body2"}
                style={{ color: theme.palette.common.white }}
              >
                Order(s) :{" "}
                <NumericLabel params={orderParams}>
                  {payload[0].value}
                </NumericLabel>
                &nbsp;(
                {payload[0].value
                  ? Math.round(
                      (payload[0].value * 100) / payload[0].payload.totalOrders
                    )
                  : 0}
                %)
              </Typography>
            ) : payload[0].name === "revenue" ? (
              <Typography
                variant={"body2"}
                style={{ color: theme.palette.common.white }}
              >
                Revenue :{" "}
                <NumericLabel params={revenueParams}>
                  {payload[0].value}
                </NumericLabel>
                &nbsp;(
                {payload[0].value
                  ? Math.round(
                      (payload[0].value * 100) / payload[0].payload.totalRevenue
                    )
                  : 0}
                %)
              </Typography>
            ) : (
              <Typography
                variant={"body2"}
                style={{ color: theme.palette.common.white }}
              >
                Customer(s) :{" "}
                <NumericLabel params={orderParams}>
                  {payload[0].value}
                </NumericLabel>
                &nbsp;(
                {payload[0].value
                  ? Math.round(
                      (payload[0].value * 100) /
                        payload[0].payload.totalCustomers
                    )
                  : 0}
                %)
              </Typography>
            )}
          </Box>
        </div>
      );
    } else if (payload.length === 2) {
      const diff = payload[0].value - payload[1].value;
      return (
        <div className={"custom-tooltip"}>
          <Box
            p={1}
            style={{
              backgroundColor: theme.palette.primary.main,
              minWidth: 70,
            }}
            borderRadius="4px"
          >
            <Grid container>
              <Grid item>
                <Typography
                  variant={"body2"}
                  style={{ color: theme.palette.common.white }}
                >
                  {capitalCase(payload[0].payload.device)}
                </Typography>
                {payload[0].name === "orders" ? (
                  <Typography
                    variant={"body2"}
                    style={{ color: theme.palette.common.white }}
                  >
                    Order(s) :{" "}
                    <NumericLabel params={orderParams}>
                      {payload[0].value}
                    </NumericLabel>
                    &nbsp;(
                    {payload[0].value
                      ? Math.round(
                          (payload[0].value * 100) /
                            payload[0].payload.totalOrders
                        )
                      : 0}
                    %)
                  </Typography>
                ) : payload[0].name === "revenue" ? (
                  <Typography
                    variant={"body2"}
                    style={{ color: theme.palette.common.white }}
                  >
                    Revenue :{" "}
                    <NumericLabel params={revenueParams}>
                      {payload[0].value}
                    </NumericLabel>
                    &nbsp;(
                    {payload[0].value
                      ? Math.round(
                          (payload[0].value * 100) /
                            payload[0].payload.totalRevenue
                        )
                      : 0}
                    %)
                  </Typography>
                ) : (
                  <Typography
                    variant={"body2"}
                    style={{ color: theme.palette.common.white }}
                  >
                    Customer(s) :{" "}
                    <NumericLabel params={orderParams}>
                      {payload[0].value}
                    </NumericLabel>
                    &nbsp;(
                    {payload[0].value
                      ? Math.round(
                          (payload[0].value * 100) /
                            payload[0].payload.totalCustomers
                        )
                      : 0}
                    %)
                  </Typography>
                )}
                {payload[0].name === "orders" ? (
                  <Typography
                    variant={"body2"}
                    style={{ color: theme.palette.common.white, opacity: 0.5 }}
                  >
                    Order(s) :{" "}
                    <NumericLabel params={orderParams}>
                      {payload[1].value}
                    </NumericLabel>
                    &nbsp;(
                    {payload[1].value
                      ? Math.round(
                          (payload[1].value * 100) /
                            payload[1].payload.compareTotalOrders
                        )
                      : 0}
                    %)
                  </Typography>
                ) : payload[0].name === "revenue" ? (
                  <Typography
                    variant={"body2"}
                    style={{ color: theme.palette.common.white, opacity: 0.5 }}
                  >
                    Revenue :{" "}
                    <NumericLabel params={revenueParams}>
                      {payload[1].value}
                    </NumericLabel>
                    &nbsp;(
                    {payload[1].value
                      ? Math.round(
                          (payload[1].value * 100) /
                            payload[1].payload.compareTotalRevenue
                        )
                      : 0}
                    %)
                  </Typography>
                ) : (
                  <Typography
                    variant={"body2"}
                    style={{ color: theme.palette.common.white, opacity: 0.5 }}
                  >
                    Customer(s) :{" "}
                    <NumericLabel params={orderParams}>
                      {payload[1].value}
                    </NumericLabel>
                    &nbsp;(
                    {payload[1].value
                      ? Math.round(
                          (payload[1].value * 100) /
                            payload[1].payload.compareTotalCustomers
                        )
                      : 0}
                    %)
                  </Typography>
                )}
              </Grid>
              <Grid item alignItems={"flex-start"}>
                {payload[1].value !== 0 ? (
                  diff > 0 ? (
                    <Typography variant={"h5"} style={{ color: "#27AE60" }}>
                      &nbsp;
                      <span style={{ fontWeight: 700 }}>
                        {Math.abs(Math.round((diff * 100) / payload[1].value))}%
                      </span>
                      &nbsp;
                      {Icons.greenLineArrow}
                    </Typography>
                  ) : diff < 0 ? (
                    <Typography variant={"h5"} style={{ color: "#eb5757" }}>
                      &nbsp;
                      <span style={{ fontWeight: 700 }}>
                        {Math.abs(Math.round((diff * 100) / payload[1].value))}%
                      </span>
                      &nbsp;
                      {Icons.redLineArrow}
                    </Typography>
                  ) : null
                ) : null}
              </Grid>
            </Grid>
          </Box>
        </div>
      );
    }
  }
  return null;
};

function DeviceGraph(props) {
  const {
    selectedSeg,
    orderData,
    property,
    compareData,
    application_states,
    set_all_group_filter,
    set_a_group_filter,
    set_b_group_filter,
    set_c_group_filter,
    hasLXRTracking,
    trackingReady,
    dataSourceLength
  } = props;
  const handleClick = (segment, filterName, filterValue) => {
    switch (segment) {
      case "all":
        set_all_group_filter(filterName, filterValue);
        break;
      case "a":
        set_a_group_filter(filterName, filterValue);
        break;
      case "b":
        set_b_group_filter(filterName, filterValue);
        break;
      case "c":
        set_c_group_filter(filterName, filterValue);
        break;
      default:
        return null;
    }
  };
  const monthCompare = application_states.monthCompare;
  const currency = application_states.currency;
  const orderParams = {
    commafy: true,
    wholenumber: "ceil",
    // shortFormat: true,
    shortFormatMinValue: 10000,
    justification: "L",
  };
  const revenueParams = {
    wholenumber: "ceil",
    locales: "en-US",
    currency: true,
    currencyIndicator: currency,
    commafy: true,
    justification: "L",
  };
  // let deviceGraphData = [];
  let deviceGraphData = useMemo(() => [], []);
  let compareDeviceGraphData = useMemo(() => [], []);
  // let compareDeviceGraphData = [];
  const theme = useTheme();
  const customerDeviceData = _.map(
    _.mapValues(_.groupBy(orderData.orders, "device"), (d) =>
      _.uniqBy(d, "customers")
    ),
    (orders, device) => ({
      device: device,
      customerIDs: _.uniqBy(
        _.flatten(_.map(orders, (d) => _.values(d.customers)))
      ),
      numberCustomers: _.uniqBy(
        _.flatten(_.map(orders, (d) => _.values(d.customers)))
      ).length
        ? _.uniqBy(_.flatten(_.map(orders, (d) => _.values(d.customers))))
            .length
        : _.sumBy(orders, "customers"),
    })
  );
  const compareCustomerDeviceData = _.map(
    _.mapValues(_.groupBy(compareData.orders, "device"), (d) =>
      _.uniqBy(d, "customers")
    ),
    (orders, device) => ({
      device: device,
      compareCustomerIDs: _.uniqBy(
        _.flatten(_.map(orders, (d) => _.values(d.customers)))
      ),
      compareNumberCustomers: _.uniqBy(
        _.flatten(_.map(orders, (d) => _.values(d.customers)))
      ).length
        ? _.uniqBy(_.flatten(_.map(orders, (d) => _.values(d.customers))))
            .length
        : _.sumBy(orders, "customers"),
    })
  );

  if (property === "orders") {
    deviceGraphData = _.map(
      _.mapValues(_.groupBy(orderData.orders, "device"), (d) =>
        _.sumBy(d, "orders")
      ),
      (orders, device) => ({
        device: device,
        orders: orders,
        totalOrders: _.sumBy(orderData.orders, "orders"),
      })
    );
    compareDeviceGraphData = _.map(
      _.mapValues(_.groupBy(compareData.orders, "device"), (d) =>
        _.sumBy(d, "orders")
      ),
      (orders, device) => ({
        device: device,
        compareOrders: orders,
        compareTotalOrders: _.sumBy(compareData.orders, "orders"),
      })
    );
  } else if (property === "revenue") {
    deviceGraphData = _.map(
      _.mapValues(_.groupBy(orderData.orders, "device"), (d) =>
        _.sumBy(d, "revenue")
      ),
      (revenue, device) => ({
        device: device,
        revenue: revenue,
        totalRevenue: _.sumBy(orderData.orders, "revenue"),
      })
    );
    compareDeviceGraphData = _.map(
      _.mapValues(_.groupBy(compareData.orders, "device"), (d) =>
        _.sumBy(d, "revenue")
      ),
      (revenue, device) => ({
        device: device,
        compareRevenue: revenue,
        compareTotalRevenue: _.sumBy(compareData.orders, "revenue"),
      })
    );
  } else {
    deviceGraphData = _.map(customerDeviceData, (d) => ({
      device: d.device,
      customerIDs: d.customerIDs,
      customers: d.numberCustomers,
      totalCustomers: _.sumBy(customerDeviceData, "numberCustomers"),
    }));
    compareDeviceGraphData = _.map(compareCustomerDeviceData, (d) => ({
      device: d.device,
      compareCustomerIDs: d.compareCustomerIDs,
      compareCustomers: d.compareNumberCustomers,
      compareTotalCustomers: _.sumBy(
        compareCustomerDeviceData,
        "compareNumberCustomers"
      ),
    }));
  }
  useEffect(() => {}, [deviceGraphData, compareDeviceGraphData]);
  // const sortedDeviceGraphData = deviceGraphData.length !== 0 ? _.sortBy(deviceGraphData, ['device']) : deviceGraphData
  // const sortedCompareDeviceGraphData = compareDeviceGraphData.length !== 0 ? _.sortBy(compareDeviceGraphData, ['device']) : compareDeviceGraphData
  const totalDevice =
    deviceGraphData.length !== 0 ? _.sumBy(deviceGraphData, property) : 0;
  // const compareTotalDevice = compareDeviceGraphData !== undefined ? compareDeviceGraphData.length !== 0 ? _.sumBy(compareDeviceGraphData, `compare${capitalCase(property)}`) : 0 : 0
  return (
    <Box
    >
      <Box p={3} py={2} borderBottom={border}>
        <Typography style={{ fontWeight: 700 }}>
          {capitalCase(property)} by Device
        </Typography>
      </Box>
      {hasLXRTracking && dataSourceLength ===1?
      <Grid container alignItems={"center"}>
        <Grid item md={6} style={{ borderRight: border }}>
          <BarChartDevice
            selectedSeg={selectedSeg}
            theme={theme}
            monthCompare={monthCompare}
            property={property}
            handleClick={handleClick}
            orderData={deviceGraphData}
            currency={currency}
            compareData={compareDeviceGraphData}
          />
        </Grid>
        <Grid item md={3}>
          <PieChartDevice property={property} orderData={deviceGraphData} />
        </Grid>
        {deviceGraphData.length !== 0 ? (
          <Grid item md={3}>
            {deviceGraphData.map((device, index) => (
              <Box
                className={"flex"}
                pb={3}
                style={{ alignItems: "center" }}
                key={index}
              >
                <div>
                  <Box
                    width={22}
                    height={20}
                    style={{ backgroundColor: COLORS[index] }}
                  />
                </div>
                &emsp;
                <div>
                  <Typography
                    variant={"h4"}
                    style={{
                      color: theme.palette.common.black,
                      lineHeight: "19px",
                    }}
                  >
                    {capitalCase(device.device)}
                  </Typography>
                  <Box className={"flex"}>
                    <Typography
                      style={{
                        color: theme.palette.common.black,
                        opacity: 0.5,
                        lineHeight: "19px",
                      }}
                    >
                      {capitalCase(property)} :&nbsp;
                    </Typography>
                    <Typography
                      style={{
                        color: theme.palette.common.black,
                        lineHeight: "19px",
                      }}
                    >
                      {property === "revenue" ? (
                        <NumericLabel params={revenueParams}>
                          {device[property]}
                        </NumericLabel>
                      ) : (
                        <NumericLabel params={orderParams}>
                          {device[property]}
                        </NumericLabel>
                      )}
                      ({Math.round((device[property] * 100) / totalDevice)}%)
                    </Typography>
                  </Box>
                </div>
              </Box>
            ))}
          </Grid>
        ) : null}
      </Grid>:
        hasLXRTracking && dataSourceLength >1?
        trackingReady?
        <Grid container alignItems={"center"}>
        <Grid item md={6} style={{ borderRight: border }}>
          <BarChartDevice
            selectedSeg={selectedSeg}
            theme={theme}
            monthCompare={monthCompare}
            property={property}
            handleClick={handleClick}
            orderData={deviceGraphData}
            currency={currency}
            compareData={compareDeviceGraphData}
          />
        </Grid>
        <Grid item md={3}>
          <PieChartDevice property={property} orderData={deviceGraphData} />
        </Grid>
        {deviceGraphData.length !== 0 ? (
          <Grid item md={3}>
            {deviceGraphData.map((device, index) => (
              <Box
                className={"flex"}
                pb={3}
                style={{ alignItems: "center" }}
                key={index}
              >
                <div>
                  <Box
                    width={22}
                    height={20}
                    style={{ backgroundColor: COLORS[index] }}
                  />
                </div>
                &emsp;
                <div>
                  <Typography
                    variant={"h4"}
                    style={{
                      color: theme.palette.common.black,
                      lineHeight: "19px",
                    }}
                  >
                    {capitalCase(device.device)}
                  </Typography>
                  <Box className={"flex"}>
                    <Typography
                      style={{
                        color: theme.palette.common.black,
                        opacity: 0.5,
                        lineHeight: "19px",
                      }}
                    >
                      {capitalCase(property)} :&nbsp;
                    </Typography>
                    <Typography
                      style={{
                        color: theme.palette.common.black,
                        lineHeight: "19px",
                      }}
                    >
                      {property === "revenue" ? (
                        <NumericLabel params={revenueParams}>
                          {device[property]}
                        </NumericLabel>
                      ) : (
                        <NumericLabel params={orderParams}>
                          {device[property]}
                        </NumericLabel>
                      )}
                      ({Math.round((device[property] * 100) / totalDevice)}%)
                    </Typography>
                  </Box>
                </div>
              </Box>
            ))}
          </Grid>
        ) : null}
        </Grid>:
        <Grid container alignItems={"center"}>
        <Grid item md={6} style={{ borderRight: border }}> 
          <DeviceDummyComponent param={'dataStatus'}/>
        </Grid>
        <Grid item md={6} > 
          <PieChartDummyComponent propoerty={property} currency={currency} param={'dataStatus'}/>
        </Grid>
        </Grid>:
        <Grid container alignItems={"center"}>
          <Grid item md={6} style={{ borderRight: border }}> 
            <DeviceDummyComponent param={'add_tracking'}/>
          </Grid>
          <Grid item md={6} > 
            <PieChartDummyComponent propoerty={property} currency={currency}param={'add_tracking'} />
          </Grid>
        </Grid>
      }
    </Box>
  );
}

function PieChartDevice(props) {
  const { orderData, property ,hasLXRTracking} = props;
  const theme = useTheme();
  return (
    <div>
      <ResponsiveContainer width={"98%"} height={250}>
        <PieChart>
          <Pie
            isAnimationActive={false}
            data={orderData}
            outerRadius={90}
            dataKey={property}
            fill={"8884d8"}
          >
            {orderData.map((entry, index) => (
              <Cell
                stroke={"none"}
                key={`cell-${index}`}
                fill={hasLXRTracking?theme.palette.primary.main:COLORS[index % COLORS.length]}
              />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
}

function BarChartDevice(props) {
  const {
    selectedSeg,
    handleClick,
    orderData,
    property,
    compareData,
    monthCompare,
    theme,
    currency,
  } = props;
  let finalDeviceBarData = !monthCompare
    ? orderData
    : _.merge(_.keyBy(orderData, "device"), _.keyBy(compareData, "device"));
  finalDeviceBarData = _.values(finalDeviceBarData);
  finalDeviceBarData.forEach((t) => {
    // t[property] ? t[property] = t[property] : t[property] = 0
    t[property] = t[property] ? t[property] : 0;
  });
  if (monthCompare) {
    finalDeviceBarData.forEach((t) => {
      t[`compare${capitalCase(property)}`]
        ? (t[`compare${capitalCase(property)}`] =
            t[`compare${capitalCase(property)}`])
        : (t[`compare${capitalCase(property)}`] = 0);
    });
  }
  return orderData.length > 0 ? (
    <ResponsiveContainer width={"98%"} height={250}>
      <BarChart
        data={finalDeviceBarData}
        margin={{ top: 30, right: 20 }}
        onClick={(payload) => {
          const value = _.find(orderData, (o) => {
            return o.device === payload.activeLabel;
          });
          if (value) {
            handleClick(selectedSeg, "device", payload.activeLabel);
            GAEventTracker(CustomerGroup(selectedSeg)().name, "Device graph");
          }
        }}
      >
        <CartesianGrid strokeDasharray="7 7" vertical={false} />
        <XAxis
          tick={<CustomXAxisTick />}
          dataKey={"device"}
          minTickGap={0}
          tickLine={false}
          stroke={"#E3E6F4"}
        />
        <YAxis tick={<CustomYAxisTick />} stroke={"#E3E6F4"} />
        <Tooltip content={<CustomDeviceTooltip currency={currency} />} />
        <Bar
          isAnimationActive={false}
          dataKey={property}
          fill={"#FF8735"}
          barSize={50}
        />
        {monthCompare ? (
          <Bar
            isAnimationActive={false}
            dataKey={(e) => {
              return e[`compare${capitalCase(property)}`];
            }}
            fill={theme.palette.primary.main}
            fillOpacity={0.25}
            barSize={50}
          />
        ) : null}
      </BarChart>
    </ResponsiveContainer>
  ) : (
    <Box
      pt={5}
      style={{ minHeight: 250, alignItems: "center", textAlign: "center" }}
    >
      <Typography>No data to display</Typography>
    </Box>
  );
}

const mapStateToProps = (state) => ({
  application_states: state.application_states,
});

const mapDispatchToProps = (dispatch) => ({
  set_all_group_filter: (filterName, filterValue) =>
    dispatch(set_all_group_filter(filterName, filterValue)),
  set_a_group_filter: (filterName, filterValue) =>
    dispatch(set_a_group_filter(filterName, filterValue)),
  set_b_group_filter: (filterName, filterValue) =>
    dispatch(set_b_group_filter(filterName, filterValue)),
  set_c_group_filter: (filterName, filterValue) =>
    dispatch(set_c_group_filter(filterName, filterValue)),
});
export default connect(mapStateToProps, mapDispatchToProps)(DeviceGraph);
