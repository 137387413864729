import React from 'react';
import {CircularProgress} from '@mui/material';
import ErrorBoundary from '../components/errorBoundary';


/**
 * Wraps the React Component with React.Suspense and FallbackComponent while loading.
 * @param {React.Component} WrappedComponent - lazy loading component to wrap.
 * @param {React.Component} FallbackComponent - component to show while the WrappedComponent is loading.
 */
export const withSuspense = (WrappedComponent, FallbackComponent = null) => {
	return class extends React.Component {
		render() {
			if (!FallbackComponent) FallbackComponent = (
        <CircularProgress
          size={100}
          style={{
            position: "fixed",
            left: "50%",
            top: "50%",
            color: "#f58120",
          }}
        />
      ); 
			return (
        <ErrorBoundary>
				<React.Suspense fallback={FallbackComponent}>
					<WrappedComponent {...this.props} />
				</React.Suspense>
        </ErrorBoundary>
			);
		}
	};
};

