import React from "react";

const DisplaySVGImage = ({ src, alt, onClick, ...props }) => {
  return (
    <img
      src={src ? src : ""}
      alt={alt ? alt : "not-found"}
      style={{ ...props }}
      onClick = {onClick}
    />
  );
};

export default DisplaySVGImage;
