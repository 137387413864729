import React from "react";
import {Box, Typography} from "@mui/material";
import {border} from "../assets/jss/commonStyles";

function DataNode(props) {
    const {title, value, borderReq, percent} = props;
    return (
        <Box borderRight={borderReq ? border : 0} style={{minWidth:'105px'}} pl={1.5}>
            <Typography variant={"h5"} gutterBottom={true}>
                {title}
            </Typography>
            {percent ?
                <Typography variant={'h3'} style={{fontWeight:700}}>
                        {value} ({percent})
                </Typography> :
                <Typography variant={'h5'} style={{fontWeight:700}}>
                    {/* <b style={wordBreak ? {wordBreak: "break-all"} : {}}> */}
                        {value}
                    {/* </b> */}
                </Typography>
            }
        </Box>
    )
};

export function RevenueDataNode(props) {
    const {title, value, borderReq, percent} = props;
    return (
        <Box borderRight={borderReq ? border : 0} px={1}>
            <Typography gutterBottom={true}>
                {title}
            </Typography>
            {percent ?
                <Typography variant={'h3'} style={{fontWeight:700}}>
                        {value} ({percent})
                </Typography> :
                <Typography variant={'h3'} style={{fontWeight:700}}>
                        {value}
                </Typography>
            }
        </Box>
    )
};

export function InsightsDataNode(props) {
    const {title, value, borderReq, percent} = props;
    return (
        <Box borderRight={borderReq ? border : 0} px={1}>
            <Typography variant={"h5"} gutterBottom={true}>
                {title}
            </Typography>
            {percent ?
                <Typography variant={'h3'} style={{fontWeight:700}}>
                        {value} ({percent})
                </Typography> :
                <Typography variant={'h3'} style={{fontWeight:700}}>
                        {value}
                </Typography>
            }
        </Box>
    )
};

export function PaddedDataNode(props) {
    const {title, value, borderReq, percent} = props;
    return (
        <Box borderRight={borderReq ? border : 0} p={2} style={{minWidth: '8rem'}}>
            <Typography variant={"h5"} color={"primary"} gutterBottom={true}>
                {title}
            </Typography>
            {percent ?
                <Typography variant={'h3'} color={"primary"} style={{fontWeight:700}}>
                        {value} ({percent})
                </Typography> :
                <Typography variant={'h3'} color={"primary"} style={{fontWeight:700}}>
                        {value}
                </Typography>
            }
        </Box>
    )
};

export default DataNode;