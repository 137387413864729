import React, {useEffect} from "react"
import {connect} from "react-redux";
import {get_user_data, terms_of_use} from "../../actions/actions";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
  useTheme
} from "@mui/material";
import Header from "../headers/header";
import {OrangeFilledButton} from "../buttons";
import TermsContent from "./termsContent"

function TermsOfUse(props) {
    const theme = useTheme()
    const [open, setOpen] = React.useState(true)
    const {terms_of_use, get_user_data, userProfile} = props;
    const [first_name, setFirstName] = React.useState(userProfile.first_name ? userProfile.first_name : '')
    const [last_name, setLastName] = React.useState(userProfile.last_name ? userProfile.last_name : '')
    const [email, setEmail] = React.useState(userProfile.email ? userProfile.email : '')
    const [first_name_error, setFirstNameError] = React.useState('')
    const [last_name_error, setLastNameError] = React.useState('')
    const [email_error, setEmailError] = React.useState('')
    const handleAccept = async () => {
        await terms_of_use(true, first_name, last_name, email)
        await setOpen(false);
        await get_user_data()
    };
    const handle_change = async (e) => {
        const name = e.target.name;
        const value = e.target.value;
        if (name === 'first_name') {
            await setFirstName(value)
            await checkFirstName(value)
        } else if (name === 'last_name') {
            await setLastName(value)
            await checkLastName(value)
        } else {
            await setEmail(value)
            await checkEmail(value)
        }
    }
    const checkEmail = async (email) => {
    //eslint-disable-next-line
        var genEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/;
        if (email.match(genEmail)) {
            await setEmailError('')
        } else {
            await setEmailError('Please Enter Valid Email')
        }
        return null
    }
    const checkFirstName = async (firstname) => {
        if (firstname.length <= 1) {
            await setFirstNameError('Please Enter Valid First Name')
        } else {
            await setFirstNameError('')
        }
        return null
    }
    const checkLastName = async (lastname) => {
        if (lastname.length <= 1) {
            setLastNameError('Please Enter Valid Last Name')
        } else {
            await setLastNameError('')
        }
        return null
    }
    useEffect(() => {
        const setFunction = async () => {
            if (Object.keys(userProfile).includes('first_name')) {
                await setFirstName(userProfile.first_name)
                await setLastName(userProfile.last_name)
                await setEmail(userProfile.email)
            }
        }
        setFunction()
    }, [userProfile])
    let enabled = ((!Boolean(first_name_error)) && (!Boolean(last_name_error)) && (!Boolean(email_error)) && (first_name.length > 0) && (last_name.length > 0) && (email.length > 0))
    return (
        <div>
            <Header/>
            <Dialog open={open} scroll={"paper"}>
                <DialogTitle>
                    <Typography variant={'h3'} style={{fontWeight:700}}>
                        Terms and Conditions Agreement
                    </Typography>
                    <Typography variant={'h5'}>
                        Please read and accept the Terms and Conditions to use LXRInsights.
                    </Typography>
                </DialogTitle>
                <DialogContent dividers={true} style={{textAlign: "justify"}}>
                    <TermsContent popup={true}/>
                </DialogContent>
                <DialogActions>
                    <form>
                        <Grid container style={{paddingTop: 8}}>
                            <Grid item xs={6} style={{paddingLeft: 4, paddingRight: 4}}>
                                <TextField
                                    variant="filled"
                                    label="First Name"
                                    type="text"
                                    required
                                    name="first_name"
                                    // inputProps={{style: {padding: 16}}}
                                    InputProps={{style: {fontSize: "1.25rem", color: theme.palette.primary.main}}}
                                    InputLabelProps={{style: {fontSize: "1rem", color: theme.palette.primary.main}}}
                                    value={first_name}
                                    onChange={async (e) => {
                                        await handle_change(e)
                                    }}
                                    error={Boolean(first_name_error)}
                                    helperText={first_name_error ? first_name_error : ' '}
                                    FormHelperTextProps={{style: {fontSize: "0.75rem"}}}
                                />
                            </Grid>
                            <Grid item xs={6} style={{paddingLeft: 4, paddingRight: 4}}>
                                <TextField
                                    variant="filled"
                                    label="Last Name"
                                    required
                                    type="text"
                                    name="last_name"
                                    // inputProps={{style: {padding: 16}}}
                                    InputProps={{style: {fontSize: "1.25rem", color: theme.palette.primary.main}}}
                                    InputLabelProps={{style: {fontSize: "1rem", color: theme.palette.primary.main}}}
                                    value={last_name}
                                    onChange={handle_change}
                                    error={Boolean(last_name_error)}
                                    helperText={last_name_error ? last_name_error : " "}
                                    FormHelperTextProps={{style: {fontSize: "0.75rem"}}}
                                />
                            </Grid>
                            <Grid item xs={6} style={{paddingLeft: 4, paddingRight: 4}}>
                                <TextField
                                    variant="filled"
                                    required
                                    label="Email"
                                    type="text"
                                    name="email"
                                    // inputProps={{style: {padding: 16}}}
                                    InputProps={{style: {fontSize: "1.25rem", color: theme.palette.primary.main}}}
                                    InputLabelProps={{style: {fontSize: "1rem", color: theme.palette.primary.main}}}
                                    value={email}
                                    onChange={handle_change}
                                    error={Boolean(email_error)}
                                    helperText={email_error ? email_error : ' '}
                                    FormHelperTextProps={{style: {fontSize: "0.75rem"}}}
                                />
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item xs={12} style={{textAlign: "end"}}>
                                <Box pr={2} pb={1}>
                                    <OrangeFilledButton disabled={!enabled} onClick={handleAccept}
                                                        variant={'contained'} style={{fontWeight:700}}>
                                        Accept
                                    </OrangeFilledButton>
                                </Box>
                            </Grid>
                        </Grid>
                    </form>
                </DialogActions>
            </Dialog>
        </div>
    )
}

const mapStateToProps = (state) => ({
    userProfile: state.userProfile
})
const mapDispatchToProps = (dispatch) => ({
    terms_of_use: (terms_accepted, first_name, last_name, email) => dispatch(terms_of_use(terms_accepted, first_name, last_name, email)),
    get_user_data: () => dispatch(get_user_data())
})
export default connect(mapStateToProps, mapDispatchToProps)(TermsOfUse);
