import React from 'react';
import {CartesianGrid, Line, LineChart, ResponsiveContainer, Text, Tooltip, XAxis, YAxis} from "recharts";
import {Box, Typography, useTheme} from "@mui/material";
import CustomTooltips from "../../components/customTooltips"
import {ShortMonths} from "../getGroupDetails";
import * as _ from "lodash"

const data = [
    {"year": 2019, "month": 9, "orders": 0},
    {"year": 2019, "month": 10, "orders": 0},
    {"year": 2019, "month": 11, "orders": 0},
    {"year": 2019, "month": 12, "orders": 0},
    {"year": 2020, "month": 1, "orders": 0},
    {"year": 2020, "month": 2, "orders": 0},
    {"year": 2020, "month": 3, "orders": 0},
    {"year": 2020, "month": 4, "orders": 0},
    {"year": 2020, "month": 5, "orders": 0},
    {"year": 2020, "month": 6, "orders": 0},
    {"year": 2020, "month": 7, "orders": 0},
    {"year": 2020, "month": 8, "orders": 0},
    {"year": 2020, "month": 9, "orders": 0}
];

export function CustomTick(props) {
    const {x, y, payload} = props;
    return (
        <g transform={`translate(${x},${y})`} >
            <Text x={0} y={0} textAnchor="end" verticalAnchor="start" fill={"#1E334E"}
            style={{
                fontWeight: 'normal',
                fontFamily: '"Lato", sans-serif',
                fontStyle: 'normal',
                fontSize: 14}}>{payload.value}</Text>
        </g>
    )
};

export function CustomYAxisTick(props) {
    const {x, y,payload} = props;
    return (
        <g transform={`translate(${x},${y})`} >
            <Text x={0} y={0} textAnchor="end" verticalAnchor="middle" fill={"#1E334E"}
                style={{
                fontWeight: 'normal',
                fontFamily: '"Lato", sans-serif',
                fontStyle: 'normal',
                fontSize: 14
            }}>{payload.value}</Text>
        </g>
    )
};

function TrendChart(props) {
    const {segmentDetail} = props;
    const theme = useTheme();
    const graphData = segmentDetail.monthly_trend ? _.map(segmentDetail.monthly_trend) : data
    return (
        <div>
            <Box pl={3} pb={2}>
                <Typography variant={"h5"} style={{opacity: 0.5}}>Orders Over Time</Typography>
            </Box>
            <ResponsiveContainer width={'95%'} height={300}>
                <LineChart data={graphData}>
                    <XAxis height={15} interval={0} tick={<CustomTick/>} xAxisId={0} dataKey={(e) => {
                        return ShortMonths[e.month - 1]
                    }}/>
                    <XAxis interval={4} tickLine={false} axisLine={false} xAxisId={1} dataKey={'year'} tick={<CustomTick/>} />
                    <YAxis tick={<CustomYAxisTick/>} />
                    <CartesianGrid/>
                    <Tooltip  allowEscapeViewBox={{ x: false, y: false }} cursor={{strokeWidth: 1, stroke: theme.palette.primary.main}} content={<CustomTooltips/>}/>
                    <Line isAnimationActive={false} strokeWidth={2} dataKey="orders" stroke={theme.palette.secondary.light} activeDot={{r: 8}}/>
                </LineChart>
            </ResponsiveContainer>
        </div>
    )
}

export default TrendChart;