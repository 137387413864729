import React, {useEffect} from "react";
import {Avatar, Box, Container, CssBaseline, Dialog, TextField, Typography, useTheme,} from "@mui/material";
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import {connect} from "react-redux";
import LoginFormStyles from "../../assets/jss/loginFormStyles"
import {OrangeFilledButton} from "../buttons";
import {Link} from "react-router-dom";
import clsx from "clsx";
import {clearLoginStatus, login_popup} from "../../actions/actions";

function LoginForm(props) {
    const classes = LoginFormStyles();
    const theme = useTheme();
    const [username, setUsername] = React.useState('');
    const [password, setPassword] = React.useState('');
    const handle_change = (e) => {
        const name = e.target.name.trim();
        const value = e.target.value;
        name === 'username' ? setUsername(value) : setPassword(value)
    };
    useEffect(() => {
        const loadScriptByURL = (id, url, callback) => {
            const isScriptExist = document.getElementById(id);

            if (!isScriptExist) {
                var script = document.createElement("script");
                script.type = "text/javascript";
                script.src = url;
                script.id = id;
                script.onload = function () {
                    if (callback) callback();
                };
                document.body.appendChild(script);
            }

            if (isScriptExist && callback) callback();
        };

        // load the script by passing the URL
        loadScriptByURL("recaptcha-key", `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_RECAPTCHA_SITE_KEY}`, function () {
            return null;
        });
    }, []);
    useEffect(() => {
    }, [props]);
    const handleLoginClose = () => {
        props.onClose();
        clearLoginStatus()
    };
    const isEnabled = username.length > 0 && password.length > 0;
    const {loginAuthentication, login_popup, clearLoginStatus} = props;
    const handleSubmit = (e, data) => {
        e.preventDefault();
        data['email']= data['email'].trim();
        if(process.env.REACT_APP_RUN_RECAPTCHA) {
            window.grecaptcha.ready(() => {
                window.grecaptcha.execute(process.env.REACT_APP_RECAPTCHA_SITE_KEY, {action: 'submit'}).then(token => {
                    data['token'] = token;
                    props.handle_login(e, data);
                });
            });
        }else{
            props.handle_login(e, data);
        }
    }
    return (
        <Dialog onClose={handleLoginClose} aria-labelledby="simple-dialog-title" open={props.popLoginOpen}
                PaperProps={{square: true}}>
            <Container maxWidth="xs" component="main">
                <CssBaseline/>
                <Box textAlign={"center"}>
                    <Avatar className={classes.avatar}>
                        <LockOutlinedIcon/>
                    </Avatar>
                    <Typography className={classes.headerClass}>
                        LOG IN
                    </Typography>
                </Box>
                <form className={classes.form} onSubmit={e => handleSubmit(e, {email: username, password})}>
                    <TextField
                        variant="filled"
                        margin="normal"
                        fullWidth
                        label="Email"
                        type="text"
                        name="username"
                        InputProps={{style: {fontSize: "1.25rem", color: theme.palette.primary.main}}}
                        InputLabelProps={{style: {fontSize: "1rem", color: theme.palette.primary.main}}}
                        value={username}
                        onChange={handle_change}
                        autoFocus
                    />
                    <TextField
                        variant="filled"
                        margin="normal"
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        InputProps={{style: {fontSize: "1.25rem", color: theme.palette.primary.main}}}
                        InputLabelProps={{style: {fontSize: "1rem", color: theme.palette.primary.main}}}
                        value={password}
                        onChange={handle_change}
                    />
                    <OrangeFilledButton
                        type="submit"
                        fullWidth
                        disabled={!isEnabled || loginAuthentication.isLoading}
                        variant="contained"
                        style={{width: '100%', fontWeight: 700, margin: theme.spacing(3, 0, 0),}}
                    >
                        Log In
                    </OrangeFilledButton>
                    <Box width={'100%'} style={{textAlign: "center"}} py={1}>
                        <Link to={'/forgot_password'} className={clsx("text-decoration-none")}>
                            <Typography style={{textDecoration: "underline"}} onClick={() => {
                                handleLoginClose();
                                login_popup(false)
                            }}>Forgotten Password?</Typography>
                        </Link>
                    </Box>
                </form>
                {loginAuthentication.error ?
                    loginAuthentication.status === 406 ?
                        <Box mt={1} textAlign={"center"}>
                            <Typography className={classes.errorFont}>{loginAuthentication.data.detail}</Typography>
                        </Box>
                        :
                    loginAuthentication.status === 400 ?
                    loginAuthentication.data.message === 'Email Not Found.' ?
                        <Box mt={1} textAlign={"center"}>
                            <Typography className={classes.errorFont}>{loginAuthentication.data.message}</Typography>
                        </Box>
                        :
                        <Box mt={1} textAlign={"center"}>
                            <Typography className={classes.errorFont}>{loginAuthentication.errorMessage}. {loginAuthentication.data.attempts_left} attempts left.</Typography>
                        </Box>
                        :
                    <Box mt={1} textAlign={"center"}>
                        <Typography variant={"body1"} color="error">&nbsp;</Typography>
                    </Box>
                        :
                    <Box mt={1} textAlign={"center"}>
                        <Typography variant={"body1"} color="error">&nbsp;</Typography>
                    </Box>
                }
            </Container>
        </Dialog>
    );
}

const mapStateToProps = (state) => ({
    loginAuthentication: state.loginAuthentication
});
const mapDispatchToProps = (dispatch) => ({
    login_popup: (loginpopup) => dispatch(login_popup(loginpopup)),
    clearLoginStatus: () => dispatch(clearLoginStatus())
});
export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);

