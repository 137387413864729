export const revenuePerCustomer = "Revenue per Customer";

export const customerTrendConfiguration = {
  clusteringCustomersObj: {
    a: "highValueCustomers",
    b: "midValueCustomers",
    c: "lowValueCustomers",
    all: "allCustomers",
  },
  segmentNamesObj: {
    a: "High Value Customers",
    b: "Mid Value Customers",
    c: "Low Value Customers",
    all: "All Customers",
  },
  comparisionObj: {
    compare_month: "month",
    compare_customers: "customers",
    compare_aov: "aov",
    compare_customers_percentage: "customers_percentage",
    compare_orders: "orders",
    compare_revenue: "revenue",
    compare_avg_monthly_visits: "avg_monthly_visits",
    compare_revenue_per_customer: "revenue_per_customer",
    compare_Avg_hvc_Score: "Avg_hvc_Score",
  },

  changePropertyObj: {
    change_aov: "aov",
    change_orders: "orders",
    change_revenue: "revenue",
    change_avg_monthly_visits: "avg_monthly_visits",
    change_revenue_per_customer: "revenue_per_customer",
    change_customers_percentage: "customers_percentage",
    change_customers: "customers",
    change_Avg_hvc_Score: "Avg_hvc_Score",
  },

  changeSelectMetricObj: {
    Avg_hvc_Score: "Avg HVC Score",
    [revenuePerCustomer]: revenuePerCustomer,
    latency: "Latency",
    aov: "aov",
  },

  yLabelTextArrowObj: {
    Avg_hvc_Score: "Avg. HVC Score",
    [revenuePerCustomer]: `Revenue / Customer`,
    latency: "Avg. Latency to Purchase",
    aov: "Avg. Order Value",
  },



  toolTipList : [
    {
      id: 1,

    }

  ]




};
