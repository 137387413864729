import React, { useEffect } from "react";
import { Box, CircularProgress, Paper, useTheme } from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import { border, cardShadow } from "../../assets/jss/commonStyles";
import ProductTable from "./productTable";
import PriceHistogram from "./priceHistogram";
import moment from "moment";
import { connect } from "react-redux";

const CustomPaper = withStyles((theme) => ({
  outlined: {
    border: border,
  },
  rounded: {
    borderRadius: 6,
  },
}))(Paper);

function ProductPage(props) {
  const {
    selectedSeg,
    data,
    compareData,
    property,
    durationFrom,
    durationTo,
    compareFrom,
    compareTo,
    application_states,
  } = props;
  const theme = useTheme();
  const currency = application_states.currency;
  const monthCompare = application_states.monthCompare;
  const currencySymbol = application_states.currencySymbol;

  const [loading, setLoading] = React.useState(true);
  useEffect(() => {
    handle_loading();
    //eslint-disable-next-line
  }, [props]);

  function handle_loading() {
    if (data.isLoading === false && compareData.isLoading === false) {
      if (data.orderedProducts) {
        if (
          moment(application_states[durationFrom]).diff(
            moment(data.start, "DD-MM-yyyy"),
            "days"
          ) === 0 &&
          moment(application_states[durationTo]).diff(
            moment(data.end, "DD-MM-yyyy"),
            "days"
          ) === 0
        ) {
          if (monthCompare) {
            if (compareData.orderedProducts) {
              if (
                moment(application_states[compareFrom]).diff(
                  moment(compareData.start, "DD-MM-yyyy"),
                  "days"
                ) === 0 &&
                moment(application_states[compareTo]).diff(
                  moment(compareData.end, "DD-MM-yyyy"),
                  "days"
                ) === 0
              ) {
                setLoading(false);
                return null;
              }
            }
          } else {
            setLoading(false);
            return null;
          }
        }
      }
    }
    // setLoading(true);
    return null;
  }
  return (
    <React.Fragment>
      {!loading ? (
        <Box>
          <Box m={1} boxShadow={cardShadow}>
            <CustomPaper variant={"outlined"}>
              <ProductTable
                selectedSeg={selectedSeg}
                data={data}
                compareData={compareData}
                property={property}
              />
            </CustomPaper>
          </Box>
          <Box m={1} boxShadow={cardShadow}>
            <CustomPaper variant={"outlined"}>
              <PriceHistogram
                selectedSeg={selectedSeg}
                data={data}
                compareData={compareData}
                property={property}
              />
            </CustomPaper>
          </Box>
        </Box>
      ) : (
        <Box style={{ minHeight: 150 }}>
          <CircularProgress
            size={100}
            style={{
              position: "relative",
              left: "50%",
              top: "50%",
              color: theme.palette.secondary.light,
            }}
          />
        </Box>
      )}
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  application_states: state.application_states,
});

export default connect(mapStateToProps, null)(ProductPage);
