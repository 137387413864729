import React from "react";
import { Box, Typography, useTheme } from "@mui/material";
import moment from "moment";
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Text,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import NumericLabel from "react-pretty-numbers";
import * as _ from "lodash";
import Icons from "../icons";
import { numbersFormat } from "../groupPage/graphs/geoOrdersGraph";
import DateComponent from "../../GlobalComponents/DateComponent";

export const DataCompare = (props) => {
  const theme = useTheme();
  const { value, compareValue, changeValue, title, params } = props;
  return (
    <React.Fragment>
      {title ? <>{title}:</> : null}&nbsp;
      {<NumericLabel params={params}>{value}</NumericLabel>}&nbsp;&nbsp;
      {changeValue && changeValue !== "-" ? (
        compareValue > value ? (
          <span style={{ color: "#eb5757" }}>
            &nbsp;
            <NumericLabel>{changeValue}</NumericLabel>
            %&nbsp;
            {Icons.redLineArrow}
          </span>
        ) : (
          <span style={{ color: "#27AE60" }}>
            &nbsp;
            <NumericLabel>{changeValue}</NumericLabel>
            %&nbsp;
            {Icons.greenLineArrow}
          </span>
        )
      ) : changeValue === "" ? (
        <span
          style={{
            color: title
              ? theme.palette.common.white
              : theme.palette.primary.main,
            opacity: 0.5,
          }}
        >
          &ensp;
        </span>
      ) : changeValue === "-" ? (
        <span
          style={{
            color: title
              ? theme.palette.common.white
              : theme.palette.primary.main,
            opacity: 0.5,
          }}
        >
          &ensp;-
        </span>
      ) : (
        <span
          style={{
            color: title
              ? theme.palette.common.white
              : theme.palette.primary.main,
            opacity: 0.5,
          }}
        >
          &ensp;0%
        </span>
      )}
      &nbsp;
    </React.Fragment>
  );
};

export const CustomerChange = (props) => {
  const theme = useTheme();
  const { value, compareValue, title, changeValue } = props;
  return (
    <React.Fragment>
      {title ? <>{title}:</> : null}&nbsp;
      {changeValue && changeValue !== "-" ? (
        compareValue > value ? (
          <span style={{ color: "#eb5757" }}>
            &nbsp;
            <NumericLabel>{value}</NumericLabel>% &nbsp;
          </span>
        ) : (
          <span style={{ color: "#27AE60" }}>
            &nbsp;
            <NumericLabel>{value}</NumericLabel>% &nbsp;
          </span>
        )
      ) : changeValue === "-" ? (
        <span
          style={{
            color: title
              ? theme.palette.common.white
              : theme.palette.primary.main,
          }}
        >
          <NumericLabel>{value}</NumericLabel>%
        </span>
      ) : (
        <span
          style={{
            color: title
              ? theme.palette.common.white
              : theme.palette.primary.main,
          }}
        >
          <NumericLabel>{value}</NumericLabel>%
        </span>
      )}
    </React.Fragment>
  );
};

function CustomTooltips(props) {
  const { active, payload, currency } = props;
  const theme = useTheme();
  const orderParams1 = {
    commafy: true,
    wholenumber: "ceil",
    // shortFormat: true,
    shortFormatMinValue: 10000,
    justification: "L",
  };
  const orderParams = {
    commafy: true,
    wholenumber: "ceil",
    shortFormat: true,
    shortFormatMinValue: 10000,
    justification: "L",
    precision: 1,
  };
  const revenueParams = {
    locales: "en-US",
    wholenumber: "ceil",
    currency: true,
    shortFormat: true,
    shortFormatMinValue: 10000,
    currencyIndicator: currency,
    commafy: true,
    justification: "L",
    precision: 1,
  };
  const revenueParams2 = {
    locales: "en-US",
    wholenumber: "ceil",
    currency: true,
    shortFormat: true,
    shortFormatMinValue: 10000,
    currencyIndicator: currency,
    commafy: true,
    justification: "L",
  };
  const paramOptions4 = {
    wholenumber: "ceil",
    justification: "L",
  };
  if (active) {
    const notFirstCluster = "compare_aov" in payload[0].payload;
    return (
      <div>
        {notFirstCluster ? (
          <Box border={"1px solid transparent"} borderRadius="8px">
            <div
              style={{
                backgroundColor: "#222",
                padding: 8,
                color: theme.palette.common.white,
                borderTopLeftRadius: 8,
                borderTopRightRadius: 8,
              }}
            >
              <Typography variant={"body2"}>
                <DateComponent dateText={payload?.[0]?.payload?.start_date} />
                &nbsp;-&nbsp;
                <DateComponent dateText={payload?.[0]?.payload?.end_date} />
              </Typography>
            </div>
            <Box
              p={1}
              color={theme.palette.common.white}
              style={{
                backgroundColor: "#000",
                borderBottomLeftRadius: 8,
                borderBottomRightRadius: 8,
              }}
            >
              <Typography variant={"body2"}>
                <DataCompare
                  value={payload[0].payload.customers}
                  changeValue={
                    payload[0].payload.prevMonth ===
                    payload[0].payload.compare_month
                      ? Math.abs(payload[0].payload.change_customers)
                      : "-"
                  }
                  params={
                    payload[0].payload.customers < 10000
                      ? orderParams1
                      : orderParams
                  }
                  title={
                    payload[0].payload.segment === "all"
                      ? "Total Customers"
                      : "Customers"
                  }
                  compareValue={payload[0].payload.compare_customers}
                />
              </Typography>
              {payload[0].payload.segment !== "all" ? (
                <Typography variant={"body2"}>
                  <CustomerChange
                    value={Math.round(payload[0].payload.customers_percentage)}
                    changeValue={
                      payload[0].payload.prevMonth ===
                      payload[0].payload.compare_month
                        ? Math.abs(
                            payload[0].payload.change_customers_percentage
                          )
                        : "-"
                    }
                    params={orderParams}
                    title={"% of All Customers"}
                    compareValue={
                      payload[0].payload.compare_customers_percentage
                    }
                  />
                </Typography>
              ) : null}
              <Typography variant={"body2"}>
                <DataCompare
                  value={payload[0].payload.orders}
                  changeValue={
                    payload[0].payload.prevMonth ===
                    payload[0].payload.compare_month
                      ? Math.abs(payload[0].payload.change_orders)
                      : "-"
                  }
                  params={
                    payload[0].payload.orders < 10000
                      ? orderParams1
                      : orderParams
                  }
                  title={
                    payload[0].payload.segment === "all"
                      ? "Total Orders"
                      : "Orders"
                  }
                  compareValue={payload[0].payload.compare_orders}
                />
              </Typography>
              <Typography variant={"body2"}>
                <DataCompare
                  value={payload[0].payload.revenue}
                  changeValue={
                    payload[0].payload.prevMonth ===
                    payload[0].payload.compare_month
                      ? Math.abs(payload[0].payload.change_revenue)
                      : "-"
                  }
                  params={
                    payload[0].payload.revenue < 10000
                      ? revenueParams2
                      : revenueParams
                  }
                  title={
                    payload[0].payload.segment === "all"
                      ? "Total Revenue"
                      : "Revenue"
                  }
                  compareValue={payload[0].payload.compare_revenue}
                />
              </Typography>
              <Typography variant={"body2"}>
                <DataCompare
                  value={payload[0].payload.revenue_per_customer}
                  changeValue={
                    payload[0].payload.prevMonth ===
                    payload[0].payload.compare_month
                      ? Math.abs(payload[0].payload.change_revenue_per_customer)
                      : "-"
                  }
                  params={
                    payload[0].payload.revenue_per_customer < 10000
                      ? revenueParams2
                      : revenueParams
                  }
                  title={"Revenue/Customer"}
                  compareValue={payload[0].payload.compare_revenue_per_customer}
                />
              </Typography>
              <Typography variant={"body2"}>
                <DataCompare
                  value={payload[0].payload.aov}
                  changeValue={
                    payload[0].payload.prevMonth ===
                    payload[0].payload.compare_month
                      ? Math.abs(payload[0].payload.change_aov)
                      : "-"
                  }
                  params={
                    payload[0].payload.aov < 10000
                      ? revenueParams2
                      : revenueParams
                  }
                  title={"AOV"}
                  compareValue={payload[0].payload.compare_aov}
                />
              </Typography>
              <Typography variant={"body2"}>
                <DataCompare
                  value={payload[0].payload.avg_monthly_visits}
                  changeValue={
                    payload[0].payload.prevMonth ===
                    payload[0].payload.compare_month
                      ? Math.abs(
                          Math.round(
                            payload[0].payload.change_avg_monthly_visits
                          )
                        )
                      : "-"
                  }
                  params={paramOptions4}
                  title={"Visits/Month"}
                  compareValue={payload[0].payload.compare_avg_monthly_visits}
                />
              </Typography>
            </Box>
          </Box>
        ) : (
          <Box border={"1px solid transparent"} borderRadius="8px">
            <div
              style={{
                backgroundColor: "#222",
                padding: 8,
                color: theme.palette.common.white,
                borderTopLeftRadius: 8,
                borderTopRightRadius: 8,
              }}
            >
              <Typography variant={"body2"}>
                <DateComponent dateText={payload?.[0]?.payload?.start_date} />
                &nbsp;-&nbsp;
                <DateComponent dateText={payload?.[0]?.payload?.end_date} />
              </Typography>
            </div>
            <Box
              p={1}
              color={theme.palette.common.white}
              style={{
                backgroundColor: "#000",
                borderBottomLeftRadius: 8,
                borderBottomRightRadius: 8,
              }}
            >
              <Typography variant={"body2"}>
                {payload[0].payload.segment === "all" ? (
                  <>Total Customers </>
                ) : (
                  <>Customers</>
                )}
                :&nbsp;
                <NumericLabel
                  params={
                    payload[0].payload.customers < 10000
                      ? orderParams1
                      : orderParams
                  }
                >
                  {payload[0].payload.customers}
                </NumericLabel>
                &nbsp;
              </Typography>
              {payload[0].payload.segment !== "all" ? (
                <Typography variant={"body2"}>
                  % of All Customers:&nbsp;
                  {Math.round(payload[0].payload.customers_percentage)}%
                </Typography>
              ) : null}
              <Typography variant={"body2"}>
                {payload[0].payload.segment === "all" ? (
                  <>Total Orders </>
                ) : (
                  <>Orders</>
                )}
                :&nbsp;
                <NumericLabel
                  params={
                    payload[0].payload.orders < 10000
                      ? orderParams1
                      : orderParams
                  }
                >
                  {payload[0].payload.orders}
                </NumericLabel>
              </Typography>
              <Typography variant={"body2"}>
                {payload[0].payload.segment === "all" ? (
                  <>Total Revenue </>
                ) : (
                  <>Revenue</>
                )}
                :&nbsp;
                <NumericLabel
                  params={
                    payload[0].payload.revenue < 10000
                      ? revenueParams2
                      : revenueParams
                  }
                >
                  {payload[0].payload.revenue}
                </NumericLabel>
              </Typography>
              <Typography variant={"body2"}>
                Revenue/Customer:&nbsp;
                <NumericLabel
                  params={
                    payload[0].payload.revenue_per_customer < 10000
                      ? revenueParams2
                      : revenueParams
                  }
                >
                  {payload[0].payload.revenue_per_customer}
                </NumericLabel>
              </Typography>
              <Typography variant={"body2"}>
                AOV:&nbsp;
                <NumericLabel
                  params={
                    payload[0].payload.aov < 10000
                      ? revenueParams2
                      : revenueParams
                  }
                >
                  {payload[0].payload.aov}
                </NumericLabel>
              </Typography>
              {payload[0].payload.avg_monthly_visits ? (
                <Typography variant={"body2"}>
                  Vists/Month:&nbsp;
                  <NumericLabel params={paramOptions4}>
                    {payload[0].payload.avg_monthly_visits}
                  </NumericLabel>
                </Typography>
              ) : null}
            </Box>
          </Box>
        )}
      </div>
    );
  }
  return null;
}

function CustomYAxisTick(props) {
  const { x, y, payload } = props;
  return (
    <g transform={`translate(${x},${y})`}>
      <Text
        x={0}
        y={0}
        textAnchor="end"
        verticalAnchor="middle"
        fill={"#1E334E"}
        style={{
          fontWeight: "normal",
          fontFamily: '"Lato", sans-serif',
          fontStyle: "normal",
          fontSize: 14,
        }}
      >
        {payload.value > 10000
          ? numbersFormat(payload.value, 0)
          : payload.value}
      </Text>
    </g>
  );
}

function CustomTick(props) {
  const { x, y, payload } = props;
  return (
    <g transform={`translate(${x},${y})`}>
      <Text
        x={0}
        y={0}
        textAnchor="middle"
        verticalAnchor="start"
        fill={"#1E334E"}
        style={{
          fontWeight: "normal",
          fontFamily: '"Lato", sans-serif',
          fontStyle: "normal",
          fontSize: 14,
        }}
      >
        {payload.value}
      </Text>
    </g>
  );
}

const CustomerTrendGraph = (props) => {
  const { data, currencySymbol, currency } = props;
  const theme = useTheme();
  const sortedData = _.orderBy(
    data,
    [
      function (o) {
        return o.date_range[1];
      },
    ],
    ["asc"]
  );
  return (
    <Box p={3} pb={4} pl={4}>
      {data && data.length > 0 ? (
        <ResponsiveContainer width={"98%"} height={318} pt={2}>
          <LineChart data={sortedData}>
            <XAxis
              height={15}
              interval={"preserveStartEnd"}
              tick={<CustomTick />}
              xAxisId={0}
              dataKey={(e) => {
                const dateText = `${moment(e.start_date).format(
                  "MMM D, YYYY"
                )}  -  ${moment(e.end_date).format("MMM D, YYYY")}`;
                return dateText;
              }}
            />
            <CartesianGrid strokeDasharray="3 3" />
            <YAxis
              tick={<CustomYAxisTick />}
              label={{
                value: "Customers",
                angle: -90,
                position: "insideLeft",
                fill: "#1E334E",
              }}
            />
            <Tooltip
              cursor={{ strokeWidth: 1, stroke: theme.palette.primary.main }}
              content={
                <CustomTooltips
                  currency={currency}
                  currencySymbol={currencySymbol}
                />
              }
            />
            <Line
              isAnimationActive={false}
              strokeWidth={2}
              dataKey="customers"
              stroke={theme.palette.secondary.light}
              activeDot={{ r: 8 }}
            />
          </LineChart>
        </ResponsiveContainer>
      ) : null}
    </Box>
  );
};

export default CustomerTrendGraph;
