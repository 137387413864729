import React, { useEffect, useMemo, useState } from "react";
import {
  Box,
  CircularProgress,
  Grid,
  ToggleButton,
  useTheme,
  Typography,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import { connect, useDispatch, useSelector } from "react-redux";
import Icons from "../icons";
import moment from "moment";
import ConversionPathSection from "./conversionPathSection";
import ConversionPathSankey from "./conversionPathSankey";
import ConversionPathCuSankey from "./conversionPathCuSankey";
import { CustomToggleButtonGroup } from "../newCustomerRemarketPage/contentHeader";
import { Link } from "react-router-dom";
import { OrangeFilledButton } from "../buttons";
import GroupPageStyles from "../../assets/jss/groupPageStyles";
import { setConversionPathFilterOptionsList } from "../../actions/actions";
import { connectionsTrackingDataStatusURL, settingConnectionsURL } from "../sidebar";

export const CustomToggleButton = withStyles((theme) => ({
  root: {
    minWidth: 90,
    textTransform: "none",
    fontSize: "0.875rem",
    border: "1px solid #afafaf",
    padding: "5px 15px",
    alignItems: "center",
    backgroundColor: theme.palette.common.white,
    color: theme.palette.primary.main,
    "&$selected,&$selected:hover": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
  },
  selected: {},
}))(ToggleButton);

function ConversionPath(props) {
  const {
    // data,
    // compareData,
    durationFrom,
    durationTo,
    compareFrom,
    compareTo,
    application_states,
    firstPathAvgPurchaseTps,
    hasLXRTracking,
    trackingReady,
    dataSourceLength,
  } = props;

  const dispatch = useDispatch();

  const data = useSelector((state) => state?.segment_path_reducer);

  const compareData = useSelector(
    (state) => state?.segment_compare_path_reducer
  );

  const segment = useSelector(
    (state) => state?.application_states?.customerAnalyticsSegmentTabName
  );

  const filterStates = useSelector((state) => state?.filterStates);

  const filter = useMemo(() => {
    switch (segment) {
      case "all":
        return filterStates?.filterAll;
      case "a":
        return filterStates?.filterA;
      case "b":
        return filterStates.filterB;
      case "c":
        return filterStates?.filterC;
      default:
        return {};
    }
  }, [segment, filterStates]);

  const [finalData, setFinalData] = useState(data);

  const [finalCompareData, setFinalCompareData] = useState(compareData);

  const [content, setContent] = useState("chart");
  const [loading, setLoading] = React.useState(true);
  const monthCompare = application_states.monthCompare;

  const theme = useTheme();
  const classes = GroupPageStyles();

  const handleContentChange = async (e, contentValue) => {
    if (contentValue !== null) {
      setContent(contentValue);
    }
  };

  useEffect(() => {
    handle_loading();
    //eslint-disable-next-line
  }, [props]);

  function handle_loading() {
    if (data.isLoading === false && compareData.isLoading === false) {
      if (data.orderedPaths) {
        if (
          moment(application_states[durationFrom]).diff(
            moment(data.start, "DD-MM-yyyy"),
            "days"
          ) === 0 &&
          moment(application_states[durationTo]).diff(
            moment(data.end, "DD-MM-yyyy"),
            "days"
          ) === 0
        ) {
          if (monthCompare) {
            if (compareData.orderedPaths) {
              if (
                moment(application_states[compareFrom]).diff(
                  moment(compareData.start, "DD-MM-yyyy"),
                  "days"
                ) === 0 &&
                moment(application_states[compareTo]).diff(
                  moment(compareData.end, "DD-MM-yyyy"),
                  "days"
                ) === 0
              ) {
                setLoading(false);
                return null;
              }
            }
          } else {
            setLoading(false);
            return null;
          }
        }
      }
    }
    setLoading(true);
    return null;
  }

  // this usememo hook will get the channel startswith array, channel endswith array and channel contains array list of channel names

  function calculateChannelsArray(convertedPathsList) {
    let startsWithChannelsArray = [];
    let containsChannelArray = [];
    let endsWithChannelArray = [];

    if (convertedPathsList?.length) {
      convertedPathsList?.forEach((each) => {
        if (each?.path?.length) {
          if (!startsWithChannelsArray.includes(each?.path?.[0]?.[0])) {
            startsWithChannelsArray.push(each?.path?.[0]?.[0]);
          }

          if (each?.path) {
            each?.path?.forEach((item) => {
              if (!containsChannelArray.includes(item?.[0])) {
                containsChannelArray.push(item?.[0]);
              }
            });
          }

          if (!endsWithChannelArray.includes(each?.path?.slice(-1)?.[0]?.[0])) {
            endsWithChannelArray.push(each?.path?.slice(-1)?.[0]?.[0]);
          }
        }
      });

      startsWithChannelsArray.sort();
      containsChannelArray.sort();
      endsWithChannelArray.sort();
    }

    return {
      startsWithChannelsArray,
      containsChannelArray,
      endsWithChannelArray,
    };
  }

  function calculateAppliedFilterList(arrayList, filter) {
    if (Object.keys(filter)?.length) {
      let filteredPathsList = [];

      if (filter?.pathStartsWith && arrayList?.length) {
        const startsWithFilterValue = filter?.pathStartsWith;

        filteredPathsList = arrayList?.filter((each) => {
          if (
            each?.path?.length &&
            each?.path?.[0]?.[0] === startsWithFilterValue
          ) {
            return each;
          }
        });
      } else {
        filteredPathsList = arrayList;
      }

      if (filter?.pathEndsWith && filteredPathsList?.length) {
        const endsWithFilterValue = filter?.pathEndsWith;

        filteredPathsList = filteredPathsList?.filter((each) => {
          if (
            each?.path?.length &&
            each?.path?.slice(-1)?.[0]?.[0] === endsWithFilterValue
          ) {
            return each;
          }
        });
      } else {
        filteredPathsList = filteredPathsList;
      }

      if (filter?.channel && filteredPathsList?.length) {
        const channelFilterValue = filter?.channel;

        filteredPathsList = filteredPathsList?.filter((each) => {
          if (
            each?.path?.length &&
            each?.path?.some((item) => item?.[0] === channelFilterValue)
          ) {
            return each;
          }
        });
      } else {
        filteredPathsList = filteredPathsList;
      }

      return filteredPathsList;
    }
  }

  const finalPathsObj = useMemo(() => {
    if (data?.converted_paths) {
      // conversionPathFilterObj consits of channel name starts with, contains with, ends with

      const conversionPathFilterObj = calculateChannelsArray(
        data?.converted_paths
      );

      dispatch(setConversionPathFilterOptionsList(conversionPathFilterObj));
    }
  }, [data, segment]);

  useEffect(() => {
    if (Object.keys(filter)?.length) {
      if (data) {
        const convertedPathsArrayList = calculateAppliedFilterList(
          data?.converted_paths,
          filter
        );
        const orderPathsArrayList = calculateAppliedFilterList(
          data?.orderedPaths,
          filter
        );

        setFinalData((prevState) => {
          return {
            ...prevState,
            orderedPaths: orderPathsArrayList,
            converted_paths: convertedPathsArrayList,
          };
        });
      }

      if (monthCompare && compareData) {
        const convertedPathsArrayList = calculateAppliedFilterList(
          compareData?.converted_paths,
          filter
        );
        const orderPathsArrayList = calculateAppliedFilterList(
          compareData?.orderedPaths,
          filter
        );

        setFinalCompareData((prevState) => {
          return {
            ...prevState,
            orderedPaths: orderPathsArrayList,
            converted_paths: convertedPathsArrayList,
          };
        });
      }
    } else if (!Object.keys(filter)?.length) {
      setFinalData(data);
      if (!monthCompare) {
        setFinalCompareData(compareData);
      }
    }
  }, [filter, data, compareData, monthCompare]);


  return !loading ? (
    <div>
      {hasLXRTracking && dataSourceLength === 1 ? (
        <React.Fragment>
          <Grid container>
            <Grid item xs={12}>
              <CustomToggleButtonGroup
                style={{ margin: "2% 2% 1%", float: "right" }}
                value={content}
                onChange={handleContentChange}
                exclusive={true}
                aria-label="button value"
              >
                <CustomToggleButton value={"chart"} aria-label="chart">
                  {content === "chart" ? (
                    <Box pt={0.5}>{Icons.sankeyWGraphTabIcon}</Box>
                  ) : (
                    <Box pt={0.5}>{Icons.sankeyGraphTabIcon}</Box>
                  )}
                  &nbsp;Conversion Flow
                </CustomToggleButton>
                <CustomToggleButton value={"cusankey"} aria-label="cusankey">
                  {content === "cusankey" ? (
                    <Box pt={0.5}>{Icons.cumulativeWTabIcon}</Box>
                  ) : (
                    <Box pt={0.5}>{Icons.cumulativeBTabIcon}</Box>
                  )}
                  &nbsp;Cumulative Flow
                </CustomToggleButton>
                <CustomToggleButton value={"line"} aria-label="line">
                  {content === "line" ? (
                    <Box pt={0.5}>{Icons.individualWFlow}</Box>
                  ) : (
                    <Box pt={0.5}>{Icons.individualBFlow}</Box>
                  )}
                  &nbsp;Individual Path
                  {/* <ArrowForwardOutlined style={{fontSize: "1rem"}}/>&nbsp;Individual Path */}
                </CustomToggleButton>
              </CustomToggleButtonGroup>
            </Grid>
          </Grid>
          {content === "chart" ? (
            <ConversionPathSankey
              data={finalData}
              compareData={finalCompareData}
              durationFrom={durationFrom}
              durationTo={durationTo}
              compareFrom={compareFrom}
              compareTo={compareTo}
              firstPathAvgPurchaseTps={firstPathAvgPurchaseTps}
              dataStartDate={data?.data_start_date}
              dataEndDate={data?.data_end_date}
            />
          ) : content === "cusankey" ? (
            <ConversionPathCuSankey
              data={finalData}
              compareData={finalCompareData}
              durationFrom={durationFrom}
              durationTo={durationTo}
              compareFrom={compareFrom}
              compareTo={compareTo}
              dataStartDate={data?.data_start_date}
              dataEndDate={data?.data_end_date}
            />
          ) : (
            content === "line" && (
              <ConversionPathSection
                data={finalData}
                compareData={finalCompareData}
                durationFrom={durationFrom}
                durationTo={durationTo}
                compareFrom={compareFrom}
                compareTo={compareTo}
                dataStartDate={data?.data_start_date}
                dataEndDate={data?.data_end_date}
              />
            )
          )}
        </React.Fragment>
      ) : hasLXRTracking && dataSourceLength > 1 ? (
        trackingReady ? (
          <React.Fragment>
            <Grid container>
              <Grid item xs={12}>
                <CustomToggleButtonGroup
                  style={{ margin: "2% 2% 1%", float: "right" }}
                  value={content}
                  onChange={handleContentChange}
                  exclusive={true}
                  aria-label="button value"
                >
                  <CustomToggleButton value={"chart"} aria-label="chart">
                    {content === "chart" ? (
                      <Box pt={0.5}>{Icons.sankeyWGraphTabIcon}</Box>
                    ) : (
                      <Box pt={0.5}>{Icons.sankeyGraphTabIcon}</Box>
                    )}
                    &nbsp;Conversion Flow
                  </CustomToggleButton>
                  <CustomToggleButton value={"cusankey"} aria-label="cusankey">
                    {content === "cusankey" ? (
                      <Box pt={0.5}>{Icons.cumulativeWTabIcon}</Box>
                    ) : (
                      <Box pt={0.5}>{Icons.cumulativeBTabIcon}</Box>
                    )}
                    &nbsp;Cumulative Flow
                  </CustomToggleButton>
                  <CustomToggleButton value={"line"} aria-label="line">
                    {content === "line" ? (
                      <Box pt={0.5}>{Icons.individualWFlow}</Box>
                    ) : (
                      <Box pt={0.5}>{Icons.individualBFlow}</Box>
                    )}
                    &nbsp;Individual Path
                    {/* <ArrowForwardOutlined style={{fontSize: "1rem"}}/>&nbsp;Individual Path */}
                  </CustomToggleButton>
                </CustomToggleButtonGroup>
              </Grid>
            </Grid>
            {content === "chart" ? (
              <ConversionPathSankey
                data={finalData}
                compareData={finalCompareData}
                durationFrom={durationFrom}
                durationTo={durationTo}
                compareFrom={compareFrom}
                compareTo={compareTo}
                firstPathAvgPurchaseTps={firstPathAvgPurchaseTps}
                dataStartDate={data?.data_start_date}
                dataEndDate={data?.data_end_date}
              />
            ) : content === "cusankey" ? (
              <ConversionPathCuSankey
                data={finalData}
                compareData={finalCompareData}
                durationFrom={durationFrom}
                durationTo={durationTo}
                compareFrom={compareFrom}
                compareTo={compareTo}
                dataStartDate={data?.data_start_date}
                dataEndDate={data?.data_end_date}
              />
            ) : (
              content === "line" && (
                <ConversionPathSection
                  data={finalData}
                  compareData={finalCompareData}
                  durationFrom={durationFrom}
                  durationTo={durationTo}
                  compareFrom={compareFrom}
                  compareTo={compareTo}
                  dataStartDate={data?.data_start_date}
                  dataEndDate={data?.data_end_date}
                />
              )
            )}
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Box className={classes.conversionPathBlurred}>
              <Box style={{ background: "#CBCBCB33" }} height={"100%"} px={2}>
                <Typography
                  style={{
                    color: theme.palette.primary.main,
                    textAlign: "center",
                    paddingTop: "10%",
                  }}
                  variant="body1"
                >
                  Data in this section is not available
                </Typography>
                <br />
                <Typography
                  style={{
                    color: theme.palette.primary.main,
                    textAlign: "center",
                    paddingTop: "20px",
                  }}
                  variant="body1"
                >
                  <Link
                    to={connectionsTrackingDataStatusURL}
                    style={{ textDecoration: "none" }}
                  >
                    <OrangeFilledButton style={{ borderRadius: 0 }}>
                      &nbsp;View Data Status&nbsp;
                    </OrangeFilledButton>
                  </Link>
                  &nbsp;for more details to show.
                </Typography>
              </Box>
            </Box>
          </React.Fragment>
        )
      ) : (
        <React.Fragment>
          <Box className={classes.conversionPathBlurred}>
            <Box style={{ background: "#CBCBCB33" }} height={"100%"} px={2}>
              <Typography
                style={{
                  color: theme.palette.primary.main,
                  textAlign: "center",
                  paddingTop: "10%",
                }}
                variant="body1"
              >
                Data in this section is not available for the current tracking
                method.
              </Typography>
              <br />
              <Typography
                style={{
                  color: theme.palette.primary.main,
                  textAlign: "center",
                  paddingTop: "20px",
                }}
                variant="body1"
              >
                <Link
                  to={settingConnectionsURL}
                  style={{ textDecoration: "none" }}
                >
                  <OrangeFilledButton style={{ borderRadius: 0 }}>
                    Add LXRTracking
                  </OrangeFilledButton>
                </Link>
                &nbsp;for more details to show.
              </Typography>
            </Box>
          </Box>
        </React.Fragment>
      )}
    </div>
  ) : (
    <Box style={{ minHeight: 150 }}>
      <CircularProgress
        size={100}
        style={{
          position: "relative",
          left: "50%",
          top: "50%",
          marginTop: "5%",
          color: theme.palette.secondary.light,
        }}
      />
    </Box>
  );
}

const mapStateToProps = (state) => ({
  application_states: state.application_states,
});
export default connect(mapStateToProps, null)(ConversionPath);
