import { Box, Grid, Typography } from "@mui/material";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { customerAnalyticsSegmentName } from "../../actions/actions";
import ProductAnalyticsPageStyles from "../../assets/jss/productAnalyticsStyles";
import { CustomTab, CustomTabs } from "../customComponents/customTabs";

const tabsList = [
  {
    id: 1,
    value: "a",
    icon: <>&ensp;High Value&ensp;</>,
    path: "/customer-analytics/a",
  },
  {
    id: 2,
    value: "b",
    icon: <>&ensp;Mid Value&ensp;</>,
    path: "/customer-analytics/b",
  },
  {
    id: 3,
    value: "c",
    icon: <>&ensp;Low Value&ensp;</>,
    path: "/customer-analytics/c",
  },
  {
    id: 4,
    value: "all",
    icon: <>&ensp;All Customers&ensp;</>,
    path: "/customer-analytics/all",
  },
];

const CustomerAnalyticsHeader = () => {
  const classes = ProductAnalyticsPageStyles();

  const history = useHistory();

  const dispatch = useDispatch();

  const params = useParams();

  const [tabValue, setTabValue] = useState(params?.selectedTab);

  const handleTabChange = (event, clickedTab) => {
    setTabValue(clickedTab);

    const selectedTab = tabsList?.filter(
      (eachTab) => eachTab?.value === clickedTab
    );

    dispatch(
      customerAnalyticsSegmentName({
        customerAnalyticsSegmentTabName: clickedTab,
      })
    );

    history.push(`${selectedTab?.[0]?.path}`);
  };

  useEffect(() => {
    // window.addEventListener("scroll", handleScroll);

    setTabValue(params?.selectedTab);

    dispatch(
      customerAnalyticsSegmentName({
        customerAnalyticsSegmentTabName: params?.selectedTab,
      })
    );
  }, [params]);

  return (
    <>
      <Box className={clsx("flex", classes.headerStyles, classes.header)}>
        <Grid container alignItems={"center"}>
          <Grid item xs={2} alignItems={"center"}>
            <Typography
              variant={"body1"}
              color={"primary"}
              style={{ fontWeight: 700 }}
              component={"span"}
            >
              &nbsp;Customer Analytics:
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <CustomTabs value={tabValue} onChange={handleTabChange}>
              {tabsList?.map((each, index) => (
                <CustomTab
                  value={each?.value}
                  icon={each?.icon}
                  key={`customer-analytics-tab-${index}`}
                />
              ))}
            </CustomTabs>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default CustomerAnalyticsHeader;
