import React from "react";
import { Box, Card, CardContent, Grid } from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import OverviewPageStyles from "../../assets/jss/overviewPageStyles";
import TrendChart from "./trendChart";
import BlockHeader from "./blockHeader";
import BlockDetails, { BlockDetailsFullWidth } from "./blockDetails";
import RevenuePieChart from "./revenuePieChart";
import * as _ from "lodash";

export const CustomCardContent = withStyles((theme) => ({
  root: {
    padding: 0,
  },
}))(CardContent);

function OverviewBlock(props) {
  const { overview_reducer, user_segment_reducer, currency, country_code } =
    props;
  const filterSegmentData = (data, segment) => {
    return segment !== "all" && data
      ? _.filter(data, {
          segment: segment,
          repeat_purchaser1: true,
        })
      : _.filter(data, { repeat_purchaser1: true });
  };
  const filter3MonthRePurchasers = (data, segment) => {
    return segment !== "all" && data
      ? _.filter(data, {
          segment: segment,
          next2months_repeat_buyer: true,
        })
      : _.filter(data, { next2months_repeat_buyer: true });
  };
  const filterChurnCustomers = (data, segment) => {
    return segment !== "all" && data
      ? _.filter(data, {
          segment: segment,
          churn_prediction: true,
        })
      : _.filter(data, { churn_prediction: true });
  };
  const classes = OverviewPageStyles();
  return (
    <Grid
      container
      spacing={1}
      alignItems={"inherit"}
      style={{ minHeight: "100%" }}
    >
      {overview_reducer.data &&
        overview_reducer.data.map((segmentDetail, index) =>
          segmentDetail.segment === "all" ? (
            <React.Fragment key={index}>
              <Grid item xs={12} pb={2} style={{ flexGrow: 1 }}>
                <BlockHeader
                  country_code={country_code}
                  currency={currency}
                  segmentDetail={segmentDetail}
                  classes={classes}
                  all={true}
                />
              </Grid>
              <Grid item md={6} style={{ flexGrow: 1 }}>
                <Card className={classes.cardClass}>
                  <CustomCardContent>
                    <BlockDetails
                      predictionList={filterSegmentData(
                        user_segment_reducer.users
                          ? user_segment_reducer.users
                          : null,
                        segmentDetail.segment
                      )}
                      repurchase3Months={filter3MonthRePurchasers(
                        user_segment_reducer.users
                          ? user_segment_reducer.users
                          : null,
                        segmentDetail.segment
                      )}
                      churnCustomers={filterChurnCustomers(
                        user_segment_reducer.users
                          ? user_segment_reducer.users
                          : null,
                        segmentDetail.segment
                      )}
                      country_code={country_code}
                      classes={classes}
                      data={overview_reducer}
                      currency={currency}
                      segmentDetail={segmentDetail}
                    />
                    <Box pt={1.5}>
                      <TrendChart segmentDetail={segmentDetail} />
                    </Box>
                  </CustomCardContent>
                </Card>
              </Grid>
            </React.Fragment>
          ) : null
        )}
      <Grid item md={6} style={{ flexGrow: 1 }}>
        <Card className={classes.cardClass}>
          <CustomCardContent>
            <RevenuePieChart
              currency={currency}
              data={overview_reducer}
              classes={classes}
            />
          </CustomCardContent>
        </Card>
      </Grid>
      {overview_reducer.data &&
        overview_reducer.data.map((segmentDetail, index) =>
          segmentDetail.segment !== "all" ? (
            <React.Fragment key={index}>
              <Grid item md={12} key={index} style={{ flexGrow: 1 }} pb={2}>
                <BlockHeader
                  country_code={country_code}
                  classes={classes}
                  currency={currency}
                  data={overview_reducer}
                  segmentDetail={segmentDetail}
                />
              </Grid>
              <Grid item md={12} style={{ flexGrow: 1 }}>
                <Card className={classes.cardClass}>
                  <CustomCardContent>
                    <BlockDetailsFullWidth
                      predictionList={filterSegmentData(
                        user_segment_reducer.users
                          ? user_segment_reducer.users
                          : null,
                        segmentDetail.segment
                      )}
                      repurchase3Months={filter3MonthRePurchasers(
                        user_segment_reducer.users
                          ? user_segment_reducer.users
                          : null,
                        segmentDetail.segment
                      )}
                      churnCustomers={filterChurnCustomers(
                        user_segment_reducer.users
                          ? user_segment_reducer.users
                          : null,
                        segmentDetail.segment
                      )}
                      country_code={country_code}
                      classes={classes}
                      currency={currency}
                      data={overview_reducer}
                      segmentDetail={segmentDetail}
                    />
                    <Box pt={1.5}>
                      <TrendChart segmentDetail={segmentDetail} />
                    </Box>
                  </CustomCardContent>
                </Card>
              </Grid>
            </React.Fragment>
          ) : null
        )}
    </Grid>
  );
}

export default OverviewBlock;
