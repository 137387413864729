import React from "react"
import Header from "../components/headers/header"
import {Grid} from "@mui/material";

export default class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {hasError: false};
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return {hasError: true};
    }

    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
        // logErrorToMyService(error, errorInfo);
        console.log("ErrorBoundary error=", error);
        console.log("ErrorBoundary errorInfo=", errorInfo);
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return (
                <div>
                    <Header/>
                    <div className={'flex'}>
                        <Grid container>
                            <Grid item md={12}>
                                <div style={{flexGrow: 1, paddingTop: 50, paddingLeft: 120}}>
                                    {/*<div className={classes.toolbar}/>*/}
                                    <h1>Something went wrong.</h1>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            )
        }

        return this.props.children;
    }
}