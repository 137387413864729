import { Tab, Tabs } from "@mui/material";

import withStyles from "@mui/styles/withStyles";

export const CustomTabs = withStyles((theme) => ({
  indicator: {
    backgroundColor: theme.palette.secondary.light,
  },
}))(Tabs);

export const CustomTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    fontSize: "1rem",
    fontWeight: 400,
    minWidth: 0,
    padding: "12px",
    color: theme.palette.primary.main,
    "&$selected": {
      color: theme.palette.secondary.light,
    },
    "&$disabled": {
      color: "#aaa",
    },
  },
  wrapper: {
    flexDirection: "row",
  },
  textColorInherit: {
    color: theme.palette.primary.main,
    opacity: 1,
  },
  selected: {
    fontWeight: 700,
    color: theme.palette.secondary.light,
  },
  disabled: {
    color: "#aaa",
  },
}))(Tab);

export const CustomTabs1 = withStyles((theme) => ({
  indicator: {
    backgroundColor: "#3333334D",
    height: "3px",
  },
}))(Tabs);

export const CustomTab1 = withStyles((theme) => ({
  root: {
    textTransform: "none",
    fontSize: "1rem",
    fontWeight: 400,
    minWidth: 0,
    padding: "12px",
    color: "#A4A4A4",
    "&$selected": {
      color: "#747474",
    },
    "&$disabled": {
      color: "#A4A4A4",
    },
  },
  wrapper: {
    flexDirection: "row",
  },
  textColorInherit: {
    color: "#A4A4A4",
    opacity: 1,
  },
  selected: {
    fontWeight: 500,
    color: "#747474",
  },
  disabled: {
    color: "#aaa",
  },
}))(Tab);

export const CustomTabs2 = withStyles((theme) => ({
  root: {
    marginLeft: "50px",
  },
}))(Tabs);

export const CustomTab2 = withStyles((theme) => ({
  root: {
    textTransform: "none",
    fontSize: "1rem",
    fontWeight: 400,
    minWidth: 0,
    textAlign: "left",
    justifyContent: "left",
    color: theme.palette.primary.main,
    "&$selected": {
      color: "#222",
    },
    "&$disabled": {
      color: "#838383",
    },
  },
  wrapper: {
    flexDirection: "row",
  },
  selected: {
    fontWeight: 700,
    color: "#222",
  },
}))(Tab);

export const CustomVerticalTabs = withStyles((theme) => ({
  indicator: {
    backgroundColor: theme.palette.secondary.light,
    left: 0,
  },
}))(Tabs);

export const CustomVerticalTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    fontSize: "1rem",
    fontWeight: 400,
    minWidth: 0,
    height:'70px',
    padding: "12px",
    border: "1px solid #EBEBEB",
    color: "#8F8F8F",
    "&$selected": {
      color: theme.palette.secondary.light,
      background:"#FFF4EB73"
    },
    "&$disabled": {
      color: "#aaa",
    },
  },
  wrapper: {
    flexDirection: "row",
  },
  textColorInherit: {
    color: theme.palette.primary.main,
    opacity: 1,
  },
  selected: {
    fontWeight: 700,
    color: theme.palette.secondary.light,
  },
  disabled: {
    color: "#aaa",
  },
}))(Tab);
