import { Grid } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import OverviewPageStyles from "../assets/jss/overviewPageStyles";
import CustomerAnalyticsHeader from "../components/customerAnalytics/customerAnalyticsHeader";
import DowntimeBanner from "../components/downtimeBanner";
import Header from "../components/headers/header";
import GroupPage from "./groupPage";
import { setConversionPathFilterOptionsList } from "../actions/actions";

export const conversionPathFilterObj = {
  startsWithChannelsArray: [],
  containsChannelArray: [],
  endsWithChannelArray: [],
};

const CustomerAnalyticsPage = () => {
  const classes = OverviewPageStyles();
  const dispatch = useDispatch();

  const application_states = useSelector((state) => state?.application_states);

  useEffect(() => {
    return () => {
      dispatch(setConversionPathFilterOptionsList(conversionPathFilterObj));
    };
  }, []);

  

  return (
    <>
      <Header />
      <div style={{ display: "flex" }}>
        <Grid container>
          <Grid item md={12}>
            <div className={classes?.content}>
              <div className={classes?.toolbar} />
              <div
                style={{
                  minHeight: application_states.window_height - 120,
                }}
              >
                <DowntimeBanner />

                <CustomerAnalyticsHeader />

                <GroupPage />
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default CustomerAnalyticsPage;
