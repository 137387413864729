import { useEffect, useState, useRef } from "react";
import ReactDOM from "react-dom";
import { TooltipContainer } from "./tooltipContainer";

const generateTooltipId = (chartId) => `highcharts-custom-tooltip-${chartId}`;

export const Tooltip = ({ chart, children }) => {
  const isInit = useRef(false);
  const [context, setContext] = useState(null);

  useEffect(() => {
    if (chart) {
      const formatter = function () {
        if (!isInit.current) {
          isInit.current = true;
          chart.tooltip.refresh.apply(chart.tooltip, [this.point]);
          chart.tooltip.hide(0);
        }
        setContext(this);
        return `<div id="${generateTooltipId(chart.index)}"></div>`;
      };

      chart.update({
        tooltip: {
          formatter,
          useHTML: true,
        },
      });
    }
  }, [chart]);

  const node = chart && document.getElementById(generateTooltipId(chart.index));

  return node && context
    ? ReactDOM.createPortal(
        <TooltipContainer>{children(context)}</TooltipContainer>,
        node
      )
    : null;
};
