import React from "react";
import {Cell, Legend, Pie, PieChart, ResponsiveContainer, Sector} from "recharts";
import {Box, Grid, Typography, useTheme} from "@mui/material";
import {border} from "../../assets/jss/commonStyles";
import CustomerGroup, {Colors} from "../getGroupDetails";
import * as _ from "lodash"
import {RevenueDataNode} from "../dataNode"
import NumericLabel from "react-pretty-numbers";

const RADIAN = Math.PI / 180;

const renderCustomizedLabel = ({cx, cy, midAngle, innerRadius, outerRadius, percent, index}) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
    return (
        <text x={x} y={y} fill={"#1E334E"} textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central"
              style={{fontWeight: 700, fontSize: '1.125rem'}}>
            {`${Math.round(percent * 100)}%`}
        </text>
    );
};

const renderActiveShape = (props) => {
    // const RADIAN = Math.PI / 180;
    const {
        cx, cy, outerRadius, startAngle, endAngle,
        fill
    } = props;
    // const sin = Math.sin(-RADIAN * midAngle);
    // const cos = Math.cos(-RADIAN * midAngle);
    return (
        <g>
            <Sector
                cx={cx}
                cy={cy}
                innerRadius={0}
                outerRadius={outerRadius + 15}
                startAngle={startAngle}
                endAngle={endAngle}
                fill={fill}
            />
        </g>
    );
};

function SelectedGroup(props) {
    const {data, Colors, activeIndex, currency} = props;
    const segment = _.map(data.data, 'segment')
    const segmentData = _.filter(data.data, function (o) {
        return o.segment === segment[activeIndex]
    })
    const paramOptions1 = {
        commafy: true,
        wholenumber: 'ceil',
        shortFormat: true,
        shortFormatMinValue: 10000,
        justification: 'L',
        precision: 1
    }
    const paramOptions2 = {
        commafy: true,
        wholenumber: 'ceil',
        shortFormat: true,
        shortFormatMinValue: 10000,
        justification: 'L',
    }
    const paramOptions3 = {
        'commafy': true,
        'precision': 0,
        'wholenumber': 'ceil',
        'locales': 'en-US',
        currency: true,
        currencyIndicator: currency,
        justification: 'L',
    };
    return (
        segmentData[0] !== undefined ?
            <Grid container >
                <Grid item xs={12}>
                    <Box py={2} pt={'20px'} borderBottom={border} className={'flex'} style={{alignItems: 'center'}}>
                        <Box width={12} height={12} style={{backgroundColor: Colors[activeIndex]}}/>
                        <Typography
                            style={{fontWeight: 700}}>&emsp;{CustomerGroup(segmentData[0].segment)().name}</Typography>
                    </Box>
                    <Box py={2} pt={2.5}>
                        {segmentData[0].customers < 10000 ?
                            <RevenueDataNode title={`Total Customers`}
                                             value={<NumericLabel
                                                 params={paramOptions2}>{segmentData[0].customers}</NumericLabel>}
                                             percent={`${Math.round(segmentData[0].customers * 100 / data.totalCustomers)}%`}
                                             borderReq={false}/> :
                            <RevenueDataNode title={`Total Customers`}
                                             value={<NumericLabel
                                                 params={paramOptions1}>{segmentData[0].customers}</NumericLabel>}
                                             percent={`${Math.round(segmentData[0].customers * 100 / data.totalCustomers)}%`}
                                             borderReq={false}/>
                        }
                    </Box>
                    <Box py={2} pt={1.5}>
                        {segmentData[0].orders < 10000 ?
                            <RevenueDataNode title={`Total Orders`}
                                             value={<NumericLabel
                                                 params={paramOptions2}>{segmentData[0].orders}</NumericLabel>}
                                             percent={`${Math.round(segmentData[0].orders * 100 / data.totalOrders)}%`}
                                             borderReq={false}/> :
                            <RevenueDataNode title={`Total Orders`}
                                             value={<NumericLabel
                                                 params={paramOptions1}>{segmentData[0].orders}</NumericLabel>}
                                             percent={`${Math.round(segmentData[0].orders * 100 / data.totalOrders)}%`}
                                             borderReq={false}/>
                        }
                    </Box>
                    <Box py={2} pt={1.5}>
                        <RevenueDataNode title={`Total Revenue`}
                                         value={<NumericLabel
                                             params={paramOptions3}>{segmentData[0].revenue}</NumericLabel>}
                                         percent={`${Math.round(segmentData[0].revenue * 100 / data.totalRevenue)}%`}
                                         borderReq={false}/>
                    </Box>
                    {segmentData[0].avg_hvc_score?
                    <Box py={2} pt={1}>
                        <RevenueDataNode title={`Avg. HVC Score`}
                                         value={<NumericLabel>{Math.round(segmentData[0].avg_hvc_score)}</NumericLabel>}
                                         borderReq={false}/>
                    </Box>:null}
                </Grid>
            </Grid> : null
    )
}

function PieChartDia(props) {
    const {data, Colors, activeIndex, setActiveIndex} = props;
    const theme=useTheme();
    return (
        <ResponsiveContainer id={'tour5thStep'} style={{width:"100%"}} height={420}>
            <PieChart style={{width:"100%"}}>
                <Pie
                    isAnimationActive={false}
                    activeIndex={activeIndex}
                    activeShape={renderActiveShape}
                    data={data}
                    labelLine={false}
                    label={renderCustomizedLabel}
                    outerRadius={120}
                    fill="#8884d8"
                    onMouseEnter={(data, index) => setActiveIndex(index)}
                    onMouseLeave={(data, index) => setActiveIndex(0)}
                    dataKey="value"
                >
                    {
                        data.map((entry, index) => <Cell fill={Colors[index % Colors.length]} stroke={'none'} key={index}/>)
                    }
                </Pie>
                <Legend align={'left'} iconType={'square'} iconSize={12}  width={500} formatter={(value, entry, index) => {
                    return (
                            <Typography component={'span'} variant={'h5'} style={{
                                fontWeight:index===activeIndex?700:500,
                                color:theme.palette.primary.main,
                                fontFamily: '"Lato", sans-serif',
                                opacity: index === activeIndex ? 1 : 0.5
                            }}>{CustomerGroup(value)().name}</Typography>
                    )
                }}/>
            </PieChart>
        </ResponsiveContainer>
    )
};

function RevenuePieChart(props) {
    const {data, currency} = props;
    const graphData = _.map(_.filter(data.data, function (o) {
        return o.segment !== 'all'
    }), (d, index) => ({
        name: d.segment,
        value: d.revenue
    }))
    const [activeIndex, setActiveIndex] = React.useState(0);
    return (
        <Grid container >
            <Grid item xs={12}>
                <Box px={3} py={2.5} borderBottom={border}>
                    <Typography
                        variant={"h4"} style={{fontWeight:700}}>
                            Customer Groups by Revenue
                    </Typography>
                </Box>
                <Box pl={1.5}>
                    <Grid container>
                        <Grid item xs={12} sm={7}>
                            <PieChartDia data={graphData} Colors={Colors} activeIndex={activeIndex}
                                             setActiveIndex={setActiveIndex}/>
                        </Grid>
                        <Grid item xs={12} sm={5}>
                            <SelectedGroup currency={currency} data={data} Colors={Colors} activeIndex={activeIndex}
                                               setActiveIndex={setActiveIndex}/>
                        </Grid>
                        </Grid>
                </Box>
            </Grid>
        </Grid>
    )
};

export default RevenuePieChart