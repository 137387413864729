import {Typography} from "@mui/material";
import React from "react"
import {capitalCase} from "change-case"

export const notificationCategories = {'overall_order':1, 'overall_revenue':2, 'overall_customer':3, 'region_order':4, 'region_revenue':5, 'region_customer':6, 'device_order':7, 'device_revenue':8, 'device_customer':9, 'channel_order':10, 'channel_revenue':11, 'channel_customer':12}
export const CategoryHeader = (type) => ({
    'overall_order': () => {
        return {
            name: "Overall Orders",
        };
    },
    'overall_revenue': () => {
        return {
            name: "Overall Revenue",
        };
    },
    'overall_customer': () => {
        return {
            name: "Overall Customers",
        };
    },
    'region_order': () => {
        return {
            name: "Regional Orders",
        };
    },
    'region_revenue': () => {
        return {
            name: "Regional Revenue",
        };
    },
    'region_customer': () => {
        return {
            name: "Regional Customers",
        };
    },
    'device_order': () => {
        return {
            name: "Device Orders",
        };
    },
    'device_revenue': () => {
        return {
            name: "Device Revenue",
        };
    },
    'device_customer': () => {
        return {
            name: "Device Customers",
        };
    },
    'channel_order': () => {
        return {
            name: "Channel Orders",
        };
    },
    'channel_revenue': () => {
        return {
            name: "Channel Revenue",
        };
    },
    'channel_customer': () => {
        return {
            name: "Channel Customers",
        };
    }
})[type];
const NotificationStatement = (notification) => {
    let statement, helpLink, notifID;
    notifID = notification._id
    switch (notification.category) {
        case 'order':
            switch (notification.notif_name) {
                case 'overall_order':
                    if (notification.change > 0) {
                        statement = <Typography component={'span'} variant={'h5'} style={{color: '#27AE60'}}>
                            {Math.abs(notification.change)}% increase in orders in last {notification.period_days} days.
                        </Typography>
                    } else {
                        statement = <Typography component={'span'} variant={'h5'} style={{color: '#eb5757'}}>
                            {Math.abs(notification.change)}% decrease in orders in last {notification.period_days} days.
                        </Typography>
                    }
                    return {
                        statement: statement,
                        helpLink: helpLink,
                        notifId: notifID
                    };
                case 'region_order':
                    if (notification.change > 0) {
                        statement = <Typography component={'span'} variant={'h5'} style={{color: '#27AE60'}}>
                            {Math.abs(notification.change)}% increase in orders from {notification.region} in
                            last {notification.period_days} days.
                        </Typography>
                    } else {
                        statement = <Typography component={'span'} variant={'h5'} style={{color: '#eb5757'}}>
                            {Math.abs(notification.change)}% decrease in orders from {notification.region} in
                            last {notification.period_days} days.
                        </Typography>
                    }
                    return {
                        statement: statement,
                        helpLink: helpLink,
                        notifId: notifID
                    };
                case 'device_order':
                    if (notification.change > 0) {
                        statement = <Typography component={'span'} variant={'h5'} style={{color: '#27AE60'}}>
                            {Math.abs(notification.change)}% increase in orders from {capitalCase(notification.device)} in
                            last {notification.period_days} days.
                        </Typography>
                    } else {
                        statement = <Typography component={'span'} variant={'h5'} style={{color: '#eb5757'}}>
                            {Math.abs(notification.change)}% decrease in orders from {capitalCase(notification.device)} in
                            last {notification.period_days} days.
                        </Typography>
                    }
                    return {
                        statement: statement,
                        helpLink: helpLink,
                        notifId: notifID
                    };
                case 'channel_order':
                    if (notification.change > 0) {
                        statement = <Typography component={'span'} variant={'h5'} style={{color: '#27AE60'}}>
                            {Math.abs(notification.change)}% increase in orders from {notification.channel} in
                            last {notification.period_days} days.
                        </Typography>
                    } else {
                        statement = <Typography component={'span'} variant={'h5'} style={{color: '#eb5757'}}>
                            {Math.abs(notification.change)}% decrease in orders from {notification.channel} in
                            last {notification.period_days} days.
                        </Typography>
                    }
                    return {
                        statement: statement,
                        helpLink: helpLink,
                        notifId: notifID
                    };
                default:
                    return null
            }
        case 'customer':
            switch (notification.notif_name) {
                case 'overall_customer':
                    if (notification.change > 0) {
                        statement = <Typography component={'span'} variant={'h5'} style={{color: '#27AE60'}}>
                            {Math.abs(notification.change)}% increase in customers in last {notification.period_days} days.
                        </Typography>
                    } else {
                        statement = <Typography component={'span'} variant={'h5'} style={{color: '#eb5757'}}>
                            {Math.abs(notification.change)}% decrease in customers in last {notification.period_days} days.
                        </Typography>
                    }
                    return {
                        statement: statement,
                        helpLink: helpLink,
                        notifId: notifID
                    };
                case 'region_customer':
                    if (notification.change > 0) {
                        statement = <Typography component={'span'} variant={'h5'} style={{color: '#27AE60'}}>
                            {Math.abs(notification.change)}% increase in customers from {notification.region} in
                            last {notification.period_days} days.
                        </Typography>
                    } else {
                        statement = <Typography component={'span'} variant={'h5'} style={{color: '#eb5757'}}>
                            {Math.abs(notification.change)}% decrease in customers from {notification.region} in
                            last {notification.period_days} days.
                        </Typography>
                    }
                    return {
                        statement: statement,
                        helpLink: helpLink,
                        notifId: notifID
                    };
                case 'device_customer':
                    if (notification.change > 0) {
                        statement = <Typography component={'span'} variant={'h5'} style={{color: '#27AE60'}}>
                            {Math.abs(notification.change)}% increase in customers from {capitalCase(notification.device)} in
                            last {notification.period_days} days.
                        </Typography>
                    } else {
                        statement = <Typography component={'span'} variant={'h5'} style={{color: '#eb5757'}}>
                            {Math.abs(notification.change)}% decrease in customers from {capitalCase(notification.device)} in
                            last {notification.period_days} days.
                        </Typography>
                    }
                    return {
                        statement: statement,
                        helpLink: helpLink,
                        notifId: notifID
                    };
                case 'channel_customer':
                    if (notification.change > 0) {
                        statement = <Typography component={'span'} variant={'h5'} style={{color: '#27AE60'}}>
                            {Math.abs(notification.change)}% increase in customers from {notification.channel} in
                            last {notification.period_days} days.
                        </Typography>
                    } else {
                        statement = <Typography component={'span'} variant={'h5'} style={{color: '#eb5757'}}>
                            {Math.abs(notification.change)}% decrease in customers from {notification.channel} in
                            last {notification.period_days} days.
                        </Typography>
                    }
                    return {
                        statement: statement,
                        helpLink: helpLink,
                        notifId: notifID
                    };
                default:
                    return null
            }
        case 'revenue':
            switch (notification.notif_name) {
                case 'overall_revenue':
                    if (notification.change > 0) {
                        statement = <Typography component={'span'} variant={'h5'} style={{color: '#27AE60'}}>
                            {Math.abs(notification.change)}% increase in revenue in last {notification.period_days} days.
                        </Typography>
                    } else {
                        statement = <Typography component={'span'} variant={'h5'} style={{color: '#eb5757'}}>
                            {Math.abs(notification.change)}% decrease in revenue in last {notification.period_days} days.
                        </Typography>
                    }
                    return {
                        statement: statement,
                        helpLink: helpLink,
                        notifId: notifID
                    };
                case 'region_revenue':
                    if (notification.change > 0) {
                        statement = <Typography component={'span'} variant={'h5'} style={{color: '#27AE60'}}>
                            {Math.abs(notification.change)}% increase in revenue from {notification.region} in
                            last {notification.period_days} days.
                        </Typography>
                    } else {
                        statement = <Typography component={'span'} variant={'h5'} style={{color: '#eb5757'}}>
                            {Math.abs(notification.change)}% decrease in revenue from {notification.region} in
                            last {notification.period_days} days.
                        </Typography>
                    }
                    return {
                        statement: statement,
                        helpLink: helpLink,
                        notifId: notifID
                    };
                case 'device_revenue':
                    if (notification.change > 0) {
                        statement = <Typography component={'span'} variant={'h5'} style={{color: '#27AE60'}}>
                            {Math.abs(notification.change)}% increase in revenue from {capitalCase(notification.device)} in
                            last {notification.period_days} days.
                        </Typography>
                    } else {
                        statement = <Typography component={'span'} variant={'h5'} style={{color: '#eb5757'}}>
                            {Math.abs(notification.change)}% decrease in revenue from {capitalCase(notification.device)} in
                            last {notification.period_days} days.
                        </Typography>
                    }
                    return {
                        statement: statement,
                        helpLink: helpLink,
                        notifId: notifID
                    };
                case 'channel_revenue':
                    if (notification.change > 0) {
                        statement = <Typography component={'span'} variant={'h5'} style={{color: '#27AE60'}}>
                            {Math.abs(notification.change)}% increase in revenue from {notification.channel} in
                            last {notification.period_days} days.
                        </Typography>
                    } else {
                        statement = <Typography component={'span'} variant={'h5'} style={{color: '#eb5757'}}>
                            {Math.abs(notification.change)}% decrease in revenue from {notification.channel} in
                            last {notification.period_days} days.
                        </Typography>
                    }
                    return {
                        statement: statement,
                        helpLink: helpLink,
                        notifId: notifID
                    };
                default:
                    return null
            }
        default:
            return null
    }
}

export default NotificationStatement;