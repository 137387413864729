import makeStyles from '@mui/styles/makeStyles';
import {border} from "./commonStyles";

const ProductAnalyticsPageStyles = makeStyles((theme) => ({
    headerStyles: {
        backgroundColor: theme.palette.backgroundColors.overview,
        paddingTop: 10,
        // paddingBottom: 8,
        paddingLeft: 16,
        borderBottom: border,
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar,
    },
    afterLoginContent: {
        flexGrow: 1,
        marginLeft: theme.spacing(7),
        [theme.breakpoints.up(600)]: {
            marginLeft: theme.spacing(13),
            paddingLeft: 0,
        },
        color: theme.palette.primary.main,
        paddingLeft: theme.spacing(2),
        backgroundColor: theme.palette.common.white,
    },
    beforeLoginContent: {
    flexGrow: 1,
    backgroundColor: theme.palette.common.white,
  },
  scrollbar: {
    "&::-webkit-scrollbar": {
      width: "0.2em",
      height: "0.4em",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
      webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.1)",
      borderRadius: "6px",
    },
  },
  header: {
    position: "sticky",
    top: 60,
    zIndex: 9,
  },
  seasonHeader:{
    position: 'sticky',
    top: 119,
    backgroundColor:theme.palette.common.white,
    zIndex: 9,
  }
}))

export default ProductAnalyticsPageStyles