import React from "react"
import {
  Box,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import {makeStyles, withStyles} from '@mui/styles';
import {border} from "../../assets/jss/commonStyles";
import {capitalCase} from 'change-case'
import NumericLabel from 'react-pretty-numbers';
import Icons from "../icons"
import {connect} from "react-redux";
import * as _ from "lodash"
import {SimpleButton} from "../buttons";
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import CustomerGroup from "../getGroupDetails";
import {FirstPage, KeyboardArrowLeft, KeyboardArrowRight, LastPage} from "@mui/icons-material";
import {GAEventTracker} from "../../index";
import moment from "moment"
import {clearGPTContent, clearGPTMoreContent, generate_content, ReportDownloaded} from "../../actions/actions";
import GroupPageStyles from "../../assets/jss/groupPageStyles";
import {CustomPagination, CustomSelect} from "../customComponents/customTable";
import GPTSidebar from "../productAnalytics/GPTSidebar"

const useStyles = makeStyles((theme) => ({
    paginationRoot: {
        flexShrink: 0,
        marginLeft: theme.spacing(2.5)
    },
}));

const CustomTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        fontSize: 11,
    },
    arrow: {
        color: theme.palette.primary.main,
    }
}))(Tooltip);
const CustomIconButton = withStyles(theme => ({
    root: {
        fontSize: 16,
    }
}))(IconButton);
const CustomTableCell = withStyles(theme => ({
    root: {
        fontSize: 14,
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        paddingTop: 8,
        paddingBottom: 8,
        paddingLeft: 4,
        paddingRight: 4,
        minWidth: 100
    }
}))(TableCell);
const CustomTableCell1 = withStyles(theme => ({
    root: {
        fontSize: 14,
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        paddingTop: 8,
        paddingBottom: 8,
        paddingLeft: 24,
        paddingRight: 4,
        minWidth: 80,
    }
}))(TableCell);

const CustomTableCell2 = withStyles(theme => ({
    root: {
        fontSize: 14,
        paddingTop: 8,
        paddingBottom: 8,
        paddingLeft: 4,
        paddingRight: 4,
        minWidth: 100,
        height:"40px"
    }
}))(TableCell);
const CustomTableCell3 = withStyles(theme => ({
    root: {
        fontSize: 14,
        paddingTop: 8,
        paddingBottom: 8,
        paddingLeft: 24,
        paddingRight: 4,
        minWidth: 80,
    }
}))(TableCell);

const CustomTableSortLabel = withStyles(theme => ({
    root: {
        '&:hover': {
            color: theme.palette.common.white
        },
        '&$active, &$active:hover': {
            color: theme.palette.common.white
        },
    },
    active:{

    },
    icon: {
        color: `${theme.palette.common.white} !important`
    }
}))(TableSortLabel);

export function TablePaginationActions(props) {
    const classes = useStyles();
    const {count, page, rowsPerPage, onChangePage} = props;

    const handleFirstPageButtonClick = (event) => {
        onChangePage(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onChangePage(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onChangePage(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
        window.scrollTo(0, 1007)
    };

    return (
        <div className={classes.paginationRoot}>
            <CustomIconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                <FirstPage/>
            </CustomIconButton>
            <CustomIconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
                <KeyboardArrowLeft/>
            </CustomIconButton>
            <CustomIconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                <KeyboardArrowRight/>
            </CustomIconButton>
            <CustomIconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                <LastPage/>
            </CustomIconButton>
        </div>
    );
}

const useStyles2 = makeStyles((theme) => ({
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    }
}));

function ProductTable(props) {
    const {selectedSeg, data, compareData, property, application_states, ReportDownloaded,generate_content,clearGPTContent} = props;
    const currency = application_states.currency;
    const currencySymbol = application_states.currencySymbol;
    const [anchorEl, setAnchorEl] = React.useState(null); 
    const [selectedRow, setSelectedRow] = React.useState(null); 
    const [gptSidebar, setGPTSidebar] = React.useState(false); 
    const [selProduct, setSelProduct] = React.useState({}); 
    const [value, setValue] = React.useState(''); 
    const [displayId, setDisplayId] = React.useState(``);
    const generateContentCount=3;

    const handleGPTDisplay = async (index) => {
        await setDisplayId(`edit${index}`);
    };
    const removeGPTDisplay = async () => {
        await setDisplayId(``);
    };

    const handleGPTClick = (newValue) => {
        GAEventTracker(newValue, 'Select GPT Channel', 'GPT Channel');
        setValue(newValue);
        handleGPTSidebar(newValue);
    };

    const handleMenuOpen = (event, index,product) => {
      GAEventTracker('GPTButton', 'Group')
      setAnchorEl(event.currentTarget);
      setSelProduct(product)
      setSelectedRow(index);
    };

    const handleMenuClose = () => {
      setAnchorEl(null);
      setSelectedRow(null);
    };

    const handleGPTSidebar = (newValue) => {
      removeGPTDisplay();
      handleMenuClose();
      setGPTSidebar(true);
      handleSubmit(newValue)
    };
    const handleSubmit = (newValue) => {
        if(newValue==='meta-description' || newValue==='gads-description'){
            const data={
                'product_name':selProduct.product,
                'channel':newValue==='meta-description'?'meta-ads':'google-ads',
                'count':generateContentCount
            }
            generate_content(data,'description')
        }else{
            const data={
                'product_name':selProduct.product,
                'channel':newValue==='gads-headline'?'google-ads':'meta-ads',
                'count':generateContentCount
            }
            generate_content(data,'headlines')
        }
      };

    const handleCloseGPTSidebar = () => {
      setGPTSidebar(false);
      clearGPTContent();
      clearGPTMoreContent()
      setSelProduct('');
      setGenerateMore(false)
    };
    const orderParams = {
        commafy: true,
        wholenumber: 'ceil',
        shortFormatMinValue: 10000,
        justification: 'L',
    };
    const revenueParams = {
        'wholenumber': 'ceil',
        'locales': 'en-US',
        shortFormatMinValue: 1000,
        // precision: 1,
        shortFormat: true,
        currency: true,
        currencyIndicator: currency,
        'commafy': true,
        justification: 'L',
    };
    const priceParams = {
        'locales': 'en-US',
        shortFormatMinValue: 10000,
        shortFormat: true,
        currency: true,
        currencyIndicator: currency,
        'commafy': true,
        justification: 'L',
    };
    const theme = useTheme();
    const classes = useStyles2();
    const styles = GroupPageStyles();
    const monthCompare = application_states.monthCompare;
    let finalTableData, productData, compareProductData;
    productData = _.map(_.orderBy(data.orderedProducts, [property], ['desc']), (d, i) => ({
        rank: i + 1,
        sku: d.sku,
        product: d.product,
        customers: d.customers,
        orders: d.orders,
        quantity: d.quantity,
        revenue: d.revenue,
        price: d.price,
    }));
    compareProductData = _.map(_.orderBy(compareData.orderedProducts, [property], ['desc']), (d, i) => ({
        compareRank: i + 1,
        product: d.product,
        compareQuantity: d.quantity,
        compareOrders: d.orders,
        sku: d.sku,
        compareRevenue: d.revenue,
        compareCustomers: d.customers,
    }));
    finalTableData = !monthCompare ? productData : _.map(productData, (d) => {
        return _.assign(d, _.find(compareProductData, {sku: d.sku}))
    });
    finalTableData = _.filter(finalTableData, function (o) {
        return o.price !== 0;
    });
    if (monthCompare) {
        finalTableData.forEach(t => {
            // t.compareRank ? t.compareRank = t.compareRank : t.compareRank = productData.length;
            // t.compareOrders ? t.compareOrders = t.compareOrders : t.compareOrders = 0;
            // t.compareCustomers ? t.compareCustomers = t.compareCustomers : t.compareCustomers = 0;
            // t.compareRevenue ? t.compareRevenue = t.compareRevenue : t.compareRevenue = 0;
            // t.compareQuantity ? t.compareQuantity = t.compareQuantity : t.compareQuantity = 0
            t.compareRank = t.compareRank ? t.compareRank :productData.length;
            t.compareOrders = t.compareOrders ?t.compareOrders : 0;
            t.compareCustomers = t.compareCustomers ? t.compareCustomers : 0;
            t.compareRevenue = t.compareRevenue ? t.compareRevenue : 0;
            t.compareQuantity = t.compareQuantity ? t.compareQuantity : 0;
        })
    }
    const [order, setOrder] = React.useState('asc');
    const [generateMore, setGenerateMore] = React.useState();
    const [orderBy, setOrderBy] = React.useState('rank');
    const [page, setPage] = React.useState(1);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    let productCount = finalTableData.length;
    const noOfPages = Math.ceil(productCount / rowsPerPage);
    const indexOfLastPost = page * rowsPerPage;
    const indexOfFirstPost = indexOfLastPost - rowsPerPage + 1;
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = event => {
        setRowsPerPage(parseInt(event.target.value, 10));
        if (rowsPerPage !== event.target.value) {
          setPage(1);
        }
    };
    const revenueShareCalc = (rev, totalRev) => {
        return (parseFloat(Math.round(rev * 100 * 100 / totalRev) / 100).toFixed(1))
    };

    function descendingComparator(a, b, orderBy) {
        if (orderBy === 'rank' || orderBy === 'price' || orderBy === 'sku' || orderBy === 'product' || orderBy === 'customers' || orderBy === 'orders' || orderBy === 'quantity' || orderBy === 'revenue') {
            if (b[orderBy] < a[orderBy]) {
                return -1;
            }
            if (b[orderBy] > a[orderBy]) {
                return 1;
            }
            return 0;
        } else if (orderBy === 'rankChange') {
            if ((b['compareRank'] - b['rank']) < (a['compareRank'] - a['rank'])) {
                return -1;
            }
            if ((b['compareRank'] - b['rank']) > (a['compareRank'] - a['rank'])) {
                return 1;
            }
            if ((b['compareRank'] - b['rank']) === (a['compareRank'] - a['rank'])) {
                if (b['rank'] > a['rank']) {
                    return -1;
                }
                if (b['rank'] < a['rank']) {
                    return 1;
                }
                return 0;
            }
            return 0
        } else if (orderBy === 'customerChange') {
            let b_change = b['compareCustomers'] > 0 ? ((b['customers'] - b['compareCustomers']) / b['compareCustomers']) : 0;
            let a_change = a['compareCustomers'] > 0 ? ((a['customers'] - a['compareCustomers']) / a['compareCustomers']) : 0;
            if (b_change < a_change) {
                return -1;
            }
            if (b_change > a_change) {
                return 1;
            }
            return 0
        } else if (orderBy === 'orderChange') {
            let b_change = b['compareOrders'] > 0 ? ((b['orders'] - b['compareOrders']) / b['compareOrders']) : 0;
            let a_change = a['compareOrders'] > 0 ? ((a['orders'] - a['compareOrders']) / a['compareOrders']) : 0;
            if (b_change < a_change) {
                return -1;
            }
            if (b_change > a_change) {
                return 1;
            }
            return 0
        } else if (orderBy === 'quantityChange') {
            let b_change = b['compareQuantity'] > 0 ? ((b['quantity'] - b['compareQuantity']) / b['compareQuantity']) : 0;
            let a_change = a['compareQuantity'] > 0 ? ((a['quantity'] - a['compareQuantity']) / a['compareQuantity']) : 0;
            if (b_change < a_change) {
                return -1;
            }
            if (b_change > a_change) {
                return 1;
            }
            return 0
        } else if (orderBy === 'revenueChange') {
            let b_change = b['compareRevenue'] > 0 ? ((b['revenue'] - b['compareRevenue']) / b['compareRevenue']) : 0;
            let a_change = a['compareRevenue'] > 0 ? ((a['revenue'] - a['compareRevenue']) / a['compareRevenue']) : 0;
            if (b_change < a_change) {
                return -1;
            }
            if (b_change > a_change) {
                return 1;
            }
            return 0
        } else if (orderBy === 'revenueShare') {
            if (revenueShareCalc(b['revenue'], data.totalRevenue) < revenueShareCalc(a['revenue'], data.totalRevenue)) {
                return -1;
            }
            if (revenueShareCalc(b['revenue'], data.totalRevenue) > revenueShareCalc(a['revenue'], data.totalRevenue)) {
                return 1;
            }
            return 0;
        } else if (orderBy === 'revenueShareChange') {
            if (((revenueShareCalc(b['revenue'], data.totalRevenue) - revenueShareCalc(b['compareRevenue'], compareData.totalRevenue)) / revenueShareCalc(b['compareRevenue'], compareData.totalRevenue)) < ((revenueShareCalc(a['revenue'], data.totalRevenue) - revenueShareCalc(a['compareRevenue'], compareData.totalRevenue)) / revenueShareCalc(a['compareRevenue'], compareData.totalRevenue))) {
                return -1;
            }
            if (((revenueShareCalc(b['revenue'], data.totalRevenue) - revenueShareCalc(b['compareRevenue'], compareData.totalRevenue)) / revenueShareCalc(b['compareRevenue'], compareData.totalRevenue)) > ((revenueShareCalc(a['revenue'], data.totalRevenue) - revenueShareCalc(a['compareRevenue'], compareData.totalRevenue)) / revenueShareCalc(a['compareRevenue'], compareData.totalRevenue))) {
                return 1;
            }
            return 0
        }
    }

    function getComparator(order, orderBy) {
        return order === 'desc'
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    }

    function stableSort(array, comparator) {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);
    }

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };
    const createSortHandler = (property) => (event) => {
        handleRequestSort(event, property);
    };
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    const downloadFile = selectedSeg === 'all' ? 'products_all' : selectedSeg === 'a' ? 'products_hvc' : selectedSeg === 'b' ? 'products_mvc' : 'products_lvc';
    let downloadData;
    downloadData = !monthCompare ? _.map(finalTableData, (d) => ({
            sku: d.sku,
            product: d.product,
            price: d.price,
            customers: d.customers,
            revenue: d.revenue,
            quantity: d.quantity,
            orders: d.orders,
        }))
        :
        _.map(finalTableData, (d) => ({
            sku: d.sku,
            product: d.product,
            price: d.price,
            customers: d.customers,
            compareCustomers: d.compareCustomers ? d.compareCustomers : 0,
            revenue: d.revenue,
            compareRevenue: d.compareRevenue ? d.compareRevenue : 0,
            quantity: d.quantity,
            compareQuantity: d.compareQuantity ? d.compareQuantity : 0,
            orders: d.orders,
            compareOrders: d.compareOrders ? d.compareOrders : 0,
        }));
    const exportToCSV = (csvData, fileName) => {
        const ws = XLSX.utils.json_to_sheet([]);
        XLSX.utils.sheet_add_json(ws, csvData, {origin: 'A5'});
        const wb = {Sheets: {'products': ws}, SheetNames: ['products']};
        if (!monthCompare) {
            XLSX.utils.sheet_add_aoa(ws, [['Customer Group', CustomerGroup(data.segment)().name, 'Selected Period', moment(data.start, 'DD-MM-yyyy').format('MM/DD/yyyy') + ' to ' + moment(data.end, 'DD-MM-yyyy').format('MM/DD/yyyy')]], {origin: "A2"});
        } else {
            XLSX.utils.sheet_add_aoa(ws, [['Customer Group', CustomerGroup(data.segment)().name, 'Selected Period', moment(data.start, 'DD-MM-yyyy').format('MM/DD/yyyy') + ' to ' + moment(data.end, 'DD-MM-yyyy').format('MM/DD/yyyy'), 'Compared Period', moment(compareData.start, 'DD-MM-yyyy').format('MM/DD/yyyy') + ' to ' + moment(compareData.end, 'DD-MM-yyyy').format('MM/DD/yyyy')]], {origin: "A2"});
        }
        let first_sheet_name = wb.SheetNames[0];
        let worksheet = wb.Sheets[first_sheet_name];
        if (!monthCompare) {
            worksheet['A5'].v = 'Sku';
            worksheet['B5'].v = 'Product Name';
            worksheet['C5'].v = 'Price';
            worksheet['D5'].v = 'Customers';
            worksheet['E5'].v = `Revenue (${currencySymbol})`;
            worksheet['F5'].v = 'Quantity';
            worksheet['G5'].v = 'Orders';
        } else {
            worksheet['A5'].v = 'Sku';
            worksheet['B5'].v = 'Product Name';
            worksheet['C5'].v = 'Price';
            worksheet['D5'].v = 'Customers (Selected Period)';
            worksheet['E5'].v = 'Customers (Compared Period)';
            worksheet['F5'].v = `Revenue (${currencySymbol}) (Selected Period)`;
            worksheet['G5'].v = `Revenue (${currencySymbol}) (Compared Period)`;
            worksheet['H5'].v = 'Quantity (Selected Period)';
            worksheet['I5'].v = 'Quantity (Compared Period)';
            worksheet['J5'].v = 'Orders (Selected Period)';
            worksheet['K5'].v = 'Orders (Compared Period)';
        }
        const excelBuffer = XLSX.write(wb, {bookType: 'xlsx', type: 'array'});
        const tableData = new Blob([excelBuffer], {type: fileType});
        FileSaver.saveAs(tableData, fileName + fileExtension);
    };
    return (
        <Box>
            <Box p={2} borderBottom={border}>
                <Typography variant={"h4"} style={{color: theme.palette.primary.main, fontWeight: 700}}>
                    Products by {capitalCase(property)}
                    <CustomTooltip title={'Download'} arrow interactive="true">
                        <Box component={'span'} onClick={() => {
                            exportToCSV(downloadData, downloadFile);
                            GAEventTracker(CustomerGroup(selectedSeg)().name, 'Download', 'Product List');
                            ReportDownloaded(downloadFile, 'Products List', selectedSeg)

                        }}>
                            <SimpleButton style={{margin: 0}}>{Icons.downloadIcon}</SimpleButton>
                        </Box>
                    </CustomTooltip>
                </Typography>
            </Box>
            {finalTableData.length > 0 ?
                <Box pt={2}>
                    <TableContainer  className={styles.scrollbar}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <CustomTableCell1 key={'rank'}
                                                      sortDirection={orderBy === 'rank' ? order : false}>
                                        <CustomTableSortLabel
                                            active={orderBy === 'rank'}
                                            direction={orderBy === 'rank' ? order : 'asc'}
                                            onClick={createSortHandler('rank')}
                                        >
                                            Rank
                                            {orderBy === 'rank' ? (
                                                <span className={classes.visuallyHidden}>
                                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                        </span>
                                            ) : null}
                                        </CustomTableSortLabel>
                                    </CustomTableCell1>
                                    {monthCompare ?
                                        <CustomTableCell key={'rankChange'}
                                                         sortDirection={orderBy === 'rankChange' ? order : false}>
                                            <CustomTableSortLabel
                                                active={orderBy === 'rankChange'}
                                                direction={orderBy === 'rankChange' ? order : 'asc'}
                                                onClick={createSortHandler('rankChange')}
                                            >
                                                Change in<br/>Rank
                                                {orderBy === 'rankChange' ? (
                                                    <span className={classes.visuallyHidden}>
                                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                        </span>
                                                ) : null}
                                            </CustomTableSortLabel>
                                        </CustomTableCell>
                                        :
                                        null
                                    }
                                    <CustomTableCell key={'sku'} sortDirection={orderBy === 'sku' ? order : false}>
                                        <CustomTableSortLabel
                                            active={orderBy === 'sku'}
                                            direction={orderBy === 'sku' ? order : 'asc'}
                                            onClick={createSortHandler('sku')}
                                        >
                                            SKU
                                            {orderBy === 'sku' ? (
                                                <span className={classes.visuallyHidden}>
                                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                        </span>
                                            ) : null}
                                        </CustomTableSortLabel>
                                    </CustomTableCell>
                                    <CustomTableCell key={'product'}
                                                     sortDirection={orderBy === 'product' ? order : false}
                                                     style={{maxWidth: monthCompare ? 290 : 320}}>
                                        <CustomTableSortLabel
                                            active={orderBy === 'product'}
                                            direction={orderBy === 'product' ? order : 'asc'}
                                            onClick={createSortHandler('product')}
                                        >
                                            Product Name
                                            {orderBy === 'product' ? (
                                                <span className={classes.visuallyHidden}>
                                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                        </span>
                                            ) : null}
                                        </CustomTableSortLabel>
                                    </CustomTableCell>
                                    <CustomTableCell></CustomTableCell>
                                    <CustomTableCell key={'price'} sortDirection={orderBy === 'price' ? order : false}>
                                        <CustomTableSortLabel
                                            active={orderBy === 'price'}
                                            direction={orderBy === 'price' ? order : 'asc'}
                                            onClick={createSortHandler('price')}
                                        >
                                            Price
                                            {orderBy === 'price' ? (
                                                <span className={classes.visuallyHidden}>
                                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                        </span>
                                            ) : null}
                                        </CustomTableSortLabel>
                                    </CustomTableCell>
                                    <CustomTableCell key={'customers'}
                                                     sortDirection={orderBy === 'customers' ? order : false}>
                                        <CustomTableSortLabel
                                            active={orderBy === 'customers'}
                                            direction={orderBy === 'customers' ? order : 'asc'}
                                            onClick={createSortHandler('customers')}
                                        >
                                            Customer(s)
                                            {orderBy === 'customers' ? (
                                                <span className={classes.visuallyHidden}>
                                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                        </span>
                                            ) : null}
                                        </CustomTableSortLabel>
                                    </CustomTableCell>
                                    {monthCompare && property === 'revenue' ?
                                        <CustomTableCell key={'customerChange'}
                                                         sortDirection={orderBy === 'customerChange' ? order : false}>
                                            <CustomTableSortLabel
                                                active={orderBy === 'customerChange'}
                                                direction={orderBy === 'customerChange' ? order : 'asc'}
                                                onClick={createSortHandler('customerChange')}
                                            >
                                                {/*&nbsp;%*/}
                                                Change in<br/>Customers
                                                {orderBy === 'customerChange' ? (
                                                    <span className={classes.visuallyHidden}>
                                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                        </span>
                                                ) : null}
                                            </CustomTableSortLabel>
                                        </CustomTableCell>
                                        :
                                        null
                                    }
                                    <CustomTableCell key={'revenue'}
                                                     sortDirection={orderBy === 'revenue' ? order : false}>
                                        <CustomTableSortLabel
                                            active={orderBy === 'revenue'}
                                            direction={orderBy === 'revenue' ? order : 'asc'}
                                            onClick={createSortHandler('revenue')}
                                        >
                                            Revenue
                                            {orderBy === 'revenue' ? (
                                                <span className={classes.visuallyHidden}>
                                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                        </span>
                                            ) : null}
                                        </CustomTableSortLabel>
                                    </CustomTableCell>
                                    {monthCompare && property === 'revenue' ?
                                        <CustomTableCell key={'revenueChange'}
                                                         sortDirection={orderBy === 'revenueChange' ? order : false}>
                                            <CustomTableSortLabel
                                                active={orderBy === 'revenueChange'}
                                                direction={orderBy === 'revenueChange' ? order : 'asc'}
                                                onClick={createSortHandler('revenueChange')}
                                            >
                                                Change in<br/>Revenue
                                                {orderBy === 'revenueChange' ? (
                                                    <span className={classes.visuallyHidden}>
                                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                        </span>
                                                ) : null}
                                            </CustomTableSortLabel>
                                        </CustomTableCell>
                                        :
                                        null
                                    }
                                    <CustomTableCell key={'quantity'}
                                                     sortDirection={orderBy === 'quantity' ? order : false}>
                                        <CustomTableSortLabel
                                            active={orderBy === 'quantity'}
                                            direction={orderBy === 'quantity' ? order : 'asc'}
                                            onClick={createSortHandler('quantity')}
                                        >
                                            Quantity
                                            {orderBy === 'quantity' ? (
                                                <span className={classes.visuallyHidden}>
                                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                        </span>
                                            ) : null}
                                        </CustomTableSortLabel>
                                    </CustomTableCell>
                                    {monthCompare && property === 'revenue' ?
                                        <CustomTableCell key={'quantityChange'}
                                                         sortDirection={orderBy === 'quantityChange' ? order : false}>
                                            <CustomTableSortLabel
                                                active={orderBy === 'quantityChange'}
                                                direction={orderBy === 'quantityChange' ? order : 'asc'}
                                                onClick={createSortHandler('quantityChange')}
                                            >
                                                Change in<br/>Quantity
                                                {orderBy === 'quantityChange' ? (
                                                    <span className={classes.visuallyHidden}>
                                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                        </span>
                                                ) : null}
                                            </CustomTableSortLabel>
                                        </CustomTableCell>
                                        :
                                        null
                                    }
                                    <CustomTableCell key={'orders'}
                                                     sortDirection={orderBy === 'orders' ? order : false}>
                                        <CustomTableSortLabel
                                            active={orderBy === 'orders'}
                                            direction={orderBy === 'orders' ? order : 'asc'}
                                            onClick={createSortHandler('orders')}
                                        >
                                            Order(s)
                                            {orderBy === 'orders' ? (
                                                <span className={classes.visuallyHidden}>
                                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                        </span>
                                            ) : null}
                                        </CustomTableSortLabel>
                                    </CustomTableCell>
                                    {monthCompare && property === 'revenue' ?
                                        <CustomTableCell key={'orderChange'}
                                                         sortDirection={orderBy === 'orderChange' ? order : false}>
                                            <CustomTableSortLabel
                                                active={orderBy === 'orderChange'}
                                                direction={orderBy === 'orderChange' ? order : 'asc'}
                                                onClick={createSortHandler('orderChange')}
                                            >
                                                Change in<br/>Orders
                                                {orderBy === 'orderChange' ? (
                                                    <span className={classes.visuallyHidden}>
                                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                        </span>
                                                ) : null}
                                            </CustomTableSortLabel>
                                        </CustomTableCell>
                                        :
                                        null
                                    }
                                    <CustomTableCell key={'revenueShare'}
                                                     sortDirection={orderBy === 'revenueShare' ? order : false}>
                                        <CustomTableSortLabel
                                            active={orderBy === 'revenueShare'}
                                            direction={orderBy === 'revenueShare' ? order : 'asc'}
                                            onClick={createSortHandler('revenueShare')}
                                        >
                                            Revenue Share
                                            {orderBy === 'revenueShare' ? (
                                                <span className={classes.visuallyHidden}>
                                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                        </span>
                                            ) : null}
                                        </CustomTableSortLabel>
                                    </CustomTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {stableSort(finalTableData, getComparator(order, orderBy))
                                    .slice((page - 1) * rowsPerPage, page * rowsPerPage)
                                    .map((product, index) =>
                                        <TableRow key={index} 
                                            onMouseEnter={(e) => handleGPTDisplay(index)}
                                            onMouseLeave={(e) => removeGPTDisplay()} 
                                            >
                                            <CustomTableCell3>
                                                {product.rank < 10 ? <>0{product.rank}</> : product.rank}
                                            </CustomTableCell3>
                                            {monthCompare ?
                                                <CustomTableCell2>
                                                    {product.compareRank ?
                                                        product.rank < product.compareRank ?
                                                            <span style={{color: '#27AE60'}}>
                                                        &nbsp;{Math.abs(product.rank - product.compareRank)}&nbsp;
                                                                {Icons.greenLineArrow}
                                                    </span>
                                                            :
                                                            product.rank > product.compareRank ?
                                                                <span style={{color: '#eb5757'}}>
                                                            &nbsp;{Math.abs(product.rank - product.compareRank)}&nbsp;
                                                                    {Icons.redLineArrow}
                                                        </span>
                                                                :
                                                                <span style={{
                                                                    color: theme.palette.primary.main,
                                                                    opacity: 0.5
                                                                }}>&ensp;-</span>
                                                        :
                                                        <span style={{
                                                            color: theme.palette.primary.main,
                                                            opacity: 0.5
                                                        }}>&ensp;-</span>
                                                    }
                                                </CustomTableCell2>
                                                :
                                                null
                                            }
                                            <CustomTableCell2>
                                                {product.sku}
                                            </CustomTableCell2>
                                            <CustomTableCell2
                                            style={!monthCompare ? {
                                                maxWidth: 320,
                                                wordBreak: "break-all"
                                            } : {minWidth: 320, wordBreak: "break-all"}}>
                                                {product.product}
                                            </CustomTableCell2>
                                            <CustomTableCell2>
                                            {!product.product?null:
                                            <IconButton onClick={(event) => handleMenuOpen(event, index,product)} 
                                                style={{
                                                            display:
                                                                `edit${index}` === displayId
                                                                    ? "block"
                                                                    : "none",
                                                            cursor: "pointer",
                                                        }}
                                                sx={{":disabled":{opacity:0.4}}}
                                                    >
                                                    {Icons.chatgptIcon}
                                            </IconButton>}
                                                <Menu
                                                    anchorEl={anchorEl}
                                                    open={Boolean(anchorEl) && selectedRow === index}
                                                    onClose={handleMenuClose}
                                                >
                                                    <MenuItem onClick={()=>{handleGPTClick('gads-headline',product)}}>Generate Google Ads Headlines</MenuItem>
                                                    <MenuItem onClick={()=>{handleGPTClick('meta-headline',product)}}>Generate Meta Headlines</MenuItem>
                                                    <MenuItem onClick={()=>{handleGPTClick('gads-description',product)}}>Generate Google Ads Description</MenuItem>
                                                    <MenuItem onClick={()=>{handleGPTClick('meta-description',product)}}>Generate Meta Ads Description</MenuItem>
                                                </Menu>
                                            <GPTSidebar open={gptSidebar} handleCancel={handleCloseGPTSidebar} product={selProduct} value={value} generateMore={generateMore} setGenerateMore={setGenerateMore} count={generateContentCount} />
                                            </CustomTableCell2>
                                            <CustomTableCell2>
                                                <NumericLabel params={priceParams}>
                                                    {product.price ? product.price : 0}
                                                </NumericLabel>
                                            </CustomTableCell2>
                                            <CustomTableCell2>
                                                {monthCompare && property === 'revenue' ?
                                                    <span style={{opacity: 0.5}}>
                                                <NumericLabel params={orderParams}>
                                                    {product.compareCustomers ? product.compareCustomers : 0}
                                                </NumericLabel>
                                                        &nbsp;|&nbsp;
                                            </span>
                                                    :
                                                    null
                                                }
                                                <NumericLabel params={orderParams}>
                                                    {product.customers ? product.customers : 0}
                                                </NumericLabel>
                                            </CustomTableCell2>
                                            {monthCompare && property === 'revenue' ?
                                                <CustomTableCell2>
                                                    {product.compareCustomers ?
                                                        product.customers > product.compareCustomers ?
                                                            <span style={{color: '#27AE60'}}>
                                                    &nbsp;{Math.abs(Math.round((product.customers - product.compareCustomers) * 100 / product.compareCustomers))}%&nbsp;
                                                                {Icons.greenLineArrow}
                                                </span>
                                                            :
                                                            product.customers < product.compareCustomers ?
                                                                <span style={{color: '#eb5757'}}>
                                                        &nbsp;{Math.abs(Math.round((product.customers - product.compareCustomers) * 100 / product.compareCustomers))}%&nbsp;
                                                                    {Icons.redLineArrow}
                                                    </span>
                                                                :
                                                                <span style={{
                                                                    color: theme.palette.primary.main,
                                                                    opacity: 0.5
                                                                }}>&ensp;-</span>
                                                        :
                                                        <span style={{
                                                            color: theme.palette.primary.main,
                                                            opacity: 0.5
                                                        }}>&ensp;-</span>
                                                    }
                                                </CustomTableCell2>
                                                :
                                                null
                                            }
                                            <CustomTableCell2>
                                                {monthCompare && property === 'revenue' ?
                                                    <span style={{opacity: 0.5}}>
                                                <NumericLabel params={revenueParams}>
                                                    {product.compareRevenue ? product.compareRevenue : 0}
                                                </NumericLabel>
                                                        &nbsp;|&nbsp;
                                            </span>
                                                    :
                                                    null
                                                }
                                                <NumericLabel params={revenueParams}>
                                                    {product.revenue ? product.revenue : 0}
                                                </NumericLabel>
                                            </CustomTableCell2>
                                            {monthCompare && property === 'revenue' ?
                                                <CustomTableCell2>
                                                    {product.compareRevenue ?
                                                        product.revenue > product.compareRevenue ?
                                                            <span style={{color: '#27AE60'}}>
                                                    &nbsp;{Math.abs(Math.round((product.revenue - product.compareRevenue) * 100 / product.compareRevenue))}%&nbsp;
                                                                {Icons.greenLineArrow}
                                                </span>
                                                            :
                                                            product.revenue < product.compareRevenue ?
                                                                <span style={{color: '#eb5757'}}>
                                                        &nbsp;{Math.abs(Math.round((product.revenue - product.compareRevenue) * 100 / product.compareRevenue))}%&nbsp;
                                                                    {Icons.redLineArrow}
                                                    </span>
                                                                :
                                                                <span style={{
                                                                    color: theme.palette.primary.main,
                                                                    opacity: 0.5
                                                                }}>&ensp;-</span>
                                                        :
                                                        <span style={{
                                                            color: theme.palette.primary.main,
                                                            opacity: 0.5
                                                        }}>&ensp;-</span>
                                                    }
                                                </CustomTableCell2>
                                                :
                                                null
                                            }
                                            <CustomTableCell2>
                                                {monthCompare && property === 'revenue' ?
                                                    <span style={{opacity: 0.5}}>
                                                <NumericLabel params={orderParams}>
                                                    {product.compareQuantity ? product.compareQuantity : 0}
                                                </NumericLabel>
                                                        &nbsp;|&nbsp;
                                            </span>
                                                    :
                                                    null
                                                }
                                                <NumericLabel params={orderParams}>
                                                    {product.quantity ? product.quantity : 0}
                                                </NumericLabel>
                                            </CustomTableCell2>
                                            {monthCompare && property === 'revenue' ?
                                                <CustomTableCell2>
                                                    {product.compareQuantity ?
                                                        product.quantity > product.compareQuantity ?
                                                            <span style={{color: '#27AE60'}}>
                                                    &nbsp;{Math.abs(Math.round((product.quantity - product.compareQuantity) * 100 / product.compareQuantity))}%&nbsp;
                                                                {Icons.greenLineArrow}
                                                </span>
                                                            :
                                                            product.quantity < product.compareQuantity ?
                                                                <span style={{color: '#eb5757'}}>
                                                        &nbsp;{Math.abs(Math.round((product.quantity - product.compareQuantity) * 100 / product.compareQuantity))}%&nbsp;
                                                                    {Icons.redLineArrow}
                                                    </span>
                                                                :
                                                                <span style={{
                                                                    color: theme.palette.primary.main,
                                                                    opacity: 0.5
                                                                }}>&ensp;-</span>
                                                        :
                                                        <span style={{
                                                            color: theme.palette.primary.main,
                                                            opacity: 0.5
                                                        }}>&ensp;-</span>
                                                    }
                                                </CustomTableCell2>
                                                :
                                                null
                                            }
                                            <CustomTableCell2>
                                                {monthCompare && property === 'revenue' ?
                                                    <span style={{opacity: 0.5}}>
                                                <NumericLabel params={orderParams}>
                                                    {product.compareOrders ? product.compareOrders : 0}
                                                </NumericLabel>
                                                        &nbsp;|&nbsp;
                                            </span>
                                                    :
                                                    null
                                                }
                                                <NumericLabel params={orderParams}>
                                                    {product.orders ? product.orders : 0}
                                                </NumericLabel>
                                            </CustomTableCell2>
                                            {monthCompare && property === 'revenue' ?
                                                <CustomTableCell2>
                                                    {product.compareOrders ?
                                                        product.orders > product.compareOrders ?
                                                            <span style={{color: '#27AE60'}}>
                                                    &nbsp;{Math.abs(Math.round((product.orders - product.compareOrders) * 100 / product.compareOrders))}%&nbsp;
                                                                {Icons.greenLineArrow}
                                                </span>
                                                            :
                                                            product.orders < product.compareOrders ?
                                                                <span style={{color: '#eb5757'}}>
                                                        &nbsp;{Math.abs(Math.round((product.orders - product.compareOrders) * 100 / product.compareOrders))}%&nbsp;
                                                                    {Icons.redLineArrow}
                                                    </span>
                                                                :
                                                                <span style={{
                                                                    color: theme.palette.primary.main,
                                                                    opacity: 0.5
                                                                }}>&ensp;-</span>
                                                        :
                                                        <span style={{
                                                            color: theme.palette.primary.main,
                                                            opacity: 0.5
                                                        }}>&ensp;-</span>
                                                    }
                                                </CustomTableCell2>
                                                :
                                                null
                                            }
                                            <CustomTableCell2>
                                                {monthCompare ?
                                                    product.compareRevenue ?
                                                        <span style={{opacity: 0.5}}>
                                                {parseFloat(Math.round(product.compareRevenue * 100 * 100 / compareData.totalRevenue) / 100).toFixed(1)}%
                                                            &nbsp;|&nbsp;
                                            </span>
                                                        :
                                                        <span style={{opacity: 0.5}}>
                                                    0%&nbsp;|&nbsp;
                                            </span>
                                                    :
                                                    null
                                                }
                                                {parseFloat(Math.round(product.revenue * 100 * 100 / data.totalRevenue) / 100).toFixed(1)}%
                                            </CustomTableCell2>
                                        </TableRow>
                                    )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                     <Box p={1} px={2}>
                        <Grid container>
                            <Grid item xs={8} style={{paddingTop:'5px'}}>
                                <Typography variant={"h5"} color={"primary"} component={"span"}>
                                    Rows / page:&nbsp;
                                </Typography>
                                <CustomSelect
                                    value={rowsPerPage}
                                    onChange={handleChangeRowsPerPage}
                                    component={"span"}
                                    disableUnderline={true}
                                    style={{fontSize: "0.875rem"}}
                                    variant={"standard"}
                                >
                                    <MenuItem value={10}>10</MenuItem>
                                    <MenuItem value={25}>25</MenuItem>
                                    <MenuItem value={50}>50</MenuItem>
                                </CustomSelect>
                                <Typography variant={"h5"} color={"primary"} component={"span"}>
                                    &nbsp;Showing: {indexOfFirstPost}-
                                    {noOfPages === page ? productCount : indexOfLastPost} Of{" "}
                                    {productCount}
                                </Typography>
                            </Grid>
                            <Grid item xs={4} style={{justifyContent: "right"}}>
                                <CustomPagination
                                    count={noOfPages}
                                    page={page}
                                    onChange={handleChangePage}
                                    siblingCount={0}
                                    boundaryCount={2}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
                :
                <Box pt={5} style={{minHeight: 250, alignItems: "center", textAlign: "center"}}>
                    <Typography>No data to display</Typography>
                </Box>
            }
        </Box>
    )
}

const mapStateToProps = (state) => ({
    application_states: state.application_states,
    generateContentReducer:state.generateContentReducer
});
const mapDispatchToProps = (dispatch) => ({
    ReportDownloaded: (report_filename, report_type, segment) => dispatch(ReportDownloaded(report_filename, report_type, segment)),
    generate_content: (data, contentType) => dispatch(generate_content(data, contentType)),
    clearGPTContent:()=>dispatch(clearGPTContent())

});
export default connect(mapStateToProps, mapDispatchToProps)(ProductTable)