import React, {useEffect} from "react"
import {Box, Button, Container, CssBaseline, Dialog, Grid, Typography} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import {Link} from "react-router-dom";
import {OrangeFilledButton, OutlinedButton, SimpleButton} from "./buttons";
import Icons from "./icons";
import {border} from "../assets/jss/commonStyles";
import {CustomSwitch} from "./groupPage/monthTrendHeader";

const CookiePolicyStyles = makeStyles((theme) => ({
    banner: {
        margin: "auto",
        marginBottom: "5px",
        padding: "1rem",
        zIndex: 1000,
        maxWidth:1080,
        position: "fixed",
        bottom: 0,
        background: theme.palette.primary.main,
        color: theme.palette.common.white,
        borderRadius: 6,
        fontSize: "0.875rem",
        // width: "72%",
        display: "flex",
        alignItems: "center",
        [theme.breakpoints.down(1920)]: {
            left: window.innerWidth/2 - 540,
        },
        [theme.breakpoints.down(960)]: {
            left: 0,
        },
        [theme.breakpoints.down(600)]: {
            left: 0,
        },
    },
    paperMd: {
        width: 600,
        minHeight: 200
    },
    buttonHover: {
        background: '#a1a1a1',
    },
    buttonSpace: {
        marginTop: 0,
        [theme.breakpoints.down(600)]: {
            marginTop: 8,
        }
    }
}));

function CustomizePolicy(props) {
    const {open, setOpen, acceptChoices, acceptAll} = props;
    const classes = CookiePolicyStyles();
    const [targetCookies, setTargetCookies] = React.useState(false);
    const handleChoices = () => {
        if (targetCookies) {
            acceptAll()
        } else {
            acceptChoices()
        }
        setOpen(false)
    }
    return (
        <Dialog open={open} maxWidth={'md'} classes={{paperWidthMd: classes.paperMd}}>
            <Container component="main">
                <CssBaseline/>
                <Box pt={2} pb={1} borderBottom={border}>
                    <Grid container alignItems={'center'}>
                        <Grid item xs={6}>
                            <Box py={1.5}>
                                <Typography variant={'h4'} style={{fontWeight: 700}}>
                                    Cookie Settings
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={6} style={{textAlign: 'end'}}>
                            <Button onClick={() => {
                                setOpen(false)
                            }} style={{textTransform: 'none'}}>
                                {Icons.closeIcon}
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
                <Box py={1}>
                    <Typography color={"primary"}>
                        When you visit any of our websites, it may store or retrieve information on your browser, mostly
                        in the form of cookies. This information might be about you, your preferences or your device and
                        is mostly used to make the site work as you expect it to. The information does not usually
                        directly identify you, but it can give you a more personalized web experience. Because we
                        respect your right to privacy, you can choose not to allow some types of cookies.Read about
                        our&nbsp;
                        <Link target={'_blank'} to={'/cookie_policy'}>cookie policy</Link> to find out more and manage
                        your preferences. Please note, blocking
                        some types of cookies may impact your experience of the site and the services we are able to
                        offer.
                    </Typography>
                </Box>
                <Box py={1}>
                    <Grid container alignItems={'center'}>
                        <Grid item xs={8}>
                            <Typography color={'primary'} style={{display: 'flex'}}>
                                Mandatory Cookies{/*&nbsp;<Box pt={'2px'}>{Icons.infoCircleIconOutline}</Box>*/}
                            </Typography>
                        </Grid>
                        <Grid item xs={4} style={{textAlign: 'end'}}>
                            <CustomSwitch checked={true} disabled={true} name={'strictCookies'}></CustomSwitch>
                        </Grid>
                    </Grid>
                </Box>
                <Box py={1}>
                    <Grid container>
                        <Grid item xs={8}>
                            <Typography color={'primary'} style={{display: 'flex'}}>
                                Analytics Cookies{/*&nbsp;<Box pt={'2px'}>{Icons.infoCircleIconOutline}</Box>*/}
                            </Typography>
                        </Grid>
                        <Grid item xs={4} style={{textAlign: 'end'}}>
                            <CustomSwitch checked={targetCookies} name={'targetCookies'} onChange={(e) => {
                                setTargetCookies(e.target.checked)
                            }}></CustomSwitch>
                        </Grid>
                    </Grid>
                </Box>
                <Box pt={2} pb={1}>
                    <Grid container spacing={2} alignItems={"center"}>
                        <Grid item>
                            <OrangeFilledButton variant={'contained'} style={{fontSize: '1rem', fontWeight: 700}}
                                                onClick={() => {
                                                    handleChoices()
                                                }}>Confirm
                                my choices</OrangeFilledButton>
                        </Grid>
                        <Grid item>
                            <OutlinedButton style={{fontSize: '1rem', fontWeight: 700}} onClick={() => {
                                acceptAll()
                                setOpen(false)
                            }}>Accept all
                                choices</OutlinedButton>
                        </Grid>
                        <Grid item>
                            <SimpleButton classes={{hover: classes.buttonHover}} onClick={() => {
                                setOpen(false)
                            }}
                                          style={{fontSize: '1rem', fontWeight: 700}}>Cancel</SimpleButton>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        </Dialog>
    )
}

export const getCookie = (cookieName) => {
    let name = cookieName + '=';
    let allCookies = document.cookie;
    let cookiesArray = allCookies.split(';');
    for (let i = 0; i < cookiesArray.length; i++) {
        let cookie = cookiesArray[i];
        while (cookie.charAt(0) === ' ') {
            cookie = cookie.substring(1);
        }
        if (cookie.indexOf(name) === 0) {
            return cookie.substring(name.length, cookie.length);
        }
    }
    return "";
};

function CookiePolicy(props) {
    const classes = CookiePolicyStyles();
    const [open, setOpen] = React.useState(false);
    const [customizePopup, setCustomizePopup] = React.useState(false);
    useEffect(() => {
        checkCookie()
    }, []);
    const checkCookie = () => {
        let visited = getCookie('lxrin_ck');
        if (visited) {
            setOpen(false)
        } else {
            setOpen(true)
        }
    };

    const acceptCookie = () => {
        document.cookie = `lxrin_ck = accepted ;`;
        checkCookie()
    };
    const acceptSelectedCookie = () => {
        document.cookie = `lxrin_ck = mandatory ;`;
        checkCookie();
        let allCookies = document.cookie;
        let cookiesArray = allCookies.split('; ');
        for (let i = 0; i < cookiesArray.length; i++) {
            let cookie = cookiesArray[i];
            if (cookie.includes('_ga') || cookie.includes('_gat') || cookie.includes('_gid')) {
                document.cookie = cookie + ';max-age=0'
            }
        }
    };
    return (
        <div className={classes.banner} style={{display: open ? 'block' : 'none'}}>
            <Grid container  spacing={1} alignItems={'center'}>
                <Grid item md={8} lg={9} xl={10}>
                    <Typography>
                        We use cookies to understand how you use our site and provide the best browsing
                        experience possible. This includes analysing our traffic, personalizing content and
                        advertising.
                        By continuing to use our site, you accept our <Link to={'/cookie_policy'}
                                                                            className={"text-decoration-none"}
                                                                            style={{color: '#f58120'}}>cookie
                        policy</Link>.
                    </Typography>
                </Grid>
                <Grid item xs={12} md={4} lg={3} xl={2}>
                    <Grid container spacing={2} style={{textAlign:'center'}}>
                        <Grid item xs={12}>
                            <OrangeFilledButton variant={'contained'}
                                                style={{width: "100%", fontSize: '1rem', fontWeight: 700}}
                                                onClick={() => acceptCookie()}>
                                Accept all cookies
                            </OrangeFilledButton>
                        </Grid>
                        <Grid item xs={12}>
                            <Button className={classes.buttonSpace} style={{
                                width: "100%",
                                border: "1px solid rgb(30, 51, 78,0.3)",
                                textTransform: "none",
                                fontSize: '1rem',
                                backgroundColor: "#fff"
                            }}
                                    onClick={() => setCustomizePopup(true)}>
                                Customize settings
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
                {/*<Grid item xs={1}>
                        <Button onClick={() => {
                            setOpen(false)
                        }} style={{color: "#fff"}}>{Icons.closeIcon}</Button>
                    </Grid>*/}
            </Grid>
            <CustomizePolicy open={customizePopup} setOpen={setCustomizePopup} acceptAll={acceptCookie}
                             acceptChoices={acceptSelectedCookie}/>
        </div>
    )
}

export default CookiePolicy;