import React, {useEffect} from "react";
import {AppBar, Badge, Box, Button, Grid, Menu, MenuItem, Popover, Toolbar, Typography,} from "@mui/material";
import withStyles from '@mui/styles/withStyles';
import HeaderStyles from "../../assets/jss/headers/headerStyles";
import Icons from "../icons";
import clsx from "clsx"
import {SimpleButton} from "../buttons";
import {
  business_profile,
  get_user_data,
  logoutUser,
  NotificationData,
  NotificationDelete,
  overview_compare,
  product_tour_run,
  set_currency_fields,
  StoredSegmentData
} from "../../actions/actions"
import {connect} from "react-redux";
import Sidebar from "../sidebar";
import {border} from "../../assets/jss/commonStyles";
import NotificationWindow from "../notificationWindow";
import {Link} from "react-router-dom";
import * as _ from "lodash";
import {GAEventTracker} from "../../index";
import {HelpOutlineOutlined, NotificationsNone, PowerSettingsNew, Settings,} from "@mui/icons-material";
import {CustomTooltip} from "../groupPage/groupOverview";

export const CustomMenu = withStyles({
    paper: {
        border: border,
        width: 270,
        position: 'relative'
    },
})(Menu);

export const CustomMenuItem = withStyles((theme) => ({
    root: {
        paddingTop: 8,
        paddingBottom: 8,
        height: 60,
        color: theme.palette.text.primary,
        borderBottom: border,
        "& > div": {
            "&:focus": {
                outline: "none !important",
                backgroundColor: 'none'
            }
        },
        "&:hover": {
            backgroundColor: 'none',
        }
    },
}))(MenuItem);

function LoggedInHeader(props) {

    //notification popup
    const [popperEl, setPopperEl] = React.useState(null);
    // const [arrowRef, setArrowRef] = React.useState(null);
    const anchorRef = React.useRef(null);
    const handlePopperClick = (event) => {
        setPopperEl(popperEl ? null : event.currentTarget);
    };
    const handlePopperClose = () => {
        setPopperEl(null);
    };
    const popperOpen = Boolean(popperEl);
    const popperId = popperOpen ? 'notification-popper' : undefined;
    /*const handleArrowRef = node => {
        setArrowRef(node)
    };*/

    //Profile Settings
    const [anchorEl, setAnchorEl] = React.useState(null);
    const firstname = localStorage.getItem('Firstname');
    const websitename = localStorage.getItem('websitename');
    const mfaEnable = localStorage.getItem('MFAEnable');
    const mfaVerify = localStorage.getItem('MFAVerified');

  
    const handleClick = (event) => {
        setPopperEl(null);
        setHelpAnchorEl(null);
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
        for (let i = 0; i < Object.keys(StoredSegmentData).length; i) {
            delete StoredSegmentData[Object.keys(StoredSegmentData)[i]]
        }
        props.logoutUser();
    };

    //Help Settings
    const [helpAnchorEl, setHelpAnchorEl] = React.useState(null);
    const handleHelpClick = (event) => {
        setPopperEl(null);
        setAnchorEl(null);
        setHelpAnchorEl(event.currentTarget);
    };
    const handleHelpClose = () => {
        setHelpAnchorEl(null);
    };

    const classes = HeaderStyles();
    const {userProfile, NotificationData, notification_data_reducer, NotificationDelete, set_currency_fields, product_tour_run, overview_compare,business_profile} = props;
    // let notifications = notification_data_reducer.notifications ? notification_data_reducer.notifications : []
    const [notifications, setNotifications] = React.useState(notification_data_reducer.notifications ? notification_data_reducer.notifications : []);
    const handleNotificationDelete = (notifId) => {
        NotificationDelete(notifId);
        let updateNotifications = _.reject(notifications, (d) => {
            return d._id === notifId
        });
        setNotifications(updateNotifications)
    };
    const deleteAllNotifications = (notifId) => {
        let updateNotifications = _.reject(notifications, (d) => {
            return d._id === notifId
        });
        setNotifications(updateNotifications)
    };
    useEffect(() => {
        if(mfaEnable === "true"){
            if(mfaVerify === "true"){
                props.get_user_data();
                props.business_profile();
                if(notification_data_reducer.isLoading === false && !notification_data_reducer.notifications) {
                    NotificationData()
                }
            }
        }
        else{
            props.get_user_data();
            props.business_profile();
            if(notification_data_reducer.isLoading === false && !notification_data_reducer.notifications) {
                NotificationData()
            }
        }
    
    //eslint-disable-next-line
    }, []);
    useEffect(() => {
        if (notification_data_reducer.notifications) {
            setNotifications(notification_data_reducer.notifications)
        }
    }, [notification_data_reducer]);
    useEffect(() => {
        const currency_setting = async (currency, currencySymbol) => {
            await set_currency_fields(currency, currencySymbol)
        };
        if (userProfile.currency) {
            currency_setting(userProfile.currency, userProfile.currency_symbol)
        }
    //eslint-disable-next-line
    }, [userProfile]);
    return <>
        <AppBar className={classes.appBar} position={"fixed"}>
            <Box alignItems={'center'}>
                <Toolbar disableGutters>
                    <Grid container alignItems={'center'}>
                        <Grid item xs={3} className={'flex'}>
                            <Link to={"/"} className={clsx("flex", "text-decoration-none")}>
                                {/*<Box py={'auto'} px={2} borderRight={"1px solid #1e334e"}>*/}
                                <Box onClick={() => {
                                    GAEventTracker('Header', 'LXRInsights Logo')
                                }}>
                                    {/*{Icons.insightsWhiteLogo}*/}
                                    {Icons.LXRInsights_Logo}
                                </Box>
                            </Link>
                            {/*<a target={"_blank"} rel="noopener noreferrer" href={"https://www.netelixir.com/"}
                               className={clsx("flex", "text-decoration-none")}>
                                <Box py={'auto'} px={2} onClick={() => {
                                    GAEventTracker('Header', 'NetElixir Logo')
                                }}>
                                    {Icons.netelixirLogo}
                                </Box>
                            </a>*/}
                        </Grid>
                        <Grid item xs={9} style={{textAlign: "end"}}>
                            <SimpleButton onClick={handleClick}>
                                {/* <Typography variant={"body1"} className={clsx(classes.userName, 'flex')}> */}
                                    <Box px={2} >
                                        <Grid container alignItems={"center"}>
                                            {/*<Grid item>Sanjay&emsp;&ensp;</Grid>*/}
                                            <Grid item style={{textAlign:"end"}}><Box display={{
                                                xs: 'none',
                                                sm: 'block'
                                            }}><Typography variant={"body1"} >Hi, {firstname?firstname:userProfile.first_name}&emsp;&ensp;</Typography></Box>
                                                {websitename || userProfile.website_name?
                                                <Box display={{
                                                xs: 'none',
                                                sm: 'block'
                                            }} style={{opacity:0.5}}> <Typography variant={"body1"}>{websitename && websitename!== "null"?websitename:userProfile.website_name}&emsp;&ensp;</Typography></Box>:null}
                                                    </Grid>
                                            <Grid item>
                                                <Box border={1.2}
                                                     borderRadius="50%">&nbsp;{Icons.accountCircleIcon}&nbsp;</Box>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                {/* </Typography> */}
                            </SimpleButton>
                            <CustomMenu
                                elevation={0}
                                MenuListProps={{
                                    disablePadding: true
                                }}
                                getcontentanchorel={null}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center',
                                }}
                                anchorEl={anchorEl}
                                keepMounted
                                open={Boolean(anchorEl)}
                                onClose={handleClose}>
                                <Link to={'/settings/users'} className={'text-decoration-none'}>
                                    <CustomMenuItem onClick={() => {
                                        handleClose();
                                        GAEventTracker('Header', 'Settings')
                                    }}>
                                        <Settings className={classes.menuItemIcon}/>&emsp;Settings
                                    </CustomMenuItem>
                                </Link>
                                {/*<Link to={'/audienceHistory'} className={'text-decoration-none'}>
                                    <CustomMenuItem onClick={() => {
                                        handleClose();
                                        GAEventTracker('Header', 'Audience History')
                                    }}>
                                        <HistoryIcon className={classes.menuItemIcon}/>&emsp;Audience History
                                    </CustomMenuItem>
                                </Link>*/}
                                <Link to={'/'} className={'text-decoration-none'}>
                                    <CustomMenuItem onClick={() => {
                                        handleClose();
                                        handleLogout();
                                        GAEventTracker('Header', 'Logout')
                                    }}>
                                        <PowerSettingsNew className={classes.menuItemIcon}/>&emsp;Logout
                                    </CustomMenuItem>
                                </Link>
                            </CustomMenu>
                            <CustomTooltip title={'Help'}>
                                {/*<Link id={'tour6thStep'} to={'/help_center/categories'}>*/}
                                    <Button id={'tour6thStep'} onClick={handleHelpClick} className={classes.helpCenterIcon} >
                                        <Box pt={"0.625rem"} px={3}>
                                            <HelpOutlineOutlined/>
                                        </Box>
                                    </Button>
                                {/*</Link>*/}
                            </CustomTooltip>
                            <CustomMenu
                                elevation={0}
                                MenuListProps={{
                                    disablePadding: true
                                }}
                                getcontentanchorel={null}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center',
                                }}
                                anchorEl={helpAnchorEl}
                                keepMounted
                                open={Boolean(helpAnchorEl)}
                                onClose={handleHelpClose}>
                                <Link to={'/help_center/categories'} className={'text-decoration-none'} target="_blank" rel="noopener noreferrer">
                                    <CustomMenuItem onClick={() => {
                                        handleHelpClose();
                                        GAEventTracker('Header', 'Help Center')
                                    }}>
                                        <HelpOutlineOutlined style={{fontSize:'24px'}}/>&emsp;Help Center
                                    </CustomMenuItem>
                                </Link>
                                {(userProfile.bu_id && userProfile.last_clustered)?
                                <Link to={'/'} className={'text-decoration-none'}>
                                    <CustomMenuItem onClick={async () => {
                                        await handleHelpClose();
                                        await overview_compare(false);
                                        await product_tour_run(true);
                                        GAEventTracker('Header', 'Product Tour')
                                    }}>
                                        {Icons.productTourHeaderIcon}&emsp;Product Tour
                                    </CustomMenuItem>
                                </Link>:null}
                                <Link to={'/contact_us'} className={'text-decoration-none'}>
                                    <CustomMenuItem onClick={() => {
                                        handleHelpClose();
                                        GAEventTracker('Header', 'Contact Us')
                                    }}>
                                        {Icons.contactUsHeaderIcon}&emsp;Contact Us
                                    </CustomMenuItem>
                                </Link>
                            </CustomMenu>
                            <CustomTooltip placement={'bottom'} title={'Notifications'}>
                            <Button className={classes.notificationIcon} aria-describedby={popperId} ref={anchorRef} onClick={(e) => {
                                handlePopperClick(e);
                                GAEventTracker('Header', 'Notifications')
                            }}>
                                <Box p={1} px={3}>
                                    <Badge variant={"dot"} invisible={!Boolean(notifications.length)}
                                           color={"secondary"} overlap={"circular"}>
                                        <NotificationsNone/>
                                    </Badge>
                                </Box>
                            </Button>
                            </CustomTooltip>
                        </Grid>
                    </Grid>
                </Toolbar>
            </Box>
        </AppBar>
        {(userProfile.bu_id && userProfile.last_clustered)?
        <Sidebar userProfile={userProfile}/>:null}
        <Popover
            // className={classes.popoverClass}
            // id={popperId}
            open={popperOpen}
            anchorEl={anchorRef.current}
            onClose={handlePopperClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}>
            <NotificationWindow
                classes={classes}
                handlePopperClose={handlePopperClose}
                notificationData={notifications}
                handleNotificationDelete={handleNotificationDelete}
                deleteAllNotifications={deleteAllNotifications}
            />
        </Popover>
    </>;
}

const mapStateToProps = (state) => ({
    userProfile: state.userProfile,
    loginAuthentication: state.loginAuthentication,
    notification_data_reducer: state.notification_data_reducer,
});
const mapDispatchToProps = (dispatch) => ({
    NotificationDelete: (notifId) => dispatch(NotificationDelete(notifId)),
    overview_compare: (overcompvalue) => dispatch(overview_compare(overcompvalue)),
    set_currency_fields: (currency, currencySymbol) => dispatch(set_currency_fields(currency, currencySymbol)),
    logoutUser: () => dispatch(logoutUser()),
    get_user_data: () => dispatch(get_user_data()),
    NotificationData: () => dispatch(NotificationData()),
    product_tour_run: (tourRunValue) => dispatch(product_tour_run(tourRunValue)),
    business_profile: () => dispatch(business_profile()),

});
export default connect(mapStateToProps, mapDispatchToProps)(LoggedInHeader);
