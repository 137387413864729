import React from "react";
import { Box, Typography, useTheme, Grid } from "@mui/material";
import { Link } from "react-router-dom";
import Icons from "./icons";
import { GAEventTracker } from "..";
import { OrangeFilledButton } from "./buttons";
import { OpenInNew } from "@mui/icons-material";

// const HomepageFooterMobile = () => {
//     const theme = useTheme();
//     return (
//         <Box style={{ backgroundColor: "#F2F6F9", textAlign: "center" }} p={2} pt={3}>
//             <Box>
//                 <Typography style={{ fontSize: "0.75rem", fontWeight: 700 }}>Looking for additional full-service e-commerce solutions? <a target={"_blank"} rel="noopener noreferrer" style={{ color: "#00B2FF", textDecoration: "underline" }}
//                     href={'https://www.netelixir.com/'}>Visit NetElixir</a> to empower your e-commerce growth through empathy, insights, and innovation.</Typography>
//             </Box>
//             {Icons.gdprLogo}
//             <Box pt={2}>
//                 <Typography component={'span'} color={'primary'} style={{
//                     fontSize: "0.875rem",
//                     fontFamily: '"proxima-nova", sans-serif'
//                 }}>
//                     <a href={'mailto:support@lxrinsights.com'} style={{ textDecoration: 'none', color: 'inherit', fontSize: "0.875rem", fontFamily: '"proxima-nova", sans-serif' }}>support@lxrinsights.com</a>
//                 </Typography>
//                 <br /><br />
//                 <Typography component={'span'} color={'primary'}
//                     style={{ fontSize: "0.875rem", fontFamily: '"proxima-nova", sans-serif' }}>
//                     <Link to={'/contact_us'} onClick={() => window.scrollTo(0, 0)}
//                         style={{ color: theme.palette.primary.main }}
//                         className={'text-decoration-none'}>Contact Us</Link>
//                 </Typography>
//                 <br /><br />
//                 <Typography component={'span'} color={'primary'}
//                     style={{ color: "#666", fontSize: "0.875rem", fontFamily: '"proxima-nova", sans-serif', paddingTop: "12px" }}>
//                     <Link to={'/do-not-sell'} onClick={() => window.scrollTo(0, 0)}
//                         style={{ color: "#666" }}
//                         className={'text-decoration-none'}>Do not sell my personal information</Link>
//                 </Typography>
//                 <br /><br />
//                 <Typography component={'span'} color={'primary'}
//                     style={{ fontSize: "0.875rem", fontFamily: '"proxima-nova", sans-serif' }}>
//                     <Link to={'/privacy-policy'} onClick={() => window.scrollTo(0, 0)}
//                         style={{ color: theme.palette.primary.main }}
//                         className={'text-decoration-none'}>Privacy Policy</Link>
//                 </Typography>
//                 <br /><br />
//                 <Typography color={'primary'} component={'span'}
//                     style={{ fontSize: "0.875rem", fontFamily: '"proxima-nova", sans-serif' }}>
//                     <Link to={'/terms'} onClick={() => window.scrollTo(0, 0)}
//                         style={{ color: theme.palette.primary.main }}
//                         className={'text-decoration-none'}>Terms and Conditions</Link>
//                 </Typography>
//                 <br /><br />
//                 <Typography component={'span'} color={'primary'}
//                     style={{ fontSize: "0.87rem", fontFamily: '"proxima-nova", sans-serif' }}>
//                     {'©'}&nbsp;{new Date().getFullYear()}&nbsp;
//                     <a target={"_blank"} rel="noopener noreferrer" className={'text-decoration-none'}
//                         href={'https://www.netelixir.com/'}
//                         style={{ color: theme.palette.primary.main }}>NetElixir</a>. All Rights Reserved.
//                 </Typography>
//             </Box>
//         </Box>
//     )
// }

const HomepageFooterMobile = (props) => {
  const { classes, handleDemoOpen, scorecard } = props;
  const theme = useTheme();
  return (
    <Box
      style={{
        backgroundColor: scorecard
          ? theme.palette.common.white
          : "rgb(242, 246, 249)",
        paddingTop: "40px",
      }}
    >
      <Box style={{ marginBottom: "-80px", position: "relative" }}>
        {scorecard ? (
          <Grid container>
            <Grid item xs={1.1}></Grid>
            <Grid item xs={9.8}>
              <Box className={classes.footerMobile}>
                <Box
                  style={{
                    height: "120px",
                    alignItems: "center",
                    textAlign: "center",
                    justifyContent: "center",
                    display: "block",
                  }}
                >
                  <Typography
                    style={{
                      color: "#222222",
                      fontSize: "0.875rem",
                      fontWeight: 700,
                      fontFamily: '"proxima-nova", sans-serif',
                      paddingTop: "30px",
                      paddingBottom: "10px",
                    }}
                  >
                    Unlock insights and start
                    <br /> optimizing business performance.
                  </Typography>
                  <OrangeFilledButton
                    id="mktBtn"
                    className={classes.proximaNovaFont}
                    style={{
                      borderRadius: "35px",
                      border: "2px solid #F58120",
                      width: "170px",
                      height: "25px",
                      fontSize: "0.75rem",
                    }}
                    variant="contained"
                  >
                    <a
                      href={"https://scorecard.lxrinsights.com/"}
                      target="_blank"
                      style={{ textDecoration: "none", color: "#ffffff" }}
                    >
                      Get Your Free Scorecard
                    </a>
                  </OrangeFilledButton>
                </Box>
                <Box
                  style={{
                    backgroundColor: "#EDEDED",
                    borderRadius: "0px 0px 20px 20px",
                    height: "80px",
                    alignItems: "center",
                    textAlign: "center",
                    display: "block",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    style={{
                      fontSize: "0.625rem",
                      color: "#333",
                      fontFamily: '"proxima-nova", sans-serif',
                      paddingTop: "18px",
                      paddingBottom: "10px",
                    }}
                  >
                    Looking for additional full-service e-commerce solutions ?
                  </Typography>
                  <span
                    style={{
                      color: theme.palette.common.cyan,
                      fontWeight: 600,
                      fontFamily: '"proxima-nova", sans-serif',
                      fontSize: "0.875rem",
                    }}
                  >
                    Visit&nbsp;
                    <a
                      style={{
                        color: theme.palette.common.cyan,
                        fontWeight: 600,
                        fontFamily: '"proxima-nova", sans-serif',
                        textDecoration: "underline",
                      }}
                      target={"_blank"}
                      rel="noopener noreferrer"
                      href={"https://www.netelixir.com/"}
                    >
                      NetElixir
                    </a>{" "}
                  </span>
                  <span>
                    <a
                      target={"_blank"}
                      rel="noopener noreferrer"
                      style={{
                        textDecoration: "none",
                      }}
                      href={"https://www.netelixir.com/"}
                    >
                      <OpenInNew
                        style={{
                          color: theme.palette.secondary.light,
                          height: "14px",
                        }}
                      />
                    </a>
                  </span>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={1.1}></Grid>
          </Grid>
        ) : (
          <Grid container>
            <Grid item xs={1.1}></Grid>
            <Grid item xs={9.8}>
              <Box className={classes.footerMobile}>
                <Box
                  style={{
                    height: "120px",
                    alignItems: "center",
                    textAlign: "center",
                    justifyContent: "center",
                    display: "block",
                  }}
                >
                  <Typography
                    style={{
                      color: "#222222",
                      fontSize: "0.875rem",
                      fontWeight: 700,
                      fontFamily: '"proxima-nova", sans-serif',
                      paddingTop: "30px",
                      paddingBottom: "10px",
                    }}
                  >
                    Get Started With LXRInsights
                  </Typography>
                  <OrangeFilledButton
                    id="mktBtn"
                    className={classes.proximaNovaFont}
                    style={{
                      borderRadius: "35px",
                      border: "2px solid #F58120",
                      width: "170px",
                      height: "25px",
                      fontSize: "0.75rem",
                    }}
                    onClick={() => {
                      handleDemoOpen();
                      GAEventTracker("Home Page", "Request Free Demo");
                    }}
                    variant="contained"
                  >
                    Try For Free
                  </OrangeFilledButton>
                </Box>
                <Box
                  style={{
                    backgroundColor: "#EDEDED",
                    borderRadius: "0px 0px 20px 20px",
                    height: "80px",
                    alignItems: "center",
                    textAlign: "center",
                    display: "block",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    style={{
                      fontSize: "0.625rem",
                      color: "#333",
                      fontFamily: '"proxima-nova", sans-serif',
                      paddingTop: "18px",
                      paddingBottom: "10px",
                    }}
                  >
                    Looking for additional full-service e-commerce solutions ?
                  </Typography>
                  <span
                    style={{
                      color: theme.palette.common.cyan,
                      fontWeight: 600,
                      fontFamily: '"proxima-nova", sans-serif',
                      fontSize: "0.875rem",
                    }}
                  >
                    Visit&nbsp;
                    <a
                      style={{
                        color: theme.palette.common.cyan,
                        fontWeight: 600,
                        fontFamily: '"proxima-nova", sans-serif',
                        textDecoration: "underline",
                      }}
                      target={"_blank"}
                      rel="noopener noreferrer"
                      href={"https://www.netelixir.com/"}
                    >
                      NetElixir
                    </a>{" "}
                  </span>
                  <span>
                    <a
                      target={"_blank"}
                      rel="noopener noreferrer"
                      style={{
                        textDecoration: "none",
                      }}
                      href={"https://www.netelixir.com/"}
                    >
                      <OpenInNew
                        style={{
                          color: theme.palette.secondary.light,
                          height: "14px",
                        }}
                      />
                    </a>
                  </span>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={1.1}></Grid>
          </Grid>
        )}
      </Box>
      <Box style={{ backgroundColor: "#192136", height: "450px" }}>
        <Box style={{ paddingTop: "100px" }}>
          <Box>
            <Typography
              component={"span"}
              variant="body2"
              style={{
                color: "#BFBFBF",
                fontFamily: '"proxima-nova", sans-serif',
              }}
            >
              <Link
                to={"/privacy-policy"}
                onClick={() => window.scrollTo(0, 0)}
                style={{ color: "#BFBFBF", marginBottom: "15px" }}
                className={"text-decoration-none"}
              >
                Privacy Policy
              </Link>
            </Typography>
            <br />
            <Typography
              component={"span"}
              variant="body2"
              style={{
                color: "#BFBFBF",
                fontFamily: '"proxima-nova", sans-serif',
                paddingBottom: "15px",
              }}
            >
              <Link
                to={"/terms"}
                onClick={() => window.scrollTo(0, 0)}
                style={{ color: "#BFBFBF" }}
                className={"text-decoration-none"}
              >
                Terms and Conditions
              </Link>
            </Typography>
            <br />
            <Typography
              component={"span"}
              variant="body2"
              style={{
                color: "#BFBFBF",
                fontFamily: '"proxima-nova", sans-serif',
                paddingBottom: "15px",
              }}
            >
              <Link
                to={"/do-not-sell"}
                onClick={() => window.scrollTo(0, 0)}
                style={{ color: "#BFBFBF" }}
                className={"text-decoration-none"}
              >
                Do not sell my personal information
              </Link>
            </Typography>
            <br />
            <Typography
              component={"span"}
              variant="body2"
              style={{
                color: "#BFBFBF",
                fontFamily: '"proxima-nova", sans-serif',
                paddingBottom: "15px",
              }}
            >
              <Link
                to={"/scorec"}
                onClick={() => window.scrollTo(0, 0)}
                style={{ color: "#BFBFBF" }}
                className={"text-decoration-none"}
              >
                Scorecard
              </Link>
            </Typography>
            <br />
            <Typography
              component={"span"}
              variant="body2"
              style={{
                color: "#BFBFBF",
                fontFamily: '"proxima-nova", sans-serif',
                paddingBottom: "15px",
              }}
            >
              <Link
                to={"/contact_us"}
                onClick={() => window.scrollTo(0, 0)}
                style={{ color: "#BFBFBF" }}
                className={"text-decoration-none"}
              >
                Contact Us
              </Link>
            </Typography>
            <br />
            <Typography
              component={"span"}
              variant="body2"
              style={{
                color: theme.palette.secondary.light,
                fontFamily: '"proxima-nova", sans-serif',
                paddingBottom: "15px",
              }}
            >
              <a
                href={"mailto:support@lxrinsights.com"}
                style={{
                  textDecoration: "none",
                  color: theme.palette.secondary.light,
                }}
              >
                support@lxrinsights.com
              </a>
            </Typography>
          </Box>
        </Box>
        <Box style={{ textAlign: "center", justifyContent: "center" }} pt={3}>
          <span
            style={{ width: "66.07px", height: "76px", paddingRight: "15px" }}
          >
            {Icons.gdprMobile}
          </span>
          <span>{Icons.ccpaMobile}</span>
          <br />
          <br />
          <Typography
            component={"span"}
            variant="body2"
            style={{
              color: "#BFBFBF",
              fontFamily: '"proxima-nova", sans-serif',
            }}
          >
            <Link
              to={"/gdpr-ccpa-compliance"}
              onClick={() => window.scrollTo(0, 0)}
              style={{ color: "#BFBFBF" }}
              className={"text-decoration-none"}
            >
              Read More about GDPR & CCPA
            </Link>
          </Typography>
        </Box>
        <Box py={2}>
          {Icons.LXRInsightsWhiteLogo}&nbsp;&nbsp;&nbsp;&nbsp;
          {/* {scorecard ? null : Icons.poweredByNetelixirLogo} */}
        </Box>
      </Box>
      <Box
        style={{ backgroundColor: theme.palette.common.white, height: "40px" }}
      >
        <Typography
          style={{ paddingTop: "10px", fontWeight: 600, color: "#222" }}
        >
          {"©"}&nbsp;{new Date().getFullYear()}&nbsp;&nbsp;|&nbsp;&nbsp;
          <a
            target={"_blank"}
            rel="noopener noreferrer"
            href={"https://www.netelixir.com/"}
            style={{ color: "#222", fontFamily: '"proxima-nova", sans-serif' }}
          >
            NetElixir
          </a>
        </Typography>
      </Box>
    </Box>
  );
};

export default HomepageFooterMobile;
