import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import ReactGA from 'react-ga4';
import {getCookie} from "./components/cookiePoilcy";

let visited = getCookie('lxrin_ck');
if (visited === 'mandatory') {
    ReactGA.initialize(process.env.REACT_APP_GAId, {gaOptions: {storage: 'none'}})
} else {
    ReactGA.initialize(process.env.REACT_APP_GAId)
}

export function GAEventTracker(GACategory, GAAction, GALabel) {
    ReactGA.event({
        category: GACategory,
        action: GAAction,
        label: GALabel
    });
}

ReactDOM.render(
    <React.StrictMode>
        <App/>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
