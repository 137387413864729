import { Box, MenuItem, Typography } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import React from "react";
import {
  conversionPathTabValue,
  CustomCheckbox,
  CustomSelect,
} from "./filterWindow";
import { border } from "../../assets/jss/commonStyles";
import { GAEventTracker } from "../..";
import CustomerGroup from "../getGroupDetails";
import { useSelector } from "react-redux";

const ConversionPathFilters = (props) => {
  const {
    tabValue,
    conversionPathStartFilter,
    conversionPathFilterOptions,
    handleConversionPathStartFilterChange,
    handleConversionPathStartChange,
    handleConversionPathEndsFilterChange,
    conversionPathStart,
    conversionPathEndsFilter,
    conversionPathEnds,
    handleConversionPathEndsChange,
    channelFilter,
    channelsListBasedOnTabValue,
    handleChannelFilterChange,
    channel,
    handleChannelChange

  } = props;

  const segment = useSelector(
    (state) => state?.application_states?.customerAnalyticsSegmentTabName
  );

  return (
    <>
      {tabValue === conversionPathTabValue ? (
        <>
          <Box p={2} py={1} borderBottom={border}>
            <Typography color={"primary"} style={{ fontWeight: 700 }}>
              Starts With
            </Typography>
            <Box style={{ textAlign: "-webkit-right" }}>
              <Box py={0.5}>
                <CustomCheckbox
                  color={"default"}
                  checked={conversionPathStartFilter}
                  disabled={
                    conversionPathFilterOptions?.startsWithChannelsArray
                      ?.length === 0
                      ? true
                      : false
                  }
                  onChange={(e) => {
                    handleConversionPathStartFilterChange(e);
                    GAEventTracker(
                      CustomerGroup(segment)().name,
                      "Path Start Filter Check Box",
                      e.target.checked
                    );
                  }}
                  indeterminate
                  indeterminateIcon={<CheckCircleIcon />}
                />
                <CustomSelect
                  disabled={!conversionPathStartFilter}
                  variant={"outlined"}
                  value={conversionPathStart}
                  onChange={(e) => {
                    handleConversionPathStartChange(e);
                    GAEventTracker(
                      CustomerGroup(segment)().name,
                      "Path Start Filter Value",
                      e.target.value
                    );
                  }}
                >
                  {conversionPathFilterOptions?.startsWithChannelsArray?.map(
                    (pathName, i) => (
                      <MenuItem
                        value={pathName}
                        key={`conversion-path-starts-with-${i}`}
                      >
                        {pathName}
                      </MenuItem>
                    )
                  )}
                </CustomSelect>
              </Box>
            </Box>
          </Box>

          <Box p={2} py={1} borderBottom={border}>
            <Typography color={"primary"} style={{ fontWeight: 700 }}>
              Ends With
            </Typography>
            <Box style={{ textAlign: "-webkit-right" }}>
              <Box py={0.5}>
                <CustomCheckbox
                  color={"default"}
                  checked={conversionPathEndsFilter}
                  disabled={
                    conversionPathFilterOptions?.endsWithChannelArray
                      ?.length === 0
                      ? true
                      : false
                  }
                  onChange={(e) => {
                    handleConversionPathEndsFilterChange(e);
                    GAEventTracker(
                      CustomerGroup(segment)().name,
                      "Path Ends Filter Check Box",
                      e.target.checked
                    );
                  }}
                  indeterminate
                  indeterminateIcon={<CheckCircleIcon />}
                />
                <CustomSelect
                  disabled={!conversionPathEndsFilter}
                  variant={"outlined"}
                  value={conversionPathEnds}
                  onChange={(e) => {
                    handleConversionPathEndsChange(e);
                    GAEventTracker(
                      CustomerGroup(segment)().name,
                      "Path Ends Filter Value",
                      e.target.value
                    );
                  }}
                >
                  {conversionPathFilterOptions?.endsWithChannelArray?.map(
                    (pathName, i) => (
                      <MenuItem
                        value={pathName}
                        key={`conversion-path-ends-with-${i}`}
                      >
                        {pathName}
                      </MenuItem>
                    )
                  )}
                </CustomSelect>
              </Box>
            </Box>
          </Box>
        </>
      ) : null}

      <Box p={2} py={1} borderBottom={border}>
        <Typography color={"primary"} style={{ fontWeight: 700 }}>
          {tabValue === conversionPathTabValue ? "Contains" : "Channel"}
        </Typography>

        <Box style={{ textAlign: "-webkit-right" }}>
          <Box py={0.5}>
            <CustomCheckbox
              color={"default"}
              checked={channelFilter}
              disabled={
                channelsListBasedOnTabValue?.length === 0 ? true : false
              }
              onChange={(e) => {
                handleChannelFilterChange(e);
                GAEventTracker(
                  CustomerGroup(segment)().name,
                  "Channel Filter Check Box",
                  e.target.checked
                );
              }}
              indeterminate
              indeterminateIcon={<CheckCircleIcon />}
            />
            <CustomSelect
              disabled={!channelFilter}
              variant={"outlined"}
              value={channel}
              onChange={(e) => {
                handleChannelChange(e);
                GAEventTracker(
                  CustomerGroup(segment)().name,
                  "Channel Filter Value",
                  e.target.value
                );
              }}
            >
              {channelsListBasedOnTabValue?.map((channel, i) => (
                <MenuItem value={channel} key={i}>
                  {channel}
                </MenuItem>
              ))}
            </CustomSelect>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ConversionPathFilters;
