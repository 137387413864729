import { Typography } from "@mui/material";
import React from "react";

const LightGrayHeading4 = (props) => {
  const { color, fontSize, fontWeight, lineHeight, whiteSpace, children } = props;

  return (
    <Typography
      fontSize={fontSize ? fontSize : "1rem"}
      fontWeight={fontWeight ? fontWeight : 500}
      fontFamily={'"Lato", sans-serif'}
      color={color ? color : "#A4A4A4"}
      lineHeight={lineHeight ? lineHeight : "19.2px"}
      whiteSpace={whiteSpace ? whiteSpace : ""}
    >
      {children}
    </Typography>
  );
};

export default LightGrayHeading4;
