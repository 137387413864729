import React from "react"
import CssBaseline from "@mui/material/CssBaseline/CssBaseline";
import {Box, Button, Container, Dialog, Grid, TextField, Tooltip, Typography, useTheme} from "@mui/material";
import {makeStyles, withStyles} from '@mui/styles';
import CSRFToken from "../csrfToken"
import {clearChangePasswordStatus, get_user_data, password_change, password_popup_showed} from "../../actions/actions";
import {connect} from "react-redux";
import {OrangeFilledButton} from "../buttons";
import {border} from "../../assets/jss/commonStyles";
import Icons from "../icons";
import moment from "moment";

export const useStyles = makeStyles((theme) => ({
    form: {
        // width: '80%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        // fontSize: "1.25rem",
        margin: theme.spacing(3, 0, 2),
        // backgroundColor: theme.palette.primary.main,
        borderRadius: 3,
        width: "50%",
        textTransform: 'none',
        fontWeight: 700
    },
    successFont: {
        color: theme.palette.success.main,
        textAlign: 'center'
    },
    errorFont: {
        color: theme.palette.error.main,
        textAlign: 'center'
    },
    paperMd: {
        width: 600,
        minHeight: 200
    },
}));
export const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
        boxShadow: '4px 4px 10px rgba(0, 0, 0, 0.6)'
    },
}))(Tooltip);

function ChangePassword(props) {
    const theme = useTheme();
    const classes = useStyles();
    const {userProfile, passwordChange, password_change, open, setDialogOpen, clearChangePasswordStatus, get_user_data,password_popup_showed} = props;
    const [old_password, setOld_password] = React.useState('');
    const [new_password1, setNew_password1] = React.useState('');
    const [new_password2, setNew_password2] = React.useState('');
    let changeYears = moment(new Date()).diff(new Date(userProfile.password_updated), "year");
    let changeMonths = moment(new Date()).diff(moment(new Date(userProfile.password_updated)).add(changeYears, 'years'), "months");
    let changeDays = moment(new Date()).diff(moment(new Date(userProfile.password_updated)).add(changeYears, 'years').add(changeMonths, 'months'), "days");

    const checkPassword = (inputtxt) => {
        // const passw = /^(?=.*[\d])(?=.*[!@#$%^&*])[\w!@#$%^&*]{8,15}$/;
        const passw = /^(?=.*[\d])[\w!@#$%^&*]{8,25}$/;
        return !!inputtxt.match(passw);
    };

    const validateForm = () => {
        return (
            checkPassword(old_password) &&
            checkPassword(new_password1) &&
            checkPassword(new_password2) &&
            old_password !== new_password1 &&
            new_password1 === new_password2
        )
    };

    const handleSubmit = async (e, data) => {
        e.preventDefault();
        await password_change(e, new FormData(e.target));
        setOld_password('');
        setNew_password1('');
        setNew_password2('');
        await get_user_data();
    };
    const handleClose = () => {
        setDialogOpen(false);
        // clearChangePasswordStatus();
        // setOld_password('');
        // setNew_password1('');
        // setNew_password2('')
    };
    const handle_change = (e) => {
        clearChangePasswordStatus();
        const name = e.target.name;
        const value = e.target.value;
        name === 'old_password' ? setOld_password(value) : name === 'new_password1' ? setNew_password1(value) : setNew_password2(value)
    };

    return (
        <Dialog open={open} maxWidth={'md'} classes={{paperWidthMd: classes.paperMd}}>
            <Container component="main">
                <CssBaseline/>
                <Box pt={2} pb={1} borderBottom={border}>
                    <Grid container alignItems={'center'}>
                        <Grid item xs={6}>
                            <Box py={1.5}>
                                <Typography variant={'h4'} style={{fontWeight: 700}}>
                                    Change Password
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={6} style={{textAlign: 'end'}}>
                            {moment(new Date()).diff(new Date(userProfile.password_updated), "year") < 1 ?
                                <Button onClick={() => {
                                    handleClose();
                                    password_popup_showed();
                                }} style={{textTransform: 'none'}}>
                                    {Icons.closeIcon}
                                </Button>
                                : null}
                        </Grid>
                    </Grid>
                </Box>
                {!passwordChange.message && moment(new Date()).diff(new Date(userProfile.password_updated), "months") >= 11 ?
                    <Box py={1}>
                        <Typography>
                            It's
                            been {changeYears ? changeYears > 1 ? `${changeYears} years` : `${changeYears} year` : null} {changeMonths ? changeMonths > 1 ? `${changeMonths} months` : `${changeMonths} month` : null} {changeDays ? changeDays > 1 ? `${changeDays} days` : `${changeDays} day` : null} since
                            you last changed your password. Please consider changing your password for security
                            reasons.
                        </Typography>
                    </Box> : null
                }
                {passwordChange.message && passwordChange.message === 'Success' ? null :
                    <React.Fragment>
                        <form className={classes.form}
                              onSubmit={e => handleSubmit(e, {old_password, new_password1, new_password2})}>
                            <CSRFToken/>
                            <Grid container>
                                <Grid item xs={2}/>
                                <Grid item xs={8}>
                                    <Typography style={{fontWeight: 700}}>Old password</Typography>
                                    <Box mb={3}>
                                        <TextField
                                            variant={"outlined"}
                                            margin="none"
                                            required
                                            fullWidth
                                            // label="Old Password"
                                            type="password"
                                            name="old_password"
                                            inputProps={{style: {padding: 10}}}
                                            InputProps={{style: {fontSize: "1rem", color: theme.palette.primary.main}}}
                                            InputLabelProps={{
                                                style: {
                                                    fontSize: "0.875rem",
                                                    color: theme.palette.primary.main
                                                }
                                            }}
                                            value={old_password}
                                            onChange={handle_change}
                                            autoFocus
                                        />
                                    </Box>
                                    <Typography component={'span'} style={{fontWeight: 700}}>New
                                        password</Typography>&ensp;<HtmlTooltip
                                    title={<Typography variant={"body2"} color={'primary'}>
                                        <ul style={{padding: 8, margin: 8, marginTop: 0, marginBottom: 0}}>
                                            <li>Your password can't be too similar to your other personal information.
                                            </li>
                                            <li>Your password must contain at least 8 characters.</li>
                                            <li>Your password can't be a commonly used password.</li>
                                            <li>Your password can't be entirely numeric.</li>
                                        </ul>
                                    </Typography>} placement={"right"}
                                    interactive>{Icons.infoCircleIconOutlineMargin}</HtmlTooltip>
                                    <Box mb={3}>
                                        <TextField
                                            variant="outlined"
                                            margin="none"
                                            fullWidth
                                            required
                                            name="new_password1"
                                            // label="New Password"
                                            type="password"
                                            inputProps={{style: {padding: 10}}}
                                            InputProps={{style: {fontSize: "1rem", color: theme.palette.primary.main}}}
                                            InputLabelProps={{
                                                style: {
                                                    fontSize: "0.875rem",
                                                    color: theme.palette.primary.main
                                                }
                                            }}
                                            value={new_password1}
                                            onChange={handle_change}
                                        />
                                    </Box>
                                    <Typography style={{fontWeight: 700}}>Re-enter new password</Typography>
                                    <Box mb={3}>
                                        <TextField
                                            variant="outlined"
                                            margin="none"
                                            required
                                            fullWidth
                                            name="new_password2"
                                            // label="Re Enter New Password"
                                            type="password"
                                            inputProps={{style: {padding: 10}}}
                                            InputProps={{style: {fontSize: "1rem", color: theme.palette.primary.main}}}
                                            InputLabelProps={{
                                                style: {
                                                    fontSize: "0.875rem",
                                                    color: theme.palette.primary.main
                                                }
                                            }}
                                            value={new_password2}
                                            onChange={handle_change}
                                        />
                                    </Box>
                                    <div style={{textAlign: 'center'}}>
                                        <OrangeFilledButton
                                            type="submit"
                                            disabled={!validateForm()}
                                            variant="contained"
                                            className={classes.submit}
                                        >
                                            Save
                                        </OrangeFilledButton>
                                    </div>
                                </Grid>
                                <Grid item xs={2}/>
                            </Grid>
                        </form>
                    </React.Fragment>}
                <Grid container>
                    <Grid item xs={2}/>
                    <Grid item xs={8} style={{textAlign:"center"}}>
                        {passwordChange.message ?
                            passwordChange.message === 'Success' ?
                                <Box py={3}>
                                <Typography
                                    color={"primary"}
                                    variant={"h1"}
                                    style={{fontWeight: 700}}
                                >
                                    Success!
                                </Typography>
                                    <br/>
                                    <Typography color={"primary"} variant={"body1"}>
                                        Your password has been changed successfully!
                                    </Typography>
                                    <br/>
                                    <br/>
                                    {Icons.successIcon}

                                </Box> :
                                <Typography className={classes.errorFont}>
                                    Password Change Failed! {passwordChange.data[Object.keys(passwordChange.data)[0]][0].replace('password',`${Object.keys(passwordChange.data)[0].slice(0,3)} password`)}
                                </Typography>
                            : null
                        }
                        {(old_password === new_password1) && old_password !== '' ?
                            <Typography className={classes.errorFont}>
                                New Password cannot be same as Old Password
                            </Typography> : null}
                    </Grid>
                    <Grid item xs={2}/>
                </Grid>
            </Container>
        </Dialog>
    )
}

const mapStateToProps = (state) => ({
    passwordChange: state.passwordChange,
    userProfile: state.userProfile,
    application_states:state.application_states
});
const mapDispatchToProps = (dispatch) => ({
    password_change: (e, data) => dispatch(password_change(e, data)),
    get_user_data: () => dispatch(get_user_data()),
    clearChangePasswordStatus: () => dispatch(clearChangePasswordStatus()),
    password_popup_showed:()=>dispatch(password_popup_showed())
});

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword)