import { store } from "../../App";
import { PRODUCT_TOUR_RUN_END } from "../../actions/actionTypes";
import { settingConnectionsURL } from "../sidebar";

const handleKeyPress = (event) => {
  if (event.key === "ArrowRight") {
    window.hopscotch.nextStep(); // Move to the next step on `>`
  } else if (event.key === "ArrowLeft") {
    window.hopscotch.prevStep(); // Move to the previous step on `<`
  }
};

export const tour = {
  id: "hello-hopscotch",
  onEnd: async function () {
    window.hopscotch.endTour(true);
    await store.dispatch({ type: PRODUCT_TOUR_RUN_END });
    window.location.hash = "/";
  },
  /*onStart: async function () {
        await store.dispatch({type: PRODUCT_TOUR_RUN_START})
        document.getElementsByClassName("hopscotch-bubble-number").innerHTML = "";
    },*/
  onClose: async function () {
    // alert("onclose");
    window.hopscotch.endTour(true);
    await store.dispatch({ type: PRODUCT_TOUR_RUN_END });
    window.location.hash = "/";
  },
  onError: async function () {
    window.hopscotch.endTour(true);
    await store.dispatch({ type: PRODUCT_TOUR_RUN_END });
    window.location.hash = "/";
  },
  steps: [
    {
      target: "tour1stStep",
      title: "",
      // content:
      //   "The side menu lets you navigate anywhere in LXRInsights. The Customers section provides performance analytics. The Audience section allows you to push audiences to different ad platforms and view audience analytics. The Predictions section provides insights on predicted customer behavior.",
      content:
        "The sidebar allows easy navigation across the platform. Use it to access a comprehensive overview of performance analytics, manage your audiences, and view predictive insights, all in one convenient location.",
      placement: "right",
      delay: 100,
      onShow: async () => {
        document.getElementsByClassName("hopscotch-bubble-number").innerHTML =
          "";
        document.addEventListener("keydown", handleKeyPress);
      },
      highlight: {
        disableClick: true,
      },
    },
    {
      target: "tour2ndStep",
      title: "",
      content:
        "Information icons offer helpful tips to help you understand the information you are viewing.",
      placement: "bottom",
      xOffset: -10,
      yOffset: -10,
      highlight: {
        disableClick: true,
      },
      onShow: function () {
        document.addEventListener("keydown", handleKeyPress);
      },
    },
    {
      target: "tour3rdStep",
      title: "",
      content: "The wand provides AI-generated insights about your data.",
      placement: "bottom",
      arrowOffset: 15,
      highlight: {
        disableClick: true,
      },
      onShow: function () {
        document.addEventListener("keydown", handleKeyPress);
      },
    },
    {
      target: "tour4thStep",
      title: "",
      content:
        "LXRInsights runs off of the last 13 months of data. This date range cannot be changed. However, you can view different ranges within the 13 month period on the Customers page; changeable dates are indicated in orange.",
      placement: "bottom",
      arrowOffset: "center",
      yOffset: -10,
      highlight: {
        disableClick: true,
      },
      onShow: function () {
        document.addEventListener("keydown", handleKeyPress);
      },
    },
    {
      target: "tour5thStep",
      title: "",
      content: "The Pie Chart is interactive when you hover over the slices.",
      placement: "bottom",
      arrowOffset: "center",
      yOffset: -100,
      // xOffset: 30,
      highlight: {
        disableClick: true,
      },
      onShow: function () {
        document.addEventListener("keydown", handleKeyPress);
      },
    },
    {
      target: "tour5(1)thStep",
      title: "",
      content:
        "The Switch to Compare feature lets you easily compare different segments or dates side by side. ",
      placement: "bottom",
      highlight: {
        disableClick: true,
      },
      xOffset:200,
      onShow: function () {
        document.addEventListener("keydown", handleKeyPress);
      },
    },
    {
      target: "tour6thStep",
      title: "",
      content:
        "The help icon provides the options to <ul><li>Go through help guides (FAQs) - where you can find definitions and examples of how to use the tool</li><li>Go through Product Tour</li><li>Contact Us</li>",
      placement: "left",
      xOffset: 20,
      yOffset: 20,
      multipage: true,
      onNext: async () => {
        window.location.hash = "/customer-analytics/a";
      },
      highlight: {
        disableClick: true,
      },
      onShow: function () {
        document.addEventListener("keydown", handleKeyPress);
      },
    },
    {
      target: "tour7thStep",
      title: "",
      content:
        "The Download icon allows you to download the audience containing hashed email addresses of customers for uploading it to other marketing platforms. ",
      placement: "left",
      arrowOffset: "left",
      onPrev: async () => {
        window.location.hash = "/";
      },
      highlight: {
        disableClick: true,
      },
      onShow: function () {
        document.addEventListener("keydown", handleKeyPress);
      },
      // xOffset: -10
    },
    {
      target: "tour7(1)thStep",
      title: "",
      content:
        "The Re-market icon allows you to push the audience to connected marketing platforms in just a few clicks.",
      placement: "left",
      arrowOffset: "left",
      highlight: {
        disableClick: true,
      },
      onShow: function () {
        document.addEventListener("keydown", handleKeyPress);
      },
      // xOffset: -10
    },
    {
      target: "tour8thStep",
      title: "",
      content:
        "The Key Metrics section gives you valuable averages and insights into customer purchase behavior. Each topic is clickable for more specific information.",
      placement: "bottom",
      highlight: {
        disableClick: true,
      },
      onShow: function () {
        document.addEventListener("keydown", handleKeyPress);
      },
    },
    {
      target: "tour9thStep",
      title: "",
      content:
        "These headers are clickable to give you trend data analytics for different aspects of your business.",
      placement: "bottom",
      highlight: {
        disableClick: true,
      },
      onShow: function () {
        document.addEventListener("keydown", handleKeyPress);
      },
    },
    {
      target: "tour10thStep",
      title: "",
      content:
        "You can select any time period within a 13-month calendar to analyze different ranges of data.",
      placement: "bottom",
      yOffset: 10,
      highlight: {
        disableClick: true,
      },
      onShow: function () {
        document.addEventListener("keydown", handleKeyPress);
      },
    },
    {
      target: "tour11thStep",
      title: "",
      content:
        "The filters icon lets you dive deeper into sales analytics by narrowing your focus on specific criteria.",
      placement: "left",
      multipage: true,
      onNext: async () => {
        window.location.hash = "/product_analytics";
      },
      highlight: {
        disableClick: true,
      },
      onShow: function () {
        document.addEventListener("keydown", handleKeyPress);
      },
    },
    {
      target: "tour12thStep",
      title: "",
      content:
        "Products are ranked by their revenue impact, with sortable details such as name, price, and total orders. Frequently bought product pairs are shown alongside key metrics like customer count, bundled price, and revenue. Category sales performance is visually represented, highlighting the number of orders in each region.",
      placement: "bottom",
      onPrev: async () => {
        window.location.hash = "/customer-analytics/a";
      },
      highlight: {
        disableClick: true,
      },
      onShow: function () {
        document.addEventListener("keydown", handleKeyPress);
      },
    },
    {
      target: "tour12(1)thStep",
      title: "",
      content: " Download Reports for offline analysis.",
      placement: "left",
      yOffset: -40,
      multipage: true,
      onNext: async () => {
        window.location.hash = "/predictions";
      },
      highlight: {
        disableClick: true,
      },
      onShow: function () {
        document.addEventListener("keydown", handleKeyPress);
      },
    },
    {
      target: "tour13thStep",
      title: "",
      content:
        "The Predictions page provides insights from LXRI's AI for returning single buyers, customers who are likely to churn in the near future, and their predicted conversion value.",
      placement: "bottom",
      onPrev: async () => {
        window.location.hash = "/product_analytics";
      },
      multipage: true,
      onNext: async () => {
        window.location.hash = "/customers/audience";
      },
      highlight: {
        disableClick: true,
      },
      onShow: function () {
        document.addEventListener("keydown", handleKeyPress);
      },
    },
    // {
    //   target: "tour14thStep",
    //   title: "",
    //   content:
    //     "The slider allows you to dial in and specifically target a segment of your shoppers within the AI-generated audience.",
    //   placement: "top",
    //   multipage: true,
    //   onNext: async () => {
    //     window.location.hash = "/customers/audience";
    //   },
    //   highlight: {
    //     disableClick: true,
    //   },
    //   onShow: function () {
    //     document.addEventListener("keydown", handleKeyPress);
    //   },
    // },
    {
      target: "tour19thStep",
      title: "",
      content:
        "Clicking on an audience reveals detailed charts and table views, with the option to push the audience to connected marketing channels.",
      placement: "top",
      onPrev: async () => {
        window.location.hash = "/predictions";
      },
      highlight: {
        disableClick: true,
      },
      onShow: function () {
        document.addEventListener("keydown", handleKeyPress);
      },
    },
    {
      target: "tour20thStep",
      title: "",
      content:
        "Create New Audience allows you to build custom audiences based on applied filters.",
      placement: "left",
      multipage: true,
      onNext: async () => {
        window.location.hash = "/customers/synced-audiences";
      },
      onShow: function () {
        document.addEventListener("keydown", handleKeyPress);
      },
      // onPrev: async () => {
      //   window.location.hash = "/experiment";
      // },
      highlight: {
        disableClick: true,
      },
    },
    {
      target: "tour21stStep",
      title: "",
      content:
        " Audiences pushed to connected marketing channels are displayed here. Clicking on an audience provides detailed insights and analytics for the synced audience.",
      placement: "top",
      xOffset: 20, // Horizontal offset (left)
      onPrev: async () => {
        window.location.hash = "/customers/audience";
      },
      highlight: {
        disableClick: true,
      },
    },
    {
      target: "tour22ndStep",
      title: "",
      content: "View History displays the status of synced audiences.",
      placement: "left",
      highlight: {
        disableClick: true,
      },
    },
    {
      //experiment-summary
      target: "tour23rdStep",
      title: "",
      content:
        "Manage and Create new or  pre-defined experiments here <br/><br/>With the pushed audience, you can create experiments to test and optimize your campaigns. Run controlled experiments to identify what works best for your goals, test different audience variations from our AI-segmented lists to boost customer retention, and use lookalike audiences to enhance new user acquisitions.",
      placement: "right",
      multipage: true,
      yOffset:120,
      onNext: async () => {
        window.location.hash = "/help_center/categories";
      },
      onShow: function () {
        document.addEventListener("keydown", handleKeyPress);
      },
      highlight: {
        disableClick: true,
      },
    },
    {
      //helpcenter
      target: "tour24thStep",
      title: "",
      content:
        "The Help Center is your go-to resource for answers to all your questions. You can explore our pre-set categories or enter keywords in the search bar to quickly find the information you need.",
      placement: "bottom",
      multipage: true,
      onPrev: async () => {
        window.location.hash = "/customers/synced-audiences";
      },
      onNext: async () => {
        window.location.hash = "/settings/users";
      },
      highlight: {
        disableClick: false,
      },
      onShow: function () {
        document.addEventListener("keydown", handleKeyPress);
      },
    },
    {
      //settings-user
      target: "tour25thStep",
      title: "",
      content:
        "Here, you can find user and login information and connected website details.",
      placement: "bottom",
      multipage: true,
      onNext: async () => {
        window.location.hash = settingConnectionsURL;
      },
      onPrev: async () => {
        window.location.hash = "/help_center/categories";
      },
      highlight: {
        disableClick: true,
      },
      onShow: function () {
        document.addEventListener("keydown", handleKeyPress);
      },
    },
    // {
    //   //settings-website
    //   target: "tour26thStep",
    //   title: "",
    //   content: "This section contains details about the website.",
    //   placement: "bottom",
    //   highlight: {
    //     disableClick: true,
    //   },
    //   multipage: true,
    //   onNext: async () => {
    //     window.location.hash = "/settings/data-sources";
    //   },
    //   onPrev: async () => {
    //     window.location.hash = "/settings/users";
    //   },
    //   onShow: function () {
    //     document.addEventListener("keydown", handleKeyPress);
    //   },
    // },
    {
      //settings-datasources
      target: "tour27thStep",
      title: "",
      content: "This is where you integrate data sources with LXRinsights.",
      placement: "bottom",
      onPrev: async () => {
        window.location.hash = "/settings/users";
      },
      xOffset: 10, // Horizontal offset (left)
      yOffset: 60,
      multipage: true,
      highlight: {
        disableClick: true,
      },
    },
  ],
  showPrevButton: !0,
  scrollDuration: 500,
  showCloseButton: !0,
  bubblePadding: 0,
  skipIfNoElement: true,
  bubbleWidth: 320,
  i18n: {
    closeTooltip: "Skip Tour",
    skipBtn: "Skip Tour",
  },
  // scrollTopMargin: 100
};
