import React from "react";
import arrowLine2 from "../assets/images/Arrow Line 2.svg";
import arrowLine1 from "../assets/images/ArrowLine 1.svg";
import LXRInsightsWhiteLogo from "../assets/images/LXRInsightsWhiteLogo.png";
import accountSettings from "../assets/images/accountSettings.svg";
import addCircleOutline from "../assets/images/addCircleOutline.svg";
import allCustomersIcon from "../assets/images/allCustomersIcon.svg";
import productAnalyticsIcon from "../assets/images/analytics.png";
import analyzingProcess from "../assets/images/analyzing_process.png";
import facesArrow from "../assets/images/arrow.png";
import arrowTimeLineImg from "../assets/images/arrowWithTimelineText.svg";
import backArrowImg from "../assets/images/arrow_back.svg";
import ascArrow from "../assets/images/ascArrow.svg";
import audienceAnalyticsPageIcon from "../assets/images/audienceAnalyticsPageIcon.svg";
import aud1 from "../assets/images/audienceManagerImages/aud1.svg";
import aud2 from "../assets/images/audienceManagerImages/aud2.svg";
import audienceImg from "../assets/images/audience_orange.svg";
import authFailed from "../assets/images/authFailed.png";
import authSuccess from "../assets/images/authSuccess.png";
import basketWhite from "../assets/images/basketwhite.svg";
import bigcomlogo from "../assets/images/bigcommerce.png";
import bigCommerce from "../assets/images/bigcommerce.svg";
import cartIcon from "../assets/images/cartIcon.svg";
import chatgptIcon from "../assets/images/chatgptIcon.svg";
import closeIcon from "../assets/images/closeIcon.svg";
import connectionsPageIcon from "../assets/images/connectionsPageIcon.svg";
import contactUsHeaderIcon from "../assets/images/contactUsHeaderIcon.svg";
import contentCopyIcon from "../assets/images/contentCopyIcon.svg";
import convPathTabIcon from "../assets/images/convPathTabIcon.svg";
import cumulativeBTabIcon from "../assets/images/cumulativeBFlow.png";
import cumulativeWTabIcon from "../assets/images/cumulativeWFlow.png";
import customerJourney from "../assets/images/customerJourney.png";
import customerRetention from "../assets/images/customerRetention.png";
import customerTabIcon from "../assets/images/customerTabIcon.svg";
import customerTrendIcon from "../assets/images/customerTrendIcon.png";
// import dataIcon from "../mobileHeaderBannerassets/images/dataIcon.svg";
import dataNotEnough from "../assets/images/dataNotEnough.png";
import deleteOrangeColor from "../assets/images/deleteOrangeColor.svg";
import descArrow from "../assets/images/descArrow.svg";
import downloadBlueLineIcon from "../assets/images/downloadBlueLineIcon.svg";
import downloadIcon from "../assets/images/downloadIcon.svg";
import downloadOraneIcon from "../assets/images/downloadOrangeIcon.svg";
import editIcon from "../assets/images/editIcon.svg";
import editOrangeColor from "../assets/images/editOrangeColor.svg";
import emailVerifyFailed from "../assets/images/email_verify_failed.png";
import emailVerifySuccess from "../assets/images/email_verify_success.png";
import errorWhileCallingAPI from "../assets/images/errorWhileCallingAPI.png";
import errorWhileTracking from "../assets/images/errorWhileTracking.png";
import exclamation from "../assets/images/exclamation.svg";
import experimentIcon from "../assets/images/experimentIcon.svg";
import experimentIcon2 from "../assets/images/experimentIcon2.svg";
import dispCamp from "../assets/images/experimentationImages/Display.png";
import PMaxCamp from "../assets/images/experimentationImages/PMaxCamp.png";
import searchCamp from "../assets/images/experimentationImages/Search.png";
import arrowRight from "../assets/images/experimentationImages/arrowRight.png";
import arrowRightOrange from "../assets/images/experimentationImages/arrowRightOrange.svg";
import buttonSlider1 from "../assets/images/experimentationImages/buttonSlider1.svg";
import buttonSlider2 from "../assets/images/experimentationImages/buttonSlider2.svg";
import createExpPredef from "../assets/images/experimentationImages/createExpPredef.svg";
import createExpPredefSel from "../assets/images/experimentationImages/createExpPredefSel.svg";
import createExpScratch from "../assets/images/experimentationImages/createExpScratch.svg";
import createExpScratchSel from "../assets/images/experimentationImages/createExpScratchSel.svg";
import exp1 from "../assets/images/experimentationImages/exp1.svg";
import exp10 from "../assets/images/experimentationImages/exp10.svg";
import exp11 from "../assets/images/experimentationImages/exp11.svg";
import exp12 from "../assets/images/experimentationImages/exp12.png";
import exp13 from "../assets/images/experimentationImages/exp13.png";
import exp14 from "../assets/images/experimentationImages/exp14.svg";
import exp15 from "../assets/images/experimentationImages/exp15.svg";
import exp16 from "../assets/images/experimentationImages/exp16.svg";
import exp17 from "../assets/images/experimentationImages/exp17.svg";
import exp18 from "../assets/images/experimentationImages/exp18.png";
import exp2 from "../assets/images/experimentationImages/exp2.svg";
import exp3 from "../assets/images/experimentationImages/exp3.svg";
import exp4 from "../assets/images/experimentationImages/exp4.svg";
import exp5 from "../assets/images/experimentationImages/exp5.svg";
import exp6 from "../assets/images/experimentationImages/exp6.svg";
import exp7 from "../assets/images/experimentationImages/exp7.svg";
import exp8 from "../assets/images/experimentationImages/exp8.svg";
import exp9 from "../assets/images/experimentationImages/exp9.svg";
import expRes1 from "../assets/images/experimentationImages/expRes1.svg";
import googleAdsLogo from "../assets/images/experimentationImages/googleAdsIcon.svg";
import launchIcon from "../assets/images/experimentationImages/launchIcon.svg";
import metaLogo from "../assets/images/experimentationImages/metaAdsIcon.svg";
import facesBanner from "../assets/images/facesBanner.png";
import failureIcon from "../assets/images/failureIcon.svg";
import ga_integration_step1 from "../assets/images/ga_integration_step1.png";
import ga_integration_step2 from "../assets/images/ga_integration_step2.png";
import gdprLogo from "../assets/images/gdprLogo.png";
import googleAds from "../assets/images/googleAds.png";
import googleAdsIcon from "../assets/images/googleAdsIcon.png";
import greenLineArrow from "../assets/images/greenLineArrow.svg";
import headerBanner from "../assets/images/headerBanner.png";
import helloIcon from "../assets/images/helloIcon.svg";
import expHelp1 from "../assets/images/helpCenterImages/ExperimentImages/expHelp1.png";
import expHelp2 from "../assets/images/helpCenterImages/ExperimentImages/expHelp2.png";
import expHelp3 from "../assets/images/helpCenterImages/ExperimentImages/expHelp3.png";
import expHelp4 from "../assets/images/helpCenterImages/ExperimentImages/expHelp4.png";
import expHelp5 from "../assets/images/helpCenterImages/ExperimentImages/expHelp5.png";
import expHelp6 from "../assets/images/helpCenterImages/ExperimentImages/expHelp6.png";
import expHelp7 from "../assets/images/helpCenterImages/ExperimentImages/expHelp7.png";
import bs1 from "../assets/images/helpCenterImages/bigcommerce/generic/bs1.png";
import bs2 from "../assets/images/helpCenterImages/bigcommerce/generic/bs2.jpg";
import bs3 from "../assets/images/helpCenterImages/bigcommerce/generic/bs3.jpg";
import bs4 from "../assets/images/helpCenterImages/bigcommerce/generic/bs4.jpg";
import btgs1 from "../assets/images/helpCenterImages/bigcommerce/generic/btgs1.png";
import btgs2 from "../assets/images/helpCenterImages/bigcommerce/generic/btgs2.png";
import btos1 from "../assets/images/helpCenterImages/bigcommerce/thankyou/btos1.png";
import btos2 from "../assets/images/helpCenterImages/bigcommerce/thankyou/btos2.png";
import gtgs1 from "../assets/images/helpCenterImages/gtm/generic/gtgs1.png";
import gtgs2 from "../assets/images/helpCenterImages/gtm/generic/gtgs2.png";
import gts1 from "../assets/images/helpCenterImages/gtm/generic/gts1.jpg";
import gts10 from "../assets/images/helpCenterImages/gtm/generic/gts10.png";
import gts11 from "../assets/images/helpCenterImages/gtm/generic/gts11.jpg";
import gts12 from "../assets/images/helpCenterImages/gtm/generic/gts12.png";
import gts13 from "../assets/images/helpCenterImages/gtm/generic/gts13.jpg";
import gts14 from "../assets/images/helpCenterImages/gtm/generic/gts14.jpg";
import gts15 from "../assets/images/helpCenterImages/gtm/generic/gts15.png";
import gts16 from "../assets/images/helpCenterImages/gtm/generic/gts16.png";
import gts17 from "../assets/images/helpCenterImages/gtm/generic/gts17.jpg";
import gts2 from "../assets/images/helpCenterImages/gtm/generic/gts2.jpg";
import gts3 from "../assets/images/helpCenterImages/gtm/generic/gts3.jpg";
import gts4 from "../assets/images/helpCenterImages/gtm/generic/gts4.jpg";
import gts5 from "../assets/images/helpCenterImages/gtm/generic/gts5.jpg";
import gts6 from "../assets/images/helpCenterImages/gtm/generic/gts6.jpg";
import gts7 from "../assets/images/helpCenterImages/gtm/generic/gts7.jpg";
import gts8 from "../assets/images/helpCenterImages/gtm/generic/gts8.jpg";
import gts9 from "../assets/images/helpCenterImages/gtm/generic/gts9.jpg";
import gtstr from "../assets/images/helpCenterImages/gtm/generic/gtstr.png";
import dtl from "../assets/images/helpCenterImages/gtm/thankyou/dtl.png";
import gttgs1 from "../assets/images/helpCenterImages/gtm/thankyou/gttgs1.png";
import gttgs2 from "../assets/images/helpCenterImages/gtm/thankyou/gttgs2.png";
import gtts1 from "../assets/images/helpCenterImages/gtm/thankyou/gtts1.png";
import gtts11 from "../assets/images/helpCenterImages/gtm/thankyou/gtts11.jpg";
import gtts12 from "../assets/images/helpCenterImages/gtm/thankyou/gtts12.png";
import gtts13 from "../assets/images/helpCenterImages/gtm/thankyou/gtts13.png";
import gtts14 from "../assets/images/helpCenterImages/gtm/thankyou/gtts14.jpg";
import gtts15 from "../assets/images/helpCenterImages/gtm/thankyou/gtts15.png";
import gtts16 from "../assets/images/helpCenterImages/gtm/thankyou/gtts16.png";
import gtts2 from "../assets/images/helpCenterImages/gtm/thankyou/gtts2.jpg";
import gtts5 from "../assets/images/helpCenterImages/gtm/thankyou/gtts5.jpg";
import gtts6 from "../assets/images/helpCenterImages/gtm/thankyou/gtts6.jpg";
import gtts7 from "../assets/images/helpCenterImages/gtm/thankyou/gtts7.jpg";
import gtts8 from "../assets/images/helpCenterImages/gtm/thankyou/gtts8.jpg";
import gtts9 from "../assets/images/helpCenterImages/gtm/thankyou/gtts9.jpg";
import gttsplus from "../assets/images/helpCenterImages/gtm/thankyou/gttsplus.png";
import gttstr from "../assets/images/helpCenterImages/gtm/thankyou/gttstr.png";
import gttstrb from "../assets/images/helpCenterImages/gtm/thankyou/gttstrb.png";
import ms1 from "../assets/images/helpCenterImages/magento/generic/ms1.png";
import ms2 from "../assets/images/helpCenterImages/magento/generic/ms2.jpg";
import ms3 from "../assets/images/helpCenterImages/magento/generic/ms3.jpg";
import ms4 from "../assets/images/helpCenterImages/magento/generic/ms4.jpg";
import ms5 from "../assets/images/helpCenterImages/magento/generic/ms5.jpg";
import mtgs1 from "../assets/images/helpCenterImages/magento/generic/mtgs1.png";
import mtgs2 from "../assets/images/helpCenterImages/magento/generic/mtgs2.png";
import mtos1 from "../assets/images/helpCenterImages/magento/thankyou/mtos1.png";
import mtos2 from "../assets/images/helpCenterImages/magento/thankyou/mtos2.png";
import mts from "../assets/images/helpCenterImages/magento/thankyou/mts.png";
import orderKeyInsights from "../assets/images/helpCenterImages/orderKeyInsights.png";
import overviewComparision from "../assets/images/helpCenterImages/overviewComparision.png";
import priceKeyInsights from "../assets/images/helpCenterImages/priceKeyInsights.png";
import productKeyInsights from "../assets/images/helpCenterImages/productKeyInsights.png";
import productMonthlyInsights from "../assets/images/helpCenterImages/productMonthlyInsights.png";
import purchasePathKeyInsights from "../assets/images/helpCenterImages/purchasePathKeyInsights.png";
import reMarketDataPoints from "../assets/images/helpCenterImages/reMarketDataPoints.png";
import reMarketDialog from "../assets/images/helpCenterImages/reMarketDialog.png";
import reMarketFilter from "../assets/images/helpCenterImages/reMarketFilter.png";
import reMarketUnavailableDialog from "../assets/images/helpCenterImages/reMarketUnavailableDialog.png";
import ss1 from "../assets/images/helpCenterImages/shopify/generic/ss1.jpg";
import ss10 from "../assets/images/helpCenterImages/shopify/generic/ss10.jpg";
import ss2 from "../assets/images/helpCenterImages/shopify/generic/ss2.jpg";
import ss3 from "../assets/images/helpCenterImages/shopify/generic/ss3.png";
import ss4 from "../assets/images/helpCenterImages/shopify/generic/ss4.jpg";
import ss5 from "../assets/images/helpCenterImages/shopify/generic/ss5.jpg";
import ss6 from "../assets/images/helpCenterImages/shopify/generic/ss6.jpg";
import ss7 from "../assets/images/helpCenterImages/shopify/generic/ss7.jpg";
import ss8 from "../assets/images/helpCenterImages/shopify/generic/ss8.jpg";
import ss9 from "../assets/images/helpCenterImages/shopify/generic/ss9.jpg";
import stgs1 from "../assets/images/helpCenterImages/shopify/generic/stgs1.png";
import stgs2 from "../assets/images/helpCenterImages/shopify/generic/stgs2.png";
import stos1 from "../assets/images/helpCenterImages/shopify/thankyou/stos1.png";
import stos2 from "../assets/images/helpCenterImages/shopify/thankyou/stos2.png";
import sts1 from "../assets/images/helpCenterImages/shopify/thankyou/sts1.jpg";
import sts2 from "../assets/images/helpCenterImages/shopify/thankyou/sts2.jpg";
import sts3 from "../assets/images/helpCenterImages/shopify/thankyou/sts3.png";
import sts4 from "../assets/images/helpCenterImages/shopify/thankyou/sts4.jpg";
import sts5 from "../assets/images/helpCenterImages/shopify/thankyou/sts5.jpg";
import sts6 from "../assets/images/helpCenterImages/shopify/thankyou/sts6.jpg";
import sts7 from "../assets/images/helpCenterImages/shopify/thankyou/sts7.jpg";
import sts8 from "../assets/images/helpCenterImages/shopify/thankyou/sts8.jpg";
import add_source from "../assets/images/helpCenterImages/signup/add_source_shopify.png";
import create_account from "../assets/images/helpCenterImages/signup/create_account.png";
import email_verification_page from "../assets/images/helpCenterImages/signup/email_verification_page.png";
import overview from "../assets/images/helpCenterImages/signup/overview.png";
import shopify_data_status from "../assets/images/helpCenterImages/signup/shopify_data_status.png";
import shopify_granting_page from "../assets/images/helpCenterImages/signup/shopify_granting_page.png";
import shopify_login from "../assets/images/helpCenterImages/signup/shopify_login.png";
import shopify_store from "../assets/images/helpCenterImages/signup/shopify_store.png";
import ws1 from "../assets/images/helpCenterImages/woocommerce/generic/ws1.png";
import ws2 from "../assets/images/helpCenterImages/woocommerce/generic/ws2.jpg";
import ws3 from "../assets/images/helpCenterImages/woocommerce/generic/ws3.jpg";
import ws4 from "../assets/images/helpCenterImages/woocommerce/generic/ws4.jpg";
import ws5 from "../assets/images/helpCenterImages/woocommerce/generic/ws5.jpg";
import ws6 from "../assets/images/helpCenterImages/woocommerce/generic/ws6.jpg";
import wtgs1 from "../assets/images/helpCenterImages/woocommerce/generic/wtgs1.png";
import wtgs2 from "../assets/images/helpCenterImages/woocommerce/generic/wtgs2.png";
import wtos1 from "../assets/images/helpCenterImages/woocommerce/thankyou/wtos1.png";
import wtos2 from "../assets/images/helpCenterImages/woocommerce/thankyou/wtos2.png";
import wts2 from "../assets/images/helpCenterImages/woocommerce/thankyou/wts2.jpg";
import wts3 from "../assets/images/helpCenterImages/woocommerce/thankyou/wts3.jpg";
import wts4 from "../assets/images/helpCenterImages/woocommerce/thankyou/wts4.jpg";
import wts5 from "../assets/images/helpCenterImages/woocommerce/thankyou/wts5.jpg";
import wts6 from "../assets/images/helpCenterImages/woocommerce/thankyou/wts6.jpg";
import heroBanner from "../assets/images/heroBanner1.png";
import historyIcon from "../assets/images/historyIcon.svg";
import ccpa from "../assets/images/homePageImages/ccpa.png";
import poweredByNetelixirLogo from "../assets/images/poweredByNetelixirLogo.png";
import openNewTabIcon from "../assets/images/openNewTabIcon.png";
import ccpa1 from "../assets/images/homePageImages/ccpa1.png";
import gdpr1 from "../assets/images/homePageImages/gdpr1.png";
import ccpaMobile from "../assets/images/homePageImages/ccpamobile.png";
import gdpr from "../assets/images/homePageImages/gdpr.png";
import gdprMobile from "../assets/images/homePageImages/gdprmobile.png";
import mobileHeaderBanner from "../assets/images/homePageImages/mobileHeaderBanner.png";
import amikaLogo from "../assets/images/homePageSlider/amikaLogo.png";
import elfLogo from "../assets/images/homePageSlider/elfLogo.png";
import evanycLogo from "../assets/images/homePageSlider/evanycLogo.png";
import lenovoLogo from "../assets/images/homePageSlider/lenovoLogo.png";
import rocklerLogo from "../assets/images/homePageSlider/rocklerLogo.png";
import stevenSingerLogo from "../assets/images/homePageSlider/stevenSingerLogo.png";
import individualBFlow from "../assets/images/individualBFlow.png";
import individualWFlow from "../assets/images/individualWFlow.png";
import infoCircle from "../assets/images/infoCircle.svg";
import infoCircleIconOutline from "../assets/images/infoCircleIconOutline.svg";
import insightsWhiteLogo from "../assets/images/insightsWhiteLogo.svg";
import integration from "../assets/images/integration.png";
import integrationIcon from "../assets/images/integrationIcon.svg";
import ixFrame from "../assets/images/ixFrame.png";
import nxLogo from "../assets/images/logo.png";
import lxrLogo from "../assets/images/lxrLogo.png";
import lxrTracking from "../assets/images/lxrtracking.svg";
import magicWand from "../assets/images/magicWand.svg";
import mailIcon from "../assets/images/mailIcon.svg";
import metaIcon from "../assets/images/metaIcon.svg";
import mfaVerificationSuccess from "../assets/images/mfaVerificationSuccess.png";
import MFAVerification from "../assets/images/mfa_verification.png";
import multiPaidPathArrow from "../assets/images/multiPaidPathArrow.svg";
import newFilterIcon from "../assets/images/newFilterIcon.svg";
import newHighCustomersIcon from "../assets/images/newHighCustomersIcon.svg";
import newLowCustomersIcon from "../assets/images/newLowCustomersIcon.svg";
import newMidCustomersIcon from "../assets/images/newMidCustomersIcon.svg";
import newRemarketIcon from "../assets/images/newRemarketIcon.svg";
import newWhiteFilterIcon from "../assets/images/newWhiteFilterIcon.svg";
import normalGraphTabIcon from "../assets/images/normalGraphTabIcon.svg";
import notEnoughData from "../assets/images/notEnoughData.png";
import orangeMagicWand from "../assets/images/orangeMagicWand.svg";
import orderTabIcon from "../assets/images/orderTabIcon.svg";
import overviewIcon from "../assets/images/overviewIcon.svg";
import placeholder from "../assets/images/placeholder.jpg";
import productAnalyticsHeaderIcon from "../assets/images/productPageHeaderIcon.svg";
import productTourHeaderIcon from "../assets/images/productTourHeaderIcon.svg";
import reMarket from "../assets/images/reMarket.png";
import reMarketIcon from "../assets/images/reMarketIcon.svg";
import reMarketPageIcon from "../assets/images/reMarketPageIcon.svg";
import reMarketing from "../assets/images/reMarketing.jpg";
import redLineArrow from "../assets/images/redLineArrow.svg";
import redealIcon from "../assets/images/redealIcon.svg";
import regionGraphLegend from "../assets/images/regionGraphLegend.svg";
import remarketBlueIcon from "../assets/images/remarketBlueIcon.svg";
import remarketOrangeIcon from "../assets/images/remarketOrangeIcon.svg";
import removeCircleOutline from "../assets/images/removeCircleOutline.svg";
import repeatbuyers from "../assets/images/repeat-buyers.png";
import reportIcon from "../assets/images/reportIcon.svg";
import revenue from "../assets/images/revenue.png";
import revenueIcon from "../assets/images/revenueIcon.svg";
import revenueTabIcon from "../assets/images/revenueTabIcon.svg";
import sampleOrderDetails from "../assets/images/sampleOrderDetails.png";
import sankeyGraphTabIcon from "../assets/images/sankeyBlack.png";
import sankeyWGraphTabIcon from "../assets/images/sankeyWhite.png";
import selectedConvPathTabIcon from "../assets/images/selectedConvPathTabIcon.svg";
import selectedCustomerTabIcon from "../assets/images/selectedCustomerTabIcon.svg";
import selectedGraphTabIcon from "../assets/images/selectedGraphTabIcon.svg";
import selectedOrderTabIcon from "../assets/images/selectedOrderTabIcon.svg";
import selectedRevenueTabIcon from "../assets/images/selectedRevenueTabIcon.svg";
import shopifylogo from "../assets/images/shopify.png";
import shopify from "../assets/images/shopify.svg";
import signupIllustration from "../assets/images/signupIllustration.png";
import singlePaidPathArrow from "../assets/images/singlePaidPathArrow.svg";
import storeName from "../assets/images/storeName.png";
import successIcon from "../assets/images/successIcon.svg";
import synchedAudienceImg from "../assets/images/synch_audience_orange.svg";
import toggleIcon from "../assets/images/toggleIcon.svg";
import tracking1 from "../assets/images/tracking1.png";
import trackingIllustration2 from "../assets/images/trackingCompleted.png";
import trackingIllustration1 from "../assets/images/trackingIllustration1.png";
import trackinglink from "../assets/images/trackingLink.svg";
import trackingAddPageException from "../assets/images/tracking_add_page_exception_v1.png";
import trackingException from "../assets/images/tracking_exception_screenshot_v1.png";
import twoPaidPathArrow from "../assets/images/twoPaidPathArrow.svg";
import upandDownArrow from "../assets/images/upandDownArrow.svg";
import userIcon from "../assets/images/userIcon.png";
import user_account from "../assets/images/user_account.svg";
import verifyEmail from "../assets/images/verifyEmail.svg";
import videoBg from "../assets/images/videoBg.png";
import warningIcon from "../assets/images/warningIcon.svg";
import whiteMagicWand from "../assets/images/whiteMagicWand.svg";
import xiIcon from "../assets/images/xiIcon.svg";

import analyticsImg from "../assets/images/analyticsnormal.svg";
import detailsImg from "../assets/images/list_alt (1).svg";

import analyticsOrangeImg from "../assets/images/analytics.svg";
import detailsOrangeImg from "../assets/images/list_alt.svg";

import reMarketIconBlackImg from "../assets/images/reMarketIconBlack.svg";
import searchIconImg from "../assets/images/searchIcon.svg";

import closeIconRedImg from "../assets/images/closeIconRed.svg";
import failureImg from "../assets/images/failureImg.svg";
import successImg from "../assets/images/successmodal.svg";
import synchedAudienceHistoryIcon from "../assets/images/audienceshistoryIcon.svg";
import audienceNotPushedImg from "../assets/images/audience_not_pushed_sync_problem.svg";
import downloadOrangeIcon from "../assets/images/downloadAudience.svg";
import audDelete from "../assets/images/audienceManagerImages/audDelete.png";
import audPushSucc from "../assets/images/audienceManagerImages/audPushSucc.png";
import grp1 from "../assets/images/AddLXRTracking/grp1.png";
import confirmImage from "../assets/images/help_outline.svg";
import successTick from "../assets/images/audienceManagerImages/success.png";
import clearFilter from "../assets/images/audienceManagerImages/clearFilter.png";
import ClientUserIcon from "../assets/images/client_user_icon.svg";
import ManagerUserIcon from "../assets/images/manager_user_icon.svg";
import keyMetricsIconImg from "../assets/images/keyMetricsIcon.svg";
import downloadOrangeIconImg from "../assets/images/downlaod 1.svg";
import reMarketIconImg from "../assets/images/reMarketIcon 1.svg";
import cartIconImg from "../assets/images/cartIcon.svg";
import synchedAudienceHistoryGreyIcon from "../assets/images/audienceshistoryIcon grey.svg";
import lxraud from "../assets/images/experimentationImages/lxraud.png";
import lxraud1 from "../assets/images/experimentationImages/lxraud1.png";
import lxrAudResult from "../assets/images/experimentationImages/lxrAudResult.png";
import noAud from "../assets/images/experimentationImages/noAud.png";

import shopifyLogoImg from "../assets/images/shopify-logo.svg";
import cancelIcon from "../assets/images/cancel.svg";

import shopifyWriteAccessImg from "../assets/images/shopify-write-access.svg";
import allUsersImg from "../assets/images/audienceManagerImages/All Users-green.svg";

import dataSourceImg from "../assets/images/settingsImages/dataSourceImage.svg";
import marketingChannelsImg from "../assets/images/settingsImages/marketingChannelsImage.svg";
import amazonverifiedpartner from "../assets/images/scorecard/amazon-verified-partner.webp";
import bbbbadge from "../assets/images/scorecard/bbb-badge.webp";
import GooglePremierPartnere1618859617215 from "../assets/images/scorecard/Google-Premier-Partner-e1618859617215.webp";
import gpdr from "../assets/images/scorecard/gpdr.webp";
import MBPBadgeDarkbackgrounds from "../assets/images/scorecard/MBP-Badge-Dark-backgrounds@1x-1.webp";
import MicrosoftPartnerLogoe1617826575969 from "../assets/images/scorecard/Microsoft-Partner-Logo-e1617826575969.webp";
import gNMSDCCERIFIED from "../assets/images/scorecard/NMSDC_CERIFIED_2024.webp";
import scorecardgraphicnew from "../assets/images/scorecard/scorecard.webp";
import Scorecardmetergraphic1 from "../assets/images/scorecard/Scorecard-meter-graphic1-1-1024x714.webp";
import score1 from "../assets/images/scorecard/1_ZBf5gtiGAfP2fJ-sLY_Q9w-1024x307.webp";
import coverimage from "../assets/images/scorecard/coverimage.png";
import LXRInsights_Logo from "../assets/images/LXRInsights_Logo.png";


const Icons = {
  helloIcon: <img src={helloIcon} alt={"Hello"} />,
  shopifylogo: (
    <img src={shopifylogo} alt={"Shopify"} style={{ padding: "9px" }} />
  ),
  bigcomlogo: (
    <img
      src={bigcomlogo}
      alt={"Bigcommerce"}
      style={{ width: "80%", margin: "auto", display: "block" }}
    />
  ),
  gdprLogo: (
    <img src={gdprLogo} alt={"GDPR"} style={{ marginBottom: "20px" }} />
  ),
  amikaLogo: <img src={amikaLogo} alt={"Amika"} style={{ padding: "9px" }} />,
  elfLogo: <img src={elfLogo} alt={"ELF"} style={{ padding: "9px" }} />,
  evanycLogo: (
    <img src={evanycLogo} alt={"Evanyc"} style={{ padding: "9px" }} />
  ),
  lenovoLogo: (
    <img src={lenovoLogo} alt={"Lenovo"} style={{ padding: "9px" }} />
  ),
  rocklerLogo: (
    <img src={rocklerLogo} alt={"Rockler"} style={{ padding: "9px" }} />
  ),
  stevenSingerLogo: (
    <img
      src={stevenSingerLogo}
      alt={"StevenSinger"}
      style={{ padding: "9px" }}
    />
  ),
  orderKeyInsights: <img src={orderKeyInsights} alt={"Order"} />,
  overviewComparision: (
    <img src={overviewComparision} alt={"Overview"} style={{ width: "70%" }} />
  ),
  priceKeyInsights: <img src={priceKeyInsights} alt={"Price"} />,
  productKeyInsights: <img src={productKeyInsights} alt={"Product"} />,
  productMonthlyInsights: <img src={productMonthlyInsights} alt={"Product"} />,
  purchasePathKeyInsights: (
    <img src={purchasePathKeyInsights} alt={"Conversion Path"} />
  ),
  reMarketDataPoints: (
    <img
      src={reMarketDataPoints}
      alt={"Data Points"}
      style={{ width: "60%" }}
    />
  ),
  reMarketDialog: <img src={reMarketDialog} alt={"Re-market"} />,
  reMarketFilter: <img src={reMarketFilter} alt={"Filter"} />,
  insightsWhiteLogo: <img src={insightsWhiteLogo} alt={"LXRInsights"} />,
  nxLogo: (
    <img
      src={nxLogo}
      alt={"NetElixir"}
      className="logoImg"
      height={50}
      width={"100%"}
      style={{ paddingLeft: "20px" }}
    />
  ),
  reMarket: (
    <img
      src={reMarket}
      alt={"Remarketing"}
      style={{ width: "90%", marginTop: "5%", marginLeft: "5%" }}
    />
  ),
  revenue: (
    <img
      src={revenue}
      alt={"Revenue"}
      style={{ width: "90%", marginTop: "5%", marginLeft: "5%" }}
    />
  ),
  reMarketing: (
    <img
      src={reMarketing}
      alt={"Remarket"}
      style={{ width: "90%", marginTop: "5%", marginLeft: "5%" }}
    />
  ),
  placeholder: (
    <img
      src={placeholder}
      alt={"Placeholder"}
      style={{ width: "90%", marginTop: "5%", marginLeft: "5%" }}
    />
  ),
  headerBanner: (
    <img src={headerBanner} alt={"Banner"} style={{ width: "100%" }} />
  ),
  heroBanner: (
    <img
      src={heroBanner}
      id={"heroBanner"}
      alt={"Banner"}
      style={{ width: "100%", marginTop: "-5%" }}
    />
  ),
  facesArrow: (
    <img
      src={facesArrow}
      alt={"Arrow"}
      style={{ width: "3%", position: "relative", left: "5px", bottom: "-5px" }}
    />
  ),
  mobileHeaderBanner: (
    <img src={mobileHeaderBanner} alt={"Banner"} style={{ width: "100%" }} />
  ),
  facesBanner: (
    <img
      src={facesBanner}
      alt={"Banner"}
      className="faceBanner"
      style={{ width: "100%" }}
    />
  ),
  videoBg: <img src={videoBg} alt={"Banner"} style={{ width: "90%" }} />,
  customerJourney: (
    <img src={customerJourney} alt={"Banner"} style={{ width: "55%" }} />
  ),
  customerRetention: (
    <img src={customerRetention} alt={"Banner"} style={{ width: "80%" }} />
  ),
  integration: (
    <img src={integration} alt={"Banner"} style={{ width: "72%" }} />
  ),
  userIcon: (
    <img
      src={userIcon}
      alt={"User Icon"}
      style={{ width: "80%", margin: "auto", display: "block" }}
    />
  ),
  overviewIcon: <img src={overviewIcon} alt={"Overview"} />,
  allCustomersIcon: <img src={allCustomersIcon} alt={"All Customers"} />,
  redealIcon: <img src={redealIcon} alt={"Redeal"} />,
  ga_integration_step1: <img src={ga_integration_step1} alt={"Step 1"} />,
  ga_integration_step2: <img src={ga_integration_step2} alt={"Step 2"} />,
  basketWhite: <img src={basketWhite} alt={"conversion"} />,
  magicWand: <img src={magicWand} alt={"Magic Wand"} />,
  whiteMagicWand: <img src={whiteMagicWand} alt={"Magic Wand"} />,
  orangeMagicWand: <img src={orangeMagicWand} alt={"Magic Wand"} />,
  greenLineArrow: <img src={greenLineArrow} alt={"Arrow"} />,
  redLineArrow: <img src={redLineArrow} alt={"Arrow"} />,
  toggleIcon: <img src={toggleIcon} alt={""} />,
  convPathTabIcon: <img src={convPathTabIcon} alt={"Conversion Paths"} />,
  revenueTabIcon: <img src={revenueTabIcon} alt={"Revenue"} />,
  orderTabIcon: <img src={orderTabIcon} alt={"Orders"} />,
  customerTabIcon: <img src={customerTabIcon} alt={"Customers"} />,
  productTabIcon: (
    <i className="fas fa-box-open" style={{ color: "#1e334e" }} />
  ),
  selectedConvPathTabIcon: (
    <img src={selectedConvPathTabIcon} alt={"Conversion Paths"} />
  ),
  selectedRevenueTabIcon: <img src={selectedRevenueTabIcon} alt={"Revenue"} />,
  selectedOrderTabIcon: <img src={selectedOrderTabIcon} alt={"Orders"} />,
  selectedCustomerTabIcon: (
    <img src={selectedCustomerTabIcon} alt={"Customers"} />
  ),
  selectedProductTabIcon: (
    <i className="fas fa-box-open" style={{ color: "#f58120" }} />
  ),
  reMarketIcon: <img src={reMarketIcon} alt={"Re-Market"} />,
  selectedGraphTabIcon: <img src={selectedGraphTabIcon} alt={"Graph"} />,
  normalGraphTabIcon: <img src={normalGraphTabIcon} alt={"Graph"} />,
  sankeyGraphTabIcon: <img src={sankeyGraphTabIcon} alt={"Graph"} />,
  sankeyWGraphTabIcon: <img src={sankeyWGraphTabIcon} alt={"Graph"} />,
  cumulativeWTabIcon: <img src={cumulativeWTabIcon} alt={"Cumulative Graph"} />,
  cumulativeBTabIcon: <img src={cumulativeBTabIcon} alt={"Cumulative Graph"} />,
  individualWFlow: <img src={individualWFlow} alt={"Individual Path"} />,
  individualBFlow: <img src={individualBFlow} alt={"Individual Path"} />,
  downloadBlueLineIcon: <img src={downloadBlueLineIcon} alt={"Download"} />,
  accountCircleIcon: <i className="fas fa-user-circle" />,
  deleteIcon: <i className="fas fa-trash-alt" />,
  newHighCustomersIcon: (
    <img src={newHighCustomersIcon} alt={"High Value Customers"} />
  ),
  newMidCustomersIcon: (
    <img src={newMidCustomersIcon} alt={"Mid Value Customers"} />
  ),
  newLowCustomersIcon: (
    <img src={newLowCustomersIcon} alt={"Low Value Customers"} />
  ),
  reMarketPageIcon: <img src={reMarketPageIcon} alt={"Re-Market"} />,
  audienceAnalyticsPageIcon: (
    <img src={audienceAnalyticsPageIcon} alt={"Audience Analytics"} />
  ),
  user_account: <img src={user_account} alt={"User"} />,
  googleAds: <img src={googleAds} alt={"Google Ads"} />,
  historyIcon: <img src={historyIcon} alt={"history"} />,
  upandDownArrow: <img src={upandDownArrow} alt={"upandDownArrow"} />,
  connectionsPageIcon: <img src={connectionsPageIcon} alt={"Connections"} />,
  infoCircleIconOutline: (
    <img
      src={infoCircleIconOutline}
      alt={"info"}
      className="info Circle"
      style={{ color: "#1e334e" }}
    />
  ),
  infoCircleIconOutlineMargin: (
    <img
      src={infoCircleIconOutline}
      alt={"info"}
      style={{ color: "#1e334e", marginBottom: -4 }}
    />
  ),
  infoCircle: <img src={infoCircle} alt={"info Circle"} />,
  closeIcon: <img src={closeIcon} alt={"close"} />,
  downloadIcon: <img src={downloadIcon} alt={"download"} />,
  editIcon: <img src={editIcon} alt={"edit"} />,
  googleAdsIcon: <img src={googleAdsIcon} alt={"googleAds"} />,
  cartIcon: <img src={cartIcon} alt={"path"} />,
  newFilterIcon: <img src={newFilterIcon} alt={"filterIcon"} />,
  newWhiteFilterIcon: <img src={newWhiteFilterIcon} alt={"filterIcon"} />,
  regionGraphLegend: <img src={regionGraphLegend} alt={"legend"} />,
  checkCircleOutline: (
    <i className="far fa-check-circle" style={{ fontSize: "large" }} />
  ),
  addCircleOutline: <img src={addCircleOutline} alt={"View More"} />,
  removeCircleOutline: (
    <img
      src={removeCircleOutline}
      style={{ bottom: "-10px" }}
      alt={"View Less"}
    />
  ),
  newRemarketIcon: <img src={newRemarketIcon} alt={"Remarket Icon"} />,
  metaIcon: <img src={metaIcon} alt={"Meta Icon"} />,
  singlePaidPathArrow: (
    <img src={singlePaidPathArrow} alt={"singlePaidPathArrow"} />
  ),
  twoPaidPathArrow: <img src={twoPaidPathArrow} alt={"twoPaidPathArrow"} />,
  multiPaidPathArrow: (
    <img src={multiPaidPathArrow} alt={"multiPaidPathArrow"} />
  ),
  successIcon: <img src={successIcon} alt={"success"} />,
  failureIcon: <img src={failureIcon} alt={"failure"} />,
  contentCopyIcon: <img src={contentCopyIcon} alt={"copy"} />,
  sampleOrderDetails: <img src={sampleOrderDetails} alt={"sampleOrder"} />,
  trackingException: (
    <img
      src={trackingException}
      alt={"tracking Exception Screenshot"}
      style={{ width: "100%" }}
    />
  ),
  trackingAddPageException: (
    <img
      src={trackingAddPageException}
      alt={"tracking Add Page Exception Screenshot"}
      style={{ width: "100%" }}
    />
  ),
  mailIcon: <img src={mailIcon} alt={"Email"} style={{ width: "100%" }} />,
  accountSettings: <img src={accountSettings} alt={"account settings"} />,
  integrationIcon: <img src={integrationIcon} alt={"integration"} />,
  reportIcon: <img src={reportIcon} alt={"reportIcon"} />,
  revenueIcon: <img src={revenueIcon} alt={"revenueIcon"} />,
  // dataIcon: <img src={dataIcon} alt={"dataIcon"} />,
  remarketOrangeIcon: <img src={remarketOrangeIcon} alt={"remarket"} />,
  remarketBlueIcon: <img src={remarketBlueIcon} alt={"remarket"} />,
  reMarketUnavailableDialog: (
    <img
      src={reMarketUnavailableDialog}
      alt={"Remarket Dialog Unavailable"}
      style={{ width: "60%" }}
    />
  ),
  contactUsHeaderIcon: (
    <img
      src={contactUsHeaderIcon}
      alt={"Contact Us"}
      style={{ width: "24px" }}
    />
  ),
  productTourHeaderIcon: (
    <img
      src={productTourHeaderIcon}
      alt={"Product Tour"}
      style={{ width: "24px" }}
    />
  ),
  customerTrendIcon: (
    <img
      src={customerTrendIcon}
      alt={"Customer Trend Icon"}
      style={{ width: "24px" }}
    />
  ),

  arrowLine2Icon: (
    <img src={arrowLine2} style={{ height: "5rem" }} alt="arrow-line-y" />
  ),
  arrowLine1Icon: (
    <img
      src={arrowLine1}
      style={{
        width: "3rem",
        height: "4rem",
      }}
      alt="arrow-line"
    />
  ),
  arrowTimeLineImgIcon: <img src={arrowTimeLineImg} alt="timeline" />,

  customerTrendDownloadIcon: (
    <img
      src={downloadOraneIcon}
      alt="Customer Trend Download Icon"
      style={{ width: "20px", height: "20px" }}
    />
  ),
  warningIcon: <img src={warningIcon} alt={"Warning"} />,
  lxrLogo: (
    <img
      src={lxrLogo}
      alt={"LXRInsights"}
      className="logoImg"
      height={50}
      width={"100%"}
      style={{ paddingLeft: "20px" }}
    />
  ),
  editOrangeColor: <img src={editOrangeColor} alt={"remarket"} />,
  deleteOrangeColor: <img src={deleteOrangeColor} alt={"delete"} />,
  deleteOrangeColorBig: (
    <img src={deleteOrangeColor} alt={"delete"} style={{ height: "25px" }} />
  ),
  stgs1: (
    <img
      src={stgs1}
      alt={"Shopify Generic Steps"}
      style={{ width: "100%", marginBottom: "2%" }}
    />
  ),
  stgs2: (
    <img
      src={stgs2}
      alt={"Shopify Generic Steps"}
      style={{ width: "100%", marginBottom: "2%" }}
    />
  ),
  ss1: (
    <img
      src={ss1}
      alt={"Shopify Generic Steps"}
      style={{ width: "100%", marginBottom: "2%" }}
    />
  ),
  ss2: (
    <img
      src={ss2}
      alt={"Shopify Generic Steps"}
      style={{ width: "100%", marginBottom: "2%" }}
    />
  ),
  ss3: (
    <img
      src={ss3}
      alt={"Shopify Generic Steps"}
      style={{ width: "50%", marginBottom: "2%" }}
    />
  ),
  ss4: (
    <img
      src={ss4}
      alt={"Shopify Generic Steps"}
      style={{ width: "50%", marginBottom: "2%" }}
    />
  ),
  ss5: (
    <img
      src={ss5}
      alt={"Shopify Generic Steps"}
      style={{ width: "100%", marginBottom: "2%" }}
    />
  ),
  ss6: (
    <img
      src={ss6}
      alt={"Shopify Generic Steps"}
      style={{ width: "100%", marginBottom: "2%" }}
    />
  ),
  ss7: (
    <img
      src={ss7}
      alt={"Shopify Generic Steps"}
      style={{ width: "100%", marginBottom: "2%" }}
    />
  ),
  ss8: (
    <img
      src={ss8}
      alt={"Shopify Generic Steps"}
      style={{ width: "100%", marginBottom: "2%" }}
    />
  ),
  ss9: (
    <img
      src={ss9}
      alt={"Shopify Generic Steps"}
      style={{ width: "100%", marginBottom: "2%" }}
    />
  ),
  ss10: (
    <img
      src={ss10}
      alt={"Shopify Generic Steps"}
      style={{ width: "100%", marginBottom: "2%" }}
    />
  ),
  stos1: (
    <img
      src={stos1}
      alt={"Shopify Order Steps"}
      style={{ width: "100%", marginBottom: "2%" }}
    />
  ),
  stos2: (
    <img
      src={stos2}
      alt={"Shopify Order Steps"}
      style={{ width: "100%", marginBottom: "2%" }}
    />
  ),
  sts1: (
    <img
      src={sts1}
      alt={"Shopify Order Steps"}
      style={{ width: "100%", marginBottom: "2%" }}
    />
  ),
  sts2: (
    <img
      src={sts2}
      alt={"Shopify Order Steps"}
      style={{ width: "100%", marginBottom: "2%" }}
    />
  ),
  sts3: (
    <img
      src={sts3}
      alt={"Shopify Order Steps"}
      style={{ width: "50%", marginBottom: "2%" }}
    />
  ),
  sts4: (
    <img
      src={sts4}
      alt={"Shopify Order Steps"}
      style={{ width: "100%", marginBottom: "2%" }}
    />
  ),
  sts5: (
    <img
      src={sts5}
      alt={"Shopify Order Steps"}
      style={{ width: "100%", marginBottom: "2%" }}
    />
  ),
  sts6: (
    <img
      src={sts6}
      alt={"Shopify Order Steps"}
      style={{ width: "100%", marginBottom: "2%" }}
    />
  ),
  sts7: (
    <img
      src={sts7}
      alt={"Shopify Order Steps"}
      style={{ width: "100%", marginBottom: "2%" }}
    />
  ),
  sts8: (
    <img
      src={sts8}
      alt={"Shopify Order Steps"}
      style={{ width: "100%", marginBottom: "2%" }}
    />
  ),
  wtgs1: (
    <img
      src={wtgs1}
      alt={"Woocommerce Generic Steps"}
      style={{ width: "100%", marginBottom: "2%" }}
    />
  ),
  wtgs2: (
    <img
      src={wtgs2}
      alt={"Woocommerce Generic Steps"}
      style={{ width: "100%", marginBottom: "2%" }}
    />
  ),
  ws1: (
    <img
      src={ws1}
      alt={"Woocommerce Generic Steps"}
      style={{ width: "100%", marginBottom: "2%" }}
    />
  ),
  ws2: (
    <img
      src={ws2}
      alt={"Woocommerce Generic Steps"}
      style={{ width: "100%", marginBottom: "2%" }}
    />
  ),
  ws3: (
    <img
      src={ws3}
      alt={"Woocommerce Generic Steps"}
      style={{ width: "100%", marginBottom: "2%" }}
    />
  ),
  ws4: (
    <img
      src={ws4}
      alt={"Woocommerce Generic Steps"}
      style={{ width: "100%", marginBottom: "2%" }}
    />
  ),
  ws5: (
    <img
      src={ws5}
      alt={"Woocommerce Generic Steps"}
      style={{ width: "100%", marginBottom: "2%" }}
    />
  ),
  ws6: (
    <img
      src={ws6}
      alt={"Woocommerce Generic Steps"}
      style={{ width: "100%", marginBottom: "2%" }}
    />
  ),
  wtos1: (
    <img
      src={wtos1}
      alt={"Woocommerce Order Steps"}
      style={{ width: "100%", marginBottom: "2%" }}
    />
  ),
  wtos2: (
    <img
      src={wtos2}
      alt={"Woocommerce Order Steps"}
      style={{ width: "100%", marginBottom: "2%" }}
    />
  ),
  wts2: (
    <img
      src={wts2}
      alt={"Woocommerce Order Steps"}
      style={{ width: "100%", marginBottom: "2%" }}
    />
  ),
  wts3: (
    <img
      src={wts3}
      alt={"Woocommerce Order Steps"}
      style={{ width: "100%", marginBottom: "2%" }}
    />
  ),
  wts4: (
    <img
      src={wts4}
      alt={"Woocommerce Order Steps"}
      style={{ width: "100%", marginBottom: "2%" }}
    />
  ),
  wts5: (
    <img
      src={wts5}
      alt={"Woocommerce Order Steps"}
      style={{ width: "100%", marginBottom: "2%" }}
    />
  ),
  wts6: (
    <img
      src={wts6}
      alt={"Woocommerce Order Steps"}
      style={{ width: "100%", marginBottom: "2%" }}
    />
  ),
  btgs1: (
    <img
      src={btgs1}
      alt={"Bigcommerce Generic Steps"}
      style={{ width: "100%", marginBottom: "2%" }}
    />
  ),
  btgs2: (
    <img
      src={btgs2}
      alt={"Bigcommerce Generic Steps"}
      style={{ width: "100%", marginBottom: "2%" }}
    />
  ),
  bs1: (
    <img
      src={bs1}
      alt={"Bigcommerce Generic Steps"}
      style={{ width: "50%", marginBottom: "2%" }}
    />
  ),
  bs2: (
    <img
      src={bs2}
      alt={"Bigcommerce Generic Steps"}
      style={{ width: "100%", marginBottom: "2%" }}
    />
  ),
  bs3: (
    <img
      src={bs3}
      alt={"Bigcommerce Generic Steps"}
      style={{ width: "100%", marginBottom: "2%" }}
    />
  ),
  bs4: (
    <img
      src={bs4}
      alt={"Bigcommerce Generic Steps"}
      style={{ width: "100%", marginBottom: "2%" }}
    />
  ),
  btos1: (
    <img
      src={btos1}
      alt={"Bigcommerce Order Steps"}
      style={{ width: "100%", marginBottom: "2%" }}
    />
  ),
  btos2: (
    <img
      src={btos2}
      alt={"Bigcommerce Order Steps"}
      style={{ width: "100%", marginBottom: "2%" }}
    />
  ),
  mtgs1: (
    <img
      src={mtgs1}
      alt={"Magento Generic Steps"}
      style={{ width: "100%", marginBottom: "2%" }}
    />
  ),
  mtgs2: (
    <img
      src={mtgs2}
      alt={"Magento Generic Steps"}
      style={{ width: "100%", marginBottom: "2%" }}
    />
  ),
  ms1: (
    <img
      src={ms1}
      alt={"Magento Generic Steps"}
      style={{ width: "50%", marginBottom: "2%" }}
    />
  ),
  ms2: (
    <img
      src={ms2}
      alt={"Magento Generic Steps"}
      style={{ width: "100%", marginBottom: "2%" }}
    />
  ),
  ms3: (
    <img
      src={ms3}
      alt={"Magento Generic Steps"}
      style={{ width: "100%", marginBottom: "2%" }}
    />
  ),
  ms4: (
    <img
      src={ms4}
      alt={"Magento Generic Steps"}
      style={{ width: "100%", marginBottom: "2%" }}
    />
  ),
  ms5: (
    <img
      src={ms5}
      alt={"Magento Generic Steps"}
      style={{ width: "100%", marginBottom: "2%" }}
    />
  ),
  mtos1: (
    <img
      src={mtos1}
      alt={"Magento Order Steps"}
      style={{ width: "100%", marginBottom: "2%" }}
    />
  ),
  mtos2: (
    <img
      src={mtos2}
      alt={"Magento Order Steps"}
      style={{ width: "100%", marginBottom: "2%" }}
    />
  ),
  gtgs1: (
    <img
      src={gtgs1}
      alt={"GTM Steps"}
      style={{ width: "100%", marginBottom: "2%" }}
    />
  ),
  gtgs2: (
    <img
      src={gtgs2}
      alt={"GTM Steps"}
      style={{ width: "100%", marginBottom: "2%" }}
    />
  ),
  gts1: (
    <img
      src={gts1}
      alt={"GTM Steps"}
      style={{ width: "100%", marginBottom: "2%" }}
    />
  ),
  gts2: (
    <img
      src={gts2}
      alt={"GTM Steps"}
      style={{ width: "100%", marginBottom: "2%" }}
    />
  ),
  gts3: (
    <img
      src={gts3}
      alt={"GTM Steps"}
      style={{ width: "100%", marginBottom: "2%" }}
    />
  ),
  gts4: (
    <img
      src={gts4}
      alt={"GTM Steps"}
      style={{ width: "100%", marginBottom: "2%" }}
    />
  ),
  gts5: (
    <img
      src={gts5}
      alt={"GTM Steps"}
      style={{ width: "100%", marginBottom: "2%" }}
    />
  ),
  gts6: (
    <img
      src={gts6}
      alt={"GTM Steps"}
      style={{ width: "100%", marginBottom: "2%" }}
    />
  ),
  gts7: (
    <img
      src={gts7}
      alt={"GTM Steps"}
      style={{ width: "100%", marginBottom: "2%" }}
    />
  ),
  gts8: (
    <img
      src={gts8}
      alt={"GTM Steps"}
      style={{ width: "100%", marginBottom: "2%" }}
    />
  ),
  gts9: (
    <img
      src={gts9}
      alt={"GTM Steps"}
      style={{ width: "100%", marginBottom: "2%" }}
    />
  ),
  gts10: (
    <img
      src={gts10}
      alt={"GTM Steps"}
      style={{ width: "100%", marginBottom: "2%" }}
    />
  ),
  gts11: (
    <img
      src={gts11}
      alt={"GTM Steps"}
      style={{ width: "100%", marginBottom: "2%" }}
    />
  ),
  gts12: (
    <img
      src={gts12}
      alt={"GTM Steps"}
      style={{ width: "100%", marginBottom: "2%" }}
    />
  ),
  gts13: (
    <img
      src={gts13}
      alt={"GTM Steps"}
      style={{ width: "100%", marginBottom: "2%" }}
    />
  ),
  gts14: (
    <img
      src={gts14}
      alt={"GTM Steps"}
      style={{ width: "100%", marginBottom: "2%" }}
    />
  ),
  gts15: (
    <img
      src={gts15}
      alt={"GTM Steps"}
      style={{ width: "100%", marginBottom: "2%" }}
    />
  ),
  gts16: (
    <img
      src={gts16}
      alt={"GTM Steps"}
      style={{ width: "100%", marginBottom: "2%" }}
    />
  ),
  gts17: (
    <img
      src={gts17}
      alt={"GTM Steps"}
      style={{ width: "100%", marginBottom: "2%" }}
    />
  ),
  gttgs1: (
    <img
      src={gttgs1}
      alt={"GTM Order Steps"}
      style={{ width: "100%", marginBottom: "2%" }}
    />
  ),
  gttgs2: (
    <img
      src={gttgs2}
      alt={"GTM Order Steps"}
      style={{ width: "100%", marginBottom: "2%" }}
    />
  ),
  gtts1: (
    <img
      src={gtts1}
      alt={"GTM Order Steps"}
      style={{ width: "100%", marginBottom: "2%" }}
    />
  ),
  gtts2: (
    <img
      src={gtts2}
      alt={"GTM Order Steps"}
      style={{ width: "100%", marginBottom: "2%" }}
    />
  ),
  gtts5: (
    <img
      src={gtts5}
      alt={"GTM Order Steps"}
      style={{ width: "100%", marginBottom: "2%" }}
    />
  ),
  gtts6: (
    <img
      src={gtts6}
      alt={"GTM Order Steps"}
      style={{ width: "100%", marginBottom: "2%" }}
    />
  ),
  gtts7: (
    <img
      src={gtts7}
      alt={"GTM Order Steps"}
      style={{ width: "100%", marginBottom: "2%" }}
    />
  ),
  gtts8: (
    <img
      src={gtts8}
      alt={"GTM Order Steps"}
      style={{ width: "100%", marginBottom: "2%" }}
    />
  ),
  gtts9: (
    <img
      src={gtts9}
      alt={"GTM Order Steps"}
      style={{ width: "100%", marginBottom: "2%" }}
    />
  ),
  gtts11: (
    <img
      src={gtts11}
      alt={"GTM Order Steps"}
      style={{ width: "100%", marginBottom: "2%" }}
    />
  ),
  gtts12: (
    <img
      src={gtts12}
      alt={"GTM Order Steps"}
      style={{ width: "100%", marginBottom: "2%" }}
    />
  ),
  gtts13: (
    <img
      src={gtts13}
      alt={"GTM Order Steps"}
      style={{ width: "100%", marginBottom: "2%" }}
    />
  ),
  gtts14: (
    <img
      src={gtts14}
      alt={"GTM Order Steps"}
      style={{ width: "100%", marginBottom: "2%" }}
    />
  ),
  gtts15: (
    <img
      src={gtts15}
      alt={"GTM Order Steps"}
      style={{ width: "100%", marginBottom: "2%" }}
    />
  ),
  gtts16: (
    <img
      src={gtts16}
      alt={"GTM Order Steps"}
      style={{ width: "100%", marginBottom: "2%" }}
    />
  ),
  gttsplus: (
    <img
      src={gttsplus}
      alt={"GTM Order Steps"}
      style={{ width: "100%", marginBottom: "2%" }}
    />
  ),
  gttstr: (
    <img
      src={gttstr}
      alt={"GTM Order Steps"}
      style={{ width: "100%", marginBottom: "2%" }}
    />
  ),
  gtstr: (
    <img
      src={gtstr}
      alt={"GTM Order Steps"}
      style={{ width: "100%", marginBottom: "2%" }}
    />
  ),
  gttstrb: (
    <img
      src={gttstrb}
      alt={"GTM Order Steps"}
      style={{ width: "100%", marginBottom: "2%" }}
    />
  ),
  dtl: (
    <img
      src={dtl}
      alt={"Data Layer Format"}
      style={{ width: "100%", marginBottom: "2%" }}
    />
  ),
  mts: (
    <img
      src={mts}
      alt={"Magento Order Steps"}
      style={{ width: "100%", marginBottom: "2%" }}
    />
  ),
  repeatbuyers: <img src={repeatbuyers} alt={"RepeatBuyers"} width={"100%"} />,
  chatgptIcon: <img src={chatgptIcon} alt={"Chat GPT"} width={"16px"} />,
  exclamation: <img src={exclamation} alt={"Exclamation"} />,
  verifyEmail: <img src={verifyEmail} alt={"verifyEmail"} />,
  lxrTracking: <img src={lxrTracking} alt={"LXRTracking"} />,
  shopify: <img src={shopify} alt={"Shopify"} />,
  bigCommerce: <img src={bigCommerce} alt={"Big Commerce"} />,
  xiIcon: <img src={xiIcon} alt={"Netelixir"} style={{ width: "100%" }} />,
  analyzingProcess: <img src={analyzingProcess} alt={"analyzingProcess"} />,
  trackingIllustration1: <img src={trackingIllustration1} alt={"Tracking"} />,
  trackingIllustration2: <img src={trackingIllustration2} alt={"Trcaking"} />,
  authSuccess: <img src={authSuccess} alt={"Authentication Successful"} />,
  authFailed: <img src={authFailed} alt={"Authentication Failed"} />,
  dataNotEnough: <img src={dataNotEnough} alt={"No Sufficient Data"} />,
  errorWhileTracking: (
    <img src={errorWhileTracking} alt={"No Sufficient Data"} height={"100%"} />
  ),
  errorWhileCallingAPI: (
    <img src={errorWhileCallingAPI} alt={"Error"} height={"100%"} />
  ),
  ixFrame: <img src={ixFrame} alt={"ix Frame"} />,
  signupIllustration: (
    <img src={signupIllustration} alt={"signup Illustration"} />
  ),
  emailVerifySuccess: (
    <img src={emailVerifySuccess} alt={"Email Verification Successful"} />
  ),
  emailVerifyFailed: (
    <img src={emailVerifyFailed} alt={"Email Verification Failed"} />
  ),
  tracking1: <img src={tracking1} style={{ width: "85%" }} />,
  trackinglink: <img src={trackinglink} />,
  notEnoughData: <img src={notEnoughData} alt={"Not Enough Data"} />,
  productAnalyticsIcon: (
    <img src={productAnalyticsIcon} alt={"Product Analytics"} />
  ),
  productAnalyticsHeaderIcon: (
    <img src={productAnalyticsHeaderIcon} alt={"Product Analytics"} />
  ),
  ascArrow: <img src={ascArrow} alt={"ascArrow"} />,
  descArrow: <img src={descArrow} alt={"descArrow"} />,
  storeName: <img src={storeName} alt={"Shopify Store Name"} />,
  add_source: (
    <img src={add_source} alt={"Add Website Details Page"} width={"85%"} />
  ),
  create_account: (
    <img
      src={create_account}
      alt={"Create Account Page"}
      height={"80%"}
      width={"70%"}
    />
  ),
  email_verification_page: (
    <img src={email_verification_page} style={{ width: "70%" }} />
  ),
  overview: <img src={overview} width={"85%"} alt={"Overview Page"} />,
  shopify_data_status: (
    <img src={shopify_data_status} alt={"shopify data status"} width={"85%"} />
  ),
  shopify_granting_page: (
    <img
      src={shopify_granting_page}
      alt={"shopify granting page"}
      width={"85%"}
    />
  ),
  shopify_store: (
    <img src={shopify_store} alt={"shopify store"} width={"85%"} />
  ),
  shopify_login: <img src={shopify_login} alt={"shopify login page"} />,
  MFAVerification: <img src={MFAVerification} alt={"MFA Verification"} />,
  mfaVerificationSuccess: (
    <img src={mfaVerificationSuccess} alt={"MFA Verification Success"} />
  ),
  experimentIcon: (
    <img
      src={experimentIcon}
      alt={"Experiments"}
      style={{ paddingLeft: "2px" }}
    />
  ),
  metaLogo: <img src={metaLogo} alt={"Meta Ads"} />,
  googleAdsLogo: <img src={googleAdsLogo} alt={"Google Ads"} />,
  createExpPredef: <img src={createExpPredef} alt={"create Experiment"} />,
  createExpPredefSel: (
    <img src={createExpPredefSel} alt={"create Experiment"} />
  ),
  createExpScratch: <img src={createExpScratch} alt={"create Experiment"} />,
  createExpScratchSel: (
    <img src={createExpScratchSel} alt={"create Experiment"} />
  ),
  exp1: <img src={exp1} alt={"create Experiment"} style={{ width: "50%" }} />,
  exp2: <img src={exp2} alt={"create Experiment"} style={{ width: "60%" }} />,
  exp3: <img src={exp3} alt={"create Experiment"} style={{ width: "30%" }} />,
  exp4: <img src={exp4} alt={"create Experiment"} style={{ width: "30%" }} />,
  exp5: <img src={exp5} alt={"create Experiment"} style={{ width: "30%" }} />,
  exp6: <img src={exp6} alt={"create Experiment"} style={{ width: "30%" }} />,
  exp7: <img src={exp7} alt={"create Experiment"} style={{ width: "30%" }} />,
  exp8: <img src={exp8} alt={"create Experiment"} style={{ width: "60%" }} />,
  exp9: <img src={exp9} alt={"create Experiment"} style={{ width: "60%" }} />,
  exp10: <img src={exp10} alt={"create Experiment"} style={{ width: "60%" }} />,
  exp11: <img src={exp11} alt={"create Experiment"} style={{ width: "50%" }} />,
  exp12: <img src={exp12} alt={"create Experiment"} />,
  exp13: <img src={exp13} alt={"create Experiment"} />,
  exp14: <img src={exp14} alt={"create Experiment"} />,
  launchIcon: <img src={launchIcon} alt={"create Experiment"} />,
  arrowRight: <img src={arrowRight} alt={"arrow Right"} />,
  arrowRightOrange: <img src={arrowRightOrange} alt={"arrow Right"} />,
  exp15: <img src={exp15} alt={"create Experiment"} style={{ width: "60%" }} />,
  exp16: <img src={exp16} alt={"create Experiment"} style={{ width: "40%" }} />,
  exp17: <img src={exp17} alt={"create Experiment"} />,
  exp18: <img src={exp18} alt={"create Experiment"} />,
  gdpr: <img src={gdpr} alt={"GDPR"} style={{ height: "137px" }} />,
  ccpa: <img src={ccpa} alt={"CCPA"} style={{ height: "137px" }} />,
  poweredByNetelixirLogo: (
    <img src={poweredByNetelixirLogo} alt={"Powered By Netelixir"} />
  ),
  LXRInsightsWhiteLogo: <img src={LXRInsightsWhiteLogo} alt={"LXRInsights"} />,
  openNewTabIcon: (
    <img
      src={openNewTabIcon}
      alt={"openNewTabIcon"}
      style={{ paddingTop: "5px" }}
    />
  ),
  gdpr1: <img src={gdpr1} alt={"GDPR"} />,
  ccpa1: <img src={ccpa1} alt={"CCPA"} />,
  gdprMobile: <img src={gdprMobile} alt={"GDPR"} />,
  ccpaMobile: <img src={ccpaMobile} alt={"CCPA"} />,
  buttonSlider1: <img src={buttonSlider1} alt={"create Experiment"} />,
  buttonSlider2: <img src={buttonSlider2} alt={"create Experiment"} />,
  experimentIcon2: (
    <img
      src={experimentIcon2}
      alt={"Experiments"}
      style={{ paddingLeft: "2px" }}
    />
  ),
  expHelp1: (
    <img
      src={expHelp1}
      alt={"create Experiment from scratch"}
      width={"85%"}
      style={{ border: "1px solid #DAE4ED" }}
    />
  ),
  expHelp2: (
    <img
      src={expHelp2}
      alt={"Experiment details"}
      width={"85%"}
      style={{ border: "1px solid #DAE4ED" }}
    />
  ),
  expHelp3: (
    <img
      src={expHelp3}
      alt={"Audience Selection"}
      width={"85%"}
      style={{ border: "1px solid #DAE4ED" }}
    />
  ),
  expHelp4: (
    <img
      src={expHelp4}
      alt={"Campaign Selection"}
      width={"85%"}
      style={{ border: "1px solid #DAE4ED" }}
    />
  ),
  expHelp5: (
    <img
      src={expHelp5}
      alt={"Budget Split and Scheduling"}
      width={"85%"}
      style={{ border: "1px solid #DAE4ED" }}
    />
  ),
  expHelp6: (
    <img
      src={expHelp6}
      alt={"Review and Launch"}
      width={"85%"}
      style={{ border: "1px solid #DAE4ED" }}
    />
  ),
  expHelp7: (
    <img
      src={expHelp7}
      alt={"Select from Preddefined Experiment"}
      width={"85%"}
      style={{ border: "1px solid #DAE4ED" }}
    />
  ),
  expRes1: <img src={expRes1} alt={"Exp Res1"} />,
  searchCamp: <img src={searchCamp} alt={"Search Campaign"} loading="lazy" />,
  dispCamp: <img src={dispCamp} alt={"Display Campaign"} loading="lazy" />,
  PMaxCamp: <img src={PMaxCamp} alt={"PMax Campaign"} loading="lazy" />,
  grp1: <img src={grp1} alt={"Top path"} />,
  audienceImg: <img src={audienceImg} alt="audiences" />,
  synchedAudienceImg: <img src={synchedAudienceImg} alt="synched-audiences" />,
  synchedAudienceHistoryImg: (
    <img src={synchedAudienceHistoryIcon} alt="synchedAudienceHistoryImg" />
  ),
  audienceNotPushedImage: audienceNotPushedImg,
  downloadOrangeImage: downloadOrangeIcon,
  remarketOrangeImage: remarketOrangeIcon,
  googleAdsImage: googleAds,
  backArrowImage: backArrowImg,
  synchedAudienceHistoryImage: synchedAudienceHistoryIcon,
  searchIconImage: searchIconImg,
  reMarketIconBlackImage: reMarketIconBlackImg,
  closeIconRedImage: closeIconRedImg,
  successImage: successImg,
  failureImage: failureImg,

  detailsImage: <img src={detailsImg} alt="details" />,
  analyticsImage: <img src={analyticsImg} alt="analytics" />,
  detailsOrangeImage: <img src={detailsOrangeImg} alt="detailsOrangeImg" />,
  analyticsOrangeImage: (
    <img src={analyticsOrangeImg} alt="analyticsOrangeImg" />
  ),
  metaIconImage: metaIcon,
  aud1: <img src={aud1} alt="audiences" />,
  aud2: <img src={aud2} alt="synched-audiences" />,
  audDelete: (
    <img src={audDelete} alt="delete-audience" style={{ width: "50%" }} />
  ),
  audPushSucc: (
    <img src={audPushSucc} alt="push-audience" style={{ width: "50%" }} />
  ),
  grp1: <img src={grp1} alt={"Top path"} />,
  confirmImg: confirmImage,
  successTick: <img src={successTick} alt={"Success"} />,
  clearFilter: <img src={clearFilter} alt={"Clear Filter"} />,
  keyMetricsIcon: <img src={keyMetricsIconImg} alt="keyMetricsIcon" />,
  downloadOrangeIconImg: (
    <img
      src={downloadOrangeIconImg}
      alt={"downloadOrangeIconImg"}
      style={{ cursor: "pointer" }}
    />
  ),
  remarketOrangeIconImg: (
    <img
      src={reMarketIconImg}
      alt="reMarketIconImg"
      style={{ cursor: "pointer" }}
    />
  ),
  ManagerUserIcon: <img src={ManagerUserIcon} alt={"Manager Icon"} />,
  ClientUserIcon: <img src={ClientUserIcon} alt={"Client Icon"} />,
  cartIconImg: <img src={cartIconImg} alt={"cartIconImg"} />,
  synchedAudienceHistoryGreyImage: synchedAudienceHistoryGreyIcon,
  lxraud1: (
    <img
      src={lxraud1}
      alt={"LXR Audience"}
      style={{ width: "60%", height: "60%", marginTop: "3px" }}
    />
  ),
  lxraud: <img src={lxraud} alt={"LXR Audience"} />,
  lxrAudResult: <img src={lxrAudResult} alt={"LXR Audience"} />,
  noAud: <img src={noAud} alt={"No Audience"} />,
  shopifyLogoImg: <img src={shopifyLogoImg} alt="shopify-logo-img" />,
  cancelIconImg: <img src={cancelIcon} alt="cancel-icon-img" />,

  shopifyWriteAccessImage: shopifyWriteAccessImg,
  shopifyLogoImg2: shopifyLogoImg,

  dataSourceImage: dataSourceImg,
  marketingChannelsImage: marketingChannelsImg,

  allUsersImg: <img src={allUsersImg} alt="allUsersImg-green" />,
  amazonverifiedpartner: (
    <img
      src={amazonverifiedpartner}
      alt="amazonverifiedpartner"
      style={{ width: "130px", height: "70px" }}
    />
  ),
  bbbbadge: (
    <img
      src={bbbbadge}
      alt="bbbbadge"
      style={{ width: "130px", height: "40px" }}
    />
  ),
  GooglePremierPartnere1618859617215: (
    <img
      src={GooglePremierPartnere1618859617215}
      alt="GooglePremierPartnere1618859617215"
      style={{ width: "130px", height: "40px" }}
    />
  ),
  gpdr: (
    <img src={gpdr} alt="gpdr-img" style={{ width: "130px", height: "40px" }} />
  ),
  MBPBadgeDarkbackgrounds: (
    <img
      src={MBPBadgeDarkbackgrounds}
      alt="cancel-icon-img"
      style={{ width: "130px", height: "70px" }}
    />
  ),
  MicrosoftPartnerLogoe1617826575969: (
    <img
      src={MicrosoftPartnerLogoe1617826575969}
      alt="cancel-icon-img"
      style={{ width: "130px", height: "40px" }}
    />
  ),
  gNMSDCCERIFIED: (
    <img
      src={gNMSDCCERIFIED}
      alt="cancel-icon-img"
      style={{ width: "100px", height: "90px" }}
    />
  ),
  scorecardgraphicnew: (
    <img
      src={scorecardgraphicnew}
      alt="cancel-icon-img"
      style={{height: "550px", border: "1px solid #000" }}
    />
  ),
  Scorecardmetergraphic1: (
    <img
      src={Scorecardmetergraphic1}
      alt="cancel-icon-img"
      style={{ width: "100%", height: "100%" }}
    />
  ),
  score1: (
    <img
      src={score1}
      alt="cancel-icon-img"
      style={{ width: "130px", height: "40px" }}
    />
  ),
  coverimage: (
    <img
      src={coverimage}
      alt="cancel-icon-img"
      style={{ width: "100%", height: "550px" }}
    />
  ),
  LXRInsights_Logo: <img src={LXRInsights_Logo} alt="LXRInsights_Logo" style={{height:'50px',paddingLeft:'16px'}} />,

};
export default Icons;
