import React, {useEffect} from 'react'
import Datamap from 'datamaps/dist/datamaps.usa.min'
import Worldmap from "datamaps/dist/datamaps.world.min"
import d3 from 'd3';
import makeStyles from '@mui/styles/makeStyles';
import * as _ from 'lodash'
import {DefaultRegionData} from "../geoLocationBlock";
import {GAEventTracker} from "../../../index";
import CustomerGroup from "../../getGroupDetails";


var countries = require('country-data').countries;
export let defaultFill = "#E1E1E1";
export let maxFill = "#1E334E";
export let minFill = "#A0A6AD";
export let highlightFill = '#FFCC80';
export let borderFill = "#FFFFFF";

export function numbersFormat(num, digits) {
    var si = [
        {value: 1, symbol: ""},
        {value: 1E3, symbol: "k"},
        {value: 1E6, symbol: "M"},
        {value: 1E9, symbol: "G"},
        {value: 1E12, symbol: "T"},
        {value: 1E15, symbol: "P"},
        {value: 1E18, symbol: "E"}
    ];
    var rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    var i;
    for (i = si.length - 1; i > 0; i--) {
        if (num >= si[i].value) {
            break;
        }
    }
    return (num / si[i].value).toFixed(digits).replace(rx, "$1") + si[i].symbol;
}

export const useStyles = makeStyles(theme => ({
    customTooltip: {
        position: 'relative',
        backgroundColor: "#1e334e !important",
        color: theme.palette.common.white,
        border: "none !important",
        borderRadius: "4px !important",
        padding: "8px !important",
        lineHeight: 1.5,
        display: 'flex'
    }
}));

function GeoOrdersGraph(props) {
    //eslint-disable-next-line
    let datamap;
    const {monthCompare, selectedSeg, regionData, compareRegionData, property, handleClick, countryData, compareCountryData, graphRegion, currencySymbol} = props;
    const classes = useStyles();
    let graphRegionData = !monthCompare ? regionData : _.merge(_.keyBy(regionData, 'state'), _.keyBy(compareRegionData, 'state'));
    let graphCountryData = !monthCompare ? countryData : _.merge(_.keyBy(countryData, 'country'), _.keyBy(compareCountryData, 'country'));
    graphRegionData = _.values(graphRegionData);
    graphCountryData = _.values(graphCountryData);
    let graphData = graphRegion === 'usa' ? graphRegionData : graphCountryData;

    // graphData = countryData

    function linearPaletteScale(value) {
        const dataValues = (graphRegion === 'usa' ? regionData : countryData).map(function (data) {
            // const dataValues = countryData.map(function (data) {
            return data.value
        });
        const minVal = Math.min(...dataValues);
        const maxVal = Math.max(...dataValues);
        return d3.scale.linear().domain([minVal, maxVal]).range([minFill, maxFill])(value);
    }

    const total = _.sumBy((graphRegion === 'usa' ? regionData : countryData), "value");
    const compareTotal = _.sumBy((graphRegion === 'usa' ? compareRegionData : compareCountryData), "compareValue");

    function reducedData() {
        const newData = graphData.reduce((object, data) => {
            // const newData = countryData.reduce((object, data) => {
            //     object[countries[data.code].alpha3] = {
            object[(graphRegion === 'usa' ? data.code : countries[data.code].alpha3)] = {
                region: graphRegion === 'usa' ? data.regionName : data.countryName,
                value: data.value,
                fillColor: data.value ? linearPaletteScale(data.value) : defaultFill,
                share: Math.round(data.value * 100 / total),
                compareValue: data.compareValue,
                compareShare: Math.round(data.compareValue * 100 / compareTotal)
            };
            return object
        }, {});
        return Object.assign({}, DefaultRegionData, newData)
        // return Object.assign({},  newData)
    }

    function renderMap() {
        // return new Worldmap({
        return (graphRegion === 'usa') ?
            new Datamap({
                element: document.getElementById('datamap-container'),
                position: 'relative',
                // scope: 'world',
                scope: 'usa',
                // width: 580,
                projection: 'mercator',
                data: reducedData(),
                fills: {defaultFill: defaultFill},
                done: function (datamap) {
                    datamap.svg.selectAll('.datamaps-subunit').on('click', function (geography) {
                        const region = _.find(regionData, function (o) {
                            return o.regionName === geography.properties.name;
                        });
                        if (region !== undefined) {
                            handleClick('region', geography.properties.name);
                            GAEventTracker(CustomerGroup(selectedSeg)().name, 'Geolocation graph')
                        }
                    });
                },
                geographyConfig: {
                    borderWidth: 0.5,
                    borderColor: borderFill,
                    highlightFillColor: highlightFill,
                    popupTemplate: function (geography, data) {
                        if (!monthCompare) {
                            if (data && data.value) {
                                if (property === 'orders') {
                                    return `<div class="${classes.customTooltip} hoverinfo ">${data.region} <br>Order(s) : ${data.value} ( ${data.share} %)</div>`;
                                } else if (property === 'revenue') {
                                    return `<div class="${classes.customTooltip} hoverinfo "> ${data.region} <br>Revenue : ${currencySymbol} ${numbersFormat(data.value, 0)} ( ${data.share} %)</div>`;
                                } else {
                                    return `<div class=" ${classes.customTooltip} hoverinfo "> ${data.region} <br>Customer(s) : ${data.value} ( ${data.share} %)</div>`;
                                }
                            } else {
                                return `<div class=" ${classes.customTooltip } hoverinfo "> ${geography.properties.name} </div>`;
                            }
                        } else {
                            if (data && data.value && data.compareValue) {
                                if (property === 'orders') {
                                    if (data.value > data.compareValue) {
                                        return `<div class=" ${classes.customTooltip} hoverinfo "><span> ${data.region} <br>Order(s) : ${data.value} ( ${data.share} %)<br><div style="opacity: 0.5">Orders(s) : ${data.compareValue} ( ${data.compareShare} %)</div></span><span style="color: #27AE60"> ${Math.abs(Math.round((data.value - data.compareValue) * 100 / data.compareValue))} % <i class="fas fa-arrow-up" style="font-size: 14px; vertical-align: middle;"/> </span></div>`;
                                    } else if (data.value < data.compareValue) {
                                        return `<div class=" ${classes.customTooltip} hoverinfo "><span> ${data.region} <br>Order(s) : ${data.value} ( ${data.share} %)<br><div style="opacity: 0.5">Orders(s) : ${data.compareValue} ( ${data.compareShare} %)</div></span><span style="color:#eb5757"> ${Math.abs(Math.round((data.value - data.compareValue) * 100 / data.compareValue))} % <i class="fas fa-arrow-down" style="font-size: 14px;"/></span></div>`;
                                    } else {
                                        return `<div class=" ${classes.customTooltip} hoverinfo "><span> ${data.region} <br>Order(s) : ${data.value} ( ${data.share} %)<br><div style="opacity: 0.5">Orders(s) : ${data.compareValue} ( ${data.compareShare} %)</div></span></div>`;
                                    }
                                } else if (property === 'revenue') {
                                    if (data.value > data.compareValue) {
                                        return `<div class=" ${classes.customTooltip} hoverinfo "><span> ${data.region} <br>Revenue : ${currencySymbol} ${numbersFormat(data.value, 0)} ( ${data.share} %)<br><div style="opacity: 0.5">Revenue : ${currencySymbol} ${numbersFormat(data.compareValue, 0)} ( ${data.compareShare} %)</div></span><span style="color: #27AE60"> ${Math.abs(Math.round((data.value - data.compareValue) * 100 / data.compareValue))} % <i class="fas fa-arrow-up" style="font-size: 14px; vertical-align: middle;"/></span></div>`;
                                    } else if (data.value < data.compareValue) {
                                        return `<div class=" ${classes.customTooltip} hoverinfo "><span> ${data.region} <br>Revenue : ${currencySymbol} ${numbersFormat(data.value, 0)} ( ${data.share} %)<br><div style="opacity: 0.5">Revenue : ${currencySymbol} ${numbersFormat(data.compareValue, 0)} ( ${data.compareShare} %)</div></span><span style="color:#eb5757"> ${Math.abs(Math.round((data.value - data.compareValue) * 100 / data.compareValue))} % <i class="fas fa-arrow-down" style="font-size: 14px;"/></span></div>`;
                                    } else {
                                        return `<div class=" ${classes.customTooltip} hoverinfo "><span> ${data.region} <br>Revenue : ${currencySymbol} ${numbersFormat(data.value, 0)} ( ${data.share} %)<br><div style="opacity: 0.5">Revenue : ${currencySymbol} ${numbersFormat(data.compareValue, 0)} ( ${data.compareShare} %)</div></span></div>`;
                                    }
                                } else {
                                    if (data.value > data.compareValue) {
                                        return `<div class=" ${classes.customTooltip} hoverinfo "><span> ${data.region} <br>Customer(s) : ${data.value} ( ${data.share} %)<br><div style="opacity: 0.5">Customer(s) : ${data.compareValue} ( ${data.compareShare} %)</div></span><span style="color: #27AE60"> ${Math.abs(Math.round((data.value - data.compareValue) * 100 / data.compareValue))} % <i class="fas fa-arrow-up" style="font-size: 14px; vertical-align: middle;"/></span></div>`;
                                    } else if (data.value < data.compareValue) {
                                        return `<div class=" ${classes.customTooltip} hoverinfo "><span> ${data.region} <br>Customer(s) : ${data.value} ( ${data.share} %)<br><div style="opacity: 0.5">Customer(s) : ${data.compareValue} ( ${data.compareShare} %)</div></span><span style="color: #eb5757"> ${Math.abs(Math.round((data.value - data.compareValue) * 100 / data.compareValue))} % <i class="fas fa-arrow-down" style="font-size: 14px;"/></span></div>`;
                                    } else {
                                        return `<div class=" ${classes.customTooltip} hoverinfo "><span> ${data.region} <br>Customer(s) : ${data.value} ( ${data.share} %)<br><div style="opacity: 0.5">Customer(s) : ${data.compareValue} ( ${data.compareShare} %)</div></span></div>`;
                                    }
                                }
                            } else if (data && data.value) {
                                if (property === 'orders') {
                                    return `<div class=" ${classes.customTooltip} hoverinfo " style="display: block"> ${data.region} <br>Order(s) : ${data.value} ( ${data.share} %)<br><div style="opacity: 0.5">Orders(s) : ${0} ( ${0} %)</div></div>`;
                                } else if (property === 'revenue') {
                                    return `<div class=" ${classes.customTooltip} hoverinfo " style="display: block"> ${data.region} <br>Revenue : ${currencySymbol} ${numbersFormat(data.value, 0)} ( ${data.share} %)<br><div style="opacity: 0.5">Revenue : ${currencySymbol} ${numbersFormat(0, 0)} ( ${0} %)</div></div>`;
                                } else {
                                    return `<div class=" ${classes.customTooltip} hoverinfo " style="display: block"> ${data.region} <br>Customer(s) : ${data.value} ( ${data.share} %)<br><div style="opacity: 0.5">Customer(s) : ${0} ( ${0} %)</div></div>`;
                                }
                            } else if (data && data.compareValue) {
                                if (property === 'orders') {
                                    return `<div class=" ${classes.customTooltip} hoverinfo "><span> ${data.region} <br>Order(s) : ${0} (${0} %)<br><div style="opacity: 0.5">Orders(s) : ${data.compareValue} ( ${data.compareShare} %)</div></span><span style="color: #eb5757"> ${Math.abs(Math.round((0 - data.compareValue) * 100 / data.compareValue))} % <i class="fas fa-arrow-down" style="font-size: 14px;"/></span></div>`;
                                } else if (property === 'revenue') {
                                    return `<div class=" ${classes.customTooltip} hoverinfo "><span> ${data.region} <br>Revenue : ${currencySymbol} ${numbersFormat(0, 0)} ( ${0} %)<br><div style="opacity: 0.5">Revenue : ${currencySymbol} ${numbersFormat(data.compareValue, 0)} ( ${data.compareShare} %)</div></span><span style="color: #eb5757"> ${Math.abs(Math.round((0 - data.compareValue) * 100 / data.compareValue))}  % <i class="fas fa-arrow-down" style="font-size: 14px;"/> </span></div>`;
                                } else {
                                    return `<div class=" ${classes.customTooltip} hoverinfo "><span> ${data.region} <br>Customer(s) : ${0} ( ${0} %)<br><div style="opacity: 0.5">Customer(s) : ${data.compareValue} ( ${data.compareShare} %)</div></span><span style="color: #eb5757"> ${Math.abs(Math.round((0 - data.compareValue) * 100 / data.compareValue))} % <i class="fas fa-arrow-down" style="font-size: 14px;"/> </span></div>`;
                                }
                            } else {
                                return `<div class=" ${classes.customTooltip} hoverinfo "> ${geography.properties.name} </div>`;
                            }
                        }
                    },
                    popupOnHover: true
                }
            }) :
            new Worldmap({
                element: document.getElementById('datamap-container'),
                position: 'relative',
                scope: 'world',
                // scope: 'usa',
                // width: 580,
                projection: 'mercator',
                data: reducedData(),
                fills: {defaultFill: defaultFill},
                done: function (datamap) {
                    datamap.svg.selectAll('.datamaps-subunit').on('click', function (geography) {
                        const country = _.find(countryData, function (o) {
                            return countries[o.code].alpha3 === geography.id;
                        });
                        if (country !== undefined) {
                            handleClick('country', country['country']);
                            GAEventTracker(CustomerGroup(selectedSeg)().name, 'Geolocation graph')
                        }
                    });
                },
                geographyConfig: {
                    borderWidth: 0.5,
                    borderColor: borderFill,
                    highlightFillColor: highlightFill,
                    popupTemplate: function (geography, data) {
                        if (!monthCompare) {
                            if (data && data.value) {
                                if (property === 'orders') {
                                    return `<div class=" ${classes.customTooltip} hoverinfo "> ${data.region} <br>Order(s) : ${data.value} ( ${data.share} %)</div>`;
                                } else if (property === 'revenue') {
                                    return `<div class=" ${classes.customTooltip} hoverinfo "> ${data.region} <br>Revenue : ${currencySymbol} ${numbersFormat(data.value, 0)} ( ${data.share} %)</div>`;
                                } else {
                                    return `<div class=" ${classes.customTooltip} hoverinfo "> ${data.region} <br>Customer(s) : ${data.value} ( ${data.share} %)</div>`;
                                }
                            } else {
                                return `<div class=" ${classes.customTooltip} hoverinfo "> ${geography.properties.name} </div>`;
                            }
                        } else {
                            if (data && data.value && data.compareValue) {
                                if (property === 'orders') {
                                    if (data.value > data.compareValue) {
                                        return `<div class=" ${classes.customTooltip} hoverinfo "><span> ${data.region} <br>Order(s) : ${data.value} ( ${data.share} %)<br><div style="opacity: 0.5">Orders(s) : ${data.compareValue} ( ${data.compareShare} %)</div></span><span style="color: #27AE60"> ${Math.abs(Math.round((data.value - data.compareValue) * 100 / data.compareValue))} % <i class="fas fa-arrow-up" style="font-size: 14px; vertical-align: middle;"/></span></div>`;
                                    } else if (data.value < data.compareValue) {
                                        return `<div class=" ${classes.customTooltip} hoverinfo "><span> ${data.region} <br>Order(s) : ${data.value} ( ${data.share} %)<br><div style="opacity: 0.5">Orders(s) : ${data.compareValue} ( ${data.compareShare} %)</div></span><span style="color:#eb5757"> ${Math.abs(Math.round((data.value - data.compareValue) * 100 / data.compareValue))} % <i class="fas fa-arrow-down" style="font-size: 14px;"/> </span></div>`;
                                    } else {
                                        return `<div class=" ${classes.customTooltip} hoverinfo "><span> ${data.region} <br>Order(s) : ${data.value} ( ${data.share} %)<br><div style="opacity: 0.5">Orders(s) : ${data.compareValue} ( ${data.compareShare} %)</div></span></div>`;
                                    }
                                } else if (property === 'revenue') {
                                    if (data.value > data.compareValue) {
                                        return `<div class=" ${classes.customTooltip} hoverinfo "><span> ${data.region} <br>Revenue : ${currencySymbol} ${numbersFormat(data.value, 0)} ( ${data.share} %)<br><div style="opacity: 0.5">Revenue : ${currencySymbol} ${numbersFormat(data.compareValue, 0)} ( ${data.compareShare} %)</div></span><span style="color: #27AE60"> ${Math.abs(Math.round((data.value - data.compareValue) * 100 / data.compareValue))} % <i class="fas fa-arrow-up" style="font-size: 14px; vertical-align: middle;"/></span></div>`;
                                    } else if (data.value < data.compareValue) {
                                        return `<div class=" ${classes.customTooltip} hoverinfo "><span> ${data.region} <br>Revenue : ${currencySymbol} ${numbersFormat(data.value, 0)} ( ${data.share} %)<br><div style="opacity: 0.5">Revenue : ${currencySymbol} ${numbersFormat(data.compareValue, 0)} ( ${data.compareShare} %)</div></span><span style="color:#eb5757"> ${Math.abs(Math.round((data.value - data.compareValue) * 100 / data.compareValue))} % <i class="fas fa-arrow-down" style="font-size: 14px;"/> </span></div>`;
                                    } else {
                                        return `<div class=" ${classes.customTooltip} hoverinfo "><span> ${data.region} <br>Revenue : ${currencySymbol} ${numbersFormat(data.value, 0)} ( ${data.share} %)<br><div style="opacity: 0.5">Revenue : ${currencySymbol} ${numbersFormat(data.compareValue, 0)} ( ${data.compareShare} %)</div></span></div>`;
                                    }
                                } else {
                                    if (data.value > data.compareValue) {
                                        return `<div class=" ${classes.customTooltip} hoverinfo "><span> ${data.region} <br>Customer(s) : ${data.value} ( ${data.share} %)<br><div style="opacity: 0.5">Customer(s) : ${data.compareValue} ( ${data.compareShare} %)</div></span><span style="color: #27AE60"> ${Math.abs(Math.round((data.value - data.compareValue) * 100 / data.compareValue))} % <i class="fas fa-arrow-up" style="font-size: 14px; vertical-align: middle;"/> </span></div>`;
                                    } else if (data.value < data.compareValue) {
                                        return `<div class=" ${classes.customTooltip} hoverinfo "><span> ${data.region} <br>Customer(s) : ${data.value} ( ${data.share} %)<br><div style="opacity: 0.5">Customer(s) : ${data.compareValue} ( ${data.compareShare} %)</div></span><span style="color: #eb5757"> ${Math.abs(Math.round((data.value - data.compareValue) * 100 / data.compareValue))} % <i class="fas fa-arrow-down" style="font-size: 14px;"/></span></div>`;
                                    } else {
                                        return `<div class=" ${classes.customTooltip} hoverinfo "><span> ${data.region} <br>Customer(s) : ${data.value} ( ${data.share} %)<br><div style="opacity: 0.5">Customer(s) : ${data.compareValue} ( ${data.compareShare} %)</div></span></div>`;
                                    }
                                }
                            } else if (data && data.value) {
                                if (property === 'orders') {
                                    return `<div class=" ${classes.customTooltip} hoverinfo " style="display: block"> ${data.region} <br>Order(s) : ${data.value} ( ${data.share} %)<br><div style="opacity: 0.5">Orders(s) : ${0} ( ${0} %)</div></div>`;
                                } else if (property === 'revenue') {
                                    return `<div class=" ${classes.customTooltip} hoverinfo " style="display: block"> ${data.region} <br>Revenue : ${currencySymbol} ${numbersFormat(data.value, 0)} ( ${data.share} %)<br><div style="opacity: 0.5">Revenue : ${currencySymbol} ${numbersFormat(0, 0)} ( ${0} %)</div></div>`;
                                } else {
                                    return `<div class=" ${classes.customTooltip} hoverinfo " style="display: block"> ${data.region} <br>Customer(s) : ${data.value} ( ${data.share} %)<br><div style="opacity: 0.5">Customer(s) : ${0} ( ${0} %)</div></div>`;
                                }
                            } else if (data && data.compareValue) {
                                if (property === 'orders') {
                                    return `<div class=" ${classes.customTooltip} hoverinfo "><span> ${data.region} <br>Order(s) : ${0} ( ${0} %)<br><div style="opacity: 0.5">Orders(s) : ${data.compareValue} ( ${data.compareShare} %)</div></span><span style="color: #eb5757"> ${Math.abs(Math.round((0 - data.compareValue) * 100 / data.compareValue))} % <i class="fas fa-arrow-down" style="font-size: 14px;"/> </span></div>`;
                                } else if (property === 'revenue') {
                                    return `<div class=" ${classes.customTooltip} hoverinfo "><span> ${data.region} <br>Revenue : ${currencySymbol} ${numbersFormat(0, 0)} ( ${0} %)<br><div style="opacity: 0.5">Revenue : ${currencySymbol} ${numbersFormat(data.compareValue, 0)} ( ${data.compareShare} %)</div></span><span style="color: #eb5757"> ${Math.abs(Math.round((0 - data.compareValue) * 100 / data.compareValue))} % <i class="fas fa-arrow-down" style="font-size: 14px;"/></span></div>`;
                                } else {
                                    return `<div class=" ${classes.customTooltip} hoverinfo "><span> ${data.region} <br>Customer(s) : ${0} ( ${0} %)<br><div style="opacity: 0.5">Customer(s) : ${data.compareValue} ( ${data.compareShare} %)</div></span><span style="color: #eb5757"> ${Math.abs(Math.round((0 - data.compareValue) * 100 / data.compareValue))} % <i class="fas fa-arrow-down" style="font-size: 14px;"/></span></div>`;
                                }
                            } else {
                                return `<div class=" ' + classes.customTooltip + ' hoverinfo ">' + geography.properties.name + '</div>`;
                            }
                        }
                    },
                    popupOnHover: true
                }
            })
    }

    function currentScreenWidth() {
        return window.innerWidth ||
            document.documentElement.clientWidth ||
            document.body.clientWidth;
    }

    useEffect(() => {
        d3.select('.datamap').remove();
        const mapContainer = d3.select('#datamap-container');
        const initialScreenWidth = currentScreenWidth();
        const containerWidth =
          initialScreenWidth < 580
            ? { height: initialScreenWidth * 0.5625 + "px" }
            : { height: "400px", width: currentScreenWidth() * 0.4 + "px"};
        mapContainer.style(containerWidth);
        //eslint-disable-next-line
        datamap = renderMap();
    }, [regionData]);

    return (
        <div id={"datamap-container"}/>
    )
}

export default GeoOrdersGraph