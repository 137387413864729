import {Box, Typography} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import React, {useEffect} from "react";
import Icons from "./icons";
import {downtime_banner} from "../actions/actions";
import {connect} from "react-redux";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  afterLoginContent: {
    display:"flex",
    marginLeft: theme.spacing(7) + 1,
    [theme.breakpoints.up(600)]: {
      marginLeft: theme.spacing(13) + 1,
      paddingLeft: 0,
    },
    paddingLeft: theme.spacing(2),
    backgroundColor: theme.palette.common.white,
  },
  beforeLoginContent: {
    display:"flex",
    backgroundColor: theme.palette.common.white,
  },
}));


const DowntimeBanner = (props) => {
  const { downtime_banner, downtime_banner_reducer, userProfile ,fullWidth} = props;
  const classes=useStyles();
  useEffect(() => {
    if (userProfile.email) {
      if ( downtime_banner_reducer.isLoading === false && !downtime_banner_reducer.banner) {
        downtime_banner();
      }    
    }
    //eslint-disable-next-line
  }, [userProfile]);
  const currentDate = new Date().toISOString();
  return (
    <>
      {userProfile.email ? (
        downtime_banner_reducer.isLoading ? null : downtime_banner_reducer.banner ? (
          currentDate >=
          downtime_banner_reducer.banner.show_banner_start_time ? (
            <Box
              p={2}
              className={
                fullWidth
                  ? userProfile.last_clustered
                    ? classes.afterLoginContent
                    : classes.beforeLoginContent
                  : "flex"
              }
            >
              {Icons.warningIcon}&nbsp;&nbsp;
              <Typography color={"primary"}>
                {downtime_banner_reducer.banner.banner_message}{" "}
                <span style={{ fontWeight: 700 }}>
                  {/* Monday Feb 13 from 8 AM to 9 AM. */}
                  {moment(downtime_banner_reducer.banner.downtime_start).format(
                    "MMMM Do YYYY, h:mm A"
                  )}
                  &nbsp;to&nbsp;
                  {moment(downtime_banner_reducer.banner.downtime_end).format(
                    "MMMM Do YYYY, h:mm A"
                  )}
                </span>
              </Typography>
            </Box>
          ) : null
        ) : null
      ) : null}
    </>
  );
};

const mapStateToProps = (state) => ({
  downtime_banner_reducer: state.downtime_banner_reducer,
  userProfile: state.userProfile,
});
const mapDispatchToProps = (dispatch) => ({
  downtime_banner: () => dispatch(downtime_banner()),
});

export default connect(mapStateToProps, mapDispatchToProps)(DowntimeBanner);
