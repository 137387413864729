import React,{useEffect} from "react";
import * as _ from "lodash";
import {numbersFormat} from "../groupPage/graphs/geoOrdersGraph";
import {CustomXAxisTick,} from "../groupPage/graphs/hourGraph";
// import ValueLabel from "@mui/material/Slider/ValueLabel";
import {scaleSqrt} from "d3-scale";
import {Bar, BarChart, CartesianGrid, ResponsiveContainer, Text, Tooltip, XAxis, YAxis,} from "recharts";
import {Box, Grid, Slider, Typography, useTheme} from "@mui/material";
import withStyles from '@mui/styles/withStyles';
import NumericLabel from "react-pretty-numbers";
import SliderValueLabel from "@mui/material/Slider/SliderValueLabel";
import { useSelector } from "react-redux";

const d3 = require("d3");

const orderParams = {
  commafy: true,
  wholenumber: "ceil",
  shortFormatMinValue: 10000,
  justification: "L",
};

export function CustomYAxisTick(props) {
    const {x, y,payload} = props;
    return (
        <g transform={`translate(${x},${y})`} >
            <Text x={0} y={0} textAnchor="end" verticalAnchor="middle" fill={'#1E334E'}
                style={{
                fontWeight: 'normal',
                fontFamily: '"Lato", sans-serif',
                fontStyle: 'normal',
                fontSize: 14
            }}>{_.isInteger(payload.value)?payload.value:null}</Text>
        </g>
    )
}


const StyledValueLabel = withStyles((theme) => ({
  offset: {
    top: -18,
  },
  label: {
    color: theme.palette.secondary.light,
  },
}))(SliderValueLabel);

export const CustomSlider = withStyles((theme) => ({
  root: {
    color: theme.palette.secondary.light,
    height: 10,
    width: "88%",
    padding: "5px 0",
  },
  markLabel: {
    color: theme.palette.common.black,
  },
  thumb: {
    height: 20,
    width: 12,
    border: "1px solid #A5A5A5",
    borderRadius: "2px 2px 2px 2px",
    backgroundColor: theme.palette.common.white,
    "&:focus, &:hover, &$active": {
      boxShadow: "inherit",
    },
  },
  active: {},
  valueLabel: {
    // color: "rgba(255,255,255,0.05)",
    color: theme.palette.primary.main,
    left: "calc(-50% + 2px)",
    paddingBottom: 50,
    // marginLeft: -10,
  },
  track: {
    height: 4,
    borderRadius: 16,
  },
  rail: {
    width: "100%",
    height: 4,
    borderRadius: 16,
    backgroundColor: "#CBCBCB",
    opacity: 1,
  },
}))(Slider);

const CustomGraphTooltip = ({ active, payload }) => {
  const theme = useTheme();
  if (active && payload !== [] && payload !== null) {
    return (
      <div className={"custom-tooltip"}>
        <Box
          p={1}
          style={{ backgroundColor: theme.palette.primary.main, minWidth: 70 }}
          borderRadius="4px"
        >
          <Grid container>
            <Grid item>
              <Typography
                variant={"body2"}
                style={{ color: theme.palette.common.white }}
              >
                {payload[0].payload.label}
              </Typography>
              <Typography
                variant={"body2"}
                style={{ color: theme.palette.common.white }}
              >
                Customers :{" "}
                <NumericLabel params={orderParams}>
                  {payload[0].value}
                </NumericLabel>
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </div>
    );
  }
  return null;
};

function ClvBlock(props) {
  const { data, currencySymbol,prediction } = props;
  const nonZeroCLVData = _.filter(data, function (o) {
    return o.clv > 0;
  });
  const selectedFilter = async (data) => {
    props.sliderCLVFilter(data);
  };
  return (
    <React.Fragment>
      {nonZeroCLVData && nonZeroCLVData.length>=1?<BarChartBlock data={data} currencySymbol={currencySymbol} prediction={prediction} nonZeroCLVData={nonZeroCLVData}  sliderFilter={selectedFilter}/>:null}
    </React.Fragment>
  );
}

const BarChartBlock=(props)=>{
  const { data, currencySymbol, prediction ,nonZeroCLVData} = props;
  let graphData, histGenerator, bins, quantityBucket, finalGraphData;
  finalGraphData = [];
  graphData = _.map(nonZeroCLVData, (d) => ({
    userId: d.USER_ID,
    clv: d.clv,
  }));
  const minRange = 1;
  const maxRange = graphData.length>1?Math.round(_.maxBy(graphData, "clv").clv):0;
  const [value, setValue] = React.useState([minRange, maxRange]);
  const maxClv = Math.ceil(_.maxBy(graphData, "clv").clv);
  let numBins = maxClv < 50 ? maxClv : 50;
  // const [numBins, setNumBins] = React.useState(maxClv < 50 ? maxClv : 50);
  quantityBucket = new Array(numBins).fill(0);
  histGenerator = d3.layout
    .histogram() // create layout object
    .bins(numBins); // number of thresholds; this will create 19+1 bins
  bins = histGenerator(
    data.map((d) => {
      return d.clv;
    })
  );

  graphData = _.sortBy(graphData, "clv");
  const filterClvData = [];
  for (let i = 0; i < graphData.length; i++) {
    if (
      Math.round(graphData[i]["clv"]) >= value[0] &&
      Math.round(graphData[i]["clv"]) <= value[1]
    ) {
      filterClvData.push(graphData[i]);
    }
  }

  for (let i = 0; i < filterClvData.length; i++) {
    for (let j = 0; j < numBins; j++) {
      if (
        bins[j]["x"] <= filterClvData[i]["clv"] &&
        bins[j]["x"] + bins[j]["dx"] >= filterClvData[i]["clv"]
      ) {
        quantityBucket[j] = quantityBucket[j] + 1;
      }
    }
  }
  for (let i = 0; i < numBins; i++) {
    finalGraphData[i] = { label: "", units: 0 };
    let min = Math.round(bins[i]["x"]);
    let max = Math.round(bins[i]["x"] + bins[i]["dx"]);
    let count = quantityBucket[i];
    finalGraphData[i]["label"] =
      currencySymbol +
      numbersFormat(min === 0 ? 1 : min, 2) +
      " - " +
      currencySymbol +
      numbersFormat(max, 2);
    finalGraphData[i]["startRange"] = min === 0 ? 1 : min;
    finalGraphData[i]["endRange"] = max;
    finalGraphData[i]["customers"] = count;
  }
  var filterGraphData = [];
  finalGraphData.forEach((d) => {
    if (value[1] >= d.startRange && value[0] <= d.endRange) {
      var obj = {
        label: d.label,
        units: d.units,
        customers: d.customers,
      };
      filterGraphData.push(obj);
    }
  });

  if (prediction.problem === "clv_12months") {
    prediction.audience_size = graphData.length;
  }
  const sliderHandler = (e, value) => {
    setValue(value);
    props.sliderFilter(value);
  };

  function valueLabelFormat(value) {
    return `${currencySymbol}${numbersFormat(value, 2)}`;
  }

  const marks = [
    {
      value: 1,
      label: `${currencySymbol}${numbersFormat(minRange, 2)}`,
    },
    {
      value: maxRange,
      label: `${currencySymbol}${numbersFormat(maxRange, 2)}`,
    },
  ];
  const scale = scaleSqrt();

  return (
    <React.Fragment>
      <ResponsiveContainer width={"98%"} height={455}>
        <BarChart
          data={filterGraphData}
          margin={{ bottom: 20, left: 80, top: 20 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            tick={<CustomXAxisTick />}
            interval={"preserveStartEnd"}
            label={{
              value: "Conversion Range",
              position: "insideBottom",
              offset: -10,
              fill: "#1E334E",
            }}
            dataKey={"label"}
            type={"category"}
          />
          <YAxis
            tick={<CustomYAxisTick />}
            tickSize={2}
            label={{
              value: "No. of Customers",
              angle: -90,
              position: "insideLeft",
              offset: -10,
              fill: "#1E334E",
            }}
            dataKey={"customers"}
            type={"number"}
            scale={scale}
          />
          <Tooltip content={<CustomGraphTooltip />} />
          <Bar
            isAnimationActive={false}
            dataKey={"customers"}
            fill={"#FF8735"}
            barSize={20}
          />
        </BarChart>
      </ResponsiveContainer>
      <Grid container>
        <Grid
          item
          xs={12}
          style={{ textAlign: "end", paddingRight: "2rem", marginTop: 12,paddingBottom:"20px" }}
        >
          <CustomSlider
            // id={"tour14thStep"}
            aria-labelledby={"prediction-slider"}
            value={value}
            valueLabelFormat={valueLabelFormat}
            components={{
              ValueLabel: StyledValueLabel,
            }}
            onChange={sliderHandler}
            max={maxRange}
            min={minRange}
            marks={marks}
            valueLabelDisplay="auto"
            disableSwap={true}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );

}

export default ClvBlock;
