import React from "react"
import {getChannelColor} from "./getGroupDetails";
import {Typography, useTheme} from "@mui/material";
import {makeStyles, withStyles} from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    arrowBox: {
        display: "flex",
        alignItems: "center",
        minWidth: "7em",
        marginBottom: 4
    },
    arrowLabel: {
        color: theme.palette.primary.main,
        minWidth: "5em"
    },
    arrowRight: {},
}));

function PathNode(props) {
    const {pathArray, secondLine} = props;
    const classes = useStyles();
    const CustomTypography = withStyles(theme => ({
        root: {
            padding: 10,
            paddingLeft: 8,
            paddingRight: 8,
            backgroundColor: "#E1E2E4",
        }
    }))(Typography);
    const CustomTypography2 = withStyles(theme => ({
        root: {
            padding: 7,
            paddingLeft: 5,
            paddingRight: 5,
            backgroundColor: getChannelColor(pathArray[0]),
        }
    }))(Typography);
    const CustomTypography1 = withStyles(theme => ({
        root: {
            width: 0,
            height: 0,
            borderTop: "19px",
            borderTopStyle: "solid",
            borderTopColor: "transparent",
            borderBottom: "19px",
            borderBottomStyle: "solid",
            borderBottomColor: "transparent",
            borderLeft: "12px",
            borderLeftStyle: "solid",
            borderLeftColor: "#E1E2E4",
        }
    }))(Typography);
    const CustomTypography3 = withStyles(theme => ({
        root: {
            padding: 3,
            paddingLeft: 8,
            paddingRight: 8,
            backgroundColor: "#E1E2E4",
        }
    }))(Typography);
    const CustomTypography4 = withStyles(theme => ({
        root: {
            padding: 8,
            paddingLeft: 5,
            paddingRight: 5,
            backgroundColor: getChannelColor(pathArray[0]),
        }
    }))(Typography);
    const CustomTypography5 = withStyles(theme => ({
        root: {
            width: 0,
            height: 0,
            borderTop: "21px",
            borderTopStyle: "solid",
            borderTopColor: "transparent",
            borderBottom: "21px",
            borderBottomStyle: "solid",
            borderBottomColor: "transparent",
            borderLeft: "15px",
            borderLeftStyle: "solid",
            borderLeftColor: "#E1E2E4",
        }
    }))(Typography);

    return (
        <>
            {secondLine ?
                <div className={classes.arrowBox}>
                    <CustomTypography4>&nbsp;</CustomTypography4>
                    <CustomTypography3 variant={"h5"} component={"div"} className={classes.arrowLabel}>
                        <span style={{fontWeight:700}}>{pathArray[1] ? pathArray[1] : pathArray[0]}</span>
                        <br/>
                        <div style={{fontSize: 12}}>{pathArray[1] ? pathArray[0] : <>&nbsp;</>}</div>
                    </CustomTypography3>
                    <CustomTypography5 component={"div"} className={classes.arrowRight}/>
                </div> :
                <div className={classes.arrowBox}>
                    <CustomTypography2>&nbsp;</CustomTypography2>
                    <CustomTypography variant={"h5"} component={"div"} className={classes.arrowLabel}>
                        <span style={{fontWeight:700}}>{pathArray[1] ? pathArray[1] : pathArray[0]}</span>
                    </CustomTypography>
                    <CustomTypography1 component={"div"} className={classes.arrowRight}/>
                </div>
            }
        </>
    )
}

export function PathSectionNode(props) {
    const {pathArray} = props;
    const classes = useStyles();
    const theme= useTheme()
    const CustomTypography = withStyles(theme => ({
        root: {
            padding: 10,
            paddingLeft: 8,
            paddingRight: 8,
            backgroundColor: "#E1E2E4",
        }
    }))(Typography);
    const CustomTypography2 = withStyles(theme => ({
        root: {
            padding: 7,
            paddingLeft: 5,
            paddingRight: 5,
            backgroundColor: getChannelColor(pathArray[0]),
        }
    }))(Typography);
    const CustomTypography1 = withStyles(theme => ({
        root: {
            width: 0,
            height: 0,
            borderTop: "19px",
            borderTopStyle: "solid",
            borderTopColor: "transparent",
            borderBottom: "19px",
            borderBottomStyle: "solid",
            borderBottomColor: "transparent",
            borderLeft: "12px",
            borderLeftStyle: "solid",
            borderLeftColor: "#E1E2E4",
        }
    }))(Typography);
    return (
        <>
            <div className={classes.arrowBox}>
                <CustomTypography2>&nbsp;</CustomTypography2>
                <CustomTypography variant={"h5"} component={"div"} className={classes.arrowLabel}>
                    <span style={{fontWeight:700}}>{pathArray[1] ? pathArray[1] : pathArray[0]}</span>&ensp;{pathArray[2] > 1 ? <span style={{color: theme.palette.common.black, textAlign:"right"}}>x{pathArray[2]}</span> : null}
                </CustomTypography>
                <CustomTypography1 component={"div"} className={classes.arrowRight}/>
            </div>
        </>
    )
}

export default PathNode;