import React, {useEffect} from "react"
import {connect} from "react-redux";
import {Box, Grid, Typography, useTheme} from "@mui/material";
import {border} from "../../../assets/jss/commonStyles";
import {capitalCase} from "change-case"
import * as _ from 'lodash'
import {Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import {CustomXAxisTick, CustomYAxisTick} from "./hourGraph";
import NumericLabel from "react-pretty-numbers";
import Icons from "../../icons";
import {
  set_a_group_filter,
  set_all_group_filter,
  set_b_group_filter,
  set_c_group_filter
} from "../../../actions/actions";
import {GAEventTracker} from "../../../index";
import CustomerGroup from "../../getGroupDetails";
import { OrangeFilledButton, WhiteFilledButton } from "../../buttons";
import { Link } from "react-router-dom";
import channelGraphDummyData from "../../../assets/files/dummyData/channelGraphData.json"
import ChannelDummyComponent from "../../dummyComponent";

const CustomChannelTooltip = ({active, payload, label, currency}) => {
    const theme = useTheme();
    const orderParams = {
        commafy: true,
        wholenumber: 'ceil',
        // shortFormat: true,
        shortFormatMinValue: 10000,
        justification: 'L',
    }
    const revenueParams = {
        'wholenumber': 'ceil',
        'locales': 'en-US',
        currency: true,
        currencyIndicator: currency,
        'commafy': true,
        justification: 'L',
    }
    if (active && payload !== [] && payload !== null) {
        if (payload.length === 1) {
            return (
                <div className={'custom-tooltip'}>
                    <Box p={1} style={{backgroundColor: theme.palette.primary.main, minWidth: 70}} borderRadius="4px">
                        <Typography variant={'body2'} style={{color: theme.palette.common.white}}>
                            {capitalCase(payload[0].payload.channel)}
                        </Typography>
                        {payload[0].name === 'orders' ?
                            <Typography variant={'body2'} style={{color: theme.palette.common.white}}>
                                Order(s) : <NumericLabel params={orderParams}>{payload[0].value}</NumericLabel>
                                &nbsp;({payload[0].value ? Math.round(payload[0].value * 100 / payload[0].payload.totalOrders) : 0}%)
                            </Typography>
                            :
                            payload[0].name === 'revenue' ?
                                <Typography variant={'body2'} style={{color: theme.palette.common.white}}>
                                    Revenue : <NumericLabel params={revenueParams}>{payload[0].value}</NumericLabel>
                                    &nbsp;({payload[0].value ? Math.round(payload[0].value * 100 / payload[0].payload.totalRevenue) : 0}%)
                                </Typography>
                                :
                                <Typography variant={'body2'} style={{color: theme.palette.common.white}}>
                                    Customer(s) : <NumericLabel params={orderParams}>{payload[0].value}</NumericLabel>
                                    &nbsp;({payload[0].value ? Math.round(payload[0].value * 100 / payload[0].payload.totalCustomers) : 0}%)
                                </Typography>

                        }
                    </Box>
                </div>
            );
        } else if (payload.length === 2) {
            const diff = payload[0].value - payload[1].value
            return (
                <div className={'custom-tooltip'}>
                    <Box p={1} style={{backgroundColor: theme.palette.primary.main, minWidth: 70}} borderRadius="4px">
                        <Grid container>
                            <Grid item>
                                <Typography variant={'body2'} style={{color: theme.palette.common.white}}>
                                    {capitalCase(payload[0].payload.channel)}
                                </Typography>
                                {payload[0].name === 'orders' ?
                                    <Typography variant={'body2'} style={{color: theme.palette.common.white}}>
                                        Order(s) : <NumericLabel params={orderParams}>{payload[0].value}</NumericLabel>
                                        &nbsp;({payload[0].value ? Math.round(payload[0].value * 100 / payload[0].payload.totalOrders) : 0}%)
                                    </Typography>
                                    :
                                    payload[0].name === 'revenue' ?
                                        <Typography variant={'body2'} style={{color: theme.palette.common.white}}>
                                            Revenue : <NumericLabel params={revenueParams}>{payload[0].value}</NumericLabel>
                                            &nbsp;({payload[0].value ? Math.round(payload[0].value * 100 / payload[0].payload.totalRevenue) : 0}%)
                                        </Typography>
                                        :
                                        <Typography variant={'body2'} style={{color: theme.palette.common.white}}>
                                            Customer(s) : <NumericLabel
                                            params={orderParams}>{payload[0].value}</NumericLabel>
                                            &nbsp;({payload[0].value ? Math.round(payload[0].value * 100 / payload[0].payload.totalCustomers) : 0}%)
                                        </Typography>

                                }
                                {payload[0].name === 'orders' ?
                                    <Typography variant={'body2'} style={{color: theme.palette.common.white, opacity: 0.5}}>
                                        Order(s) : <NumericLabel params={orderParams}>{payload[1].value}</NumericLabel>
                                        &nbsp;({payload[1].value ? Math.round(payload[1].value * 100 / payload[1].payload.compareTotalOrders) : 0}%)
                                    </Typography>
                                    :
                                    payload[0].name === 'revenue' ?
                                        <Typography variant={'body2'}
                                                    style={{color: theme.palette.common.white, opacity: 0.5}}>
                                            Revenue : <NumericLabel params={revenueParams}>{payload[1].value}</NumericLabel>
                                            &nbsp;({payload[1].value ? Math.round(payload[1].value * 100 / payload[1].payload.compareTotalRevenue) : 0}%)
                                        </Typography>
                                        :
                                        <Typography variant={'body2'}
                                                    style={{color: theme.palette.common.white, opacity: 0.5}}>
                                            Customer(s) : <NumericLabel
                                            params={orderParams}>{payload[1].value}</NumericLabel>
                                            &nbsp;({payload[1].value ? Math.round(payload[1].value * 100 / payload[1].payload.compareTotalCustomers) : 0}%)
                                        </Typography>

                                }
                            </Grid>
                            <Grid item alignItems={"flex-start"}>
                                {payload[1].value !== 0 ?
                                    diff > 0 ?
                                        <Typography variant={'h5'} style={{color: '#27AE60'}}>
                                            &nbsp;<span style={{fontWeight:700}}>{Math.abs(Math.round(diff * 100 / payload[1].value))}%</span>&nbsp;
                                            {Icons.greenLineArrow}
                                        </Typography>
                                        :
                                        diff < 0 ?
                                            <Typography variant={'h5'} style={{color: '#eb5757'}}>
                                                &nbsp;<span style={{fontWeight:700}}>{Math.abs(Math.round(diff * 100 / payload[1].value))}%</span>&nbsp;
                                                {Icons.redLineArrow}
                                            </Typography>
                                            :
                                            null
                                    :
                                    null
                                }
                            </Grid>
                        </Grid>
                    </Box>
                </div>
            );
        }

    }
    return null
}

function ChannelGraph(props) {
    const {selectedSeg, property, orderData, compareData, application_states, set_all_group_filter, set_a_group_filter, set_b_group_filter, set_c_group_filter,dataSourceLength,hasLXRTracking,trackingReady} = props;
    const handleClick = (filterName, filterValue) => {
        switch (selectedSeg) {
            case 'all':
                set_all_group_filter(filterName, filterValue);
                break;
            case 'a':
                set_a_group_filter(filterName, filterValue);
                break;
            case 'b':
                set_b_group_filter(filterName, filterValue);
                break;
            case 'c':
                set_c_group_filter(filterName, filterValue);
                break;
            default:
                return null
        }
    }
    const monthCompare = application_states.monthCompare
    const currency = application_states.currency
    const theme = useTheme();
    let channelGraphData, compareChannelGraphData;
    const customerChannelData = _.map(_.mapValues(_.groupBy(orderData.orders, "channel"), d => _.uniqBy(d, "customers")), (orders, channel) => ({
        "channel": channel,
        "customerIDs": _.uniqBy(_.flatten(_.map(orders, (d) => _.values(d.customers)))),
        "numberCustomers": _.uniqBy(_.flatten(_.map(orders, (d) => _.values(d.customers)))).length ? _.uniqBy(_.flatten(_.map(orders, (d) => _.values(d.customers)))).length : _.sumBy(orders, "customers"),
    }));
    const compareCustomerChannelData = _.map(_.mapValues(_.groupBy(compareData.orders, "channel"), d => _.uniqBy(d, "customers")), (orders, channel) => ({
        "channel": channel,
        "compareCustomerIDs": _.uniqBy(_.flatten(_.map(orders, (d) => _.values(d.customers)))),
        "compareNumberCustomers": _.uniqBy(_.flatten(_.map(orders, (d) => _.values(d.customers)))).length ? _.uniqBy(_.flatten(_.map(orders, (d) => _.values(d.customers)))).length : _.sumBy(orders, "customers"),
    }));

    if (property === "orders") {
        channelGraphData = _.map(_.mapValues(_.groupBy(orderData.orders, "channel"), d => _.sumBy(d, "orders")), (orders, channel) => ({
            "channel": channel,
            "orders": orders,
            "totalOrders": _.sumBy(orderData.orders, "orders"),
        }));
        compareChannelGraphData = _.map(_.mapValues(_.groupBy(compareData.orders, "channel"), d => _.sumBy(d, "orders")), (orders, channel) => ({
            "channel": channel,
            "compareOrders": orders,
            "compareTotalOrders": _.sumBy(compareData.orders, "orders"),
        }));
    } else {
        if (property === "revenue") {
            channelGraphData = _.map(_.mapValues(_.groupBy(orderData.orders, "channel"), d => _.sumBy(d, "revenue")), (revenue, channel) => ({
                "channel": channel,
                "revenue": revenue,
                "totalRevenue": _.sumBy(orderData.orders, "revenue"),
            }));
            compareChannelGraphData = _.map(_.mapValues(_.groupBy(compareData.orders, "channel"), d => _.sumBy(d, "revenue")), (revenue, channel) => ({
                "channel": channel,
                "compareRevenue": revenue,
                "compareTotalRevenue": _.sumBy(compareData.orders, "revenue"),
            }));
        } else {
            channelGraphData = _.map(customerChannelData, (d) => ({
                "channel": d.channel,
                "customerIDs": d.customerIDs,
                "customers": d.numberCustomers,
                "totalCustomers": _.sumBy(customerChannelData, "numberCustomers")
            }));
            compareChannelGraphData = _.map(compareCustomerChannelData, (d) => ({
                "channel": d.channel,
                "compareCustomerIDs": d.compareCustomerIDs,
                "compareCustomers": d.compareNumberCustomers,
                "compareTotalCustomers": _.sumBy(compareCustomerChannelData, "compareNumberCustomers")
            }));
        }
    }
    useEffect(() => {
    }, [channelGraphData, compareChannelGraphData])
    let finalChannelGraphData = !monthCompare ? channelGraphData : _.merge(_.keyBy(channelGraphData, 'channel'), _.keyBy(compareChannelGraphData, 'channel'));
    finalChannelGraphData = _.values(finalChannelGraphData)
    finalChannelGraphData.forEach(t => {
        // t[property] ? t[property] = t[property] : t[property] = 0
        t[property]=t[property]?t[property]:0
    })
    if (monthCompare) {
        finalChannelGraphData.forEach(t => {
            t[`compare${capitalCase(property)}`] ? t[`compare${capitalCase(property)}`] = t[`compare${capitalCase(property)}`] : t[`compare${capitalCase(property)}`] = 0
        })
    }
    return (
        <Box>
            <Box p={2} borderBottom={border}>
                <Typography variant={"h4"} style={{ fontWeight: 700}}>
                    {capitalCase(property)} by Channel</Typography>
            </Box>
            {hasLXRTracking && dataSourceLength ===1 ?
             <React.Fragment>
             {orderData.orders.length > 0 ?
                 <ResponsiveContainer width={'98%'} height={300}>
                     <BarChart layout={'vertical'} data={finalChannelGraphData} margin={{top: 30, left: 55}}
                               onClick={(payload) => {
                                   const value = _.find(channelGraphData, (o) => {
                                       return o.channel === payload.activeLabel
                                   })
                                   if (value) {
                                       handleClick('channel', payload.activeLabel)
                                       GAEventTracker(CustomerGroup(selectedSeg)().name, 'Channel graph')
                                   }
                               }}>
                         <CartesianGrid strokeDasharray="7 7" horizontal={false}/>
                         <XAxis tick={<CustomXAxisTick/>} type={'number'} stroke={'#E3E6F4'}/>
                         <YAxis tick={<CustomYAxisTick/>} dataKey={'channel'} type={"category"} stroke={'#E3E6F4'}/>
                         <Tooltip content={<CustomChannelTooltip currency={currency}/>}/>
                         <Bar isAnimationActive={false} dataKey={property} fill={'#FF8735'} barSize={10}/>
                         {monthCompare ? <Bar isAnimationActive={false} dataKey={(e) => {
                             return e[`compare${capitalCase(property)}`]
                         }} fill={theme.palette.primary.main} fillOpacity={0.25} barSize={10}/> : null}
                     </BarChart>
                 </ResponsiveContainer>
                 :
                 <Box pt={5} style={{minHeight: 250, alignItems: "center", textAlign: "center"}}>
                     <Typography>No data to display</Typography>
                 </Box>
             }
             </React.Fragment>:
             hasLXRTracking && dataSourceLength>1?
                trackingReady?
                <React.Fragment>
                {orderData.orders.length > 0 ?
                    <ResponsiveContainer width={'98%'} height={300}>
                        <BarChart layout={'vertical'} data={finalChannelGraphData} margin={{top: 30, left: 55}}
                                onClick={(payload) => {
                                    const value = _.find(channelGraphData, (o) => {
                                        return o.channel === payload.activeLabel
                                    })
                                    if (value) {
                                        handleClick('channel', payload.activeLabel)
                                        GAEventTracker(CustomerGroup(selectedSeg)().name, 'Channel graph')
                                    }
                                }}>
                            <CartesianGrid strokeDasharray="7 7" horizontal={false}/>
                            <XAxis tick={<CustomXAxisTick/>} type={'number'} stroke={'#E3E6F4'}/>
                            <YAxis tick={<CustomYAxisTick/>} dataKey={'channel'} type={"category"} stroke={'#E3E6F4'}/>
                            <Tooltip content={<CustomChannelTooltip currency={currency}/>}/>
                            <Bar isAnimationActive={false} dataKey={property} fill={'#FF8735'} barSize={10}/>
                            {monthCompare ? <Bar isAnimationActive={false} dataKey={(e) => {
                                return e[`compare${capitalCase(property)}`]
                            }} fill={theme.palette.primary.main} fillOpacity={0.25} barSize={10}/> : null}
                        </BarChart>
                    </ResponsiveContainer>
                    :
                    <Box pt={5} style={{minHeight: 250, alignItems: "center", textAlign: "center"}}>
                        <Typography>No data to display</Typography>
                    </Box>
                }
                </React.Fragment>:
                <ChannelDummyComponent param={'dataStatus'}/>
                :
                <ChannelDummyComponent param={'add_tracking'}/>
             }
            {/* {!hasChannelField?
                hasLXRTracking?
                    trackingReady?
                    <Grid container>
                        <Grid item xs={12}>
                            <Box style={{textAlign:'center',height:'150px'}}>
                                <Typography style={{textAlign:'center',marginTop:'50px'}}>No data to show</Typography>
                            </Box>
                        </Grid>
                    </Grid>:
                    <ChannelDummyComponent trackingReady={trackingReady}/>
                :
                <ChannelDummyComponent trackingReady={trackingReady}/>
            :
            <React.Fragment>
            {orderData.orders.length > 0 ?
                <ResponsiveContainer width={'98%'} height={300}>
                    <BarChart layout={'vertical'} data={finalChannelGraphData} margin={{top: 30, left: 55}}
                              onClick={(payload) => {
                                  const value = _.find(channelGraphData, (o) => {
                                      return o.channel === payload.activeLabel
                                  })
                                  if (value) {
                                      handleClick('channel', payload.activeLabel)
                                      GAEventTracker(CustomerGroup(selectedSeg)().name, 'Channel graph')
                                  }
                              }}>
                        <CartesianGrid strokeDasharray="7 7" horizontal={false}/>
                        <XAxis tick={<CustomXAxisTick/>} type={'number'} stroke={'#E3E6F4'}/>
                        <YAxis tick={<CustomYAxisTick/>} dataKey={'channel'} type={"category"} stroke={'#E3E6F4'}/>
                        <Tooltip content={<CustomChannelTooltip currency={currency}/>}/>
                        <Bar isAnimationActive={false} dataKey={property} fill={'#FF8735'} barSize={10}/>
                        {monthCompare ? <Bar isAnimationActive={false} dataKey={(e) => {
                            return e[`compare${capitalCase(property)}`]
                        }} fill={theme.palette.primary.main} fillOpacity={0.25} barSize={10}/> : null}
                    </BarChart>
                </ResponsiveContainer>
                :
                <Box pt={5} style={{minHeight: 250, alignItems: "center", textAlign: "center"}}>
                    <Typography>No data to display</Typography>
                </Box>
            }
            </React.Fragment>} */}
        </Box>
    )
}

const mapStateToProps = (state) => ({
    application_states: state.application_states,
    filterStates: state.filterStates
})
const mapDispatchToProps = (dispatch) => ({
    set_all_group_filter: (filterName, filterValue) => dispatch(set_all_group_filter(filterName, filterValue)),
    set_a_group_filter: (filterName, filterValue) => dispatch(set_a_group_filter(filterName, filterValue)),
    set_b_group_filter: (filterName, filterValue) => dispatch(set_b_group_filter(filterName, filterValue)),
    set_c_group_filter: (filterName, filterValue) => dispatch(set_c_group_filter(filterName, filterValue))
})
export default connect(mapStateToProps, mapDispatchToProps)(ChannelGraph)