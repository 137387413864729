import { Box, Modal } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { handleSuccessModal } from "../actions/actions";
import { OrangeFilledButton } from "../components/buttons";
import Icons from "../components/icons";
import Heading2 from "./GlobalTexts/Heading2";

export const modalStyleProps = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  boxShadow: 24,
  outline: "none",
};

const SuccessModal = () => {
  const dispatch = useDispatch();

  const isOpen = useSelector(
    (state) => state?.fetch_custom_filter_reducer?.isSuccessModalOpen
  );

  const handleClose = () => {
    dispatch(
      handleSuccessModal({
        value: false,
        message1: "",
      })
    );
  };

  useEffect(() => {
    setTimeout(() => {
      if (isOpen?.value) {
        dispatch(
          handleSuccessModal({
            value: false,
            message1: "",
          })
        );
      }
    }, 5000);
  }, []);

  return (
    <>
      <Modal
        open={isOpen?.value}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyleProps}>
          <Box sx={{ width: "500px" }}>
            <Box>
              <Box style={{ backgroundColor: "#68E1FD", textAlign: "center" }}>
                {Icons.audPushSucc}
              </Box>
              <Box
                p={1}
                style={{ textAlign: "center", backgroundColor: "#FFFFFF" }}
              >
                <Box>
                  <Heading2 color="#00D83A">{isOpen?.message1}</Heading2>
                  <br />
                  <OrangeFilledButton
                    style={{ borderRadius: 0, fontWeight: 500 }}
                    onClick={handleClose}
                  >
                    OK
                  </OrangeFilledButton>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default SuccessModal;
