import React from "react"
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

import { Box, Grid, Typography } from "@mui/material";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import NumericLabel from "react-pretty-numbers";
import { Tooltip } from "./tooltip";

import { useTheme } from "@emotion/react";
import { useDispatch, useSelector } from "react-redux";
import {
  changeMetricValue,
  customerTrendGraphObject,
} from "../../actions/actions";
import Icons from "../icons";
import { customerTrendConfiguration, revenuePerCustomer } from "./config";
import { CustomerChange, DataCompare } from "./customerTrendGraph";

const {
  clusteringCustomersObj,
  segmentNamesObj,
  comparisionObj,
  changePropertyObj,
  changeSelectMetricObj,
} = customerTrendConfiguration;

const orderParams1 = {
  commafy: true,
  wholenumber: "ceil",
  shortFormatMinValue: 10000,
  justification: "L",
};
const orderParams = {
  commafy: true,
  wholenumber: "ceil",
  shortFormat: true,
  shortFormatMinValue: 10000,
  justification: "L",
  precision: 1,
};

const paramOptions4 = {
  wholenumber: "ceil",
  justification: "L",
};

function changeDateFormat(dateText) {
  return moment(dateText).format("MMM YYYY");
}

function makeMonthYear(monthYearArr) {
  const [year, month] = monthYearArr;

  const monthYear = new Date(year, month - 1);

  return changeDateFormat(monthYear);
}

function xAxisDateFormat(item) {
  return `${moment(item?.data_start).format("MMM YY")} - ${moment(
    item?.data_end
  ).format("MMM YY")}`;
}

function makeDataObj(each, item) {
  return {
    ...item,
    clustering_date: changeDateFormat(each?.clustered_date),
    start_date: changeDateFormat(item?.data_start),
    end_date: changeDateFormat(item?.data_end),
    segmentName: segmentNamesObj?.[item?.segment],
    revenue_per_customer: Math.round(item?.revenue / item?.customers),
    aov: item?.revenue / item?.orders,
    month_year: makeMonthYear(each?.month_year),
    customers_percentage: Math.round(
      (item?.customers / each?.segments?.[3]?.customers) * 100
    ),
    prevMonth: parseInt(
      moment(item?.data_end).subtract(1, "months").format("MM")
    ),
    month: parseInt(moment(item?.data_end).format("MM")),
    date_format:
      moment(item?.data_start).format("MMM YYYY") +
      " - " +
      moment(item?.data_end).format("MMM YYYY"),
  };
}

const graphDataArray = (dataList) => {
  const finalObj = {
    xAxisLabels: [],
    allCustomers: [],
    highValueCustomers: [],
    midValueCustomers: [],
    lowValueCustomers: [],
  };

  dataList?.forEach((each) => {
    each?.segments?.forEach((item) => {
      if (clusteringCustomersObj?.[item?.segment]) {
        finalObj[clusteringCustomersObj?.[item?.segment]] = [
          ...finalObj[clusteringCustomersObj?.[item?.segment]],
          makeDataObj(each, item),
        ];
      }

      if (item?.segment === "all" &&
          !finalObj["xAxisLabels"].includes(xAxisDateFormat(item)))
        finalObj["xAxisLabels"] = [
          ...finalObj["xAxisLabels"],
          xAxisDateFormat(item),
        ];
    });
  });

  for (const key in finalObj) {
    if (key !== "xAxisLabels" && Array.isArray(finalObj[key])) {
      finalObj[key].reverse();
    }
  }

  return finalObj;
};

function compareData(dataObj, customersType) {
  let obj = { ...dataObj };

  for (let i = 0; i <= dataObj?.[customersType]?.length - 1; i++) {
    if (i < dataObj?.[customersType]?.length - 1) {
      if (obj && obj[customersType] && obj[customersType][i]) {
        Object.keys(comparisionObj)?.forEach((each) => {
          obj[customersType][i][each] =
            obj[customersType][i + 1][comparisionObj[each]];
        });
      }

      if (
        obj[customersType][i]["prevMonth"] ===
        obj[customersType][i + 1]["month"]
      ) {
        Object.entries(changePropertyObj)?.forEach(([key, value]) => {
          if (key === "change_Avg_hvc_Score") {
            obj[customersType][i][key] = Math.round(
              Math.round(obj[customersType][i][value]) -
                Math.round(obj[customersType][i + 1][value])
            );
          } else {
            obj[customersType][i][key] = Math.round(
              ((Math.round(obj[customersType][i][value]) -
                Math.round(obj[customersType][i + 1][value])) *
                100) /
                Math.round(obj[customersType][i + 1][value])
            );
          }
        });
      } else {
        Object.keys(changePropertyObj)?.forEach((key) => {
          obj[customersType][i][key] = "-";
        });
      }
    } else {
      Object.keys(changePropertyObj)?.forEach((key) => {
        obj[customersType][i][key] = "NA";
      });
    }
  }

  return obj;
}

function makeFinalObjsData(payloadObj) {
  let finalObj = {};

  Object.keys(payloadObj)?.forEach((key) => {
    if (key !== "xAxisLabels") {
      const obj = compareData(payloadObj, key);

      finalObj = { ...finalObj, ...obj };
    }
  });

  for (const key in finalObj) {
    if (key !== "xAxisLabels" && Array.isArray(finalObj[key])) {
      finalObj[key].reverse();
    }
  }

  return finalObj;
}

function changeGraphYAxisLables(selectedMetric, item) {
  if (selectedMetric === revenuePerCustomer) {
    return Math.round(item?.revenue / item?.customers);
  }

  return item[selectedMetric];
}

const CustomerTrendHighChart = () => {
  const dispatch = useDispatch();

  const customerTrendReducer = useSelector(
    (state) => state?.customerTrendReducer
  );

  const selectedMetricValue = useSelector(
    (state) => state?.customerTrendReducer?.selectedMetricValue
  );

  const currency = useSelector((state) => state?.application_states?.currency);

  const [chartOptions, setChartOptions] = useState({});

  const [chart, setChart] = useState(null);

  const callback = useCallback((chart) => {
    setChart(chart);
  }, []);

  function showAreaGraph(customerTrendReducer, areaGraphType) {
    const sortedDatesArr =
        customerTrendReducer?.customer_group_trends_data?.sort((a, b) => {
          if (a.month_year[0] < b.month_year[0]) return -1;
          if (a.month_year[0] > b.month_year[0]) return 1;

          // If years are the same, compare months
          if (a.month_year[1] < b.month_year[1]) return -1;
          if (a.month_year[1] > b.month_year[1]) return 1;

          // If both years and months are the same, return 0
          return 0;
        });

    const data = graphDataArray(sortedDatesArr);


    const graphData = makeFinalObjsData(data);

    const chartOptionsData = {
      chart: {
        plotBorderWidth: 2,
      },
      xAxis: {
        categories: graphData?.xAxisLabels,

        labels: {
          useHTML: true,
          style: {
            whiteSpace: "nowrap",
            textOverflow: "none",
            overflow: "justify",
            margin: 20,
          },

          formatter: function () {
            // return this.axis.categories[this.pos];
            const numOfXAxisLabels = 12;
            var step = Math.ceil((this.axis.max - this.axis.min) / numOfXAxisLabels); // Adjust the divisor for the desired number of labels to show
            if (this.isFirst || this.pos % step === 0) {
              if (
                  this.isLast &&
                  graphData?.xAxisLabels?.length > 1 &&
                  this.value !== graphData?.xAxisLabels?.length
              ) {
                return (
                    '<span style="margin-left: -60px;">' + this.value + "</span>"
                );
              } else {
                return this.value;
              }
            } else {
              return "";
            }
          },
          step:1
        },
      },

      title: {
        text: null,
      },

      yAxis: {
        title: {
          text: null,
        },
        labels: {
          style: {
            textOverflow: "none",
          },

          formatter: function () {
            switch (selectedMetricValue) {
              case revenuePerCustomer:
                return "$" + this.value;
              case "latency":
                return `${Math.round(this.value / 24)} days`;
              case "aov":
                return "$" + this.value;

              default:
                return this.value;
            }
          },
        },
      },
      tooltip: {
        style: {
          pointerEvents: "auto",
          opacity: 0,
        },
      },
      plotOptions: {
        [areaGraphType]: {
          pointPlacement: "on",
          title: "",
          marker: {
            enabled: true,
            symbol: "circle",
            radius: 5,
            states: {
              hover: {
                enabled: true,
              },
            },
          },
        },
      },

      navigation: {
        buttonOptions: {
          enabled: false,
        },
      },

      legend: {
        enabled: false,
      },

      series: [
        {
          type: areaGraphType,
          name: "HVC",
          data: graphData?.highValueCustomers?.map((each) => ({
            y: changeGraphYAxisLables(selectedMetricValue, each),
            obj: each,
          })),
          color: "#F58120",
          fillColor: "rgba(254, 241, 218, 0.9)",
        },
        {
          type: areaGraphType,
          name: "MVC",
          data: graphData?.midValueCustomers?.map((each) => ({
            y: changeGraphYAxisLables(selectedMetricValue, each),
            obj: each,
          })),
          color: "#2D9CDB",
          fillColor: "rgba(213,235,248, 0.9)",
        },

        {
          type: areaGraphType,
          name: "LVC",
          data: graphData?.lowValueCustomers?.map((each) => ({
            y: changeGraphYAxisLables(selectedMetricValue, each),
            obj: each,
          })),
          color: "#7C7C82",
          fillColor: "rgba(207,212,219, 0.9)",
        },
      ],
    };

    setChartOptions(chartOptionsData);

    return graphData;
  }

  const revenueParams = {
    locales: "en-US",
    wholenumber: "ceil",
    currency: true,
    shortFormat: true,
    shortFormatMinValue: 10000,
    currencyIndicator: currency,
    commafy: true,
    justification: "L",
    precision: 1,
  };
  const revenueParams2 = {
    locales: "en-US",
    wholenumber: "ceil",
    currency: true,
    shortFormat: true,
    shortFormatMinValue: 10000,
    currencyIndicator: currency,
    commafy: true,
    justification: "L",
  };

  function renderMetricValue(hoveredPointData) {
    const renderToolTipObj = {
      Avg_hvc_Score: () => {
        const theme = useTheme();

        const value = Math.round(hoveredPointData?.Avg_hvc_Score);
        const changeValue = hoveredPointData?.compare_month
          ? hoveredPointData?.prevMonth === hoveredPointData?.compare_month
            ? Math.abs(hoveredPointData?.change_Avg_hvc_Score)
            : "-"
          : "";

        const title = changeSelectMetricObj?.[selectedMetricValue];
        const compareValue = hoveredPointData?.compare_Avg_hvc_Score;

        return (
          <Typography variant={"body2"}>
            <>
              {title ? <>{title}:</> : null}&nbsp;
              {<NumericLabel>{value}</NumericLabel>}&nbsp;&nbsp;
              {changeValue && changeValue !== "-" ? (
                compareValue > value ? (
                  <span style={{ color: "#eb5757" }}>
                    &nbsp;
                    <NumericLabel>{changeValue}</NumericLabel>
                    &nbsp;
                    {Icons.redLineArrow}
                  </span>
                ) : (
                  <span style={{ color: "#27AE60" }}>
                    &nbsp;
                    <NumericLabel>{changeValue}</NumericLabel>
                    &nbsp;
                    {Icons.greenLineArrow}
                  </span>
                )
              ) : changeValue === "" ? (
                <span
                  style={{
                    color: title
                      ? theme.palette.common.white
                      : theme.palette.primary.main,
                    opacity: 0.5,
                  }}
                >
                  &ensp;
                </span>
              ) : changeValue === "-" ? (
                <span
                  style={{
                    color: title
                      ? theme.palette.common.white
                      : theme.palette.primary.main,
                    opacity: 0.5,
                  }}
                >
                  &ensp;-
                </span>
              ) : (
                <span
                  style={{
                    color: title
                      ? theme.palette.common.white
                      : theme.palette.primary.main,
                    opacity: 0.5,
                  }}
                >
                  &ensp;0%
                </span>
              )}
              &nbsp;
            </>
          </Typography>
        );
      },

      [revenuePerCustomer]: () => {
        return (
          <Typography variant={"body2"}>
            <DataCompare
              value={hoveredPointData?.revenue_per_customer}
              changeValue={
                hoveredPointData?.compare_month
                  ? hoveredPointData?.prevMonth ===
                    hoveredPointData?.compare_month
                    ? Math.abs(hoveredPointData?.change_revenue_per_customer)
                    : "-"
                  : ""
              }
              params={
                hoveredPointData?.revenue_per_customer < 10000
                  ? revenueParams2
                  : revenueParams
              }
              title={"Revenue/Customer"}
              compareValue={hoveredPointData?.compare_revenue_per_customer}
            />
          </Typography>
        );
      },

      latency: () => {
        return (
          <Typography variant={"body2"}>
            {changeSelectMetricObj?.[selectedMetricValue]}: &nbsp;
            <span>{`${Math.round(hoveredPointData?.latency / 24)} Days`} </span>
          </Typography>
        );
      },

      aov: () => {
        return (
          <Typography variant={"body2"}>
            <DataCompare
              value={hoveredPointData?.aov}
              changeValue={
                hoveredPointData?.compare_month
                  ? hoveredPointData?.prevMonth ===
                    hoveredPointData?.compare_month
                    ? Math.abs(hoveredPointData?.change_aov)
                    : "-"
                  : ""
              }
              params={
                hoveredPointData?.aov < 10000 ? revenueParams2 : revenueParams
              }
              title={"AOV"}
              compareValue={hoveredPointData?.compare_aov}
            />
          </Typography>
        );
      },
    };

    return <>{renderToolTipObj[selectedMetricValue]()}</>;
  }
  useEffect(() => {
    return () => {
      dispatch(changeMetricValue({ selectedMetricValue: "Avg_hvc_Score" }));
    };
  }, [dispatch]);

  useEffect(() => {
    const areaGraphType =
      selectedMetricValue === "Avg_hvc_Score" ? "spline" : "areaspline";

    const graphDataObj = showAreaGraph(customerTrendReducer, areaGraphType);

    dispatch(customerTrendGraphObject(graphDataObj));
  }, [dispatch, selectedMetricValue]);

  return (
    <Grid container xs={12}>
    <Grid item xs={12}>
      <HighchartsReact
        highcharts={Highcharts}
        options={chartOptions}
        callback={callback}
      />

      <Tooltip chart={chart}>
        {(formatterContext) => {
          const { series } = formatterContext;
          const hoveredPointData =
            series?.options?.data?.[formatterContext?.point?.index]?.obj;
          return (
            <>
              <Box
                style={{
                  backgroundColor: "#222",
                  borderRadius: 10,
                }}
              >
                <Typography
                  style={{
                    paddingTop: "0.5rem",
                    color: "#ffffff",
                    textAlign: "center",
                  }}
                  variant={"body1"}
                >
                  {hoveredPointData?.segmentName.replace("Customers", "") || ""}
                </Typography>
                <Box
                  style={{
                    borderBottom: "1px solid gray",
                    padding: "0.5rem",
                    color: "#ffffff",
                  }}
                >
                  <Typography variant={"body2"}>
                    {hoveredPointData?.date_format || ""}
                  </Typography>
                </Box>

                <div
                  style={{
                    backgroundColor: "#222",
                    padding: 8,
                    color: "white",
                    borderRadius: 8,
                  }}
                >
                  {renderMetricValue(hoveredPointData)}

                  <Typography variant={"body2"}>
                    <DataCompare
                      value={hoveredPointData?.customers || ""}
                      changeValue={
                        hoveredPointData?.compare_month
                          ? hoveredPointData?.prevMonth ===
                            hoveredPointData?.compare_month
                            ? Math.abs(hoveredPointData?.change_customers)
                            : "-"
                          : ""
                      }
                      params={
                        hoveredPointData?.customers < 10000
                          ? orderParams1
                          : orderParams
                      }
                      title={
                        hoveredPointData?.segment === "all"
                          ? "Total Customers"
                          : "Customers"
                      }
                      compareValue={hoveredPointData?.compare_customers}
                    />
                  </Typography>

                  <Typography variant={"body2"}>
                    <CustomerChange
                      value={Math.round(hoveredPointData?.customers_percentage)}
                      changeValue={
                        hoveredPointData?.compare_month
                          ? hoveredPointData?.prevMonth ===
                            hoveredPointData?.compare_month
                            ? Math.abs(
                                hoveredPointData?.change_customers_percentage
                              )
                            : "-"
                          : ""
                      }
                      params={orderParams}
                      title={"% of All Customers"}
                      compareValue={
                        hoveredPointData?.compare_customers_percentage
                      }
                    />
                  </Typography>

                  <Typography variant={"body2"}>
                    <DataCompare
                      value={hoveredPointData?.orders}
                      changeValue={
                        hoveredPointData?.compare_month
                          ? hoveredPointData?.prevMonth ===
                            hoveredPointData?.compare_month
                            ? Math.abs(hoveredPointData?.change_orders)
                            : "-"
                          : ""
                      }
                      params={
                        hoveredPointData?.orders < 10000
                          ? orderParams1
                          : orderParams
                      }
                      title={
                        hoveredPointData?.segment === "all"
                          ? "Total Orders"
                          : "Orders"
                      }
                      compareValue={hoveredPointData?.compare_orders}
                    />
                  </Typography>

                  <Typography variant={"body2"}>
                    <DataCompare
                      value={hoveredPointData?.revenue}
                      changeValue={
                        hoveredPointData?.compare_month
                          ? hoveredPointData?.prevMonth ===
                            hoveredPointData?.compare_month
                            ? Math.abs(hoveredPointData?.change_revenue)
                            : "-"
                          : ""
                      }
                      params={
                        hoveredPointData?.revenue < 10000
                          ? revenueParams2
                          : revenueParams
                      }
                      title={
                        hoveredPointData?.segment === "all"
                          ? "Total Revenue"
                          : "Revenue"
                      }
                      compareValue={hoveredPointData?.compare_revenue}
                    />
                  </Typography>

                  <Typography variant={"body2"}>
                    <DataCompare
                      value={hoveredPointData?.avg_monthly_visits}
                      changeValue={
                        hoveredPointData?.compare_month
                          ? hoveredPointData?.prevMonth ===
                            hoveredPointData?.compare_month
                            ? Math.abs(
                                Math.round(
                                  hoveredPointData?.change_avg_monthly_visits
                                )
                              )
                            : "-"
                          : ""
                      }
                      params={paramOptions4}
                      title={"Visits/Month"}
                      compareValue={
                        hoveredPointData?.compare_avg_monthly_visits
                      }
                    />
                  </Typography>
                </div>
              </Box>
            </>
          );
        }}
      </Tooltip>
    </Grid>
    </Grid>
  );
};

export default CustomerTrendHighChart;
