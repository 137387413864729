import React from "react";
import {
  Box,
  Checkbox,
  Grid,
  ListItemText,
  MenuItem,
  Select,
  Skeleton,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useTheme,
} from "@mui/material";
import { makeStyles, withStyles } from "@mui/styles";
import { overview_data, UserSegmentData } from "../../actions/actions";
import { connect } from "react-redux";
import {
  BorderAllRounded,
  BubbleChartOutlined,
  ExpandMore,
  TableChartOutlined,
} from "@mui/icons-material";
import Icons from "../icons";
import { CustomCheckboxOrangeOutline1 } from "../customComponents/customCheckbox";

const styles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: theme.spacing(4),
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
  selectClassIcon: {
    top: "calc(50% - 8px)",
  },
  selectClassOutlined: {
    padding: 8,
    paddingRight: 24,
  },
}));

// const ITEM_HEIGHT = 48;
// const ITEM_PADDING_TOP = 8;
// const MenuProps = {
//   PaperProps: {
//     style: {
//       maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
//       width: 250
//     }
//   }
// };

const CustomCheckbox = withStyles((theme) => ({
  root: {
    "&$checked": {
      // color: "#f58120 !important"
      color: theme.palette.secondary.light,
    },
  },
  checked: {},
}))(Checkbox);
export const CustomToggleButtonGroup = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.common.white,
    color: "#A4A4A4",
    "&$selected": {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.secondary.light,
      border: `1px solid ${theme.palette.secondary.light}`,
    },
  },
  selected: {},
}))(ToggleButtonGroup);
export const CustomToggleButton = withStyles(theme => ({
  root: {
    minWidth: 90,
    textTransform: 'none',
    fontSize: "0.875rem",
    border: '1px solid #afafaf',
    borderRadius:0,
    padding: 6,
    backgroundColor: "#EBEBEB",
    alignItems: 'center',
    color: "#A4A4A4",
    '&$selected,&$selected:hover': {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.secondary.light,
      border:`1px solid ${theme.palette.secondary.light}`,
    }
  },
  selected: {}
}))(ToggleButton);

function ContentHeader(props) {
  const theme = useTheme();
  const classes = styles();
  // const [content, setContent] = React.useState('graph')
  const {
    content,
    setContent,
    overview_reducer,
    headCells,
    columnNames,
    setColumnNames,
  } = props;
  const handleContentChange = async (e, contentValue) => {
    if (contentValue !== null) {
      setContent(contentValue);
    }
  };
  const handleSelectChange = (event) => {
    setColumnNames(event.target.value);
  };
  return overview_reducer.isLoading || !overview_reducer.data ? (
    <Grid container>
      <Grid item xs={12}>
        <Skeleton component={"div"} height={"104px"} />
      </Grid>
    </Grid>
  ) : (
    <Box p={1} style={{ backgroundColor: theme.palette.common.white }}>
      <Grid container spacing={1} alignItems={"center"}>
        <Grid item xs={7}>
          <Box pl={1}>
            <Typography
              color={"#747474"}
              style={{ fontSize: "14px", fontWeight: 700 }}
            >
              {content === "table" ? "Customer Details" : "Revenue Vs Recency"}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={3} style={{ textAlign: "right" }}>
          {content === "table" ? (
            <Select
              variant={"outlined"}
              value={columnNames}
              style={{ borderRadius: 0 }}
              classes={{
                icon: classes.selectClassIcon,
                outlined: classes.selectClassOutlined,
              }}
              multiple
              onChange={handleSelectChange}
              IconComponent={ExpandMore}
              displayEmpty
              renderValue={() => (
                <Typography style={{ color: "#A4A4A4", fontSize: "14px" }}>
                  Configure Columns
                </Typography>
              )}
            >
              <MenuItem disabled value={""} style={{ display: "none" }}>
                Configure Columns
              </MenuItem>
              {headCells.map((cell) =>
                cell.display ? (
                  <MenuItem key={cell.label} value={cell.label}>
                    <CustomCheckboxOrangeOutline1
                      checked={columnNames.indexOf(cell.label) > -1}
                    />
                    <ListItemText primary={cell.label} />
                  </MenuItem>
                ) : null
              )}
            </Select>
          ) : null}
        </Grid>
        <Grid item xs={2}>
          <CustomToggleButtonGroup
            value={content}
            onChange={handleContentChange}
            exclusive={true}
            aria-label="button value"
          >
            <CustomToggleButton value={"graph"} aria-label="graph">
              <BubbleChartOutlined
                style={{
                  color:
                    content === "graph"
                      ? theme.palette.secondary.light
                      : "#838383",
                }}
              />
              &nbsp;Graph
            </CustomToggleButton>
            <CustomToggleButton value={"table"} aria-label="table">
              <TableChartOutlined
                style={{
                  color:
                    content !== "graph"
                      ? theme.palette.secondary.light
                      : "#838383",
                }}
              />
              &nbsp;Table
            </CustomToggleButton>
          </CustomToggleButtonGroup>
        </Grid>
      </Grid>
    </Box>
  );
}

const mapStateToProps = (state) => ({
  user_segment_reducer: state.user_segment_reducer,
  overview_reducer: state.overview_reducer,
});
const mapDispatchToProps = (dispatch) => ({
  overview_data: () => dispatch(overview_data()),
  UserSegmentData: () => dispatch(UserSegmentData()),
});
export default connect(mapStateToProps, mapDispatchToProps)(ContentHeader);
