import {
  Box,
  IconButton,
  InputBase,
  OutlinedInput,
  styled,
} from "@mui/material";

import React from "react";
const StyledOutlinedInput = styled(OutlinedInput)(({ theme }) => ({
  "& .MuiOutlinedInput-notchedOutline": {
    border: "none",
  },
  "&:hover .MuiOutlinedInput-notchedOutline": {
    border: "none",
  },
  "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
    border: "none",
  },
}));

const InputComponent = (props) => {
  const { border, onChange, value } = props;

  return (
    <Box width="100%" height="100%">
      {/* <StyledOutlinedInput
        placeholder="Please enter text"
        sx={{ width: "100%", height: "100%" }}
      /> */}

      <OutlinedInput
        placeholder="Please enter text"
        onChange={onChange}
        value={value}
        sx={{
          width: "100%",
          height: "100%",
          border: border,

          "& .MuiOutlinedInput-notchedOutline": {
            border: border,
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            border: border,
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            border: border,
          },
        }}
      />
    </Box>
  );
};

export default InputComponent;
