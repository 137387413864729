import React from 'react'
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from "@mui/material";
import {makeStyles, withStyles} from '@mui/styles';
import * as _ from 'lodash'
import {capitalCase} from "change-case"
import NumericLabel from 'react-pretty-numbers';
import Icons from "../icons";
import GroupPageStyles from '../../assets/jss/groupPageStyles';

const CustomTableCell = withStyles(theme => ({
    root: {
        fontSize: 16,
        padding: 10
    },
    stickyHeader: {
        zIndex: 0,
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white
    }
}))(TableCell)

const CustomTableCell2 = withStyles(theme => ({
    root: {
        fontSize: 16,
        padding: 10
    }
}))(TableCell)

const CustomTableSortLabel = withStyles((theme) => ({
  root: {
    "&:hover": {
      color: theme.palette.common.white,
    },
    "&$active, &$active:hover": {
      color: theme.palette.common.white,
    },
  },
  active: {},
  icon: {
    color: `${theme.palette.common.white} !important`,
  },
}))(TableSortLabel);
const useStyles2 = makeStyles((theme) => ({
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    }
}))

function descendingComparator(a, b, orderBy) {
    if (orderBy === 'state' || orderBy === 'value') {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    } else {
        let b_change = b['compareValue'] > 0 ? ((b['value'] - b['compareValue']) / b['compareValue']) : 0
        let a_change = a['compareValue'] > 0 ? ((a['value'] - a['compareValue']) / a['compareValue']) : 0
        if (b_change < a_change) {
            return -1;
        }
        if (b_change > a_change) {
            return 1;
        }
        return 0;
    }
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

function GeoLocationTable(props) {
    const {propertyData, populationData, customerData, chart, property, monthCompare, comparePropertyData, comparePopulationData, compareCustomerData, propertyCountryData, comparePropertyCountryData, customerCountryData, compareCustomerCountryData, graphRegion, currency} = props;
    const orderParams = {
        commafy: true,
        shortFormatMinValue: 10000,
        justification: 'L',
    }
    const revenueParams = {
        'wholenumber': 'ceil',
        'locales': 'en-US',
        currency: true,
        currencyIndicator: currency,
        'commafy': true,
        justification: 'L',
    }
    let data, compareData;
    let countryData, compareCountryData;
    const styles=GroupPageStyles();
    const [order, setOrder] = React.useState('desc');
    const [orderBy, setOrderBy] = React.useState('value');
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);

    };
    const createSortHandler = (property) => (event) => {
        handleRequestSort(event, property);
    };
    let finalTableData, finalRegionData,finalCountryData;
    if (chart === 'property') {
        data = _.map(propertyData, (d) => ({
            state: d.state,
            country: d.country,
            value: Math.round(d.value)
        }))
        compareData = _.map(comparePropertyData, (d) => ({
            state: d.state,
            country: d.country,
            compareValue: Math.round(d.compareValue)
        }))
        countryData = _.map(propertyCountryData, (d) => ({
            country: d.country,
            value: Math.round(d.value)
        }))
        compareCountryData = _.map(comparePropertyCountryData, (d) => ({
            country: d.country,
            compareValue: Math.round(d.compareValue)
        }))
    } else if (chart === 'population') {
        data = _.map(populationData, (d) => ({
            state: d.state,
            value: property === 'revenue' ? Math.round(d.divValue) : parseFloat((d.divValue / 1).toFixed(1))
        }))
        compareData = _.map(comparePopulationData, (d) => ({
            state: d.state,
            compareValue: property === 'revenue' ? Math.round(d.compareDivValue) : parseFloat((d.compareDivValue / 1).toFixed(1))
        }))
    } else {
        data = _.map(customerData, (d) => ({
            state: d.state,
            country: d.country,
            value: property === 'revenue' ? Math.round(d.value / d.numberCustomers) : parseFloat((d.value / d.numberCustomers).toFixed(1))
        }))
        compareData = _.map(compareCustomerData, (d) => ({
            state: d.state,
            country: d.country,
            compareValue: property === 'revenue' ? Math.round(d.compareValue / d.compareNumberCustomers) : parseFloat((d.compareValue / d.compareNumberCustomers).toFixed(1))
        }))
        countryData = _.map(customerCountryData, (d) => ({
            country: d.country,
            value: property === 'revenue' ? Math.round(d.value / d.numberCustomers) : parseFloat((d.value / d.numberCustomers).toFixed(1))
        }))
        compareCountryData = _.map(compareCustomerCountryData, (d) => ({
            country: d.country,
            compareValue: property === 'revenue' ? Math.round(d.compareValue / d.compareNumberCustomers) : parseFloat((d.compareValue / d.compareNumberCustomers).toFixed(1))
        }))
    }
    finalRegionData = !monthCompare ? data : _.merge(_.keyBy(data, 'state'), _.keyBy(compareData, 'state'));
    finalRegionData = _.values(finalRegionData)
    finalCountryData = !monthCompare ? countryData : _.merge(_.keyBy(countryData, 'country'), _.keyBy(compareCountryData, 'country'));
    finalCountryData = _.values(finalCountryData)
    finalTableData = finalRegionData
    finalTableData.forEach(t => {
        // t.value ? t.value = t.value : t.value = 0
        t.value=t.value?t.value:0
    })
    if (monthCompare) {
        finalTableData.forEach(t => {
            // t.compareValue ? t.compareValue = t.compareValue : t.compareValue = 0
            t.compareValue = t.compareValue ? t.compareValue :0;
           })
    }
    finalTableData = _.orderBy(finalTableData, orderBy, order)
    const classes = useStyles2();

    return (
        <TableContainer style={{maxHeight:450}} className={styles.scrollbar}>
            <Table stickyHeader>
                <TableHead>
                    <TableRow>
                        {graphRegion === 'world' ?
                            <CustomTableCell key={'country'} sortDirection={orderBy === 'country' ? order : false}>
                                <CustomTableSortLabel
                                    active={orderBy === 'country'}
                                    direction={orderBy === 'country' ? order : 'asc'}
                                    onClick={createSortHandler('country')}
                                >
                                    Country
                                    {orderBy === 'country' ? (
                                        <span className={classes.visuallyHidden}>
                                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                        </span>
                                    ) : null}
                                </CustomTableSortLabel>
                            </CustomTableCell>
                            :
                            null
                        }
                        <CustomTableCell key={'state'} sortDirection={orderBy === 'state' ? order : false}>
                            <CustomTableSortLabel
                                active={orderBy === 'state'}
                                direction={orderBy === 'state' ? order : 'asc'}
                                onClick={createSortHandler('state')}
                            >
                                Region
                                {orderBy === 'state' ? (
                                    <span className={classes.visuallyHidden}>
                                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                        </span>
                                ) : null}
                            </CustomTableSortLabel>
                        </CustomTableCell>
                        {chart === 'property' ?
                            <CustomTableCell key={'value'} sortDirection={orderBy === 'value' ? order : false}>
                                <CustomTableSortLabel
                                    active={orderBy === 'value'}
                                    direction={orderBy === 'value' ? order : 'asc'}
                                    onClick={createSortHandler('value')}
                                >
                                    Total {capitalCase(property)}
                                    {orderBy === 'value' ? (
                                        <span className={classes.visuallyHidden}>
                                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                        </span>
                                    ) : null}
                                </CustomTableSortLabel>
                            </CustomTableCell>
                            :
                            chart === 'population' ?
                                <CustomTableCell key={'value'} sortDirection={orderBy === 'value' ? order : false}>
                                    <CustomTableSortLabel
                                        active={orderBy === 'value'}
                                        direction={orderBy === 'value' ? order : 'asc'}
                                        onClick={createSortHandler('value')}
                                    >
                                        {capitalCase(property)} per million
                                        {orderBy === 'value' ? (
                                            <span className={classes.visuallyHidden}>
                                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                        </span>
                                        ) : null}
                                    </CustomTableSortLabel>
                                </CustomTableCell>
                                :
                                <CustomTableCell key={'value'} sortDirection={orderBy === 'value' ? order : false}>
                                    <CustomTableSortLabel
                                        active={orderBy === 'value'}
                                        direction={orderBy === 'value' ? order : 'asc'}
                                        onClick={createSortHandler('value')}
                                    >
                                        {capitalCase(property)} per customer
                                        {orderBy === 'value' ? (
                                            <span className={classes.visuallyHidden}>
                                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                        </span>
                                        ) : null}
                                    </CustomTableSortLabel>
                                </CustomTableCell>
                        }
                        {monthCompare ?
                            <CustomTableCell key={'change'} sortDirection={orderBy === 'change' ? order : false}>
                                <CustomTableSortLabel
                                    active={orderBy === 'change'}
                                    direction={orderBy === 'change' ? order : 'asc'}
                                    onClick={createSortHandler('change')}
                                >
                                    Change
                                    {orderBy === 'change' ? (
                                        <span className={classes.visuallyHidden}>
                                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                        </span>
                                    ) : null}
                                </CustomTableSortLabel>
                            </CustomTableCell>
                            :
                            null
                        }
                    </TableRow>
                </TableHead>
                <TableBody>
                    {stableSort(finalTableData, getComparator(order, orderBy)).map((row, index) => (
                        <TableRow key={index}>
                            {graphRegion === 'world' ?
                                <CustomTableCell2>{row.country}</CustomTableCell2>
                                :
                                null}
                            <CustomTableCell2>{row.state}</CustomTableCell2>
                            <CustomTableCell2 style={{width:monthCompare?160:null}}>
                                {monthCompare ?
                                    <Grid container>
                                        <Grid item xs={3} style={{textAlign: 'right'}}>
                                            <span style={{opacity: 0.5}}>
                                            {property === 'revenue' ?
                                                <NumericLabel params={revenueParams}>
                                                    {row.compareValue ? row.compareValue : 0}
                                                </NumericLabel>
                                                :
                                                <NumericLabel params={orderParams}>
                                                    {row.compareValue ? row.compareValue : 0}
                                                </NumericLabel>
                                            }
                                            </span>
                                        </Grid>
                                        <span style={{opacity: 0.1}}>
                                            &emsp;|&emsp;
                                        </span>
                                        <Grid item xs={6}>
                                            {property === 'revenue' ?
                                                <NumericLabel params={revenueParams}>
                                                    {row.value ? row.value : 0}
                                                </NumericLabel>
                                                :
                                                <NumericLabel params={orderParams}>
                                                    {row.value ? row.value : 0}
                                                </NumericLabel>
                                            }
                                        </Grid>
                                    </Grid>
                                    :
                                    property === 'revenue' ?
                                        <NumericLabel params={revenueParams}>
                                            {row.value ? row.value : 0}
                                        </NumericLabel>
                                        :
                                        <NumericLabel params={orderParams}>
                                            {row.value ? row.value : 0}
                                        </NumericLabel>
                                }
                            </CustomTableCell2>
                            {monthCompare ?
                                <CustomTableCell2>
                                    {row.compareValue ?
                                        row.value - row.compareValue > 0 ?
                                            <Typography style={{color: '#27AE60'}}>
                                                {Math.abs(Math.round((row.value - row.compareValue) * 100 / row.compareValue))}%&nbsp;
                                                {Icons.greenLineArrow}
                                            </Typography>
                                            :
                                            row.value - row.compareValue < 0 ?
                                                <Typography style={{color: '#eb5757'}}>
                                                    {Math.abs(Math.round((row.value - row.compareValue) * 100 / row.compareValue))}%&nbsp;
                                                    {Icons.redLineArrow}
                                                </Typography>
                                                :
                                                row.value ?
                                                    <Typography color={"primary"} style={{opacity: 0.5}}>-</Typography>
                                                    :
                                                    <Typography
                                                        style={{color: '#eb5757'}}>100%{Icons.redLineArrow}</Typography>
                                        :
                                        <Typography color={"primary"} style={{opacity: 0.5}}>-</Typography>
                                    }
                                </CustomTableCell2>
                                :
                                null
                            }
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default GeoLocationTable