export const predListArray = ['single_repeat_buyer', 'repeat_buyer_next2ms', 'clv_12months', 'visitor_propensity',"churn_prediction"]
const PredictionDetails = (type) => ({
  'single_repeat_buyer': () => {
    return {
      predNo: 1,
      name: "Returning Single Buyers",
      shortDescription: "Single buyers from last 2 months who are likely to repeat purchase.",
      description: "Predicts who will return to your business among the single buyers from the last two months. Also provides the past landing and purchases insights of this audience.",
      reMarketButton: true,
      audience_size: true,
      user_segment: 'repeat_purchaser1',
      download_name: 'returning_single_buyers',
      filter_name:"Returning Single Buyers"
    };
  },
  'repeat_buyer_next2ms': () => {
    return {
      predNo: 2,
      name: "Return in Next 2 Months",
      shortDescription: "Customers who have purchased at least once in last 6 months and likely to repeat purchase in next 2 months.",
      description: "Predicts who will return in the next 2 months among the customers from the last 6 months. Also provides the past landing and purchases insights of this audience.",
      reMarketButton: true,
      audience_size: true,
      user_segment: 'next2months_repeat_buyer',
      download_name: 'return_in_next_2months',
      filter_name:"Return in next 2 months"
    };
  },
  'clv_12months': () => {
    return {
      predNo: 5,
      name: "Predicted Conversion Value",
      shortDescription: "Customers with nonzero predicted conversion value in next 12 months.",
      description: "Predicts the future conversion value of your customers in the next 12 months. Also provides the expected AOV to optimally allocate resources.",
      reMarketButton: false,
      audience_size: false,
      user_segment: 'clv',
      download_name: 'top_pred_purchase_customers'
    };
  },
  'visitor_propensity': () => {
    return {
      predNo: 3,
      name: "Visitor to Customer",
      shortDescription: "Site visitors who will likely convert",
      description: "Predicts who will convert in the next two months among all your visitors from the last month. Also provides the past landing and purchases insights of this audience.",
      reMarketButton: false,
      audience_size: true,
      user_segment: '',
      download_name: ''
    };
  },
   'churn_prediction': () => {
    return {
      predNo: 4,
      name: "Customers At Risk",
      shortDescription: "Customers who are likely to churn in the near future.",
      description: "Customers who are likely to churn in the near future.",
      reMarketButton: true,
      audience_size: true,
      user_segment: "churn_prediction",
      download_name: "customers_at_risk",
      filter_name:"Customers at Risk"
    };
  }
})[type];

export default PredictionDetails;