import CircleIcon from "@mui/icons-material/Circle";
import { Box, MenuItem, Select, Typography } from "@mui/material";

import React, { useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import * as XLSX from "xlsx";
import { changeMetricValue } from "../../actions/actions";
import { SimpleButton } from "../buttons";
import Icons from "../icons";
import CustomerTrendHighChart from "./customerTrendHighChart";
import { revenuePerCustomer } from "./config";

import * as FileSaver from "file-saver";
import { GAEventTracker } from "../..";
import { customerTrendConfiguration } from "./config";
import { CustomTooltip } from "../groupPage/groupOverview";

export const defaultMetric = "Avg_hvc_Score";

const defaultMetricLabel = "Avg. HVC Score";

const { yLabelTextArrowObj } = customerTrendConfiguration;

const metricOptionsList = [
  {
    id: 1,
    label: "Avg. HVC Score",
    value: "Avg_hvc_Score",
  },

  {
    id: 2,
    label: "Revenue / Customer",
    value: revenuePerCustomer,
  },

  {
    id: 3,
    label: "Avg. Latency",
    value: "latency",
  },

  {
    id: 4,
    label: "Avg. Order Value",
    value: "aov",
  },
];

const clusteredCustomers = [
  {
    id: 1,
    name: "High Value",
    color: "#F58120",
  },

  {
    id: 2,
    name: "Mid Value",
    color: "#2D9CDB",
  },

  {
    id: 3,
    name: "Low Value",
    color: "#7C7C82",
  },
];

const CustomerTrendMetricGraph = () => {
  const dispatch = useDispatch();

  const customerGroupTrendsData = useSelector(
    (state) => state?.customerTrendReducer?.customer_group_trends_data
  );

  const graphDataObj = useSelector(
    (state) => state?.customerTrendReducer?.graphDataObj
  );

  const currencySymbol = useSelector(
    (state) => state?.application_states?.currencySymbol
  );

  const businessProfile = useSelector((state) => state?.businessProfile);

  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";

  const fileExtension = ".xlsx";

  let headers = [
    "segmentName",
    "data_start",
    "data_end",
    "customers",
    "change_customers",
    "orders",
    "change_orders",
    "revenue",
    "change_revenue",
    "revenue_per_customer",
    "change_revenue_per_customer",
    "aov",
    "change_aov",
    businessProfile.ecom ? null : "avg_monthly_visits",
    businessProfile.ecom ? null : "change_avg_monthly_visits",
  ];

  const [selectedMetricLabel, setSelectedMetricLabel] =
    useState(defaultMetricLabel);

  const [selectedMetric, setSelectedMetric] = useState(defaultMetric);

  const handleChange = (event) => {
    setSelectedMetric(event.target.value);

    metricOptionsList?.forEach((each) => {
      if (each?.value === event.target.value) {
        setSelectedMetricLabel(each?.label);
      }
    });

    dispatch(changeMetricValue({ selectedMetricValue: event.target.value }));
  };

  const exportToCSV = (csvData, fileName) => {
    const headersObj = {
      A4: "Segment",
      B4: "Start Date",
      C4: "End Date",
      D4: "Customers",
      E4: "% Change in Customers",
      F4: "Orders",
      G4: "% Change in Orders",
      H4: `Revenue (${currencySymbol})`,
      I4: "% Change in Revenue",
      J4: `Revenue/Customer (${currencySymbol})`,
      K4: "% Change in Revenue/Customer",
      L4: `AOV (${currencySymbol})`,
      M4: "% Change in AOV",
      N4: "Visits/Month",
      O4: "% Change in Visits/Month",
    };

    const ws = XLSX.utils.json_to_sheet([]);

    XLSX.utils.sheet_add_json(ws, csvData, { origin: "A4" });

    const wb = {
      Sheets: { "customer trend": ws },
      SheetNames: ["customer trend"],
    };

    XLSX.utils.sheet_add_aoa(ws, [["Customer Trend"]], { origin: "A2" });

    let first_sheet_name = wb.SheetNames[0];
    let worksheet = wb.Sheets[first_sheet_name];

    Object.entries(headersObj).forEach(([key, value]) => {
      if (
        !businessProfile.ecom &&
        ["N4", "O4"].includes(key) &&
        worksheet[key]
      ) {
        worksheet[key].v = value;
      } else {
        if (worksheet[key]) {
          worksheet[key].v = value;
        }
      }
    });

    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });

    const tableData = new Blob([excelBuffer], { type: fileType });

    FileSaver.saveAs(tableData, fileName + fileExtension);
  };

  const handleDownload = (graphDataObj) => {
    let dataArray = [];

    Object.keys(graphDataObj)?.forEach((eachGraph) => {
      if (eachGraph !== "xAxisLabels") {
        if (graphDataObj[eachGraph]?.length) {
          const graphArr = graphDataObj[eachGraph]?.map((each) => {
            let obj = {};

            headers?.forEach((eachHeader) => {
              if (each.hasOwnProperty(eachHeader)) {
                if (
                  ["revenue", "revenue_per_customer", "aov"].includes(
                    eachHeader
                  )
                ) {
                  obj[eachHeader] =
                    parseFloat(
                      (Math.round(each[eachHeader] * 100) / 100).toFixed(2)
                    ) || each[eachHeader];
                } else if (["data_start", "data_end"].includes(eachHeader)) {
                  obj[eachHeader] = each[eachHeader].replace("T", " ");
                } else {
                  obj[eachHeader] = each[eachHeader];
                }
              }
            });

            return obj;
          });

          dataArray.push(...graphArr);
        }
      }
    });

    exportToCSV(dataArray, "customer_trend");
  };

  return (
    <Box
      width="95%"
      height={"auto"}
      m={4}
      padding={0}
      marginTop={7}
      sx={{ display: "flex", flexDirection: "column" }}
      style={{
        fontFamily: '"Lato", sans-serif',
        backgroundColor: "#fff",
      }}
      boxShadow="rgba(255, 255, 255, 1) 0px -2px 10px 0px, rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px"
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          padding: 3,
          border: "1px solid #F0F0F0",
          borderRadius: 1,
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column", gap: 1.3 }}>
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <Typography
              style={{
                fontWeight: "normal",
                lineHeight: "24px",
                fontSize: "20px",
                color: "#000000",
              }}
              variant="h3"
              sx={{ fontWeight: "semi-bold" }}
            >
              Customer Trend
            </Typography>

            <CustomTooltip title={"Download"} arrow interactive="true">
              <SimpleButton
                disabled={
                  customerGroupTrendsData
                    ? customerGroupTrendsData?.length === 0
                      ? true
                      : false
                    : true
                }
                onClick={() => {
                  handleDownload(graphDataObj);
                  GAEventTracker(
                    "Customer Trend",
                    "Download",
                    "Customer Trend"
                  );
                }}
              >
                {Icons.customerTrendDownloadIcon}
              </SimpleButton>
            </CustomTooltip>
          </Box>

          <Typography
            variant="body1"
            fontSize={"14px"}
            lineHeight={"16.8px"}
            color={"#4F4F4F"}
            fontWeight={400}
          >
            {`${selectedMetricLabel} Over Time`}
          </Typography>
        </Box>

        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          {clusteredCustomers?.map((each, index) => (
            <Box
              sx={{ display: "flex", alignItems: "center", gap: 1 }}
              key={`customers-group-${index}+${each?.id}`}
            >
              <CircleIcon sx={{ fontSize: 12, color: each?.color }} />
              <Typography
                variant="body1"
                style={{
                  lineHeight: "14.4px",
                  fontWeight: 400,
                  fontSize: "12px",
                  color: "#000000",
                }}
              >
                {each?.name}
              </Typography>
            </Box>
          ))}
        </Box>

        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography
            variant="body1"
            color={"#4F4F4F"}
            style={{ fontWeight: 400, fontSize: "14px", lineHeight: "16.8px" }}
          >
            Select a Metric : &nbsp;
          </Typography>
          <Select
            value={selectedMetric}
            onChange={handleChange}
            size="small"
            displayEmpty
            inputProps={{ "aria-label": "Without label" }}
            sx={{
              padding: 0,
              borderRadius: 1,
              border: 0,
              width: "13rem",
              fontSize: "14px",

              "&:hover": {
                "&& fieldset": {
                  border: "1px solid #C6C6C6",
                },
              },
              "&:focus": {
                "&& fieldset": {
                  border: "1px solid #C6C6C6",
                },
              },
              "&.Mui-focused": {
                "&& fieldset": {
                  border: "1px solid #C6C6C6",
                },
              },
            }}
          >
            {metricOptionsList?.map((each, index) => (
              <MenuItem
                value={each?.value}
                key={`metric-options-${each?.id}-${index}`}
              >
                {each?.label}
              </MenuItem>
            ))}
          </Select>
        </Box>
      </Box>

      <Box
        sx={{
          padding: "1rem 2rem",
          border: "3px solid #F6F6F6",
          borderRadius: 1,
        }}
      >
        {!customerGroupTrendsData?.length ? (
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100px",
            }}
          >
            <Typography color={"#4F4F4F"}>No Data to display.</Typography>
          </Box>
        ) : (
          <>
            <Box sx={{ display: "flex", gap: 1 }} marginTop={3}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 3,
                  marginTop: 3,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: 2,
                  }}
                >
                  {Icons.arrowLine1Icon}

                  <Typography
                    variant="body1"
                    fontSize={"0.85rem"}
                    color={"#7C7C82"}
                    style={{
                      writingMode: "vertical-rl",
                      transform: "rotate(180deg)",
                    }}
                  >
                    {yLabelTextArrowObj?.[selectedMetric]}
                  </Typography>

                  {Icons.arrowLine2Icon}
                </Box>
              </Box>

              <CustomerTrendHighChart />
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              marginTop={3}
            >
              {Icons.arrowTimeLineImgIcon}
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
};

export default CustomerTrendMetricGraph;
