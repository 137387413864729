import React, {Fragment} from 'react';
import {Box, Breadcrumbs, CircularProgress, Drawer, Tooltip, Typography, useTheme} from "@mui/material";
import {makeStyles, withStyles} from '@mui/styles';
import {border} from "../assets/jss/commonStyles";
import CustomerGroup from "./getGroupDetails";
import Icons from "./icons";
import {NavigateNext} from "@mui/icons-material";
import {SimpleButton} from "./buttons";
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import * as _ from "lodash";
import {connect} from "react-redux";
import {GAEventTracker} from "../index";
import NumericLabel from "react-pretty-numbers"
import {capitalCase} from 'change-case'
import {ReportDownloaded} from "../actions/actions";
import PredictionDetails from "./getPredictionDetails"

const CustomTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        // boxShadow: theme.shadows[1],
        fontSize: 11,
    },
    arrow: {
        color: theme.palette.primary.main,
    }
}))(Tooltip);
const useStyles = makeStyles((theme) => ({
    separatorClass: {
        marginLeft: 0,
        marginRight: 0,
        color: theme.palette.primary.main,
        opacity: 0.5
    }
}))
const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const fileExtension = '.xlsx';
const paramOptions = {
    commafy: true,
    justification: 'L'
}

function DrawerList(props) {
    const {onClose, segmentDetail, predictionList, user_segment_reducer, repurchase3Months, ReportDownloaded, application_states, churnCustomers} = props;
    const currencySymbol = application_states.currencySymbol
    const downloadFile = PredictionDetails('single_repeat_buyer')().name
    const file3Months = PredictionDetails('repeat_buyer_next2ms')().name
    const churnFile = PredictionDetails('churn_prediction')().name
    let downloadData;
    let data3Months;
    let churnData;
    const predListKeyArray = predictionList.length > 0 ? Object.keys(predictionList[0]) : []
    const rep3mListKeyArray = repurchase3Months.length > 0 ? Object.keys(repurchase3Months[0]) : []
    const churnListKeyArray = churnCustomers.length > 0 ? Object.keys(churnCustomers[0]) : []
    downloadData = _.map(predictionList, (d) => ({
        USER_ID: d.USER_ID,
        Segment: CustomerGroup(d.segment)().name,
        recency: d.recency,
        frequency: d.frequency,
        monetary_value: d.monetory_value,
        clv: Math.ceil(d.clv),
        CLIENT_ORDER_ID_last_order: d.CLIENT_ORDER_ID_last_order,
        order_value_last_order: d.order_value_last_order,
        firstOrderDate: d.SERVER_TIMESTAMP_first_order,
        lastOrderDate: d.SERVER_TIMESTAMP_last_order,
        device_last_order: d.device_last_order ? capitalCase(d.device_last_order) : '',
        region_last_order: d.region_last_order ? d.region_last_order : '',
        channel_last_order: d.channel_last_order ? d.channel_last_order : '',
    }))
    data3Months = _.map(repurchase3Months, (d) => ({
        USER_ID: d.USER_ID,
        Segment: CustomerGroup(d.segment)().name,
        recency: d.recency,
        frequency: d.frequency,
        monetary_value: d.monetory_value,
        clv: Math.ceil(d.clv),
        CLIENT_ORDER_ID_last_order: d.CLIENT_ORDER_ID_last_order,
        order_value_last_order: d.order_value_last_order,
        firstOrderDate: d.SERVER_TIMESTAMP_first_order,
        lastOrderDate: d.SERVER_TIMESTAMP_last_order,
        device_last_order: d.device_last_order ? capitalCase(d.device_last_order) : '',
        region_last_order: d.region_last_order ? d.region_last_order : '',
        channel_last_order: d.channel_last_order ? d.channel_last_order : '',
    }))
    churnData = _.map(churnCustomers, (d) => ({
        USER_ID: d.USER_ID,
        Segment: CustomerGroup(d.segment)().name,
        recency: d.recency,
        frequency: d.frequency,
        monetary_value: d.monetory_value,
        clv: Math.ceil(d.clv),
        CLIENT_ORDER_ID_last_order: d.CLIENT_ORDER_ID_last_order,
        order_value_last_order: d.order_value_last_order,
        firstOrderDate: d.SERVER_TIMESTAMP_first_order,
        lastOrderDate: d.SERVER_TIMESTAMP_last_order,
        device_last_order: d.device_last_order ? capitalCase(d.device_last_order) : '',
        region_last_order: d.region_last_order ? d.region_last_order : '',
        channel_last_order: d.channel_last_order ? d.channel_last_order : '',
    }))
    const exportToCSV = (csvData, fileName) => {
        const ws = XLSX.utils.json_to_sheet([]);
        XLSX.utils.sheet_add_json(ws, csvData, {origin: 'A5'})
        const wb = {Sheets: {'Returning Single Buyers': ws}, SheetNames: ['Returning Single Buyers']};
        XLSX.utils.sheet_add_aoa(ws, [['Customer Group', CustomerGroup(segmentDetail.segment)().name]], {origin: "A2"});
        let first_sheet_name = wb.SheetNames[0];
        let worksheet = wb.Sheets[first_sheet_name];
        worksheet['A5'].v = 'User Id';
        worksheet['B5'].v = 'Segment';
        worksheet['C5'].v = 'Recency (Days)';
        worksheet['D5'].v = 'Frequency';
        worksheet['E5'].v = `Revenue (${currencySymbol})`;
        worksheet['F5'].v = `Predicted Conversion Value (${currencySymbol})`;
        worksheet['G5'].v = 'Last Order Id';
        worksheet['H5'].v = `Last Order Value (${currencySymbol})`;
        worksheet['I5'].v = 'First Order Date';
        worksheet['J5'].v = 'Last Order Date';
        worksheet['K5'].v = predListKeyArray.includes("device_last_order") ? 'Last Order Device' : '';
        worksheet['L5'].v = predListKeyArray.includes("region_last_order") ? 'Last Order Region' : '';
        worksheet['M5'].v = predListKeyArray.includes("channel_last_order") ? 'Last Order Channel' : '';
        const excelBuffer = XLSX.write(wb, {bookType: 'xlsx', type: 'array'});
        const tableData = new Blob([excelBuffer], {type: fileType});
        FileSaver.saveAs(tableData, fileName + fileExtension);
    }
    const exportToCSV3months = (csvData, fileName) => {
        const ws = XLSX.utils.json_to_sheet([]);
        XLSX.utils.sheet_add_json(ws, csvData, {origin: 'A5'})
        const wb = {Sheets: {'Return in Next 2 Months': ws}, SheetNames: ['Return in Next 2 Months']};
        XLSX.utils.sheet_add_aoa(ws, [['Customer Group', CustomerGroup(segmentDetail.segment)().name]], {origin: "A2"});
        let first_sheet_name = wb.SheetNames[0];
        let worksheet = wb.Sheets[first_sheet_name];
        worksheet['A5'].v = 'User Id';
        worksheet['B5'].v = 'Segment';
        worksheet['C5'].v = 'Recency (Days)';
        worksheet['D5'].v = 'Frequency';
        worksheet['E5'].v = `Revenue (${currencySymbol})`;
        worksheet['F5'].v = `Predicted Conversion Value (${currencySymbol})`;
        worksheet['G5'].v = 'Last Order Id';
        worksheet['H5'].v = `Last Order Value (${currencySymbol})`;
        worksheet['I5'].v = 'First Order Date';
        worksheet['J5'].v = 'Last Order Date';
        worksheet['K5'].v = rep3mListKeyArray.includes("device_last_order") ? 'Last Order Device' : '';
        worksheet['L5'].v = rep3mListKeyArray.includes("region_last_order") ? 'Last Order Region' : '';
        worksheet['M5'].v = rep3mListKeyArray.includes("channel_last_order") ? 'Last Order Channel' : '';
        const excelBuffer = XLSX.write(wb, {bookType: 'xlsx', type: 'array'});
        const tableData = new Blob([excelBuffer], {type: fileType});
        FileSaver.saveAs(tableData, fileName + fileExtension);
    }
    const exportToCSVchurn = (csvData, fileName) => {
        const ws = XLSX.utils.json_to_sheet([]);
        XLSX.utils.sheet_add_json(ws, csvData, {origin: 'A5'})
        const wb = {Sheets: {'Customers at Risk': ws}, SheetNames: ['Customers at Risk']};
        XLSX.utils.sheet_add_aoa(ws, [['Customer Group', CustomerGroup(segmentDetail.segment)().name]], {origin: "A2"});
        let first_sheet_name = wb.SheetNames[0];
        let worksheet = wb.Sheets[first_sheet_name];
        worksheet['A5'].v = 'User Id';
        worksheet['B5'].v = 'Segment';
        worksheet['C5'].v = 'Recency (Days)';
        worksheet['D5'].v = 'Frequency';
        worksheet['E5'].v = `Revenue (${currencySymbol})`;
        worksheet['F5'].v = `Predicted Conversion Value (${currencySymbol})`;
        worksheet['G5'].v = 'Last Order Id';
        worksheet['H5'].v = `Last Order Value (${currencySymbol})`;
        worksheet['I5'].v = 'First Order Date';
        worksheet['J5'].v = 'Last Order Date';
        worksheet['K5'].v = churnListKeyArray.includes("device_last_order") ? 'Last Order Device' : '';
        worksheet['L5'].v = churnListKeyArray.includes("region_last_order") ? 'Last Order Region' : '';
        worksheet['M5'].v = churnListKeyArray.includes("channel_last_order") ? 'Last Order Channel' : '';
        const excelBuffer = XLSX.write(wb, {bookType: 'xlsx', type: 'array'});
        const tableData = new Blob([excelBuffer], {type: fileType});
        FileSaver.saveAs(tableData, fileName + fileExtension);
    }
    const theme = useTheme()
    const classes = useStyles();
    return (
        <div style={{width: 350}} role={'presentation'} onClick={onClose} onKeyDown={onClose}>
            <Box p={2.5} borderBottom={border} className={'flex'} justifyContent={'space-between'}>
                <Typography style={{fontWeight:700}}>Predictions</Typography>
            </Box>
            {user_segment_reducer.isLoading ?
                <CircularProgress size={20} style={{
                    position: 'relative',
                    left: '50%',
                    top: '50%',
                    color: theme.palette.secondary.light
                }}/>
                :
                null
            }
            {predictionList.length > 0 ?
                <Box px={2} style={{backgroundColor: theme.palette.common.white}} borderBottom={border}>
                    <Box py={1.5} borderBottom={border}>
                        <Breadcrumbs classes={{
                            separator: classes.separatorClass
                        }} separator={<NavigateNext fontSize="small"/>} aria-label="breadcrumb">
                            <Typography color="textPrimary" variant={'h5'}
                                        style={{
                                            opacity: 0.5,
                                            fontWeight: 400
                                        }}>{CustomerGroup(segmentDetail.segment)().name}</Typography>
                            <Typography color="textPrimary" variant={'h5'}
                                        style={{fontWeight: 400}}>{PredictionDetails('single_repeat_buyer')().name}</Typography>
                        </Breadcrumbs>
                    </Box>
                    <Box p={1} py={2} justifyContent={'space-between'}>
                        {/*<Grid container alignItems={"center"}>*/}
                        {/*    <Grid item xs={9} lg={10}>*/}
                        <Box pb={1}>
                            <Typography color="textPrimary" variant={'h5'} style={{fontWeight: 400}}>
                                There are <span style={{fontWeight:700}}><NumericLabel params={paramOptions}>{predictionList.length}</NumericLabel></span> first
                                time purchasers in the last 2 months who we predict are
                                likely to buy from you again.
                                Download the list to learn more and personalize your engagement.
                            </Typography>
                        </Box>
                        <CustomTooltip title={'Download'} arrow interactive>
                            <SimpleButton onClick={() => {
                                exportToCSV(downloadData, downloadFile);
                                GAEventTracker('Prediction', 'Download', 'Repeat Buyer')
                                ReportDownloaded(downloadFile, 'Prediction List', segmentDetail.segment)
                            }} style={{margin: 0}}>
                                {Icons.downloadIcon}
                            </SimpleButton>
                        </CustomTooltip>
                        {/*</Grid>*/}
                        {/*</Grid>*/}
                    </Box>
                </Box>
                :
                null}
            {repurchase3Months.length > 0 ?
                <Box px={2} style={{backgroundColor: theme.palette.common.white}} borderBottom={border}>
                    <Box py={1.5} borderBottom={border}>
                        <Breadcrumbs classes={{
                            separator: classes.separatorClass
                        }} separator={<NavigateNext fontSize="small"/>} aria-label="breadcrumb">
                            <Typography color="textPrimary" variant={'h5'}
                                        style={{
                                            opacity: 0.5,
                                            fontWeight: 400
                                        }}>{CustomerGroup(segmentDetail.segment)().name}</Typography>
                            <Typography color="textPrimary" variant={'h5'}
                                        style={{fontWeight: 400}}>{PredictionDetails('repeat_buyer_next2ms')().name}</Typography>
                        </Breadcrumbs>
                    </Box>
                    <Box p={1} py={2} justifyContent={'space-between'}>
                        {/*<Grid container alignItems={"center"}>*/}
                        {/*    <Grid item xs={9} lg={10}>*/}
                        <Box pb={1}>
                            <Typography color="textPrimary" variant={'h5'} style={{fontWeight: 400}}>
                                These <span style={{fontWeight:700}}><NumericLabel
                                params={paramOptions}>{repurchase3Months.length}</NumericLabel></span> customers who bought
                                from your website in the last 6 months are predicted to buy again
                                in the next 2 months. Download the customer list here.
                            </Typography>
                        </Box>
                        <CustomTooltip title={'Download'} arrow interactive>
                            <SimpleButton onClick={() => {
                                exportToCSV3months(data3Months, file3Months);
                                GAEventTracker('Prediction', 'Download', '3 month purchaser')
                                ReportDownloaded(file3Months, 'Prediction List', segmentDetail.segment)
                            }} style={{margin: 0}}>
                                {Icons.downloadIcon}
                            </SimpleButton>
                        </CustomTooltip>
                        {/*</Grid>*/}
                        {/*</Grid>*/}
                    </Box>
                </Box>
                :
                null}
                {churnCustomers.length > 0 ?
                <Box px={2} style={{backgroundColor: theme.palette.common.white}} borderBottom={border}>
                    <Box py={1.5} borderBottom={border}>
                        <Breadcrumbs classes={{
                            separator: classes.separatorClass
                        }} separator={<NavigateNext fontSize="small"/>} aria-label="breadcrumb">
                            <Typography color="textPrimary" variant={'h5'}
                                        style={{
                                            opacity: 0.5,
                                            fontWeight: 400
                                        }}>{CustomerGroup(segmentDetail.segment)().name}</Typography>
                            <Typography color="textPrimary" variant={'h5'}
                                        style={{fontWeight: 400}}>{PredictionDetails('churn_prediction')().name}</Typography>
                        </Breadcrumbs>
                    </Box>
                    <Box p={1} py={2} justifyContent={'space-between'}>
                        {/*<Grid container alignItems={"center"}>*/}
                        {/*    <Grid item xs={9} lg={10}>*/}
                        <Box pb={1}>
                            <Typography color="textPrimary" variant={'h5'} style={{fontWeight: 400}}>
                                These <span style={{fontWeight:700}}><NumericLabel
                                params={paramOptions}>{churnCustomers.length}</NumericLabel></span> customers who bought
                                from your website in the last 13 months are predicted to churn in near future. Download
                                the customer list here.
                            </Typography>
                        </Box>
                        <CustomTooltip title={'Download'} arrow interactive>
                            <SimpleButton onClick={() => {
                                exportToCSVchurn(churnData, churnFile);
                                GAEventTracker('Prediction', 'Download', 'Churned Customers')
                                ReportDownloaded(churnFile, 'Prediction List', segmentDetail.segment)
                            }} style={{margin: 0}}>
                                {Icons.downloadIcon}
                            </SimpleButton>
                        </CustomTooltip>
                        {/*</Grid>*/}
                        {/*</Grid>*/}
                    </Box>
                </Box>
                :
                null}
            {!(predictionList.length > 0) && !(repurchase3Months.length > 0)&& !(churnCustomers.length > 0) && !user_segment_reducer.isLoading ?
                <Box p={2} style={{textAlign: 'center'}}>
                    <Typography color={"primary"}>
                        No predictions to display
                    </Typography>
                </Box>
                :
                null
            }
        </div>
    )
}

function PredictionSidebar(props) {
    const {open, onClose, segmentDetail, predictionList, user_segment_reducer, repurchase3Months, ReportDownloaded, application_states, churnCustomers} = props;
    return (
        <Fragment>
            <Drawer anchor={"right"} onClose={onClose} open={open}>
                <DrawerList user_segment_reducer={user_segment_reducer} onClose={onClose} segmentDetail={segmentDetail}
                            repurchase3Months={repurchase3Months} churnCustomers={churnCustomers} predictionList={predictionList}
                            ReportDownloaded={ReportDownloaded} application_states={application_states}/>
            </Drawer>
        </Fragment>
    )
}

const mapStateToProps = (state) => ({
    user_segment_reducer: state.user_segment_reducer,
    application_states: state.application_states
})
const mapDispatchToProps = (dispatch) => ({
    ReportDownloaded: (report_filename, report_type, segment) => dispatch(ReportDownloaded(report_filename, report_type, segment))
})
export default connect(mapStateToProps, mapDispatchToProps)(PredictionSidebar);