import { Typography } from "@mui/material";
import React from "react";

const Heading2 = ({ color, children }) => {
  return (
    <Typography
      fontSize={"1.5rem"}
      fontWeight={700}
      fontFamily={'"Lato", sans-serif'}
      color={color ? color : "#4E4E4E"}
      lineHeight={"28.8px"}
    >
      {children}
    </Typography>
  );
};

export default Heading2;
