import React, {useEffect} from "react"
import {Box, Button, Grid, Typography, useTheme} from "@mui/material";
import {border} from "../../../assets/jss/commonStyles";
import * as _ from "lodash";
import {capitalCase} from "change-case"
import HeatMap from "react-heatmap-grid";
import moment from "moment";
import Icons from "../../icons"
import {connect} from "react-redux";
import {
  set_a_group_filter,
  set_all_group_filter,
  set_b_group_filter,
  set_c_group_filter
} from "../../../actions/actions";
import {GAEventTracker} from "../../../index";
import CustomerGroup from "../../getGroupDetails";

function DayAndHourGraph(props) {
    const theme = useTheme();
    const {selectedSeg, data, property, compareData, application_states, set_all_group_filter, set_a_group_filter, set_b_group_filter, set_c_group_filter} = props;
    const handleClick = (filterName, filterValue) => {
        switch (selectedSeg) {
            case 'all':
                set_all_group_filter(filterName, filterValue);
                break;
            case 'a':
                set_a_group_filter(filterName, filterValue);
                break;
            case 'b':
                set_b_group_filter(filterName, filterValue);
                break;
            case 'c':
                set_c_group_filter(filterName, filterValue);
                break;
            default:
                return null
        }
    }
    const [gridCompare, setGridCompare] = React.useState(false)
    const monthCompare = application_states.monthCompare
    const currencySymbol = application_states.currencySymbol
    const orders = !gridCompare ? data.orders : compareData.orders;
    if (orders) {
        orders.forEach(d => {
            d.day_of_week = moment(d.date).format('dddd')
        })
    }
    const xLabels = new Array(24).fill(0).map((_, i) => `${i}`);
    // Display only even labels
    const xLabelsVisibility = new Array(24)
      .fill(1)
    // .map((_, i) => (i % 2 === 0));

    const yLabels = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
    const yLabelsDisplay = ["M", "T", "W", "T", "F", "S", "S"];
    let gridDataObj;
    if (property === "orders") {
        gridDataObj = _.mapValues(_.groupBy(orders, "day_of_week"), d => _.mapValues(_.groupBy(d, "hour"), (t) => _.sumBy(t, "orders")));
    } else {
        if (property === "revenue") {
            gridDataObj = _.mapValues(_.groupBy(orders, "day_of_week"), d => _.mapValues(_.groupBy(d, "hour"), (t) => _.sumBy(t, "revenue")));
        } else {
            gridDataObj = _.mapValues(_.groupBy(orders, "day_of_week"), d => _.mapValues(_.groupBy(d, "hour"), (t) => _.uniqBy(_.flatten(_.map(t, (d) => _.values(d.customers)))).length ? _.uniqBy(_.flatten(_.map(t, (d) => _.values(d.customers)))).length : _.sumBy(t, "customers")));
        }
    }
    let gridGraphData = [];
    yLabels.forEach(w => {
        let loopTime = gridDataObj[w];
        let tempArray = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        xLabels.forEach(t => {
            if (loopTime !== undefined && loopTime[t] === undefined) {
                tempArray[t] = 0;
            } else if (loopTime !== undefined) {
                tempArray[t] = loopTime[t];
            }
        });
        gridGraphData[w] = tempArray;
    });
    useEffect(() => {
    }, [props])
    return (
        <Box>
            <Box p={!monthCompare ? 2.1 : 1.2} px={3} borderBottom={border}>
                <Grid container alignItems={"center"}>
                    <Grid item xs={7}>
                        <Typography variant={"h4"} style={{fontWeight: 700}}>
                            {capitalCase(property)} by day and hour (UTC)
                        </Typography>
                    </Grid>
                    <Grid item xs={5} style={{textAlign: 'end'}}>
                        {monthCompare ?
                            <Button onClick={(e) => {
                                setGridCompare(!gridCompare);
                                GAEventTracker(CustomerGroup(selectedSeg)().name, 'Day Hour Grid Compare')
                            }} variant={"outlined"} style={{minWidth: 210}}>
                                {!gridCompare ?
                                    <React.Fragment>
                                    <Typography className={'flex'}
                                                style={{alignItems: 'center', textTransform: 'none'}}>
                                        {Icons.toggleIcon}&ensp;Selected Period&ensp;
                                    </Typography>
                                     <Box width={10} height={10}
                                             style={{backgroundColor: theme.palette.secondary.light}}/>
                                    </React.Fragment>
                                    :
                                    <React.Fragment>
                                    <Typography className={'flex'}
                                                style={{alignItems: 'center', textTransform: 'none'}}>
                                        {Icons.toggleIcon}&ensp;Compared Period&ensp;
                                    </Typography>
                                    <Box width={10} height={10}
                                             style={{backgroundColor: theme.palette.primary.main, opacity: 0.5}}/>
                                    </React.Fragment>
                                }
                            </Button>
                            :
                            null}
                    </Grid>
                </Grid>
            </Box>
            {orders.length > 0 ?
                <React.Fragment>
                    <div style={{width: "100%", paddingTop: 50, fontSize: 14, fontFamily: '"Lato", sans-serif'}}>
                        <HeatMap squares
                                 height={21}
                                 xLabelsLocation={"bottom"}
                                 yLabels={yLabelsDisplay}
                                 data={_.values(gridGraphData)}
                                 xLabels={xLabels}
                                 yLabelTextAlign={'center'}
                                 xLabelWidth={20}
                                 xLabelsVisibility={xLabelsVisibility}
                                 unit={property === 'orders' ? 'Order(s)' : property === 'revenue' ? 'Revenue' : 'Customer(s)'}
                                 cellStyle={(background, value, min, max, data, x, y) => ({
                                     background: !gridCompare ? `rgb(255, 135, 53, ${1 - (max - value) / (max - min)})` : `rgb(30, 51, 78, ${1 - (max - value) / (max - min)})`,
                                     fontSize: "14px",
                                     color: "#000",
                                 })}
                                 title={(value, unit) => property === 'revenue' ? `${currencySymbol}${Math.round(value)} ${unit}` : `${value} ${unit}`}
                                 onClick={(x, y) => {
                                     const clickDay = yLabels[y]
                                     if (!gridCompare && gridGraphData[clickDay][x] > 0) {
                                         handleClick('hour', x)
                                         handleClick('day_of_week', yLabels[y])
                                         GAEventTracker(CustomerGroup(selectedSeg)().name, 'Day Hour Grid Graph')
                                     }
                                 }}
                        />
                    </div>
                    <Box pl={5} pt={2} position={'relative'}>
                        <Typography variant={'body2'}>Hours</Typography>
                    </Box>
                </React.Fragment>
                :
                <Box pt={5} style={{alignItems: "center", textAlign: "center"}}>
                    <Typography>No data to display</Typography>
                </Box>
            }
        </Box>
    )
};

const mapStateToProps = (state) => ({
    application_states: state.application_states,
    filterStates: state.filterStates
})
const mapDispatchToProps = (dispatch) => ({
    set_all_group_filter: (filterName, filterValue) => dispatch(set_all_group_filter(filterName, filterValue)),
    set_a_group_filter: (filterName, filterValue) => dispatch(set_a_group_filter(filterName, filterValue)),
    set_b_group_filter: (filterName, filterValue) => dispatch(set_b_group_filter(filterName, filterValue)),
    set_c_group_filter: (filterName, filterValue) => dispatch(set_c_group_filter(filterName, filterValue))
})
export default connect(mapStateToProps, mapDispatchToProps)(DayAndHourGraph)